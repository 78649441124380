import { useAppSelector } from "../../../store";

const AdminComponent = ({ children }: { children: JSX.Element }) => {
  const { current } = useAppSelector((state) => state.userReducer);

  if (!current || !current.admin) {
    return null;
  }

  return <>{children}</>;
};

export default AdminComponent