import ICurveData from "../../../types/curve-data";
import http from "../../http";

const getRoute = (path?: string) => {
  const root = "/curve-data";
  if (!path) return root;
  return root + "/" + path;
};

const curveDataService = {
  update: () => http.post(getRoute()),
  get: () => http.get<{data: ICurveData[]}>(getRoute()),
};

export default curveDataService;
