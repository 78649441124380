import parseToNumberString from "../../../utils/parse-to-number-string";

interface INumberFieldProps {
  value: number;
  type: "money" | "percentage";
  negative?: boolean;
}

const NumberField = ({ value, type, negative = false }: INumberFieldProps) => {
  switch (type) {
    case "money":
      return (
        <span
          style={{
            float: 'right',
            color: negative ? 'red' : '',
          }}>
          {negative && '-'}${parseToNumberString(value)}
        </span>);
    case "percentage":
      return (
        <span
          style={{
            float: 'right',
            color: negative ? 'red' : '',
          }}>
          {negative && '-'}{parseToNumberString(value)}%
        </span>);
    default:
      return null;
  }

}
export default NumberField;