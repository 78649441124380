import { Button, Card, Col, Result, Row } from 'antd';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import { useLocation } from 'react-router';
import useMediaQuery from 'use-media-antd-query';

interface FallbackProps {
  error: Error;
  resetErrorBoundary: (...args: Array<unknown>) => void;
}

const ErrorFallback = ({ error, resetErrorBoundary }: FallbackProps) => {
  const colSize = useMediaQuery();
  const location = useLocation();
  localStorage.setItem('error-boundary-error', JSON.stringify({
    location: location.pathname,
    name: error.name,
    message: error.message,
  }));
  
  return (
    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', height: '100vh' }}>
      <Row gutter={24}>
        <Col offset={!["xs","sm"].includes(colSize) ? 6 : 1} xs={22} md={12}>
          <Card style={{boxShadow: 'none', background: 'transparent'}}>
            <Result
              status="warning"
              title="Oh no, something isn't quite right."
              subTitle={
                <span>
                  Often a simple page refresh can resolve intermittent issues.<br />
                  If the issue persists, please report the issue and we will try to resolve it as quickly as we can.
                </span>}
              icon={
                <img src="images/dino-error.png" style={{ height: '30vh' }} />
              }
              extra={
                <Button type="primary" key="console" onClick={resetErrorBoundary}>
                  Refresh
                </Button>
              }
            />
          </Card>
        </Col>
      </Row>
    </div>
  )
}

const ErrorBoundary: React.FC = ({ children }) => {
  return (
    <ReactErrorBoundary FallbackComponent={ErrorFallback}>
      {children}
    </ReactErrorBoundary>
  )
}

export default ErrorBoundary;