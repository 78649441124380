import { StepAction } from '../../actions/step';

export interface IStepState {
  currentStepId: number;
}

const initialState: IStepState = {
  currentStepId: 1,
};

export const stepReducer = (
  state: IStepState = initialState,
  action: StepAction
): IStepState => {
  switch (action.type) {
    case "UPDATE_STEP_CURRENT_STEP_ID":
      return { ...state, currentStepId: action.payload };
    default:
      return state;
  }
};