import { Card, Col, Row, Table } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { NetCashflowWaterfallDistribution } from "../../../../../types/monthly-proforma/net-cashflow-waterfall-distribution";
import { IAnnualProforma, getYearlyProforma } from "../data-source";
import { dataSource } from "../data-sources";
const { Column } = Table;


type AnnualProformaProps = {
  distribution: NetCashflowWaterfallDistribution;
  startDate: Date;
};

const AnnualProforma: React.FC<AnnualProformaProps> = ({
  distribution,
  startDate,
}) => {
  const [proformaYears, setProformaYears] = useState<IAnnualProforma[]>([]);

  useEffect(() => {
    setProformaYears(getYearlyProforma(distribution.monthlyProforma));
  }, [distribution]);


const getCleanValue = (value: number) => {
  if (value === 0 || !value) return 0;
  const cleanValue = value.toLocaleString("en", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  if (value < 0) {
    return "(" + cleanValue + ")";
  }
  return cleanValue;
};


  const getMonthHeader = (month: number, year: number) => {
    return (
      <div
        style={{ display: "flex", flexDirection: "column", textAlign: "end" }}
      >
        <h6 style={{ margin: 0 }}>
          <i>Year {year}</i>
        </h6>
        <h6 style={{ margin: 0 }}>
          <i>Month {month}</i>
        </h6>
        <h6 style={{ margin: 0 }}>
          <i>
            {moment(startDate).add(month, "M").endOf("M").format("MM/DD/YYYY")}
          </i>
        </h6>
      </div>
    );
  };

  return (
    <>
      <Row gutter={24}>
        <Col span={24}>
          <Table
            scroll={{ x: true, y: 500 }}
            style={{ whiteSpace: "nowrap" }}
            dataSource={dataSource.filter(
              (x) => x.name !== "outstandingLoanBalance" && x.name !== "debtServices"
            )}
            pagination={false}
            bordered
          >
            <Column
            fixed
              title={
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                    textAlign: "end",
                  }}
                >
                  <h6 style={{ margin: 0 }}>
                    <i>Year</i>
                  </h6>
                  <h6 style={{ margin: 0 }}>
                    <i>Month</i>
                  </h6>
                  <h6 style={{ margin: 0 }}>
                    <i>Month Ending</i>
                  </h6>
                </div>
              }
              render={(value, record, index) => {
                //@ts-ignore
                return <span>{record?.title}</span>;
              }}
            />
            {proformaYears.map((year, index) => {
              return (
                <Column
                  key={index}
                  title={getMonthHeader(12 * index, index)}
                  render={(value, record: any, index) => {
                    if (record.titleOnly) {
                      return null;
                    }
                    switch (record.type) {
                      case "acquisitionCost":
                        //@ts-ignore
                        return (
                          <div style={{ textAlign: "end" }}>
                            <span>
                              {
                                //@ts-ignore
                                getCleanValue(year.acquisitionCosts[record.name])
                              }
                            </span>
                          </div>
                        );
                      case "rentalRevenue":
                        //@ts-ignore
                        return (
                          <div style={{ textAlign: "end" }}>
                            <span>
                              {
                                //@ts-ignore
                                getCleanValue(year.rentalRevenue[record.name])
                              }
                            </span>
                          </div>
                        );
                      case "otherIncome":
                        //@ts-ignore
                        return (
                          <div style={{ textAlign: "end" }}>
                            <span>
                              {
                                //@ts-ignore
                                getCleanValue(year.otherIncome[record.name])
                              }
                            </span>
                          </div>
                        );
                      case "effectiveGrossIncome":
                        return (
                          <div style={{ textAlign: "end" }}>
                            <b>
                              {
                                //@ts-ignore
                                getCleanValue(year.effectiveGrossIncomeTotal)
                              }
                            </b>
                          </div>
                        );
                      case "controllableExpense":
                        //@ts-ignore
                        return (
                          <div style={{ textAlign: "end" }}>
                            <span>
                              {
                                //@ts-ignore
                                getCleanValue(year.expenses.controllable[record.name])
                              }
                            </span>
                          </div>
                        );
                      case "nonControllableExpense":
                        //@ts-ignore
                        return (
                          <div style={{ textAlign: "end" }}>
                            <span>
                              {
                                //@ts-ignore
                                getCleanValue(year.expenses.nonControllable[record.name])
                              }
                            </span>
                          </div>
                        );
                      case "expenseTotal":
                        return (
                          <div style={{ textAlign: "end" }}>
                            {record.name === "ratio" ? (
                              <i style={{ opacity: 0.5 }}>
                                {year.expenses.ratio.toFixed(1)}%
                              </i>
                            ) : (
                              <b>{getCleanValue(year.expenses.total)}</b>
                            )}
                          </div>
                        );
                      case "netOperatingIncome":
                        return (
                          <div style={{ textAlign: "end" }}>
                            <b>{getCleanValue(year.netOperatingIncome)}</b>
                          </div>
                        );
                      case "assetManagementFee":
                        return (
                          <div style={{ textAlign: "end" }}>
                            <span>{getCleanValue(year.assetManagementFee)}</span>
                          </div>
                        );
                      case "originalAcquisitionLoan":
                        return (
                          <div style={{ textAlign: "end" }}>
                            <span>
                              {
                                //@ts-ignore
                                getCleanValue(year.financing.originalLoan[record.name])
                              }
                            </span>
                          </div>
                        );
                      case "refinanceLoan":
                        return (
                          <div style={{ textAlign: "end" }}>
                            <span>
                              {
                                //@ts-ignore
                                getCleanValue(year.financing.refinanceLoan[record.name])
                              }
                            </span>
                          </div>
                        );
                      case "dscr":
                        return (
                          <div style={{ textAlign: "end", opacity: 0.5 }}>
                            <i>{year.dscr.toFixed(2)}x</i>
                          </div>
                        );
                      case "netCashFlowAfterDebtService":
                        return (
                          <div style={{ textAlign: "end" }}>
                            <b>{getCleanValue(year.netCashFlowAfterDebtService)}</b>
                          </div>
                        );
                      case "disposition":
                        return (
                          <div style={{ textAlign: "end" }}>
                            <b>
                              {
                                //@ts-ignoreƒ
                                getCleanValue(year.dipositon[record.name])
                              }
                            </b>
                          </div>
                        );
                      case "unleveredCashFlow":
                        return (
                          <div style={{ textAlign: "end" }}>
                            <span>
                              {
                                //@ts-ignore
                                getCleanValue(year.unleveredCashFlow)
                              }
                            </span>
                          </div>
                        );
                      case "leveredCashFlow":
                        return (
                          <div style={{ textAlign: "end" }}>
                            <span>
                              {
                                //@ts-ignore
                                getCleanValue(year.leveredCashFlow)
                              }
                            </span>
                          </div>
                        );
                      default:
                        break;
                    }
                  }}
                />
              );
            })}
          </Table>
        </Col>
      </Row>
    </>
  );
};
export default AnnualProforma;
