import { Table, Input } from "antd";
import { ChangeEvent, useEffect, useState } from "react";
import { IYearOneProformaIncomeExpenseItem } from "..";
import { useAppSelector } from "../../../../../store";
import NumberField from "../../../../shared/number-field";

interface IYearOneProformaRentIncomeAssumptionsProps {
  rentIncomeItems: IYearOneProformaIncomeExpenseItem[];
  totalUnits: number;
}

const YearOneProformaRentIncomeAssumptions = ({
  rentIncomeItems,
  totalUnits,
}: IYearOneProformaRentIncomeAssumptionsProps) => {
  const [yearOneRentIncomeTotals, setYearOneRentIncomeTotals] = useState<{
    grossPotentialRent: number;
    vacancyLoss: number;
    lossToLease: number;
    rentConcessions: number;
    creditLossBadDebt: number;
    nonRevenueUnits: number;
  }>();
  const {
    cashFlowReducer: { distribution },
  } = useAppSelector(({ cashFlowReducer }) => {
    return { cashFlowReducer };
  });

  useEffect(() => {
    if (distribution) {
      setYearOneRentIncomeTotals(distribution.getYearOneRentIncomeTotals());
    }
  }, [distribution]);

  const getYearOneTotal = (index: number) => {
    if (yearOneRentIncomeTotals) {
      const key = Object.keys(yearOneRentIncomeTotals)[index];
      //@ts-ignore
      return yearOneRentIncomeTotals[key];
    }

    return 0;
  };

  const otherIncomeColumns = [
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: "40%",
      render: (_: any, record: any, index: number) => (
        <span>{rentIncomeItems[index].description}</span>
      ),
    },
    {
      title: "T-12 Financials Amount Per Unit",
      dataIndex: "t12FinancialsAmountPerUnit",
      key: "t12FinancialsAmountPerUnit",
      width: "15%",
      render: (_: any, record: any, index: number) => (
        <NumberField
          type="money"
          negative={index > 0 && rentIncomeItems[index].amount != 0}
          value={rentIncomeItems[index].amount / totalUnits}
        />
      ),
    },
    {
      title: "T-12 Financials Amount",
      dataIndex: "t12FinancialsAmount",
      key: "t12FinancialsAmount",
      width: "15%",
      render: (_: any, record: any, index: number) => (
        <NumberField
          type="money"
          negative={index > 0 && rentIncomeItems[index].amount != 0}
          value={rentIncomeItems[index].amount}
        />
      ),
    },
    {
      title: "Year One Amount Per Unit",
      dataIndex: "yearOnePerUnit",
      key: "yearOnePerUnit",
      width: "15%",
      render: (_: any, record: any, index: number) => (
        <NumberField
          type="money"
          negative={index > 0 && rentIncomeItems[index].amount != 0}
          value={Math.abs(getYearOneTotal(index) / totalUnits)}
        />
      ),
    },
    {
      title: "Year One Total",
      dataIndex: "yearOneTotal",
      key: "yearOneTotal",
      width: "15%",
      render: (_: any, record: any, index: number) => (
        <NumberField
          type="money"
          negative={index > 0 && rentIncomeItems[index].amount != 0}
          value={Math.abs(getYearOneTotal(index))}
        />
      ),
    },
  ];

  return (
    <>
      <Table
        columns={otherIncomeColumns}
        dataSource={rentIncomeItems}
        bordered
        pagination={false}
        scroll={{ x: true }}
        style={{ whiteSpace: "nowrap" }}
      />
    </>
  );
};
export default YearOneProformaRentIncomeAssumptions;
