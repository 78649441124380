import React, { lazy, Suspense, useState } from "react";

const ThemeProvider: React.FC = ({ children }) => {
  const [loaded,setLoaded] = useState<boolean>(false);
  const theme = localStorage.getItem("theme") || "night"

  const DarkTheme = lazy(() => new Promise(async resolve => {
    const module = await import("../themes/dark");
    setLoaded(true)
    //@ts-ignore
    resolve(module)
  }));
  
  
  const LightTheme = lazy(() => new Promise(async resolve => {
    const module = await import("../themes/light");
    setLoaded(true)
    //@ts-ignore
    resolve(module)
  }));

  return (
    <>
      <Suspense fallback={null}>
        { theme === "day" ? <LightTheme/> : <DarkTheme/> }
      </Suspense>

      { loaded && children}
    </>
  );
};

export default ThemeProvider;
