interface IAsteriskFieldProps {
  description: string;
  color?: string;
}

const AsteriskField = ({ description, color="red" }: IAsteriskFieldProps) => {

  return <span>{description}<span style={{ color: color }}> *</span></span>

}
export default AsteriskField;