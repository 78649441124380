const formatPhoneNumber = (phoneNumber: number | string | undefined) => {
  if (!phoneNumber) return null;
  var cleaned = ("" + phoneNumber.toString()).replace(/\D/g, "");
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return null;
};

export default formatPhoneNumber;
