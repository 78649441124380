import { UploadFile } from 'antd/lib/upload/interface';
import axios, { AxiosRequestConfig } from 'axios';
import http from '../../http';

export interface IS3File {
  key: string;
  url: string;
  name: string;
  uid: string;
}

const getRoute = (path?: string) => {
  const root = '/s3'
  if (!path) return root;
  return root + '/' + path;
}

const s3Service = {
  download: (prefix: string) => http.post<{files: IS3File[]}>(getRoute(), { prefix: prefix }),
  upload: async (identifier: string, rootPath: string, uploadFile?: UploadFile, file?: File, fileName?: string) => {
    let body = {
        type: uploadFile?.type || file?.type,
        key: rootPath + '/' + identifier + '/' + (fileName || (uploadFile?.name || file?.name)),
      }


    const response = await http.post<{ url: string}>(getRoute('url/'), body);
    http.put(response.data.url, uploadFile?.originFileObj ||  file, { headers: { "Content-Type": uploadFile?.type! || file?.type! } })
  },
  delete: (s3Key: string) => http.delete<string>(getRoute(), { data: { key: s3Key } }),
  getUploadUrl: () => http.get(getRoute('/url'))
}

export default s3Service;
