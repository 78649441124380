import { Table } from "antd";
import Column from "antd/lib/table/Column";
import ColumnGroup from "antd/lib/table/ColumnGroup";
import NumberField from "../../../../shared/number-field";
import { IAnnualNoiCashOnCashSummary } from "../investment-summary-service";

interface IAnnualNoiCashOnCashSummaryProps {
  annualNoiCashOnCashSummary: IAnnualNoiCashOnCashSummary;
}

const AnnualNoiCashOnCashSummary: React.FC<
  IAnnualNoiCashOnCashSummaryProps
> = ({ annualNoiCashOnCashSummary }) => {
  const { years } = annualNoiCashOnCashSummary;

  return (
    <>
      <Table
        title={() => (
          <div style={{ textAlign: "center" }}>
            <b>Annual NOI & Cash-on-Cash Summary</b>
          </div>
        )}
        dataSource={years}
        pagination={false}
        bordered
        style={{ whiteSpace: "nowrap" }}
        scroll={{ x: true }}
      >
        <Column
          title="Year"
          dataIndex="year"
          key="year"
          render={(value, record, index) => {
            return index === years.length - 1 ? null : (
              <span>{years[index].year}</span>
            );
          }}
        />
        <Column
          title="NOI"
          dataIndex="noi"
          key="noi"
          render={(value, record, index) => {
            return <NumberField type="money" value={years[index].noi} />;
          }}
        />
        <Column
          title="Net Operating Project Cash Flow"
          dataIndex="nopcf"
          key="nopcf"
          render={(value, record, index) => {
            return (
              <NumberField
                type="money"
                value={years[index].netOperatingProjetCashFlow}
              />
            );
          }}
        />
        <ColumnGroup title="Cash-on-Cash">
          <Column
            title="Project"
            dataIndex="project"
            key="project"
            render={(value, record, index) => {
              return (
                <NumberField
                  type="percentage"
                  value={years[index].projectCashOnCash}
                />
              );
            }}
          />
          <Column
            title="LP Investor"
            dataIndex="lpInvestor"
            key="lpInvestor"
            render={(value, record, index) => {
              return (
                <NumberField
                  type="percentage"
                  value={years[index].lpInvestorCashOnCash}
                />
              );
            }}
          />
        </ColumnGroup>
      </Table>
    </>
  );
};
export default AnnualNoiCashOnCashSummary;
