import moment from "moment";
// @ts-ignore
import { IPMT, PPMT, PMT } from '@formulajs/formulajs'

export interface IAcquisitionCosts {
  purchasePrice: number;
  acquisitionFeePercentage: number;
  acquisitionFee: number;
  transferTaxesPercentage: number;
  transferTaxes: number;
  loanFees: number;
  dueDilligence: number;
  legalFees: number;
  capExRenovation: number;
  reserves: number;
  otherClosingCostsPercentage: number;
  otherClosingCosts: number;
  debtServiceReservesPAndI: number;
  reTaxReserves: number;
  insuranceReserves: number;
  operatingCashFlowShortfall: number;
}

export interface ILoanAssumptions {
  lender: string;
  date: Date;
  purchasePriceLeveragePercentage: number;
  capExLeveragePercentage: number;
  termYears: number;
  interestRate: number;
  interestOnlyMonths: number;
  amortizationYears: number;
  loanFeesPercentage: number;
  loanFees: number;
  prepaymentPenaltyPercentage: number;
  prepaymentPenalty: number;
  debtServiceReservesPandIMonths: number;
  debtServiceReservesPandIRelase: "yes" | "no";
  debtServiceReservesPandIRelaseTiming: number;
  debtServiceReservesPandITotal: number;
  monthlyDebtService: number;
  annualDebtService: number;
}

export interface IRefinancingPermanentLoanAssumptions {
  refinancing: boolean;
  loanType: "ltv" | "dscr";
  lender: string;
  date: Date;
  month: number;
  year: number;
  interestRate: number;
  interestOnlyMonths: number;
  amortizationYears: number;
  noiTrailing12Months: number;
  capRate: number;
  propertyValuation: number;
  ltvPercentage: number;
  dscrPercentage: number;
  ltvTotal: number;
  dscrTotal: number;
  refinanceLoanAmount: number;
  loanFeesPercentage: number;
  loanFees: number;
  originalLoanRepayment: number;
  distributionProceeds: number;
}

export interface ILoanScheduleMonth {
  year: number;
  month: number;
  date: Date;
  startingBalance: number;
  payment: number;
  principal: number;
  interest: number;
  endingBalance: number;
  totalInterestPaid: number;
  totalPrincipalPaid: number;
}

export interface IEquitySplit {
  gpSponsor: number;
  lpInvestors: number;
}

export interface ILoan {
  loanTotal: number;
  acquisitionCosts: IAcquisitionCosts;
  loanAssumptions: ILoanAssumptions;
  refinancingPermanentLoanAssumptions: IRefinancingPermanentLoanAssumptions;
  acquisitionLoanSchedule: ILoanScheduleMonth[];
  refinancingLoanSchedule: ILoanScheduleMonth[];
  equitySplit: IEquitySplit;
  calculateDynamicAcquisitionCosts(): void;
  calculateLoanSchedule(type: "acquisition" | "refinancing"): void;
  calculateLoanTotal(): void;
  calculateDebtService(): void;
}

export class Loan implements ILoan {
  loanTotal = 0;
  acquisitionCosts: IAcquisitionCosts = {
    purchasePrice: 0,
    acquisitionFeePercentage: 0,
    acquisitionFee: 0,
    transferTaxesPercentage: 0,
    transferTaxes: 0,
    loanFees: 0,
    dueDilligence: 0,
    legalFees: 0,
    capExRenovation: 0,
    reserves: 0,
    otherClosingCostsPercentage: 0,
    otherClosingCosts: 0,
    debtServiceReservesPAndI: 0,
    reTaxReserves: 0,
    insuranceReserves: 0,
    operatingCashFlowShortfall: 0,
  }
  loanAssumptions: ILoanAssumptions = {
    lender: "",
    date: new Date(),
    purchasePriceLeveragePercentage: 0,
    capExLeveragePercentage: 0,
    termYears: 0,
    interestRate: 0,
    interestOnlyMonths: 0,
    amortizationYears: 0,
    loanFeesPercentage: 0,
    loanFees: 0,
    prepaymentPenaltyPercentage: 0,
    prepaymentPenalty: 0,
    debtServiceReservesPandIMonths: 0,
    debtServiceReservesPandIRelase: "yes",
    debtServiceReservesPandIRelaseTiming: 0,
    debtServiceReservesPandITotal: 0,
    monthlyDebtService: 0,
    annualDebtService: 0,
  }
  refinancingPermanentLoanAssumptions: IRefinancingPermanentLoanAssumptions = {
    refinancing: false,
    loanType: "ltv",
    lender: "",
    date: new Date(),
    month: 0,
    year: 0,
    interestRate: 0,
    interestOnlyMonths: 0,
    amortizationYears: 0,
    noiTrailing12Months: 0,
    capRate: 0,
    propertyValuation: 0,
    ltvPercentage: 0,
    dscrPercentage: 0,
    ltvTotal: 0,
    dscrTotal: 0,
    refinanceLoanAmount: 0,
    loanFeesPercentage: 0,
    loanFees: 0,
    originalLoanRepayment: 0,
    distributionProceeds: 0,
  }
  acquisitionLoanSchedule: ILoanScheduleMonth[] = [];
  refinancingLoanSchedule: ILoanScheduleMonth[] = [];

  equitySplit: IEquitySplit = {
    gpSponsor: 0,
    lpInvestors: 0,
  }

  calculateDynamicAcquisitionCosts(): void {
    this._calculateAcquisitionFee();
    this._calculateOtherClosingCosts();
    this._calculateTransferTaxes();
  }

  calculateLoanTotal(): void {
    const purchasePriceLeverage = ((this.loanAssumptions.purchasePriceLeveragePercentage|| 0)/100) * this.acquisitionCosts.purchasePrice;
    const capExLeverage = ((this.loanAssumptions.capExLeveragePercentage || 0)/100) * this.acquisitionCosts.capExRenovation;
    this.loanTotal = purchasePriceLeverage + capExLeverage;
  }

  calculateDebtService(): void {
    const { amortizationYears: amoritizationYears, interestRate, debtServiceReservesPandIMonths} = this.loanAssumptions;
    let interestRateTotal = (interestRate/100)/12;
    let monthlyDebtService = -1 * PMT(interestRateTotal, amoritizationYears * 12, this.loanTotal);
    this.loanAssumptions = {
      ...this.loanAssumptions,
      monthlyDebtService,
      annualDebtService: monthlyDebtService * 12,
      debtServiceReservesPandITotal: monthlyDebtService * debtServiceReservesPandIMonths,
    }
  }

  calculateLoanSchedule(type: "acquisition" | "refinancing"): void {
    let amortizationYears = 0;
    let date = new Date()
    let interestRate = 0;
    let interestOnlyMonths  = 0;
    
    switch (type) {
      case "acquisition":
        amortizationYears = this.loanAssumptions.amortizationYears;
        date = this.loanAssumptions.date;
        interestRate = this.loanAssumptions.interestRate;
        interestOnlyMonths = this.loanAssumptions.interestOnlyMonths;
        break;
      case "refinancing":
        amortizationYears = this.refinancingPermanentLoanAssumptions.amortizationYears;
        date = this.refinancingPermanentLoanAssumptions.date;
        interestRate = this.refinancingPermanentLoanAssumptions.interestRate;
        interestOnlyMonths = this.refinancingPermanentLoanAssumptions.interestOnlyMonths;
        break;
      default:
        break;
    }


    const totalMonths = amortizationYears * 12;
    const loanTotal = type === "acquisition" ? this.loanTotal : this.refinancingPermanentLoanAssumptions.refinanceLoanAmount;
    let schedule: ILoanScheduleMonth[] = [{
      year: 0,
      month: 0,
      date,
      startingBalance: 0,
      payment: 0,
      principal: 0,
      interest: 0,
      endingBalance: loanTotal,
      totalInterestPaid: 0,
      totalPrincipalPaid: 0,
    }];

    let year = 1;
    let paymentDate = moment(date);
    let totalInterestPaid = 0;
    let totalPrinicpalPaid = 0;
    let interestRateTotal = (interestRate/100)/12;
    for (let month = 1; month <= totalMonths; month++) {
      paymentDate = paymentDate.add(1, 'M');
      paymentDate = paymentDate.endOf('month');
      let startingBalance = schedule[month - 1].endingBalance;
      let interest = 0;
      let principal = 0;
      let payment = 0;
      if (month <= interestOnlyMonths) {
        interest = (interestRateTotal * loanTotal);
      } else {
        interest = -1 * IPMT(interestRateTotal, month - interestOnlyMonths, totalMonths, loanTotal);
        principal = -1 * PPMT(interestRateTotal, month - interestOnlyMonths, totalMonths, loanTotal)
      }

      totalInterestPaid += interest;
      totalPrinicpalPaid += principal;
      payment = interest + principal;

      let endingBalance = startingBalance;
      if (month > interestOnlyMonths) {
        endingBalance = endingBalance - principal;
      }

      schedule.push({
        year: year,
        month: month,
        date: paymentDate.toDate(),
        startingBalance: startingBalance,
        payment: payment,
        principal: principal,
        interest: interest,
        endingBalance: endingBalance,
        totalInterestPaid: totalInterestPaid,
        totalPrincipalPaid: totalPrinicpalPaid,
      });

      if (month % 12 === 0) {
        year += 1;
      }
    }

    switch (type) {
      case "acquisition":
        this.acquisitionLoanSchedule = schedule
        break;
      case "refinancing":
        this.refinancingLoanSchedule = schedule;
        break;
      default:
        break;
    }
  }

  private _calculateAcquisitionFee(): void {
    const { acquisitionFeePercentage, purchasePrice } = this.acquisitionCosts;
    this.acquisitionCosts.acquisitionFee = (acquisitionFeePercentage / 100) * purchasePrice;
  }

  private _calculateOtherClosingCosts(): void {
    const { otherClosingCostsPercentage, purchasePrice } = this.acquisitionCosts;
    this.acquisitionCosts.otherClosingCosts = (otherClosingCostsPercentage / 100) * purchasePrice;
  }

  private _calculateTransferTaxes(): void {
    const { transferTaxesPercentage, purchasePrice } = this.acquisitionCosts;
    this.acquisitionCosts.transferTaxes = (transferTaxesPercentage / 100) * purchasePrice;
  }
}

