import { Col, notification, Row } from "antd";
import AnnualNoiCashOnCashSummary from "./annual-noi-cash-on-cash-summary";
import HypotheticalInvestment from "./hypothetial-investment";
import HypotheticalInvestmentCashFlow from "./hypothetical-investment-cash-flow";
import NetReturnsToInvestors from "./net-returns-to-investors";
import OwnershipStructure from "./ownership-structure";
import ProjectLevelReturns from "./project-level-returns";
import PropertySummary from "./property-summary";
import { RootState, useAppDispatch, useAppSelector } from "../../../../store";
import InvestmentOverview from "./investment-overview";
import { useEffect, useState } from "react";
import HypotheticalInvestmentReturnVsHoldPeriod from "./hypothetical-investment-return-vs-hold";
import { SyncOutlined } from "@ant-design/icons";
import propertyService from "../../../../api/services/property";
import { useLocation } from "react-router";
import mapProperty from "../../../../types/property/map-property";
import Button from "antd-button-color";
import {
  getInvestmentSummary,
  downloadSummary,
} from "./investment-summary-service";

const InvestmentSummary = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const {
    property,
    loan,
    distribution: cashFlow,
  } = useAppSelector(
    ({ propertyReducer, loanReducer, cashFlowReducer }: RootState) => {
      const { property } = propertyReducer;
      const { loan } = loanReducer;
      const { distribution } = cashFlowReducer;
      return { property, loan, distribution };
    }
  );
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const updateDistribution = async () => {
      dispatch({
        type: "UPDATE_CASH_FLOW_DISTRIBUTION",
        payload: { property, loan },
      });
    };
    updateDistribution().then(() => {
      if (!property.inProgress) {
        setLoading(false);
        return;
      }
      setTimeout(() => {
        setLoading(false);
        propertyService
          .update({ ...property, inProgress: false, loan })
          .then(() => {
            notification.success({
              message: "Property analyzed succesfully!",
              duration: 5,
              placement: "bottomRight",
            });
            dispatch({
              type: "UPDATE_PROPERTY",
              payload: mapProperty({ ...property, inProgress: false }),
            });
          })
          .catch((err) => {
            notification.error({
              message: "An error was encountered while updating property",
              duration: 5,
              placement: "bottomRight",
            });
            console.error(err);
          });
      }, 1000);
    });
  }, []);

  if (loading) {
    return (
      <div style={{ display: "flex", justifyContent: "center", marginTop: 50 }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <h1>Calculating investment summary</h1>
          <SyncOutlined style={{ marginLeft: 10, fontSize: 35 }} spin />
        </div>
      </div>
    );
  }

  const summary = getInvestmentSummary(property, loan, cashFlow!);
  const {
    propertySummary,
    ownershipStructure,
    projectLevelReturns,
    annualNoiCashOnCashSummary,
    netReturnsToInvestors,
    hypotheticalInvestment,
    hypotheticalInvestmentCashFlow,
    hypotheticalInvestmentReturnVsHoldPeriod,
    investmentOverview,
  } = summary;

  return (
    <>
      <Row
        gutter={24}
        style={{
          marginTop: location.pathname.includes("analyzer") ? "inherit" : -25,
        }}
      >
        <Col span={24}>
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              marginBottom: 10,
            }}
          >
            <Button type="success" onClick={() => downloadSummary(property.name, summary)}>
              Download Summary
            </Button>
          </div>
        </Col>
        <Col xs={24} lg={12} style={{ marginBottom: 25 }}>
          <PropertySummary propertySummary={propertySummary} />
        </Col>
        <Col xs={24} lg={12} style={{ marginBottom: 25 }}>
          <OwnershipStructure ownershipStructure={ownershipStructure} />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} lg={12} style={{ marginBottom: 25 }}>
          <ProjectLevelReturns projectLevelReturns={projectLevelReturns} />
        </Col>
        <Col xs={24} lg={12} style={{ marginBottom: 25 }}>
          <AnnualNoiCashOnCashSummary
            annualNoiCashOnCashSummary={annualNoiCashOnCashSummary}
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} lg={12} style={{ marginBottom: 25 }}>
          <NetReturnsToInvestors
            netReturnsToInvestors={netReturnsToInvestors}
          />
        </Col>
        <Col xs={24} lg={12} style={{ marginBottom: 25 }}>
          <HypotheticalInvestment
            hypotheticalInvestment={hypotheticalInvestment}
          />
        </Col>
      </Row>
      <Row gutter={24} style={{ marginBottom: 25 }}>
        <Col span={24}>
          <HypotheticalInvestmentCashFlow
            hypotheticalInvestmentCashFlow={hypotheticalInvestmentCashFlow}
            cashFlow={cashFlow!}
          />
        </Col>
      </Row>
      <Row gutter={24} style={{ marginBottom: 25 }}>
        <Col span={24}>
          <HypotheticalInvestmentReturnVsHoldPeriod
            hypotheticalInvestmentReturnVsHoldPeriod={
              hypotheticalInvestmentReturnVsHoldPeriod
            }
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={24}>
          <InvestmentOverview investmentOverview={investmentOverview} />
        </Col>
      </Row>
    </>
  );
};
export default InvestmentSummary;
