import { Table, Input } from "antd";
import { ChangeEvent, useEffect, useState } from "react";
import { IYearOneProformaIncomeExpenseItem } from "..";
import NumberField from "../../../../shared/number-field";

interface IYearOneProformaExpensesAssumptionsProps {
  expenseItems: IYearOneProformaIncomeExpenseItem[];
  totalUnits: number;
  onChange(items: IYearOneProformaIncomeExpenseItem[]): void;
}

const YearOneProformaExpensesAssumptions = ({ expenseItems: startingExpemseItems, totalUnits, onChange }: IYearOneProformaExpensesAssumptionsProps) => {
  const [expenseItems, setExpenseItems] = useState<IYearOneProformaIncomeExpenseItem[]>(startingExpemseItems);


  const handleChange = (event: ChangeEvent<HTMLInputElement>, index: number) => {
    let newExpenseItems = [...expenseItems];
    const { target: { value } } = event;
    newExpenseItems[index].yearOneAmount = parseFloat(value);
    setExpenseItems(newExpenseItems);
  };

  useEffect(() => {
    onChange(expenseItems);
    
  }, [expenseItems]);

  const expenseColumns = [
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: '40%',
      render: (_: any, record: any, index: number) =>
        <span>{expenseItems[index].description}</span>
    },
    {
      title: 'T-12 Financials Cost Per Unit',
      dataIndex: 't12FinancialsCostPerUnit',
      key: 't12FinancialsCostPerUnit',
      width: '15%',
      render: (_: any, record: any, index: number) =>
        <NumberField type="money" value={expenseItems[index].amount / totalUnits} />
    },
    {
      title: 'T-12 Financials Cost',
      dataIndex: 't12FinancialsCost',
      key: 't12FinancialsCost',
      width: '15%',
      render: (_: any, record: any, index: number) =>
        <NumberField type="money" value={expenseItems[index].amount} />
    },
    {
      title: 'Year One Cost Per Unit',
      dataIndex: 'yearOnePerUnit',
      key: 'yearOnePerUnit',
      width: '15%',
      render: (_: any, record: any, index: number) =>
        <Input type="number" value={parseFloat(expenseItems[index].yearOneAmount.toFixed(2))} min={0} step={0.01} onChange={e => handleChange(e, index)} />
    },
    {
      title: 'Year One Total',
      dataIndex: 'yearOnePerUnit',
      key: 'yearOnePerUnit',
      width: '15%',
      render: (_: any, record: any, index: number) =>
        <NumberField type="money" value={expenseItems[index].yearOneAmount * totalUnits} />
    },
  ];

  return (
    <>
      <Table
        columns={expenseColumns}
        dataSource={expenseItems}
        bordered
        pagination={false}
        scroll={{x:true}}
        style={{whiteSpace:'nowrap'}}
      />
    </>
  );

}
export default YearOneProformaExpensesAssumptions;