import { useAuth0 } from "@auth0/auth0-react";
import { Routes, Route, Navigate } from "react-router-dom";
import AuthWall from "../components/auth-wall";
import Contacts from "../components/contacts";
import FeatureRequest from "../components/feature-request";
import LayoutMain from "../components/layout-main";
import Profile from "../components/profile";
import Properties from "../components/properties";
import NewProperty from "../components/properties/new";
import PropertyAnalyzer from "../components/property-analyzer";
import InvestmentSummary from "../components/property-analyzer/steps/investment-summary";
import ReportIssue from "../components/report-issue";
import VersionHistory from "../components/version";
import { useAppSelector } from "../store";


const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const { isAuthenticated } = useAuth0();
  const { current } = useAppSelector((state) => state.userReducer);

  if (!isAuthenticated || !current) {
    return <Navigate replace to="/auth" />;
  }

  return <LayoutMain>{children}</LayoutMain>;
};

const RoutesProvider = () => {
  return (
    <Routes>
      <Route path="auth" element={<AuthWall />} />
      <Route path="invitation/:id" element={<AuthWall />} />
      <Route
        path="properties"
        element={
          <RequireAuth>
            <Properties />
          </RequireAuth>
        }
      />
      <Route
        path="new-property"
        element={
          <RequireAuth>
            <NewProperty />
          </RequireAuth>
        }
      />
      {["analyzer", "analyzer/:id"].map((path, key) => (
        <Route
          key={key}
          path={path}
          element={
            <RequireAuth>
              <PropertyAnalyzer />
            </RequireAuth>
          }
        />
      ))}
      <Route
        path="investment-summary/:id"
        element={
          <RequireAuth>
            <InvestmentSummary />
          </RequireAuth>
        }
      ></Route>
      <Route
        path="profile"
        element={
          <RequireAuth>
            <Profile />
          </RequireAuth>
        }
      />
      <Route
        path="contacts"
        element={
          <RequireAuth>
            <Contacts />
          </RequireAuth>
        }
      />
      <Route
        path="feature-request"
        element={
          <RequireAuth>
            <FeatureRequest />
          </RequireAuth>
        }
      />
      <Route
        path="report-issue"
        element={
          <RequireAuth>
            <ReportIssue />
          </RequireAuth>
        }
      />
      <Route
        path="version-history"
        element={
          <RequireAuth>
            <VersionHistory />
          </RequireAuth>
        }
      />
      <Route
        path="*"
        element={
          <RequireAuth>
            <Navigate replace to="/properties" />
          </RequireAuth>
        }
      />
    </Routes>
  );
};

export default RoutesProvider;
