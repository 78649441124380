import IIssue from '../../../types/issue';
import http from '../../http';

const getRoute = (path?: string) => {
  const root = '/issue'
  if (!path) return root;
  return root + '/' + path;
}

const issueService = {
  get: (userId: string) => http.get<{ issues: IIssue[]}>(getRoute(userId)),
  create: (issue: IIssue) => http.post<string>(getRoute(), { issue }),
}

export default issueService;
