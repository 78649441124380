import { Card, Col } from 'antd';
import { useEffect, useState } from 'react';
import CountUp from 'react-countup';
import { BsBuilding, BsDoorOpen, BsRulers, BsConeStriped } from "react-icons/bs";
import './index.css';
import IAnalyzedProperty from '../../../types/analyzed-property';
import useMediaQuery from 'use-media-antd-query';

type PropertyStatisticsProps = {
  inProgress: IAnalyzedProperty[];
  complete: IAnalyzedProperty[];
}

const PropertyStatistics: React.FC<PropertyStatisticsProps> = ({ inProgress, complete }) => {
  const colSize = useMediaQuery();

  if (["xs","sm"].includes(colSize)) {
    return null;
  }

  return (
    <div style={{display:'flex', width: '100%'}} data-tour="property-statistics">
      <Col xs={12} md={6} style={{marginBottom: 10}}>
        <Card className='statistic-card box-shadow'>
          <BsBuilding style={{color:'#a61d24'}} size={30} />
          <h1>Properties Analyzed</h1>
          <span><CountUp duration={1} end={complete.length} /></span>
        </Card>
      </Col>
      <Col xs={12} md={6} style={{marginBottom: 10 }}>
      <Card className='statistic-card box-shadow'>
          <BsDoorOpen style={{color:'#177ddc'}} size={30} />
          <h1>Units</h1>
          <CountUp duration={1} separator=","  end={complete.map(p => p.totalUnits).reduce((a, b) => a + b, 0)} />
        </Card>
      </Col>
      <Col xs={12} md={6} style={{marginBottom: 10 }}>
      <Card className='statistic-card box-shadow'>
          <BsRulers style={{color:'#28a745'}} size={30} />
          <h1>Sq. Ft.</h1>
          <CountUp duration={1} separator="," end={complete.map(p => p.totalSqFt).reduce((a, b) => a + b, 0)} />
        </Card>
      </Col>
      <Col xs={12} md={6} style={{marginBottom: 10 }}>
      <Card className='statistic-card box-shadow'>
          <BsConeStriped style={{color:'#eca52b'}} size={30} />
          <h1>Properties In Progress</h1>
          <span><CountUp duration={1} end={inProgress.length} /></span>
        </Card>
      </Col>
    </div>
  )
};

export default PropertyStatistics;