import IInvitation from '../../../types/invitation';
import http from '../../http';

const getRoute = (path?: string) => {
  const root = '/invitations'
  if (!path) return root;
  return root + '/' + path;
}

const invitationService = {
  get: (invitationId: string) => http.get<{ invitation: IInvitation}>(getRoute(invitationId)),
  submit: (invitations: IInvitation[]) => http.post<string>(getRoute(), { invitations }),
}

export default invitationService;
