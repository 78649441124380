export interface IVersionNote {
  mainNote: string;
  secondaryNotes: string[]
}

export interface IVersion {
  number: string;
  features?: IVersionNote[];
  bugFixes?: IVersionNote[];
  other?: IVersionNote[];
  date: Date;
}

const versions: IVersion[] =[
  {
    number: 'Beta',
    features: [
      {
        mainNote: 'Guided Tutorial',
        secondaryNotes:[
          'Added a guided tour to walk users through the application',
        ]
      },
      {
        mainNote: 'Property Creation Changes',
        secondaryNotes:[
          'Properties can now be created using a number of methods',
        ]
      },
      {
        mainNote: 'Theming',
        secondaryNotes:[
          'Users can now toggle between two themes: day and night',
        ]
      },
      {
        mainNote: 'User feedback, requests and issue reporting',
        secondaryNotes:[
          'Feedback can now be provided by users',
          'USers can now both report issues and request features throughout the application',
        ]
      },
      {
        mainNote: 'Downloadable properties',
        secondaryNotes:[
          'Users can generate a file for their respective properties',
        ]
      },
    ],
    bugFixes: [
      {
        mainNote: 'Mobile design enhancements',
        secondaryNotes: [
          'Application changes make the user experience much more enjoyable on a mobile device'
        ]
      },
      {
        mainNote: 'Property evaluation changes',
        secondaryNotes: [
          'Addressed issues where calculations were incorrect at different stages of the property analyzation process'
        ]
      }
    ],
    other: [
      {
        mainNote: 'As the beta continues, more features will be implemented and bugs addressed.',
        secondaryNotes: [] }
      
    ],
    date: new Date(2022, 4, 1),
  },
  {
    number: '0.1.3',
    features: [
      {
        mainNote: 'Analyzer updates',
        secondaryNotes:[
          'Added a monthly proforma viewer',
          'Properties can now have images uploaded',
          'Location service provides a city, state and county for a provided zip code',
        ]
      },
    ],
    bugFixes: [
      {
        mainNote: 'Mobile design enhancements',
        secondaryNotes: [
          'Application changes make the user experience much more enjoyable on a mobile device'
        ]
      },
      {
        mainNote: 'Property evaluation changes',
        secondaryNotes: [
          'Addressed issues where calculations were incorrect at different stages of the property analyzation process'
        ]
      }
    ],
    date: new Date(2022, 3, 8),
  },
  {
    number: '0.1.2',
    features: [
      {
        mainNote: 'Analyzer updates',
        secondaryNotes:[
          'Auto-save implemented',
          'Properties are now categorized into two distinct buckets: In Progress and Analyzed',
          'Users can now access property investment summaries directly from the home page',
        ]
      },
    ],
    bugFixes: [
      {
        mainNote: 'Mobile design enhancements',
        secondaryNotes: [
          'Addressed issues on mobile where information would be inaccessible',
          'Continued improvements for application when viewed on devices with varying screen sizes',
        ]
      },
      {
        mainNote: 'Routing issues',
        secondaryNotes: [
          'Fixed issues when a user would manipulate the URL to an invalid route'
        ]
      },
    ],
    date: new Date(2022, 2, 28),
  },
  {
    number: '0.1.1',
    features: [
      {
        mainNote: 'Authentication',
        secondaryNotes:[
          'Authenticate via Google or other email using Auth0',
          'Access limited to those with Beta access code',
          'Ability to save, edit and delete properties',
        ]
      },
      {
        mainNote: 'User profile maintenance',
        secondaryNotes:[
          'Users can modify their personal details via the profile page',
        ]
      }
    ],
    date: new Date(2022, 2, 17),
  },
  {
    number: '0.1.0',
    other: [
      {
        mainNote: 'The 7F Multifamily Analyzer application build has begun!  Stay tuned for updates.',
        secondaryNotes: [] }
      
    ],
    date: new Date(2022, 2, 15),
  },

]

export default versions;