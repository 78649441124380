import { useAuth0 } from "@auth0/auth0-react";
import { Form, Row, Image } from "antd";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import userService from "../../api/services/user";
import { useAppDispatch } from "../../store";
import { User } from "../../types/user";
import ButtonColor from "antd-button-color";
import pj from "../../../package.json";
import DisclaimerModal from "../modals/disclaimer";
import Spinner from "../shared/spinner";
import invitationService from "../../api/services/invitation";
import IInvitation from "../../types/invitation";

const AuthWall = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isLoading, isAuthenticated, loginWithRedirect, user, logout } =
    useAuth0();
  const [showDisclaimer, setShowDisclaimer] = useState<boolean>(false);
  const [invitation, setInvitation] = useState<IInvitation>();

  useEffect(() => {
    if (user) {
      userService
        .verify(user)
        .then(async (res) => {
          const user = new User(res.data.user);
          await user.getAvatarFromS3();
          const currentTheme = localStorage.getItem("theme");
          localStorage.setItem("theme", user.theme!);
          if (currentTheme !== user.theme) {
            window.location.reload();
          }
          await dispatch({ type: "UPDATE_USER_CURRENT_USER", payload: user });
          navigate("/properties");
        })
        .catch((err) => {
          console.log(err);
          logout();
          navigate("/");
        });
    }
  }, [user]);

  useEffect(() => {
    if (location.pathname.includes("invitation")) {
      const invitationId = location.pathname.split("/")[2];
      invitationService
        .get(invitationId)
        .then((res) => {
          if (res.data.invitation) {
            setInvitation(res.data.invitation);
          } else {
            navigate("/auth");
          }
        })
        .catch((err) => {
          console.log(err);
          navigate("/auth");
        });
    }
  }, [location]);

  const handleLogin = () => {
    localStorage.setItem("hardlogout", "no");
    loginWithRedirect({ scope: "profile user_metadata" });
  };

  const handleRegister = () => {
    localStorage.setItem("hardlogout", "no");
    loginWithRedirect({
      screen_hint: "signup",
      email: invitation?.to.email,
      firstName: invitation?.to.firstName,
      lastName: invitation?.to.lastName,
    });
  };

  if (user || isLoading || isAuthenticated) {
    return (
      <Row
        style={{
          height: "100vh",
          flexDirection: "column",
          backgroundColor: "black",
        }}
        justify="center"
        align="middle"
      >
        <Spinner size="large" />
      </Row>
    );
  }

  return (
    <Row
      style={{
        height: "100vh",
        flexDirection: "column",
        backgroundColor: "black",
      }}
      justify="center"
      align="middle"
    >
      <Image
        src="images/logo.png"
        preview={false}
        style={{ marginBottom: 50, padding: "0 10%" }}
      />
      {invitation && (
        <h3 style={{ marginBottom: 50 }}>
          Welcome, {invitation.to.firstName + " " + invitation.to.lastName}!
        </h3>
      )}
      <Form layout="vertical">
        <Form.Item>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <ButtonColor
              style={{ width: "100%", minWidth: 150 }}
              size="large"
              type="success"
              onClick={() =>
                location.pathname.includes("invitation")
                  ? handleRegister()
                  : handleLogin()
              }
            >
              {location.pathname.includes("invitation") ? "Register" : "Login"}
            </ButtonColor>
            <h5
              style={{ marginTop: 15, cursor: "pointer", color: "#568db1" }}
              onClick={() => setShowDisclaimer(true)}
            >
              Disclaimer
            </h5>
          </div>
        </Form.Item>
      </Form>
      <div
        style={{
          fontSize: 10,
          position: "absolute",
          left: 10,
          bottom: 10,
        }}
      >
        <span style={{ color: "lightgray" }}>
          &copy; 7 Figure Flipping 2022. All Rights Reserved.
        </span>
      </div>
      <div style={{ position: "absolute", bottom: 10, right: 15 }}>
        <span>{pj.beta ? `v1.0.0` : `v${pj.version}`}</span>
      </div>
      <DisclaimerModal
        visible={showDisclaimer}
        onDismiss={() => setShowDisclaimer(false)}
      />
    </Row>
  );
};

export default AuthWall;
