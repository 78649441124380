import { Card, Col, Row, Select, Table } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { IMonthlyProforma } from "../../../../../types/monthly-proforma";
import { NetCashflowWaterfallDistribution } from "../../../../../types/monthly-proforma/net-cashflow-waterfall-distribution";
import {
  getAcquisitionCost,
  getRentalRevenueItem,
  getOtherIncomeItem,
  getEffectiveGrossIncome,
  getControllableExpense,
  getNonControllableExpense,
  getExpenseTotal,
  getNetOperatingIncome,
  getAssetManagementFee,
  getOriginalAcquisitionLoan,
  getRefinanceLoan,
  getDscr,
  getNetCashFlowAfterDebtService,
  getDisposition,
  getUnleveredCashFlow,
  getLeveredCashFlow,
} from "../data-source";
import { dataSource } from "../data-sources";
const { Option } = Select;
const { Column } = Table;

interface IYear {
  year: number;
  months: number[];
}

type MonthlyProformaViewerProps = {
  distribution: NetCashflowWaterfallDistribution;
  startDate: Date;
};

const MonthlyProformaViewer: React.FC<MonthlyProformaViewerProps> = ({
  distribution,
  startDate,
}) => {
  const [years, setYears] = useState<IYear[]>([]);
  const [selectedYear, setSelectedYear] = useState<number>(0);
  const [proformaMonths, setProformaMonths] = useState<IMonthlyProforma[]>([]);

  const getMonthHeader = (month: number, year: number) => {
    return (
      <div
        style={{ display: "flex", flexDirection: "column", textAlign: "end" }}
      >
        <h6 style={{ margin: 0 }}>
          <i>Year {year}</i>
        </h6>
        <h6 style={{ margin: 0 }}>
          <i>Month {month}</i>
        </h6>
        <h6 style={{ margin: 0 }}>
          <i>
            {moment(startDate).add(month, "M").endOf("M").format("MM/DD/YYYY")}
          </i>
        </h6>
      </div>
    );
  };

  useEffect(() => {
    if (distribution) {
      const { monthlyProforma } = distribution;
      let years: IYear[] = [];
      const proformaYears = new Set(monthlyProforma.map((m) => m.year));
      proformaYears.forEach((year, yearIndex) => {
        let newYear: IYear = {
          year,
          months: [],
        };
        monthlyProforma.forEach((month, monthIndex) => {
          if (year === 1 && monthIndex === 0) {
            newYear.months.push(0);
          }

          if (year !== 0 && month.year === newYear.year) {
            newYear.months.push(month.month);
          }
        });
        if (year !== 0) {
          years[yearIndex] = newYear;
        }
      });
      years.unshift();
      setYears(years);
      setSelectedYear(1);
    }
  }, [distribution]);

  useEffect(() => {
    if (!isNaN(selectedYear) && selectedYear >= 0 && distribution) {
      const { monthlyProforma } = distribution;
      let months: IMonthlyProforma[] = [];
      if (selectedYear === 1) {
        months.push(monthlyProforma.filter((m) => m.month === 0)[0]);
      }
      months = months.concat(
        monthlyProforma.filter((m) => m.year === selectedYear)
      );
      setProformaMonths(months);
    }
  }, [selectedYear]);

  const getCleanValue = (value: number | undefined) => {
    if (value === 0 || !value) return 0;
    const cleanValue = value.toLocaleString("en", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
    if (value < 0) {
      return "(" + cleanValue + ")";
    }
    return cleanValue;
  };

  return (
    <>
      <Row gutter={24}>
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={6}
          xl={6}
          xxl={6}
          style={{ marginBottom: 25 }}
        >
          <Select
            defaultValue={1}
            placeholder="Select year"
            style={{ width: 200 }}
            onChange={(year) => setSelectedYear(year)}
          >
            {years.map((year) => {
              return <Option value={year.year}>Year {year.year}</Option>;
            })}
          </Select>
        </Col>
        <Col span={24}>
          <Table
            scroll={{ x: true, y: 500 }}
            style={{ whiteSpace: "nowrap" }}
            dataSource={dataSource}
            pagination={false}
            bordered
          >
            <Column
              fixed
              title={
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                    textAlign: "end",
                  }}
                >
                  <h6 style={{ margin: 0 }}>
                    <i>Year</i>
                  </h6>
                  <h6 style={{ margin: 0 }}>
                    <i>Month</i>
                  </h6>
                  <h6 style={{ margin: 0 }}>
                    <i>Month Ending</i>
                  </h6>
                </div>
              }
              render={(value, record, index) => {
                //@ts-ignore
                return <span>{record?.title}</span>;
              }}
            />
            {proformaMonths.map((month, index) => {
              return (
                <Column
                  key={index}
                  title={getMonthHeader(month.month, month.year)}
                  render={(value, record: any, index) => {
                    if (record.titleOnly) {
                      return null;
                    }
                    switch (record.type) {
                      case "acquisitionCost":
                        return (
                          <div style={{ textAlign: "end" }}>
                            <span>
                              {getCleanValue(getAcquisitionCost(month, record.name))}
                            </span>
                          </div>
                        );
                      case "rentalRevenue":
                        return (
                          <div style={{ textAlign: "end" }}>
                            <span>
                              {getCleanValue(getRentalRevenueItem(month, record.name))}
                            </span>
                          </div>
                        );
                      case "otherIncome":
                        return (
                          <div style={{ textAlign: "end" }}>
                            <span>
                              {getCleanValue(getOtherIncomeItem(month, record.name))}
                            </span>
                          </div>
                        );
                      case "effectiveGrossIncome":
                        return (
                          <div style={{ textAlign: "end" }}>
                            <b>{getCleanValue(getEffectiveGrossIncome(month, record.name))}</b>
                          </div>
                        );
                      case "controllableExpense":
                        return (
                          <div style={{ textAlign: "end" }}>
                            <span>
                              {getCleanValue(getControllableExpense(month, record.name))}
                            </span>
                          </div>
                        );
                      case "nonControllableExpense":
                        return (
                          <div style={{ textAlign: "end" }}>
                            <span>
                              {getCleanValue(getNonControllableExpense(month, record.name))}
                            </span>
                          </div>
                        );
                      case "expenseTotal":
                        return (
                          <div style={{ textAlign: "end" }}>
                            {record.name === "ratio" ? (
                              <i style={{ opacity: 0.5 }}>
                                {getCleanValue(getExpenseTotal(month, record.name))}
                              </i>
                            ) : (
                              <b>{getCleanValue(getExpenseTotal(month, record.name))}</b>
                            )}
                          </div>
                        );
                      case "netOperatingIncome":
                        return (
                          <div style={{ textAlign: "end" }}>
                            <b>{getCleanValue(getNetOperatingIncome(month, record.name))}</b>
                          </div>
                        );
                      case "assetManagementFee":
                        return (
                          <div style={{ textAlign: "end" }}>
                            <span>
                              {getCleanValue(getAssetManagementFee(month, record.name))}
                            </span>
                          </div>
                        );
                      case "originalAcquisitionLoan":
                        return (
                          <div style={{ textAlign: "end" }}>
                            {record.name === "outstandingLoanBalance" ? (
                              <i style={{ opacity: 0.5 }}>
                                {getCleanValue(getOriginalAcquisitionLoan(month, record.name))}
                              </i>
                            ) : (
                              <span>
                                {getCleanValue(getOriginalAcquisitionLoan(month, record.name))}
                              </span>
                            )}
                          </div>
                        );
                      case "refinanceLoan":
                        return (
                          <div style={{ textAlign: "end" }}>
                            {record.name === "outstandingLoanBalance" ? (
                              <i style={{ opacity: 0.5 }}>
                                {getCleanValue(getRefinanceLoan(month, record.name))}
                              </i>
                            ) : (
                              <span>
                                {getCleanValue(getRefinanceLoan(month, record.name))}
                              </span>
                            )}
                          </div>
                        );
                      case "dscr":
                        return (
                          <div style={{ textAlign: "end", opacity: 0.5 }}>
                            <i>{getDscr(month, record.name).toFixed(2)}x</i>
                          </div>
                        );
                      case "netCashFlowAfterDebtService":
                        return (
                          <div style={{ textAlign: "end" }}>
                            <b>
                              {getCleanValue(getNetCashFlowAfterDebtService(
                                month,
                                record.name
                              ))}
                            </b>
                          </div>
                        );
                      case "disposition":
                        return (
                          <div style={{ textAlign: "end" }}>
                            <b>{getCleanValue(getDisposition(month, record.name))}</b>
                          </div>
                        );
                      case "unleveredCashFlow":
                        return (
                          <div style={{ textAlign: "end" }}>
                            <span>
                              {getCleanValue(getUnleveredCashFlow(month, record.name))}
                            </span>
                          </div>
                        );
                      case "leveredCashFlow":
                        return (
                          <div style={{ textAlign: "end" }}>
                            <span>
                              {getCleanValue(getLeveredCashFlow(month, record.name))}
                            </span>
                          </div>
                        );
                      default:
                        break;
                    }
                  }}
                />
              );
            })}
          </Table>
        </Col>
      </Row>
    </>
  );
};
export default MonthlyProformaViewer;
