import { Row, Col, Card } from 'antd';
import { useEffect, useState } from 'react';
import s3Service, { IS3File } from '../../../../../api/services/s3';
import ImageUploader from '../../../../shared/image-uploader';
import { IInvestmentOverview } from '../investment-summary-service';

interface IInvestmentOverviewProps {
  investmentOverview: IInvestmentOverview;
}

const InvestmentOverview: React.FC<IInvestmentOverviewProps> = ({ investmentOverview }) => {
  const {  propertyId, overview, marketInformationAndCurrentStatus } = investmentOverview;
  const [images, setImages] = useState<IS3File[]>([]);

  useEffect(() => {
    if (propertyId)
      s3Service.download(`property/${propertyId}`)
        .then(res => {
          if (res.data.files) {
            setImages(res.data.files)
          }
        })
        .catch(err => console.log(err));
  }, [])

  return (
    <Row gutter={24}>
      {overview ? (
        <Col xs={24} sm={8} style={{ marginBottom: 25 }}>
          <Card title={<b style={{ fontSize: 14 }}>Investment Overview</b>}>
            {overview}
          </Card>
        </Col>
      ) : null}
      {marketInformationAndCurrentStatus ? (
        <Col xs={24} sm={8} style={{ marginBottom: 25 }}>
          <Card title={<b style={{ fontSize: 14 }}>Market Information & Current Status</b>}>
            {marketInformationAndCurrentStatus}
          </Card>
        </Col>
      ) : null}
      {images.length > 0 ? (
        <Col xs={24} sm={8}>
          <Card title={<b style={{ fontSize: 14 }}>Images</b>}>
            <ImageUploader viewOnly propertyId={''} images={images} />
          </Card>
        </Col>
      ) : null}
    </Row>
  )
}
export default InvestmentOverview;