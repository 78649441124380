import { Card, Col, Form, Input, notification, Row, Select } from "antd";
import { useState, useEffect } from "react";
import { useAppDispatch } from "../../../store";
import ButtonColor from "antd-button-color";
import { GiBulldozer, GiFamilyHouse, GiNewspaper } from "react-icons/gi";
import NewPropertyCard from "./new-property-card.styled";
import IAnalyzedProperty from "../../../types/analyzed-property";
import useMediaQuery from "use-media-antd-query";
import propertyService from "../../../api/services/property";
import { ILoan } from "../../../types/loan";
import { IProperty } from "../../../types/property";
import mapProperty from "../../../types/property/map-property";
import { useNavigate } from "react-router";
import Spinner from "../../shared/spinner";
import generateDefaultProperty from "../../property-analyzer/templates/default/property";
import generateDefaultLoan from "../../property-analyzer/templates/default/loan";
import { useTour } from "@reactour/tour";
import PropertyUploader from "./property-uploader";
import generateEmptyProperty from "../../property-analyzer/templates/empty/property";
import { generateEmptyLoan } from "../../property-analyzer/templates/empty/loan";
import mapLoan from "../../../types/loan/map-loan";
const { Option } = Select;

const getPropertyOption = (property: IAnalyzedProperty) => {
  const { name, streetAddress, city, state, zipCode } = property;
  let formattedStreetAddress = streetAddress ? ` ${streetAddress}` : "";
  let formattedCity = city ? ` ${city}` : "";
  let formattedCityStateZip = formattedCity
    ? `${city}, ${state} ${zipCode}`
    : ` ${state} ${zipCode}`;
  return (
    (name ? name + " - " : "") +
    formattedStreetAddress +
    " " +
    formattedCityStateZip
  );
};

type NewPropertyMethod = "template" | "scratch" | "existing" | "upload";

const iconSizes = {
  xs: 35,
  sm: 35,
  md: 35,
  lg: 40,
  xl: 50,
  xxl: 50,
};

const NewProperty: React.FC = () => {
  const { isOpen } = useTour();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [uploadedProperty, setUploadedProperty] = useState<IAnalyzedProperty>();
  const [properties, setProperties] = useState<IAnalyzedProperty[]>([]);
  const [newPropertyMethod, setNewPropertyMethod] =
    useState<NewPropertyMethod>("template");
  const colSize = useMediaQuery();
  const [form] = Form.useForm();
  const [disableCreate, setDisableCreate] = useState(true);

  const handleFormChange = () => {
    setDisableCreate(
      !form.getFieldValue("propertyName") ||
        (newPropertyMethod === "existing" &&
          !form.getFieldValue("propertyId")) ||
        (newPropertyMethod === "upload" && !uploadedProperty)
    );
  };

  useEffect(() => {
    handleFormChange();
  }, [uploadedProperty]);

  useEffect(() => {
    setLoading(true);
    propertyService
      .get()
      .then((res) => {
        setProperties(res.data.complete);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  const handleCancel = () => {
    navigate(`/properties/`);
  };

  const handleCreate = () => {
    let newProperty: IAnalyzedProperty;
    let property: IProperty;
    let loan: ILoan;
    const { propertyName, propertyId } = form.getFieldsValue();
    switch (newPropertyMethod) {
      case "template":
        property = generateDefaultProperty();
        loan = generateDefaultLoan();
        property.name = propertyName;
        newProperty = { ...property, loan };
        break;
      case "scratch":
        property = generateEmptyProperty();
        loan = generateEmptyLoan();
        property.name = propertyName;
        newProperty = { ...property, loan };
        break;
      case "existing":
        property = properties.find((property) => property._id === propertyId)!;
        loan = properties.find((property) => property._id === propertyId)!.loan;
        property.name = propertyName;
        newProperty = { ...property, loan };
        delete newProperty._id;
        break;
      case "upload":
        property = uploadedProperty!;
        loan = uploadedProperty!.loan;
        property.name = propertyName;
        newProperty = { ...property, loan };
        delete newProperty._id;
        break;
      default:
        break;
    }

    propertyService
    //@ts-ignore
      .create(newProperty)
      .then((res) => {
        dispatch({
          type: "UPDATE_PROPERTY",
          payload: mapProperty(res.data.property),
        });
        dispatch({
          type: "UPDATE_LOAN",
          payload: mapLoan(res.data.property.loan),
        });
        navigate(`/analyzer/${res.data.property._id}`);
      })
      .catch((err) => {
        notification.error({
          message: "An error was encountered while creating property",
          duration: 5,
          placement: "bottomRight",
        });
      });
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner size="large" />
      </div>
    );
  }

  return (
    <Card style={{marginTop: -20}}>
      <Row gutter={24} style={{ textAlign: "center", marginBottom: 25 }}>
        <Col span={24} data-tour="choose-build-method">
          <h3>Choose build method:</h3>
        </Col>
      </Row>
      <Row
        data-tour="property-build-methods"
        gutter={24}
        style={{ marginBottom: 25 }}
      >
        <Col xs={24} md={6} offset={["xs", "sm"].includes(colSize) ? 0 : 3}>
          <NewPropertyCard
            onClick={() => setNewPropertyMethod("template")}
            active={
              ((!isOpen as boolean) && newPropertyMethod === "template") ||
              (isOpen as boolean)
            }
            color="#177ddc"
            className="box-shadow"
          >
            <h4>Template</h4>
            <GiNewspaper size={iconSizes[colSize]} />
            <h4>
              Property will be initialized with sample data populated. <br />{" "}
              This is the ideal build method for a new user.
            </h4>
          </NewPropertyCard>
        </Col>
        <Col xs={24} md={6}>
          <NewPropertyCard
            onClick={() => setNewPropertyMethod("scratch")}
            active={
              ((!isOpen as boolean) && newPropertyMethod === "scratch") ||
              (isOpen as boolean)
            }
            color="#eca52b"
            className="box-shadow"
          >
            <h4>Scratch</h4>
            <GiBulldozer size={iconSizes[colSize]} />
            <h4>
              Property will be initialized with a completely empty state. <br />{" "}
              This build method <i>should</i> be utilized by users with previous
              experience analyzing properties.
            </h4>
          </NewPropertyCard>
        </Col>
        <Col xs={24} md={6}>
          <NewPropertyCard
            onClick={() => setNewPropertyMethod("existing")}
            active={
              ((!isOpen as boolean) && newPropertyMethod === "existing") ||
              (isOpen as boolean)
            }
            color="#28a745"
            className="box-shadow"
          >
            <h4>Existing</h4>
            <GiFamilyHouse size={iconSizes[colSize]} />
            <h4>
              Property will be initialized with a previously analyzed property
              (chosen below):
            </h4>
          </NewPropertyCard>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col
          offset={["xs", "sm"].includes(colSize) ? 0 : 6}
          xs={24}
          md={12}
          style={{ textAlign: "center" }}
        >
          <Card>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 25,
              }}
            >
              <Form
                layout="vertical"
                style={{ width: "50%" }}
                form={form}
                initialValues={{
                  propertyName: undefined,
                  propertyId: undefined,
                }}
                onFieldsChange={handleFormChange}
              >
                {newPropertyMethod === "upload" ? (
                  <PropertyUploader onPropertyUploaded={setUploadedProperty} />
                ) : null}
                <Form.Item
                  label="Property Name"
                  labelAlign="left"
                  name="propertyName"
                  rules={[
                    { required: true, message: "Please enter property name" },
                  ]}
                >
                  <Input type="text" placeholder="Property Name" />
                </Form.Item>
                {newPropertyMethod === "existing" ? (
                  <Form.Item
                    label="Property Source"
                    name="propertyId"
                    rules={[
                      { required: true, message: "Please select a property" },
                    ]}
                  >
                    <Select placeholder="Select a property">
                      {properties.map((property) => (
                        <Option key={property._id} value={property._id}>
                          {getPropertyOption(property)}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                ) : null}
                <Form.Item
                  style={{ marginTop: 25 }}
                  data-tour="create-property"
                >
                  <ButtonColor
                    onClick={handleCreate}
                    type="success"
                    disabled={!isOpen && disableCreate}
                  >
                    Create
                  </ButtonColor>
                  <ButtonColor
                    onClick={handleCancel}
                    type="lightdark"
                    style={{ marginLeft: 10 }}
                  >
                    Cancel
                  </ButtonColor>
                </Form.Item>
              </Form>
            </div>
          </Card>
        </Col>
      </Row>
    </Card>
  );
};

export default NewProperty;
