import { Input, Table } from "antd";
import React, { ReactElement, useEffect, useState } from "react";
import { IIncomeExpenseItem } from "../../../../../types/property";
import AsteriskField from "../../../../shared/asterisk-field";
import NumberField from "../../../../shared/number-field";

interface IProformaExpenseReconciliationRentIncomeTableProps {
  currentItems: IIncomeExpenseItem[];
  totalUnits: number;
  grossPotentialRent: number;
  onItemsChanged(items: IIncomeExpenseItem[]): void
}

interface IRentIncomeItem extends IIncomeExpenseItem {
  view: string | ReactElement;
}

const ProformaExpenseReconciliationRentIncomeTable = ({ currentItems, totalUnits, grossPotentialRent, onItemsChanged }: IProformaExpenseReconciliationRentIncomeTableProps) => {
  const [gPr, setGpr] = useState<number>(grossPotentialRent);
  const [items, setItems] = useState<IRentIncomeItem[]>(currentItems.map((item, i) => {
    return {
      ...item,
      view: i === 0 ? <span>{item.description}</span> : i === currentItems.length - 1 ? <b>{item.description}</b> : <AsteriskField description={item.description} />,
    }
  }));

  const handleRentItemChange = (event: React.ChangeEvent<HTMLInputElement>, index: number, field: string) => {
    const { target: { value } } = event;
    let newRentIncomeItems = [...items];
    if (!isNaN(parseFloat(value))) {
      if (index === 0 && field === "amount") {
        setGpr(parseFloat(value));
      }
      newRentIncomeItems[index] = {
        ...newRentIncomeItems[index],
        [field]: parseFloat(value),
      }

      const netRentalIncome = newRentIncomeItems[0].amount - newRentIncomeItems
        .map((x, index) => index > 0 && index !== newRentIncomeItems.length - 1 ? x.amount : 0)
        .reduce((prev, curr) => prev + curr, 0);

      newRentIncomeItems[newRentIncomeItems.length - 1].amount = netRentalIncome;

      setItems(newRentIncomeItems);
    } else {
      newRentIncomeItems[index].amount = 0
      setItems(newRentIncomeItems);
    }
  }

  const rentIncomeColumns = [
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: '40%',
      render: (_: any, record: any, index: number) => <span style={{ whiteSpace: 'nowrap' }}>{items[index].view}</span>,
    },
    {
      title: 'T-12 Financials',
      dataIndex: 'amount',
      key: 'amount',
      width: '15%',
      render: (_: any, record: any, index: number) =>
        index === items.length - 1 ? <b><NumberField value={items[index].amount} type="money" /></b> :
          <Input
            style={{ minWidth: 100 }}
            type="number"
            min={0}
            placeholder="Amount"
            value={items[index].amount}
            onChange={e => handleRentItemChange(e, index, "amount")}
          />
    },
    {
      title: '% of GPR / EGI',
      dataIndex: 'percentOfGprEgi',
      key: 'percentOfGprEgi',
      width: '15%',
      render: (_: any, record: any, index: number) =>
        index === items.length - 1 ? <b><NumberField value={(items[index].amount / gPr) * 100} type="percentage" /></b> :
          index === 0 ? null : <div><NumberField negative={gPr > 0 && items[index].amount > 0} value={gPr <= 0 ? 0 : (items[index].amount / gPr) * 100} type="percentage" /></div>
    },
    {
      title: '$ / Month',
      dataIndex: 'costPerMonth',
      key: 'costPerMonth',
      width: '15%',
      render: (_: any, record: any, index: number) =>
        index === items.length - 1 ? <b><NumberField value={items[index].amount / 12} type="money" /></b> :
          <NumberField negative={index !== 0 && items[index].amount > 0} value={items[index].amount / 12} type="money" />
    },
    {
      title: '$ / Unit',
      dataIndex: 'costPerUnit',
      key: 'costPerUnit',
      width: '15%',
      render: (_: any, record: any, index: number) =>
        index === items.length - 1 ? <b><NumberField value={items[index].amount / totalUnits} type="money" /></b> :
          <NumberField negative={index !== 0 && items[index].amount > 0} value={items[index].amount / totalUnits} type="money" />
    },
  ];

  useEffect(() => {
    onItemsChanged(items.map(i => {
      return {
        description: i.description,
        name: i.name,
        amount: i.amount,
      }
    }));
  }, [items]);

  return (
    <>
      <Table
        style={{ whiteSpace: 'nowrap' }}
        columns={rentIncomeColumns}
        dataSource={items}
        pagination={false}
        bordered
        scroll={{ x: true }}
        size="small"
      />
      <h1 style={{ textAlign: 'start', marginTop: 10, fontSize: 12 }}><span style={{ color: 'red' }}>*</span> - field is calculated as a negative amount</h1>
    </>
  )

}
export default ProformaExpenseReconciliationRentIncomeTable;