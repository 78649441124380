import { Loan } from "../../../../types/loan";

export const generateDefaultLoan = (): Loan  => {
  let testLoan = new Loan();
  testLoan.acquisitionCosts = {
    ...testLoan.acquisitionCosts,
    purchasePrice: 9200000,
    acquisitionFeePercentage: 2,
    acquisitionFee: 184000,
    transferTaxesPercentage: .37,
    transferTaxes: 34000,
    dueDilligence: 25000,
    legalFees: 25000,
    reserves: 141750,
    otherClosingCostsPercentage: 1,
    otherClosingCosts: 92000,
    capExRenovation:  275625,
  }
  testLoan.loanAssumptions = {
    ...testLoan.loanAssumptions,
    lender: "Taylor",
    date: new Date(2021, 9, 31),
    capExLeveragePercentage: 1,
    purchasePriceLeveragePercentage: 75,
    termYears: 7,
    interestRate: 4,
    interestOnlyMonths: 12,
    amortizationYears: 30,
    loanFeesPercentage: 1,
    loanFees:  69000,
    prepaymentPenaltyPercentage: 0,
    prepaymentPenalty: 0,
    debtServiceReservesPandIMonths: 0,
    debtServiceReservesPandIRelase: "yes",
    debtServiceReservesPandIRelaseTiming: 0,
    debtServiceReservesPandITotal: 0,
  }
  testLoan.refinancingPermanentLoanAssumptions = {
    refinancing: false,
    loanType: "ltv",
    lender: "",
    date: new Date(2022, 9, 31),
    month: 36,
    year: 3,
    interestRate: 4,
    interestOnlyMonths: 12,
    amortizationYears: 30,
    noiTrailing12Months:  370637.82,
    capRate: 5.5,
    propertyValuation:  6738851.23,
    ltvPercentage: 75,
    dscrPercentage: 1.25,
    ltvTotal:  5054138.42,
    dscrTotal:  5127251.35,
    refinanceLoanAmount:  5054138.42,
    loanFeesPercentage: 1,
    loanFees:  50541.38,
    originalLoanRepayment:  6652026,
    distributionProceeds:  -1648429.52,
  }
  testLoan.loanTotal = 6900000;
  testLoan.calculateLoanSchedule("acquisition");
  testLoan.calculateLoanSchedule("refinancing");
  testLoan.calculateDynamicAcquisitionCosts();
  testLoan.calculateLoanTotal();
  testLoan.calculateDebtService();
  testLoan.equitySplit = {
    gpSponsor: 10,
    lpInvestors: 90,
  }


  return testLoan;
}
export default generateDefaultLoan;