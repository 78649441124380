import generateEmptyLoan from '../../../components/property-analyzer/templates/empty/loan';
import { ILoan } from '../../../types/loan';
import { LoanAction } from "../../actions/loan";

export interface ILoanState {
  loan: ILoan;
}

const initialState: ILoanState = {
  loan: generateEmptyLoan(),
};

export const loanReducer = (
  state: ILoanState = initialState,
  action: LoanAction
): ILoanState => {
  let { loan } = state;
  switch (action.type) {
    case "UPDATE_LOAN":
      return { ...state, loan: action.payload };
    case "UPDATE_LOAN_ACQUISITION_COSTS":
      loan.acquisitionCosts = action.payload;
      loan.calculateDynamicAcquisitionCosts();
      return { ...state, loan };
    case "UPDATE_LOAN_ACQUISITION_BRIDGE_LOAN_ASSUMPTIONS":
      loan.loanAssumptions = action.payload;
      loan.calculateLoanSchedule("acquisition");
      loan.calculateLoanTotal();
      loan.calculateDebtService();
      return { ...state, loan };
    case "UPDATE_LOAN_EQUITY_SPLIT":
      loan.equitySplit = action.payload;
      return { ...state, loan };
    case "UPDATE_LOAN_CAP_EX_RENOVATION_BUDGET":
      loan.acquisitionCosts.capExRenovation = action.payload;
      return { ...state, loan };
    case "UPDATE_LOAN_REFINANCING_PERMANENT_LOAN_ASSUMPTIONS":
      loan.refinancingPermanentLoanAssumptions = action.payload;
      if (action.payload.refinancing) {
        loan.calculateLoanSchedule("refinancing");
      } else {
        loan.refinancingLoanSchedule = [];
      }
      return { ...state, loan };
    default:
      return state;
  }
};