import { Input, Table } from "antd";
import React, { ReactElement, useEffect, useState } from "react";
import { IIncomeExpenseItem } from "../../../../../types/property";
import NumberField from "../../../../shared/number-field";

interface IProformaExpenseReconciliationOtherIncomeTableProps {
  currentItems: IIncomeExpenseItem[];
  totalUnits: number;
  grossPotentialRent: number;
  onItemsChanged(items: IIncomeExpenseItem[]): void
}

interface IOtherIncomeItem extends IIncomeExpenseItem {
  view: string | ReactElement;
}

const ProformaExpenseReconciliationOtherIncomeTable = ({ currentItems, totalUnits, grossPotentialRent, onItemsChanged }: IProformaExpenseReconciliationOtherIncomeTableProps) => {
  const [items, setItems] = useState<IOtherIncomeItem[]>(currentItems.map((item, i) => {
    return {
      ...item,
      view: i === currentItems.length - 1 ? <b>{item.description}</b> : <span>{item.description}</span>,
    }
  }));

  const handleOtherItemChange = (event: React.ChangeEvent<HTMLInputElement>, index: number, field: string) => {
    const { target: { value } } = event;
    let newOtherIncomeItems = [...items];
    if (!isNaN(parseFloat(value))) {
      newOtherIncomeItems[index] = {
        ...newOtherIncomeItems[index],
        [field]: parseFloat(value),
      }

      const netOtherIncome = newOtherIncomeItems
        .map((x, index) => index !== newOtherIncomeItems.length - 1 ? x.amount : 0)
        .reduce((prev, curr) => prev + curr, 0);

      newOtherIncomeItems[newOtherIncomeItems.length - 1].amount = netOtherIncome;

      setItems(newOtherIncomeItems);
    } else {
      newOtherIncomeItems[index].amount = 0
      setItems(newOtherIncomeItems);
    }
  }

  const otherIncomeColumns = [
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: '40%',
      render: (_: any, record: any, index: number) => items[index].view,
    },
    {
      title: 'T-12 Financials',
      dataIndex: 'amount',
      key: 'amount',
      width: '15%',
      render: (_: any, record: any, index: number) =>
        index === items.length - 1 ? <b><NumberField value={items[index].amount} type="money" /></b> :
          <Input
            type="number"
            min={0}
            placeholder="Amount"
            value={items[index].amount}
            onChange={e => handleOtherItemChange(e, index, "amount")}
          />
    },
    {
      title: '% of GPR / EGI',
      dataIndex: 'percentOfGprEgi',
      key: 'percentOfGprEgi',
      width: '15%',
      render: (_: any, record: any, index: number) =>
        index === items.length - 1 ? <b><NumberField value={(items[index].amount / grossPotentialRent) * 100} type="percentage" /></b> :
          <NumberField value={grossPotentialRent <= 0 ? 0 : (items[index].amount / grossPotentialRent) * 100} type="percentage" />
    },
    {
      title: '$ / Month',
      dataIndex: 'costPerMonth',
      key: 'costPerMonth',
      width: '15%',
      render: (_: any, record: any, index: number) =>
        index === items.length - 1 ? <b><NumberField value={items[index].amount / 12} type="money" /></b> :
          <NumberField value={items[index].amount / 12} type="money" />
    },
    {
      title: '$ / Unit',
      dataIndex: 'costPerUnit',
      key: 'costPerUnit',
      width: '15%',
      render: (_: any, record: any, index: number) =>
        index === items.length - 1 ? <b><NumberField value={items[index].amount / totalUnits} type="money" /></b> :
          <NumberField value={items[index].amount / totalUnits} type="money" />
    },
  ];

  useEffect(() => {
    onItemsChanged(items.map(i => {
      return {
        description: i.description,
        name: i.name,
        amount: i.amount,
      }
    }));
  }, [items]);

  return (
    <Table
      style={{ whiteSpace: 'nowrap' }}
      columns={otherIncomeColumns}
      dataSource={items}
      pagination={false}
      bordered
      size="small"
    />
  )

}
export default ProformaExpenseReconciliationOtherIncomeTable;