import { createStore } from "redux";
import { propertyReducer } from "./reducers/property";
import { loanReducer } from "./reducers/loan";
import { cashFlowReducer } from "./reducers/cash-flow";
import { combineReducers, compose } from 'redux'
import { stepReducer } from "./reducers/step";
import { userReducer } from "./reducers/user";
import { IProperty } from "../types/property";
import { ILoan } from "../types/loan";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

//@ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

var reducers = combineReducers({
  propertyReducer,
  loanReducer,
  cashFlowReducer,
  stepReducer,
  userReducer
});

export const store = createStore(reducers,composeEnhancers());
export type RootState = ReturnType<typeof reducers>;
export type AppDispatch = typeof store.dispatch

export const getStoreProperty = (): IProperty => {
  return store.getState().propertyReducer.property;
}

export const getStoreLoan = (): ILoan => {
  return store.getState().loanReducer.loan;
}

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector