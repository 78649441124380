import { Card, Col, Form, Input, notification, Row, Select } from "antd";
import Button from "antd-button-color";
import { useState } from "react";
import featureRequestService from "../../api/services/feature-request";
import { useAppSelector } from "../../store";
import { SyncOutlined } from "@ant-design/icons";
import RequestedFeatures from "./table";
import useMediaQuery from "use-media-antd-query";
import ShortcutButton from "../shared/shortcut-button";
import { PlusOutlined } from "@ant-design/icons";
import { useHotkeys } from "react-hotkeys-hook";

const { Option } = Select;

const FeatureRequest = () => {
  const colSize = useMediaQuery();
  const [showForm, setShowForm] = useState(false);
  const { current } = useAppSelector((state) => state.userReducer);
  const [saving, setSaving] = useState<boolean>(false);
  const [newFeatureCount, setNewFeatureCount] = useState(0);
  const [form] = Form.useForm();
  useHotkeys("r", () => setShowForm(true));

  const onFinish = (values: any) => {
    setSaving(true);
    featureRequestService
      .create({
        userId: current?._id!,
        summary: values.summary,
        description: values.description,
        priority: values.priority,
        firstName: current?.firstName || "",
        lastName: current?.lastName || "",
        status: "open",
      })
      .then(() => {
        setSaving(false);
        setNewFeatureCount((count) => count + 1);
        form.resetFields();
        notification.success({
          message: "Request submitted succesfully",
          duration: 5,
          placement: "bottomRight",
        });
        setShowForm(false);
      })
      .catch((err) => {
        notification.error({
          message: "An error was encountered while submitting feature request",
          duration: 5,
          placement: "bottomRight",
        });
        setSaving(false);
      });
  };

  return (
    <Row gutter={24}>
      <Col
        xs={24}
        md={12}
        offset={["xs", "sm"].includes(colSize) ? 0 : 6}
        style={{ marginBottom: 25 }}
      >
        {showForm ? (
          <Card>
            <div>
              <h1 style={{ fontSize: "1.5em", display: "inline-block" }}>
                Request a New Feature
              </h1>
            </div>
            <Form
              layout="vertical"
              form={form}
              onFinish={onFinish}
              initialValues={{
                from:
                  current?.firstName +
                  " " +
                  current?.lastName +
                  " <" +
                  current?.email +
                  ">",
                summary: null,
                description: null,
              }}
            >
              <Form.Item label="From" name="from">
                <Input disabled />
              </Form.Item>
              <Form.Item
                label="Summary"
                name="summary"
                rules={[{ required: true, message: "Summary is required" }]}
              >
                <Input placeholder="A brief overview of the request" />
              </Form.Item>
              <Form.Item
                label="Description"
                name="description"
                rules={[{ required: true, message: "Description is required" }]}
              >
                <Input.TextArea
                  placeholder="Please describe the feature that you would like to see implemented"
                  rows={6}
                />
              </Form.Item>
              <Form.Item
                label="Priority"
                name="priority"
                rules={[{ required: true, message: "Priority is required" }]}
              >
                <Select>
                  <Option value="low" style={{ color: "green" }}>
                    <span style={{ color: "green" }}>Low</span>
                  </Option>
                  <Option value="medium" style={{ color: "orange" }}>
                    <span style={{ color: "orange" }}>Medium</span>
                  </Option>
                  <Option value="high" style={{ color: "red" }}>
                    <span style={{ color: "red" }}>High</span>
                  </Option>
                </Select>
              </Form.Item>
              <p>
                <b>Note:</b> any requested features will be visible by{" "}
                <i>all</i> users across the platform
              </p>
              <Form.Item style={{ marginTop: 10 }}>
                <Button htmlType="submit" type="success">
                  {saving ? (
                    <span>
                      Requesting <SyncOutlined spin />
                    </span>
                  ) : (
                    "Request"
                  )}
                </Button>
                <Button
                  type="lightdark"
                  style={{ marginLeft: 10 }}
                  onClick={() => setShowForm(false)}
                >
                  Cancel
                </Button>
              </Form.Item>
            </Form>
          </Card>
        ) : (
          <>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h1 style={{ fontSize: "1.5em", display: "inline-block" }}>
                Features Requested
              </h1>
              <ShortcutButton
                title="New Request"
                type="success"
                size="small"
                shortcutKey="r"
                onClick={() => setShowForm(true)}
                style={{ marginRight: 10 }}
                mobileIcon={<PlusOutlined />}
              />
            </div>
            <RequestedFeatures newFeatureCount={newFeatureCount} />
          </>
        )}
      </Col>
    </Row>
  );
};

export default FeatureRequest;
