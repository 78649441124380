import { Table } from "antd";
import NumberField from "../../../../shared/number-field";

interface IEgiNoiTableProps {
  grossPotentialRent: number;
  netRentalIncome: number;
  totalOtherIncome: number;
  expenseTotals: number;
  assetManagementFee: number;
  totalUnits: number;
}

const EgiNoiTable = ({ grossPotentialRent, netRentalIncome, totalOtherIncome, expenseTotals, assetManagementFee, totalUnits }: IEgiNoiTableProps) => {
  const egiColumns = [
    {
      key: 'description',
      dataIndex: 'description',
    },
    {
      title: 'T-12 Financials',
      key: 'total',
      dataIndex: 'total',
    },
    {
      title: '% of GPR',
      key: 'percentOfGpr',
      dataIndex: 'percentOfGpr',
    },
    {
      title: '$ / Month',
      key: 'costPerMonth',
      dataIndex: 'costPerMonth',
    },
    {
      title: '$ / Unit',
      key: 'costPerUnit',
      dataIndex: 'costPerUnit',
    },
  ]

  const egiDataSource = [
    {
      description: <b>Effective Gross Income (EGI)</b>,
      total: <NumberField type="money" value={netRentalIncome + totalOtherIncome} />,
      percentOfGpr: <NumberField type="percentage" value={((netRentalIncome + totalOtherIncome) / grossPotentialRent) * 100} />,
      costPerMonth: <NumberField type="money" value={(netRentalIncome + totalOtherIncome) / 12} />,
      costPerUnit: <NumberField type="money" value={(netRentalIncome + totalOtherIncome) / totalUnits} />,
    },
    {
      description: <b>Net Operating Income (NOI)</b>,
      total: <NumberField type="money" value={(netRentalIncome + totalOtherIncome) - expenseTotals + assetManagementFee} />,
      percentOfGpr: null,
      costPerMonth: <NumberField type="money" value={((netRentalIncome + totalOtherIncome) - expenseTotals + assetManagementFee) / 12} />,
      costPerUnit: <NumberField type="money" value={((netRentalIncome + totalOtherIncome) - expenseTotals + assetManagementFee) / totalUnits} />,
    },
  ]
  return (
    <Table
      style={{ marginTop: 50, whiteSpace:'nowrap' }}
      pagination={false}
      bordered
      size="small"
      columns={egiColumns}
      dataSource={egiDataSource}
      scroll={{x:true}}
    />
  )
};

export default EgiNoiTable;