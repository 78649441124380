import { Table } from "antd";
import NumberField from "../../../../shared/number-field";
import { IPropertySummary } from "../investment-summary-service";

interface IPropertySummaryProps {
  propertySummary: IPropertySummary;
}

const PropertySummary: React.FC<IPropertySummaryProps> = ({
  propertySummary,
}) => {
  const {
    assetType,
    purchasePrice,
    totalBasis,
    totalUnits,
    totalSqFt,
    goingInCapRate,
    goingInNoi,
    goingInOccupancy,
  } = propertySummary;

  const columns = [
    {
      dataIndex: "description",
      key: "description",
      width: "50%",
    },
    {
      dataIndex: "column2",
      key: "column2",
      width: "25%",
    },
    {
      dataIndex: "column3",
      key: "column3",
      width: "25%",
    },
  ];

  const dataSource = [
    {
      key: "1",
      description: "Asset Type",
      column3: <span style={{ float: "right" }}>{assetType}</span>,
    },
    {
      key: "2",
      description: "Purchase Price",
      column3: <NumberField type="money" value={purchasePrice} />,
    },
    {
      key: "3",
      description: "Total Basis",
      column3: <NumberField type="money" value={totalBasis} />,
    },
    {
      key: "4",
      description: "Total Units",
      column3: <span style={{ float: "right" }}>{totalUnits}</span>,
    },
    {
      key: "5",
      description: "Total Square Footage",
      column3: (
        <span style={{ float: "right" }}>{totalSqFt.toLocaleString()}</span>
      ),
    },
    {
      key: "6",
      description: "Going-In Cap Rate",
      column3: (
        <NumberField
          type="percentage"
          value={goingInCapRate}
        />
      ),
    },
    {
      key: "7",
      description: "Going-In NOI",
      column3: <NumberField type="money" value={goingInNoi} />,
    },
    {
      key: "8",
      description: "Going-In Occupancy",
      column3: <NumberField type="percentage" value={goingInOccupancy} />,
    },
    {
      key: "9",
      description: null,
      column2: <b>Per Unit</b>,
      column3: <b>Per Sq. Ft.</b>,
    },
    {
      key: "10",
      description: "Acquisition Price",
      column2: <NumberField type="money" value={purchasePrice / totalUnits} />,
      column3: <NumberField type="money" value={purchasePrice / totalSqFt} />,
    },
    {
      key: "11",
      description: "Total Basis",
      column2: <NumberField type="money" value={totalBasis / totalUnits} />,
      column3: <NumberField type="money" value={totalBasis / totalSqFt} />,
    },
  ];

  return (
    <>
      <Table
        title={() => (
          <div style={{ textAlign: "center" }}>
            <b>Property Summary</b>
          </div>
        )}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        bordered
        showHeader={false}
        style={{ whiteSpace: "nowrap" }}
        scroll={{ x: true }}
      />
    </>
  );
};
export default PropertySummary;
