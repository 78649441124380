import { User } from "@auth0/auth0-react";
import IUser from "../../../types/user";
import http, { updateHeaderAuth0UserId } from "../../http";

const getRoute = (path?: string) => {
  const root = "/user";
  if (!path) return root;
  return root + "/" + path;
};

const verify = (user: User) => {
  updateHeaderAuth0UserId(user.sub);
  return http.post<{ user: IUser; newUser: boolean }>(getRoute("verify"), {
    user,
  });
};

const update = (user: IUser) => {
  localStorage.setItem("theme", user.theme!);
  updateHeaderAuth0UserId(user.auth0Id);
  return http.post<{ user: IUser }>(getRoute(), { user });
};

const addContact = (userId: string, email: string) => {
  const request = {
    userId,
  };
  return http.post<{ found: boolean }>(getRoute(`contact-request/${email}`), {
    request,
  });
};

const findByEmail = (email: string) => {
  return http.post<{
    found: boolean;
    userId: string;
    email: string;
    firstName: string;
    lastName: string;
  }>(getRoute(`find/${email}`));
};

export interface IContactDetails {
  userId: string;
  email: string;
  firstName: string;
  lastName: string;
  city: string;
  state: string;
  phoneNumber: number;
  request: boolean;
  favorite: boolean;
  avatarColor: string;
}

const getContacts = () => {
  return http.post<{
    contacts: IContactDetails[]
  }>(getRoute("get-contacts"));
};

const removeContact = (contactId: string) => {
  return http.delete<string>(getRoute(`remove-contact/${contactId}`));
};

const updateContact = (contact: IContactDetails) => {
  return http.post<string>(getRoute(`update-contact`), { contact });
};

interface IContactResponse {
  userId: string;
  accepted: boolean;
}

const processContactRequest = (response: IContactResponse) => {
  return http.post<string>(getRoute("contact-response"), { response });
};

const deleteAccount = () => {
  return http.delete<string>(getRoute());
};


const userService = {
  verify,
  update,
  addContact,
  findByEmail,
  getContacts,
  processContactRequest,
  removeContact,
  updateContact,
  deleteAccount
};

export default userService;
