import { Col, Form, Input, notification, Row, Tooltip } from "antd";
import React from "react";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../store";
import Modal from "..";
import ButtonColor from "antd-button-color";
import { SyncOutlined, CloseOutlined, PlusOutlined } from "@ant-design/icons";
import invitationService from "../../../api/services/invitation";
import IInvitation from "../../../types/invitation";
import { useForm } from "antd/lib/form/Form";

type InviteUsersModalProps = {
  visible: boolean;
  onDismiss(): void;
};

const InviteUsersModal: React.FC<InviteUsersModalProps> = ({
  visible,
  onDismiss,
}) => {
  const { current } = useAppSelector((state) => state.userReducer);
  const [showInviteUsersModal, setShowInviteUsersModal] =
    useState<boolean>(visible);
  const [sending, setSending] = useState<boolean>(false);
  const [invitations, setInvitations] = useState<
    { email: string; firstName: string; lastName: string }[]
  >([{ email: "", firstName: "", lastName: "" }]);
  const [form] = useForm();

  const handleDismiss = () => {
    setSending(false);
    setShowInviteUsersModal(false);
    setInvitations([{ email: "", firstName: "", lastName: "" }]);
    onDismiss();
  };

  useEffect(() => {
    setShowInviteUsersModal(visible);
  }, [visible]);

  const onFinish = (values: {}) => {
    setSending(true);
    let outgoingInvites: IInvitation[] = [];
    for (let i = 0; i < invitations.length; i++) {
      outgoingInvites.push({
        to: {
          //@ts-ignore
          firstName: values[`first-name-${i}`],
          //@ts-ignore
          lastName: values[`last-name-${i}`],
          //@ts-ignore
          email: values[`email-${i}`],
        },
        from: {
          email: current?.email!,
          firstName: current?.firstName!,
          lastName: current?.lastName!,
          userId: current?._id!,
        },
      });
    }
    invitationService
      .submit(outgoingInvites)
      .then(() => {
        notification.success({
          message: `Invitation${invitations.length > 1 ? "s" : ""} sent`,
          duration: 5,
          placement: "bottomRight",
        });
        form.resetFields();
        handleDismiss();
      })
      .catch((err) => {
        setSending(false);
        notification.error({
          message: "An error was encountered while sending invitation",
          duration: 5,
          placement: "bottomRight",
        });
      });
  };

  return (
    <Modal
      title="Invite Users"
      visible={showInviteUsersModal}
      onDismiss={() => handleDismiss()}
      width={750}
    >
      <div>
        <Form layout="vertical" onFinish={onFinish} form={form}>
          {invitations.map((invitation, index) => (
            <Row gutter={24} key={index}>
              <Col span={8}>
                <Form.Item
                  name={`email-${index}`}
                  label={index === 0 ? "Email Address" : ""}
                  rules={[
                    {
                      type: "email",
                      message: "Please input a valid email",
                    },
                    { required: true, message: "Email is required" },
                  ]}
                >
                  <Input
                    placeholder="jsmith@example.com"
                    type="text"
                    name={`email-${index}`}
                  />
                </Form.Item>
              </Col>
              <Col span={invitations.length > 1 ? 7 : 8}>
                <Form.Item
                  name={`first-name-${index}`}
                  label={index === 0 ? "First Name" : ""}
                  rules={[
                    {
                      required: true,
                      message: "First name is required",
                    },
                  ]}
                >
                  <Input
                    placeholder="John"
                    type="text"
                    name={`first-name-${index}`}
                  />
                </Form.Item>
              </Col>
              <Col span={invitations.length > 1 ? 7 : 8}>
                <Form.Item
                  name={`last-name-${index}`}
                  label={index === 0 ? "Last Name" : ""}
                  rules={[
                    {
                      required: true,
                      message: "Last name is required",
                    },
                  ]}
                >
                  <Input
                    placeholder="Smith"
                    type="text"
                    name={`last-name-${index}`}
                  />
                </Form.Item>
              </Col>
              <Col span={invitations.length === 1 ? 0 : 2}>
                <Form.Item>
                  <Tooltip title="Remove Invitee" mouseEnterDelay={0.25}>
                    <CloseOutlined
                      onClick={() =>
                        setInvitations((invitations) =>
                          invitations.filter((_, i) => i !== index)
                        )
                      }
                      style={{
                        fontSize: 20,
                        cursor: "pointer",
                        display: index === 0 ? "none" : "inherit",
                      }}
                    />
                  </Tooltip>
                </Form.Item>
              </Col>
            </Row>
          ))}
          <Form.Item label="" style={{ marginTop: 5 }}>
            {invitations.length >= 10 ? (
              <p>* Maximum of 10 invitations can be sent at one time</p>
            ) : (
              <Tooltip title="Add Invitee" mouseEnterDelay={0.25}>
                <ButtonColor
                  size="small"
                  type="primary"
                  onClick={() =>
                    setInvitations((invitations) => [
                      ...invitations,
                      { email: "", firstName: "", lastName: "" },
                    ])
                  }
                >
                  <PlusOutlined />
                </ButtonColor>
              </Tooltip>
            )}
          </Form.Item>
          <Form.Item label="">
            <div style={{ display: "flex", justifyContent: "end" }}>
              <ButtonColor
                htmlType="submit"
                type="success"
                // disabled={rating === 0}
              >
                {sending ? (
                  <span>
                    Sending <SyncOutlined spin />
                  </span>
                ) : (
                  `Send Invitation${invitations.length > 1 ? "s" : ""}`
                )}
              </ButtonColor>
            </div>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default InviteUsersModal;
