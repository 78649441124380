import { Row, Col, Input, Tabs, Table, Card } from "antd";
import { useEffect, useState } from "react";
import { IRenovationItem } from "../../../../types/property";
import InteriorRenovations from "./interior";
import ExteriorRenovations from "./exterior";
import { IUpdatePropertyRenovationsPayload } from "../../../../store/actions/property";
import NumberField from "../../../shared/number-field";
import useMediaQuery from 'use-media-antd-query';
import { useAppDispatch, useAppSelector } from "../../../../store";
const { TabPane } = Tabs;


const Renovations = () => {
  const dispatch = useAppDispatch();
  const { property: {
    totalRenovatingUnits,
    totalUnits,
    renovations: {
      interiorRenovations,
      exteriorRenovations,
      contingencyPercentage,
      contingency,
      interiorRenovationCost,
      exteriorRenovationCost,
      totalBudget
    } } } = useAppSelector(state => state.propertyReducer);
  const [percentage, setPercentage] = useState<number>(contingencyPercentage || 0);
  const [payload, setPayload] = useState<IUpdatePropertyRenovationsPayload>({
    interiorRenovations,
    exteriorRenovations,
    contingencyPercentage,
  });
  const colSize = useMediaQuery();

  const handleUpdateContigencyPercentage = (value: number): void => {
    setPercentage(value);
    setPayload(payload => ({ ...payload, contingencyPercentage: value }));
  }

  const handleRenovationChange = (items: IRenovationItem[], type: "interior" | "exterior") => {
    let newPayload = { ...payload }
    switch (type) {
      case "interior":
        newPayload.interiorRenovations = items;
        break;
      case "exterior":
        newPayload.exteriorRenovations = items;
        break;
      default:
        break;
    }

    setPayload(newPayload);
  }

  useEffect(() => {
    dispatch({
      type: "UPDATE_PROPERTY_RENOVATIONS",
      payload: payload
    });

  }, [payload]);

  const columns = [
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: ["xs", "sm"].includes(colSize) ? '50%' : "65%"
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
      width: ["xs", "sm"].includes(colSize) ? '50%' : "35%"
    },
  ];

  const dataSourceOverview = [
    {
      key: '1',
      description: "Total Units",
      value: <span style={{ float: 'right' }}>{totalUnits}</span>
    },
    {
      key: '2',
      description: "Units Scheduled for Interior Renovation",
      value: <span style={{ float: 'right' }}>{totalRenovatingUnits}</span>
    },
    {
      key: '3',
      description: "Contigency",
      value: <Input
        suffix={<span>%</span>}
        type="number"
        value={contingencyPercentage}
        onChange={e => handleUpdateContigencyPercentage(parseFloat(e.target.value) || 0)}
        defaultValue={0}
        min={0}
        max={100}
        step={0.1}
        style={{ width: 100, float: 'right' }}
      />
    },
  ]

  const dataSourceBudget = [
    {
      key: '1',
      description: "Interior Unit Renovations",
      value: <NumberField type="money" value={interiorRenovationCost} />
    },
    {
      key: '2',
      description: "Exterior/Common Areas",
      value: <NumberField type="money" value={exteriorRenovationCost} />
    },
    {
      key: '3',
      description: <span>Contingency ({percentage}%)</span>,
      value: <NumberField type="money" value={contingency} />
    },
    {
      key: '4',
      description: 'Total Budget',
      value: <NumberField type="money" value={totalBudget} />
    },
  ];

  return (
    <>

      <Col xs={24} sm={24} md={24} lg={20} xl={16} xxl={16} style={{ paddingLeft: 0 }}>
        <Row gutter={24}>
          <Col xs={24} md={12} style={{ marginBottom: 25 }}>
            <Table
              title={() => <b>Overview</b>}
              showHeader={false}
              columns={columns}
              dataSource={dataSourceOverview}
              pagination={false}
              bordered
            />
          </Col>
          <Col xs={24} md={12} style={{ marginBottom: 25 }}>
            <Table
              title={() => <b>Budget</b>}
              showHeader={false}
              columns={columns}
              dataSource={dataSourceBudget}
              pagination={false}
              bordered
            />
          </Col>
        </Row>
        <Card>
          <Tabs defaultActiveKey={totalRenovatingUnits > 0 ? "1" : "2"} style={{marginTop:-15}}>
            <TabPane tab="Interior" key={1}>
              <InteriorRenovations items={interiorRenovations} totalRenovatingUnits={totalRenovatingUnits} onChange={items => handleRenovationChange(items, "interior")} />
            </TabPane>
            <TabPane tab="Exterior" key={2}>
              <ExteriorRenovations items={exteriorRenovations} totalUnits={totalUnits} onChange={items => handleRenovationChange(items, "exterior")} />
            </TabPane>
          </Tabs>
        </Card>
      </Col>
    </>
  )

}
export default Renovations;