import { IAcquisitionDataSource, IAssetManagementFeeType, IControllableExpenseType, IDispositionType, IDscrType, IEffectiveGrossIncomeType, IExpenseTotalType, ILeveredCashFlowType, INetCashFlowAfterDebtServiceType, INetOperatingIncomeType, INonControllableExpenseType, IOriginalAcquisitionLoanType, IOtherIncomeDataSource, IRefinanceLoanType, IRentalRevenueDataSource, IUnleveredCashFlowType } from "./data-source";

const acquisitionCosts: IAcquisitionDataSource[] = [
  { type: "acquisitionCost", title: <b><i>Acquisition </i></b >, name: null, titleOnly: true },
  { type: "acquisitionCost", title: <span style={{ marginLeft: 10 }}> Purchase Price </span>, name: 'purchasePrice' },
  { type: "acquisitionCost", title: <span style={{ marginLeft: 10 }}> Acquisition Fee </span>, name: 'acquisitionFee' },
  { type: "acquisitionCost", title: <span style={{ marginLeft: 10 }}> Transfer Taxes </span>, name: 'transferTaxes' },
  { type: "acquisitionCost", title: <span style={{ marginLeft: 10 }}> Due Diligence </span>, name: 'dueDilligence' },
  { type: "acquisitionCost", title: <span style={{ marginLeft: 10 }}> Legal Fees </span>, name: "legalFees" },
  { type: "acquisitionCost", title: <span style={{ marginLeft: 10 }}> CapEx / Renovation </span>, name: "capExRenovation" },
  { type: "acquisitionCost", title: <span style={{ marginLeft: 10 }}> Reserves </span>, name: "reserves" },
  { type: "acquisitionCost", title: <span style={{ marginLeft: 10 }}> Other Closing Costs </span>, name: "otherClosingCosts" },
  { type: "acquisitionCost", title: <span style={{ marginLeft: 10 }}> RE Tax Reserves </span>, name: "reTaxReserves" },
  { type: "acquisitionCost", title: <span style={{ marginLeft: 10 }}> Insurance Reserves </span>, name: "insuranceReserves" },
  { type: "acquisitionCost", title: <span style={{ marginLeft: 10 }}> Debt Service Reserves(P & I) </span>, name: "debtServices" },
  { type: "acquisitionCost", title: null, name: "total" },
];



const rentalRevenue: IRentalRevenueDataSource[] = [
  { type: "rentalRevenue", title: <b><i>Rental Revenue</i></b>, name: null, titleOnly: true },
  { type: "rentalRevenue", title: <span style={{ marginLeft: 10 }}> Gross Potential Rent </span>, name: 'grossPotentialRent' },
  { type: "rentalRevenue", title: <span style={{ marginLeft: 10 }}> Vacancy Loss </span>, name: 'vacancyLoss' },
  { type: "rentalRevenue", title: <span style={{ marginLeft: 10 }}> Loss - to - Lease </span>, name: "lossToLease" },
  { type: "rentalRevenue", title: <span style={{ marginLeft: 10 }}> Rent Concessions </span>, name: 'rentConcessions' },
  { type: "rentalRevenue", title: <span style={{ marginLeft: 10 }}> Credit Loss / Bad Debt </span>, name: "creditLossBadDebt" },
  { type: "rentalRevenue", title: <span style={{ marginLeft: 10 }}> Non - revenue Units </span>, name: "nonRevenueUnits" },
  { type: "rentalRevenue", title: null, name: "total" },
];

const otherIncome: IOtherIncomeDataSource[] = [
  { type: "otherIncome", title: <b><i>Other Income</i></b>, name: null, titleOnly: true },
  { type: "otherIncome", title: <span style={{ marginLeft: 10 }}>RUBS</span>, name: "rubs" },
  { type: "otherIncome", title: <span style={{ marginLeft: 10 }}>Laundry / Vending Income</span>, name: "laundryVendingIncome" },
  { type: "otherIncome", title: <span style={{ marginLeft: 10 }}>[Placeholder - Other Income]</span>, name: "placeholderOtherIncome" },
  { type: "otherIncome", title: <span style={{ marginLeft: 10 }}>Fee Income</span>, name: "feeIncome" },
  { type: "otherIncome", title: <span style={{ marginLeft: 10 }}>Other</span>, name: "other" },
  { type: "otherIncome", title: null, name: "total" },
];

const effectiveGrossIncome: IEffectiveGrossIncomeType =
  { type: 'effectiveGrossIncome', title: <b>Effective Gross Income (EGI)</b>, name: 'total' };

const controllableExpenses: IControllableExpenseType[] = [
  { type: "controllableExpense", title: <b><i>Expenses</i></b>, name: null, titleOnly: true },
  { type: "controllableExpense", title: <i style={{ marginLeft: 5 }}>Controllable</i>, name: null },
  { type: "controllableExpense", title: <span style={{ marginLeft: 10 }}>Property Management Fee</span>, name: "propertyManagementFee" },
  { type: "controllableExpense", title: <span style={{ marginLeft: 10 }}>General and Administration</span>, name: "generalAndAdministration" },
  { type: "controllableExpense", title: <span style={{ marginLeft: 10 }}>Contract Services</span>, name: "contractServices" },
  { type: "controllableExpense", title: <span style={{ marginLeft: 10 }}>Marketing</span>, name: "marketing" },
  { type: "controllableExpense", title: <span style={{ marginLeft: 10 }}>Payroll</span>, name: "payroll" },
  { type: "controllableExpense", title: <span style={{ marginLeft: 10 }}>Repairs and Maintenance</span>, name: "repairsAndMaintenance" },
  { type: "controllableExpense", title: <span style={{ marginLeft: 10 }}>Turnover Costs</span>, name: "turnoverCosts" },
  { type: "controllableExpense", title: <span style={{ marginLeft: 10 }}>Cable / Internet</span>, name: "cableInternet" },
  { type: "controllableExpense", title: null, name: "total" },
];

const nonControllableExpenses: INonControllableExpenseType[] = [
  { type: "nonControllableExpense", title: <i style={{ marginLeft: 5 }}>Non-Controllable</i>, name: null, titleOnly: true },
  { type: "nonControllableExpense", title: <span style={{ marginLeft: 10 }}>Insurance</span>, name: "insurance" },
  { type: "nonControllableExpense", title: <span style={{ marginLeft: 10 }}>Real Estate Taxes</span>, name: "realEstateTaxes" },
  { type: "nonControllableExpense", title: <span style={{ marginLeft: 10 }}>Utilities</span>, name: "utilities" },
  { type: "nonControllableExpense", title: <span style={{ marginLeft: 10 }}>Replacement Reserves</span>, name: "replacementReserves" },
  { type: "nonControllableExpense", title: null, name: "total" },
];

const expenseTotals: IExpenseTotalType[] = [
  { type: "expenseTotal", title: <b style={{ marginLeft: 10 }}>Total Expenses</b>, name: "total" },
  { type: "expenseTotal", title: <i style={{ marginLeft: 15, opacity: 0.5 }}>Expense Ratio</i>, name: "ratio" },
];

const netOperatingIncome: INetOperatingIncomeType =
  { type: 'netOperatingIncome', title: <b>Net Operating Income</b>, name: 'total' };

const assetManagementFee: IAssetManagementFeeType =
  { type: 'assetManagementFee', title: <span>Asset Management Fee</span>, name: 'assetManagementFee' };

const originalAcquisitionLoan: IOriginalAcquisitionLoanType[] = [
  { type: "originalAcquisitionLoan", title: <b><i>Financing</i></b>, name: null, titleOnly: true },
  { type: "originalAcquisitionLoan", title: <b style={{ marginLeft: 5 }}>Original Acquisition Loan</b>, name: null },
  { type: "originalAcquisitionLoan", title: <span style={{ marginLeft: 10 }}>Proceeds</span>, name: "proceeds" },
  { type: "originalAcquisitionLoan", title: <span style={{ marginLeft: 10 }}>Loan Fees</span>, name: "loanFees" },
  { type: "originalAcquisitionLoan", title: <span style={{ marginLeft: 10 }}>Interest Expense</span>, name: "interestExpense" },
  { type: "originalAcquisitionLoan", title: <span style={{ marginLeft: 10 }}>Prinicpal Repayment</span>, name: "principalRepayment" },
  { type: "originalAcquisitionLoan", title: <b style={{ marginLeft: 15 }}><i>Total Debt Service</i></b>, name: "totalDebtService" },
  { type: "originalAcquisitionLoan", title: <i style={{ marginLeft: 10, opacity: 0.5 }}>Outstanding Loan Balance</i>, name: "outstandingLoanBalance" },
  { type: "originalAcquisitionLoan", title: <span style={{ marginLeft: 10 }}>Loan Balance Repayment</span>, name: "loanBalanceRepayment" },
  { type: "originalAcquisitionLoan", title: <span style={{ marginLeft: 10 }}>Prepayment Penalty</span>, name: "prepaymentPenalty" },
];

const refinanceLoan: IRefinanceLoanType[] = [
  { type: "refinanceLoan", title: <b style={{ marginLeft: 5 }}>Refinance Loan</b>, name: null, titleOnly: true },
  { type: "refinanceLoan", title: <span style={{ marginLeft: 10 }}>Proceeds</span>, name: "proceeds" },
  { type: "refinanceLoan", title: <span style={{ marginLeft: 10 }}>Loan Fees</span>, name: "loanFees" },
  { type: "refinanceLoan", title: <span style={{ marginLeft: 10 }}>Interest Expense</span>, name: "interestExpense" },
  { type: "refinanceLoan", title: <span style={{ marginLeft: 10 }}>Prinicpal Repayment</span>, name: "principalRepayment" },
  { type: "refinanceLoan", title: <b style={{ marginLeft: 15 }}><i>Total Debt Service</i></b>, name: "totalDebtService" },
  { type: "refinanceLoan", title: <i style={{ marginLeft: 10, opacity: 0.5 }}>Outstanding Loan Balance</i>, name: "outstandingLoanBalance" },
  { type: "refinanceLoan", title: <span style={{ marginLeft: 10 }}>Loan Balance Repayment</span>, name: "loanBalanceRepayment" },
];

const dscr: IDscrType =
  { type: 'dscr', title: <i style={{ marginLeft: 5, opacity: 0.5 }}>DSCR</i>, name: 'dscr' };

const netCashFlowAfterDebtService: INetCashFlowAfterDebtServiceType =
  { type: 'netCashFlowAfterDebtService', title: <b>Net Cash Flow After Debt Service</b>, name: 'netCashFlowAfterDebtService' };

const disposition: IDispositionType[] = [
  { type: "disposition", title: <i style={{ marginLeft: 5 }}>Disposition</i>, name: null, titleOnly: true },
  { type: "disposition", title: <span style={{ marginLeft: 10 }}>Gross Sale Proceeds</span>, name: "grossSaleProceeds" },
  { type: "disposition", title: <span style={{ marginLeft: 10 }}>Disposition Fee</span>, name: "dispositionFee" },
  { type: "disposition", title: <span style={{ marginLeft: 10 }}>Sale/Closing Costs</span>, name: "saleClosingCosts" },
  { type: "disposition", title: <span style={{ marginLeft: 10 }}>Loan Repayment at Sale</span>, name: "loanRepaymentAtSale" },
  { type: "disposition", title: <b style={{ marginLeft: 15 }}>Net Proceeds from Sale</b>, name: "netProceedsFromSale" },
];
const unleveredCashFlow: IUnleveredCashFlowType =
  { type: 'unleveredCashFlow', title: <span>Unlevered Cash Flow</span>, name: 'unleveredCashFlow' };

const leveredCashFlow: ILeveredCashFlowType =
{ type: 'leveredCashFlow', title: <span>Levered Cash Flow</span>, name: 'leveredCashFlow' };

const dataSource = [
  ...acquisitionCosts,
  ...rentalRevenue,
  ...otherIncome,
  effectiveGrossIncome,
  ...controllableExpenses,
  ...nonControllableExpenses,
  ...expenseTotals,
  netOperatingIncome,
  assetManagementFee,
  ...originalAcquisitionLoan,
  ...refinanceLoan,
  dscr,
  netCashFlowAfterDebtService,
  ...disposition,
  unleveredCashFlow,
  leveredCashFlow,
];

export { dataSource };