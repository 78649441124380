import IAnalyzedProperty from '../../../types/analyzed-property';
import { IProperty } from '../../../types/property';
import http from '../../http';

const getRoute = (path?: string) => {
  const root = '/property'
  if (!path) return root;
  return root + '/' + path;
}

const propertyService = {
  get: () => http.get<{ inProgress: IAnalyzedProperty[], complete: IAnalyzedProperty[], shared: IAnalyzedProperty[] }>(getRoute()),
  create: (property: IAnalyzedProperty) => http.post<{ property: IAnalyzedProperty}>(getRoute(), { property }),
  update: (property: IAnalyzedProperty) => http.put<IProperty>(getRoute(property._id), { property }),
  delete: (propertyId: string) => http.delete<string>(getRoute(propertyId)),
  addImage: (propertyId: string, imageUrl: string) => http.post<string>(getRoute(`/images/${propertyId}`), {imageUrl}),
  share: (contactId: string, propertyId: string) => http.post<string>(getRoute('share'), { request: { contactId, propertyId } })
}

export default propertyService;
