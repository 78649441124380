import { Assumption } from "../../../../types/assumptions";
import Property from "../../../../types/property";
import { RentRollUnit } from "../../../../types/rent-roll-unit";

let gprGrowth = new Assumption("Gross Potential Rent", "grossPotentialRent", "growth");
gprGrowth = {
  ...gprGrowth,
  year1: 2,
  year2: 2,
  year3: 2,
  year4: 2,
  year5: 2,
  year6: 2,
  year7: 2,
  year8: 2,
  year9: 2,
  year10: 2,
}

const unitA = new RentRollUnit(
  {
    type :   "1 bed / 1 bath",
    unitCount:  26,
    squareFtPerUnit:  700,
    rent:  780,
    marketRent:  875,
    renovatedUnits:  0,
    renovatedMarketRent: 0, 
    unitsScheduledForRenovation: 0,
    renovationsStartedPerMonth:  0,
    renovationStartMonth:  0,
    renovationDownTime:  0
  },
  gprGrowth,
);

const unitB = new RentRollUnit(
  {
    type :   "2 bed / 1 bath",
    unitCount:  22,
    squareFtPerUnit:  900,
    rent:  850,
    marketRent:  950,
    renovatedUnits:  0,
    renovatedMarketRent: 0, 
    unitsScheduledForRenovation: 0,
    renovationsStartedPerMonth:  0,
    renovationStartMonth:  0,
    renovationDownTime:  0
  },
  gprGrowth,
);

const unitC = new RentRollUnit(
  {
    type :   "2 bed / 2 bath",
    unitCount:  27,
    squareFtPerUnit:  1100,
    rent:  900,
    marketRent:  1150,
    renovatedUnits:  0,
    renovatedMarketRent: 0, 
    unitsScheduledForRenovation: 0,
    renovationsStartedPerMonth:  0,
    renovationStartMonth:  0,
    renovationDownTime:  0
  },
  gprGrowth,
);

const testUnits = [unitA, unitB, unitC];

export const generateDefaultProperty = (): Property => {
  let property = new Property(testUnits);
  property.name = "";
  property.streetAddress = "1234 Main Street";
  property.city = "Nashville";
  property.state = "TN";
  property.county = 'Davidson';
  property.zipCode = 37011;
  property.yearBuilt = 2004;
  property.type = "Multifamily Unit";
  property.currentOccupancy = 97;
  property.buildingClass = "Class A";
  property.updateInteriorRenovations([
    { description: "Item #1", cost: 4500 },
  ]);
  property.updateExteriorRenovations([
    { description: "Item #1", cost: 3500 },
  ]);
  property.updateRenovationContingencyPercentage(5);
  property.grossPotentialRent =  1256358;
  property.rentIncomeItems = [
    { description: "Gross Potential Rent", name: "grossPotentialRent", amount:  1256358 },
    { description: "Vacancy Loss", name: "vacancyLoss", amount:  57900 },
    { description: "Loss-to-Lease", name: "lossToLease", amount: 92020 },
    { description: "Rent Concessions", name: "rentConcessions", amount: 8500 },
    { description: "Credit Loss / Bad Debt", name: "creditLossBadDebt", amount: 10456 },
    { description: "Non-Revenue Units", name: "nonRevenueUnits", amount: 0 },
    { description: "Net Rental Income", name: "netRentalIncome", amount: 1087482 },
  ];
  property.otherIncomeItems = [
    { description: "RUBS", name: "rubs", amount: 0 },
    { description: "Laundry / Vending Income", name: "laundryVendingIncome", amount: 0 },
    { description: "[Placeholder - Other Income]", name: "placeholderOtherIncome", amount: 0 },
    { description: "Fee Income", name: "feeIncome", amount: 0 },
    { description: "Other", name: "other", amount: 14703 },
    { description: "Total Other Income", name: "totalOtherIncome", amount: 14703 },
  ];
  property.expenseItems = [
    { description: "General and Administration", name: "generalAndAdministration", amount: 36078 },
    { description: "Contract Services", name: "contractServices", amount: 25400 },
    { description: "Marketing", name: "marketing", amount: 15678 },
    { description: "Payroll", name: "payroll", amount: 124850 },
    { description: "Repairs and Maintenance", name: "repairsAndMaintenance", amount: 58900 },
    { description: "Turnover Costs", name: "turnoverCosts", amount: 42000 },
    { description: "Cable / Internet", name: "cableInternet", amount: 0 },
    { description: "Insurance", name: "insurance", amount: 47054 },
    { description: "Real Estate Taxes", name: "realEstateTaxes", amount: 119494 },
    { description: "Utilities", name: "utilities", amount: 80456 },
    { description: "Property Management Fee", name: "propertyManagementFee", amount: 57800 },
    { description: "Asset Management Fee", name: "assetManagementFee", amount: 0 },
    { description: "Replacement Reserves", name: "replacementReserves", amount: 0 },
    { description: "Expense Totals", name: "expenseTotals", amount:  607710  },
  ]

  property.growthAssumptions[0] = {
    ...property.growthAssumptions[0],
    fixed: true,
    year1: 2,
    year2: 2,
    year3: 2,
    year4: 2,
    year5: 2,
    year6: 2,
    year7: 2,
    year8: 2,
    year9: 2,
    year10: 2,
  }
  property.growthAssumptions[1] = {
    ...property.growthAssumptions[1],
    fixed: true,
    year1: 2,
    year2: 2,
    year3: 2,
    year4: 2,
    year5: 2,
    year6: 2,
    year7: 2,
    year8: 2,
    year9: 2,
    year10: 2,
  }
  property.growthAssumptions[2] = {
    ...property.growthAssumptions[2],
    fixed: true,
    year1: 2,
    year2: 2,
    year3: 2,
    year4: 2,
    year5: 2,
    year6: 2,
    year7: 2,
    year8: 2,
    year9: 2,
    year10: 2,
  }
  property.growthAssumptions[3] = {
    ...property.growthAssumptions[3],
    fixed: true,
    year1: 4,
    year2: 4,
    year3: 4,
    year4: 4,
    year5: 4,
    year6: 4,
    year7: 4,
    year8: 4,
    year9: 4,
    year10: 4,
  }

  property.annualAssumptions[0] = {
    ...property.annualAssumptions[0],
    fixed: false,
    year1: 10,
    year2: 5,
    year3: 5,
    year4: 5,
    year5: 5,
    year6: 5,
    year7: 5,
    year8: 5,
    year9: 5,
    year10: 5,
  }

  property.annualAssumptions[1] = {
    ...property.annualAssumptions[1],
    fixed: true,
    year1: 1.5,
    year2: 1.5,
    year3: 1.5,
    year4: 1.5,
    year5: 1.5,
    year6: 1.5,
    year7: 1.5,
    year8: 1.5,
    year9: 1.5,
    year10: 1.5,
  }


  property.annualAssumptions[2] = {
    ...property.annualAssumptions[2],
    fixed: false,
    year1: 0.075,
    year2: 0.025,
    year3: 0.025,
    year4: 0.025,
    year5: 0.025,
    year6: 0.025,
    year7: 0.025,
    year8: 0.025,
    year9: 0.025,
    year10: 0.025,
  }

  property.annualAssumptions[3] = {
    ...property.annualAssumptions[3],
    fixed: false,
    year1: 3.5,
    year2: 1,
    year3: 1,
    year4: 1,
    year5: 1,
    year6: 1,
    year7: 1,
    year8: 1,
    year9: 1,
    year10: 1,
  }

  property.annualAssumptions[4] = {
    ...property.annualAssumptions[4],
    fixed: true,
    year1: 0,
    year2: 0,
    year3: 0,
    year4: 0,
    year5: 0,
    year6: 0,
    year7: 0,
    year8: 0,
    year9: 0,
    year10: 0,
  }

  property.annualAssumptions[5] = {
    ...property.annualAssumptions[5],
    fixed: true,
    year1: 4,
    year2: 4,
    year3: 4,
    year4: 4,
    year5: 4,
    year6: 4,
    year7: 4,
    year8: 4,
    year9: 4,
    year10: 4,
  }

  property.annualAssumptions[6] = {
    ...property.annualAssumptions[6],
    fixed: true,
    year1: 2,
    year2: 2,
    year3: 2,
    year4: 2,
    year5: 2,
    year6: 2,
    year7: 2,
    year8: 2,
    year9: 2,
    year10: 2,
  }

  property.yearOneProformaAssumptions = {
    rentIncomeItems: [
      { description: "Gross Potential Rent", name: "grossPotentialRent", amount:  1256358, yearOneAmount: 0 },
      { description: "Vacancy Loss", name: "vacancyLoss", amount:  57900, yearOneAmount: 0 },
      { description: "Loss-to-Lease", name: "lossToLease", amount: 92020, yearOneAmount: 0 },
      { description: "Rent Concessions", name: "rentConcessions", amount: 8500, yearOneAmount: 0 },
      { description: "Credit Loss / Bad Debt", name: "creditLossBadDebt", amount: 10456, yearOneAmount: 0 },
      { description: "Non-Revenue Units", name: "nonRevenueUnits", amount: 0, yearOneAmount: 0 },
    ],
    otherIncomeItems: [
      { description: "RUBS", name: "rubs", amount: 0, yearOneAmount: 150 },
      { description: "Laundry / Vending Income", name: "laundryVendingIncome", amount: 0, yearOneAmount: 0 },
      { description: "[Placeholder - Other Income]", name: "placeholderOtherIncome", amount: 0, yearOneAmount: 0 },
      { description: "Fee Income", name: "feeIncome", amount: 0, yearOneAmount: 0 },
      { description: "Other", name: "other", amount: 14073, yearOneAmount: 196 },
    ],
    expenseItems: [
      { description: "General and Administration", name: "generalAndAdministration", amount: 36078, yearOneAmount: 250 },
      { description: "Contract Services", name: "contractServices", amount: 25400, yearOneAmount: 225 },
      { description: "Marketing", name: "marketing", amount: 15678, yearOneAmount: 150 },
      { description: "Payroll", name: "payroll", amount: 124850, yearOneAmount: 1250 },
      { description: "Repairs and Maintenance", name: "repairsAndMaintenance", amount: 58900, yearOneAmount: 600 },
      { description: "Turnover Costs", name: "turnoverCosts", amount: 42000, yearOneAmount: 400 },
      { description: "Cable / Internet", name: "cableInternet", amount: 0, yearOneAmount: 0 },
      { description: "Insurance", name: "insurance", amount: 47054, yearOneAmount: 450 },
      { description: "Real Estate Taxes", name: "realEstateTaxes", amount: 119494, yearOneAmount: 1572 },
      { description: "Utilities", name: "utilities", amount: 80456, yearOneAmount: 1059},
      { description: "Replacement Reserves", name: "replacementReserves", amount: 0, yearOneAmount: 250 },
    ]
  }

  property.reservesTaxes = {
    ...property.reservesTaxes,
    months: 6,
    release: false,
    releaseMonths: 0,
    yearOneMonthlyAmount: (property.getTaxes() * property.totalUnits) /12,
    yearOneTotal: property.getTaxes() * property.totalUnits,
    yearOneTotalPerUnit: property.getTaxes(),
  }

  property.reservesInsurance = {
    ...property.reservesInsurance,
    months: 12,
    release: false,
    releaseMonths: 0,
    yearOneMonthlyAmount: (property.getInsurance() * property.totalUnits) /12,
    yearOneTotal: property.getInsurance() * property.totalUnits,
    yearOneTotalPerUnit: property.getInsurance(),
  }

  property.investmentTimeline  = {
    acquisitionDate: new Date(2021, 9, 31),
    refinanceDate: new Date(2024, 9, 31),
    exitDispositionDate: new Date(2028, 9, 31),
    holdPeriodYears: 7,
    exitMonth: 84,
    distributionStartDate: 1,
    renovationStartMonth: 0,
    renovationEndMonth: 0,
  }

  property.exitDispositionSummary = {
    date: new Date(2028, 9, 31),
    capRate: 5,
    capRateSpread: -37,
    occupancy: 95,
    noiTrailing12Months: 391140,
    grossSaleProceeds:  7822804,
    dispositionFeePercentage: 1,
    dispositionFee: 78228,
    saleClosingCostsPercentage: 3,
    saleClosingCosts: 234684,
    loanRepaymentAtSale: 4776096,
    netSaleProceeds: 2733795
  }

  property.promoteIncentiveHurdles = {
    investorPreferredReturn: 8,
    t1HurdlePercentage: 8,
    t1PromotePercentage: 30,
    t2HurdlePercentage: 12,
    t2PromotePercentage: 30,
    t3HurdlePercentage: 15,
    t3PromotePercentage: 30,
    t4HurdlePercentage: 20,
    t4PromotePercentage: 30,
  }

  property.updateRentRollUnitMix(testUnits);
  
 return property;
};

export default generateDefaultProperty;
