import { useEffect, useRef, useState } from "react";
import {
  getStoreLoan,
  getStoreProperty,
  useAppSelector,
  useAppDispatch,
} from "../../../../store";
import {
  Col,
  Input,
  Row,
  Select,
  Table as AntdTable,
  TableProps,
  Tabs,
} from "antd";
import { IRefinancingPermanentLoanAssumptions } from "../../../../types/loan";
import moment from "moment";
import NumberField from "../../../shared/number-field";
// @ts-ignore
import { PV } from "@formulajs/formulajs";
import RefinancingLoanSchedule from "./refinancing-loan-schedule";
import styled from "styled-components";
const { Option } = Select;
const { TabPane } = Tabs;

interface StyledTableProps extends TableProps<any> {
  refinancing: boolean;
}

const Table = styled(AntdTable)<StyledTableProps>`
  tbody > :not(tr:nth-child(2)) {
    opacity: ${(props) => (props.refinancing ? 1 : 0.5)}!important;
  }
`;

const columns = [
  {
    dataIndex: "description",
    key: "description",
    width: "50%",
  },
  {
    dataIndex: "percentField",
    key: "percentField",
    width: "25%",
  },
  {
    dataIndex: "value",
    key: "value",
    width: "25%",
  },
];

const RefinancingLoanAssumptions = () => {
  const dispatch = useAppDispatch();
  const {
    cashFlow,
    loan: {
      refinancingPermanentLoanAssumptions,
      loanAssumptions: { date: bridgeLoanDate },
      acquisitionLoanSchedule,
      loanTotal,
    },
    property: {
      investmentTimeline: { exitMonth },
    },
  } = useAppSelector((state) => {
    return {
      loan: state.loanReducer.loan,
      cashFlow: state.cashFlowReducer.distribution!,
      property: state.propertyReducer.property,
    };
  });
  const [form, setForm] = useState<IRefinancingPermanentLoanAssumptions>({
    ...refinancingPermanentLoanAssumptions,
    originalLoanRepayment:
      refinancingPermanentLoanAssumptions.originalLoanRepayment || loanTotal,
  });
  const formRef = useRef<IRefinancingPermanentLoanAssumptions | undefined>();

  const handleFormChange = (field: string, value: any) => {
    setForm((form) => ({ ...form, [field]: value }));
  };

  useEffect(() => {
    const { month } = form;
    let noiTrailing12Months = 0;
    if (
      month > 0 &&
      cashFlow &&
      cashFlow.monthlyProforma &&
      cashFlow.monthlyProforma.length > 0
    ) {
      [...Array(12)].forEach((_, trailingMonth) => {
        if (month - trailingMonth > 0) {
          noiTrailing12Months +=
            cashFlow.monthlyProforma[month - trailingMonth].netOperatingIncome;
        }
      });
    }

    setForm((form) => ({ ...form, noiTrailing12Months }));
  }, [form.month, cashFlow]);

  useEffect(() => {
    let newForm = { ...form };
    const {
      loanType,
      interestRate,
      amortizationYears,
      noiTrailing12Months,
      dscrPercentage,
      propertyValuation,
      loanFeesPercentage,
    } = newForm;
    newForm.dscrTotal =
      dscrPercentage > 0
        ? -1 *
          PV(
            interestRate / 100,
            amortizationYears,
            noiTrailing12Months / dscrPercentage
          )
        : 0;
    newForm.ltvTotal = propertyValuation * (form.ltvPercentage / 100);
    switch (loanType) {
      case "dscr":
        newForm.refinanceLoanAmount = newForm.dscrTotal;
        break;
      case "ltv":
        newForm.refinanceLoanAmount = newForm.ltvTotal;
        break;
      default:
        break;
    }
    newForm.loanFees = newForm.refinanceLoanAmount * (loanFeesPercentage / 100);

    setForm(newForm);
  }, [
    form.loanType,
    form.interestRate,
    form.amortizationYears,
    form.noiTrailing12Months,
    form.dscrPercentage,
    form.ltvPercentage,
    form.propertyValuation,
    form.loanFeesPercentage,
  ]);

  useEffect(() => {
    let newForm = { ...form };
    const { noiTrailing12Months, capRate } = newForm;
    if (capRate > 0) {
      newForm.propertyValuation = noiTrailing12Months / (capRate / 100);
    } else {
      newForm.propertyValuation = 0;
    }

    setForm(newForm);
  }, [form.noiTrailing12Months, form.capRate]);

  useEffect(() => {
    formRef.current = form;
  }, [form]);

  interface IColumn {
    key: string;
    description: any;
    value: any;
    percentField?: any;
  }

  const dataSource: IColumn[] = [
    {
      key: "1",
      description: "Refinance?",
      value: (
        <Select
          style={{ minWidth: 100 }}
          value={form.refinancing}
          onChange={(e) => handleFormChange("refinancing", e)}
        >
          <Option value={true}>Yes</Option>
          <Option value={false}>No</Option>
        </Select>
      ),
    },
    {
      key: "2",
      description: "Refinance Method",
      value: (
        <Select
          style={{ minWidth: 100 }}
          disabled={!form.refinancing}
          value={form.loanType}
          onChange={(e) => handleFormChange("loanType", e)}
        >
          <Option value="ltv">LTV</Option>
          <Option value="dscr">DSCR</Option>
        </Select>
      ),
    },
    {
      key: "3",
      description: "Lender",
      value: (
        <Input
          defaultValue={form.lender}
          disabled={!form.refinancing}
          type="text"
          placeholder="Lender"
          onBlur={(e) => handleFormChange("lender", e.target.value)}
          style={{ minWidth: 100 }}
        />
      ),
    },
    {
      key: "4",
      description: "Investment Exit Date",
      value: (
        <span>
          {moment(bridgeLoanDate)
            .add(exitMonth, "M")
            .endOf("M")
            .format("MM/DD/YYYY")}
        </span>
      ),
    },
    {
      key: "5",
      description: "Refinance Date",
      value: (
        <span>
          {moment(bridgeLoanDate)
            .add(form.month, "M")
            .endOf("M")
            .format("MM/DD/YYYY")}
        </span>
      ),
    },
    {
      key: "6",
      description: "Refinance Month",
      value: (
        <Input
          value={form.month}
          disabled={!form.refinancing}
          type="number"
          min={0}
          placeholder="Month"
          onChange={(e) =>
            handleFormChange(
              "month",
              Math.min(
                exitMonth,
                Math.min(120, parseFloat(e.target.value) || 0)
              )
            )
          }
          style={{ minWidth: 100 }}
        />
      ),
    },
    {
      key: "7",
      description: "Refinance Year",
      value: <span>Year {acquisitionLoanSchedule[form.month]?.year}</span>,
    },
    {
      key: "8",
      description: "Interest Rate",
      value: (
        <Input
          suffix={<span>%</span>}
          disabled={!form.refinancing}
          value={form.interestRate}
          type="number"
          min={0}
          max={100}
          step={0.01}
          placeholder="Rate"
          onChange={(e) =>
            handleFormChange("interestRate", parseFloat(e.target.value) || 0)
          }
          style={{ minWidth: 100 }}
        />
      ),
    },
    {
      key: "9",
      description: "Interest Only Months",
      value: (
        <Input
          value={form.interestOnlyMonths}
          disabled={!form.refinancing}
          type="number"
          min={0}
          max={100}
          step={1}
          placeholder="Months"
          onChange={(e) =>
            handleFormChange(
              "interestOnlyMonths",
              parseFloat(e.target.value) || 0
            )
          }
          style={{ minWidth: 100 }}
        />
      ),
    },
    {
      key: "10",
      description: "Amortization Years",
      value: (
        <Input
          value={form.amortizationYears}
          disabled={!form.refinancing}
          type="number"
          min={0}
          step={1}
          placeholder="Years"
          onChange={(e) =>
            handleFormChange(
              "amortizationYears",
              parseFloat(e.target.value) || 0
            )
          }
          style={{ minWidth: 100 }}
        />
      ),
    },
    {
      key: "11",
      description: "Refinancing NOI (trailing 12 month NOI)",
      value: <NumberField type="money" value={form.noiTrailing12Months} />,
    },
    {
      key: "12",
      description: "Refinancing Cap Rate",
      value: (
        <Input
          suffix={<span>%</span>}
          disabled={!form.refinancing}
          value={form.capRate}
          type="number"
          min={0}
          max={100}
          step={0.01}
          placeholder="Percentage"
          onChange={(e) =>
            handleFormChange("capRate", parseFloat(e.target.value) || 0)
          }
          style={{ minWidth: 100 }}
        />
      ),
    },
    {
      key: "13",
      description: "Property Valuation",
      value: <NumberField type="money" value={form.propertyValuation} />,
    },
    {
      key: "14",
      description: "Refinancing Loan Sizing - LTV",
      //@ts-ignore
      percentField: (
        <Input
          suffix={<span>%</span>}
          disabled={!form.refinancing}
          value={form.ltvPercentage}
          type="number"
          min={0}
          max={100}
          step={0.01}
          placeholder="Percentage"
          onChange={(e) =>
            handleFormChange("ltvPercentage", parseFloat(e.target.value) || 0)
          }
          style={{ minWidth: 100 }}
        />
      ),
      value: <NumberField type="money" value={form.ltvTotal} />,
    },
    {
      key: "15",
      description: "Refinancing Loan Sizing - DSCR",
      //@ts-ignore
      percentField: (
        <Input
          suffix={<span>x</span>}
          disabled={!form.refinancing}
          value={form.dscrPercentage}
          type="number"
          min={0}
          step={0.01}
          placeholder="Multiplier"
          onChange={(e) =>
            handleFormChange("dscrPercentage", parseFloat(e.target.value) || 0)
          }
          style={{ minWidth: 100 }}
        />
      ),
      value: <NumberField type="money" value={form.dscrTotal} />,
    },
    {
      key: "16",
      description: <b>Refinance Loan Amount</b>,
      value: (
        <b>
          <NumberField type="money" value={form.refinanceLoanAmount} />
        </b>
      ),
    },
    {
      key: "17",
      description: "Loan Fees",
      //@ts-ignore
      percentField: (
        <Input
          suffix={<span>%</span>}
          disabled={!form.refinancing}
          value={form.loanFeesPercentage}
          type="number"
          min={0}
          max={100}
          step={0.01}
          placeholder="Percentage"
          onChange={(e) =>
            handleFormChange(
              "loanFeesPercentage",
              parseFloat(e.target.value) || 0
            )
          }
          style={{ minWidth: 100 }}
        />
      ),
      value: (
        <NumberField
          type="money"
          value={form.refinanceLoanAmount * (form.loanFeesPercentage / 100)}
        />
      ),
    },
    {
      key: "18",
      description: "Original Loan Repayment",
      value: <NumberField type="money" value={form.originalLoanRepayment} />,
    },
    {
      key: "19",
      description: "Refinancing Distribution Proceeds",
      value: (
        <NumberField
          type="money"
          value={
            form.refinanceLoanAmount -
            form.loanFees -
            form.originalLoanRepayment
          }
        />
      ),
    },
  ];

  const dispatchUpdate = () => {
    const { month, loanFees, originalLoanRepayment, refinanceLoanAmount } =
      formRef.current!;
    dispatch({
      type: "UPDATE_LOAN_REFINANCING_PERMANENT_LOAN_ASSUMPTIONS",
      payload: {
        ...formRef.current!,
        date: moment(bridgeLoanDate).add(month, "M").endOf("M").toDate(),
        distributionProceeds:
          refinanceLoanAmount - loanFees - originalLoanRepayment,
      },
    });

    dispatch({
      type: "UPDATE_CASH_FLOW_DISTRIBUTION",
      payload: { property: getStoreProperty(), loan: getStoreLoan() },
    });
  };

  useEffect(() => {
    dispatch({
      type: "UPDATE_CASH_FLOW_DISTRIBUTION",
      payload: { property: getStoreProperty(), loan: getStoreLoan() },
    });

    return () => {
      dispatchUpdate();
    };
  }, []);

  return (
    <>
      <Tabs
        defaultActiveKey="1"
        onTabClick={() => dispatchUpdate()}
        style={{ marginTop: -15 }}
      >
        <TabPane tab="Assumptions" key={1}>
          <Row>
            <Col
              xs={24}
              sm={24}
              md={20}
              lg={16}
              xl={12}
              xxl={10}
              style={{ paddingLeft: 0 }}
            >
              <Table
                refinancing={form.refinancing}
                dataSource={dataSource}
                columns={columns}
                pagination={false}
                bordered
                showHeader={false}
                scroll={{ x: true }}
                style={{ whiteSpace: "nowrap" }}
              />
            </Col>
          </Row>
        </TabPane>
        { form.refinancing && 
          <TabPane disabled={loanTotal <= 0} tab="Refinancing Loan Schedule" key={2}>
            <RefinancingLoanSchedule />
          </TabPane>
        }
      </Tabs>
    </>
  );
};
export default RefinancingLoanAssumptions;
