import { Col, Input, Table, Select, Row, Card } from "antd";
import { useEffect, useRef, useState } from "react";
import moment from "moment";
import locationService from "../../../../api/services/location";
import ImageUploader from "../../../shared/image-uploader";
import TextArea from "antd/lib/input/TextArea";
import s3Service, { IS3File } from "../../../../api/services/s3";
import useMediaQuery from "use-media-antd-query";
import { useAppDispatch, useAppSelector } from "../../../../store";

const { Option } = Select;

const PropertyOverview = () => {
  const { property } = useAppSelector((state) => state.propertyReducer);
  const dispatch = useAppDispatch();
  const [form, setForm] = useState({
    name: property.name || "",
    type: property.type || "",
    streetAddress: property.streetAddress || "",
    city: property.city || "",
    state: property.state || "",
    county: property.county || "",
    yearBuilt: property.yearBuilt || moment(new Date()).year(),
    buildingClass: property.buildingClass || undefined,
    currentOccupancy: property.currentOccupancy || 0,
    zipCode: property.zipCode || 0,
    investmentOverview: property.investmentOverview || "",
    marketInformationAndCurrentStatus:
      property.marketInformationAndCurrentStatus || "",
  });
  const [images, setImages] = useState<IS3File[]>([]);
  const formRef = useRef(null);
  const colSize = useMediaQuery();

  const columns = [
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: colSize === "xs" ? "50%" : "65%",
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
      width: colSize === "xs" ? "50%" : "35%",
    },
  ];

  const handleFormChange = (field: string, value: any) => {
    setForm((form) => ({ ...form, [field]: value }));
    if (field === "zipCode") {
      setTimeout(() => {
        locationService
          .get(value)
          .then((res) => {
            if (res.data.location) {
              const { city, state, county } = res.data.location;
              setForm((form) => ({
                ...form,
                zipCode: value,
                city,
                state,
                county,
              }));
            } else {
              setForm((form) => ({
                ...form,
                zipCode: value,
                city: "",
                state: "",
                county: "",
              }));
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }, 100);
    }
  };

  useEffect(() => {
    // @ts-ignore
    formRef.current = form;
  }, [form]);

  useEffect(() => {
    if (formRef.current) {
      dispatch({ type: "UPDATE_PROPERTY_OVERVIEW", payload: formRef.current });
    }
  }, [formRef.current]);

  useEffect(() => {
    if (property._id)
      s3Service
        .download(`property/${property._id}`)
        .then((res) => {
          if (res.data) {
            setImages(res.data.files);
          }
        })
        .catch((err) => console.log(err));
  }, [property._id]);

  const dataSource = [
    {
      key: "1",
      description: "Property Name",
      value: (
        <Input
          value={form.name}
          type="text"
          placeholder="Property name"
          onChange={(e) => handleFormChange("name", e.target.value)}
        />
      ),
    },
    {
      key: "2",
      description: "Property Type",
      value: (
        <Input
          value={form.type}
          type="text"
          placeholder="Property type"
          onChange={(e) => handleFormChange("type", e.target.value)}
        />
      ),
    },
    {
      key: "3",
      description: "Street Address",
      value: (
        <Input
          value={form.streetAddress}
          type="text"
          placeholder="Street address"
          onChange={(e) => handleFormChange("streetAddress", e.target.value)}
        />
      ),
    },
    {
      key: "4",
      description: "Zip Code",
      value: (
        <Input
          value={form.zipCode}
          type="number"
          min={0}
          max={99999}
          placeholder="Zip Code"
          onChange={(e) =>
            handleFormChange("zipCode", parseFloat(e.target.value) || 0)
          }
        />
      ),
    },
    {
      key: "5",
      description: "City",
      value: (
        <Input value={form.city} type="text" placeholder="City" disabled />
      ),
    },
    {
      key: "6",
      description: "State",
      value: (
        <Input value={form.state} type="text" placeholder="State" disabled />
      ),
    },
    {
      key: "7",
      description: "County",
      value: (
        <Input value={form.county} type="text" placeholder="County" disabled />
      ),
    },
    {
      key: "8",
      description: "Year Built",
      value: (
        <Input
          value={form.yearBuilt}
          type="number"
          min={0}
          max={moment(new Date()).year()}
          placeholder="Year built"
          onChange={(e) =>
            handleFormChange("yearBuilt", parseFloat(e.target.value) || 0)
          }
        />
      ),
    },
    {
      key: "9",
      description: "Building Class",
      value: (
        <Select
          value={form.buildingClass}
          style={{ width: "100%" }}
          placeholder="Building class"
          onChange={(e) => handleFormChange("buildingClass", e)}
        >
          <Option value="Class A">Class A</Option>
          <Option value="Class B">Class B</Option>
          <Option value="Class C">Class C</Option>
          <Option value="Class D">Class D</Option>
        </Select>
      ),
    },
    {
      key: "10",
      description: "Current Occupancy",
      value: (
        <Input
          suffix={<span>%</span>}
          value={form.currentOccupancy}
          type="number"
          min={0}
          max={100}
          placeholder="Current occupancy"
          onChange={(e) =>
            handleFormChange(
              "currentOccupancy",
              parseFloat(e.target.value) || 0
            )
          }
        />
      ),
    },
  ];

  return (
    <>
      <Row gutter={24}>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={16}
          xl={16}
          xxl={12}
          style={{ marginBottom: 25 }}
        >
          <Table
            columns={columns}
            dataSource={dataSource}
            bordered
            pagination={false}
            showHeader={false}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={12}>
          <Card>
            <div style={{ marginBottom: 25 }}>
              <h3>Investment Overview</h3>
              <TextArea
                rows={3}
                value={form.investmentOverview}
                placeholder="This section is dedicated to provide an overview of the investment opportunity."
                onChange={(e) =>
                  handleFormChange("investmentOverview", e.target.value)
                }
              />
            </div>
            <div style={{ marginBottom: 25 }}>
              <h3>Market Information & Current Status</h3>
              <TextArea
                rows={6}
                value={form.marketInformationAndCurrentStatus}
                placeholder="This section is dedicated to provide an overview of available market information and any relevant current status updates relating to the property."
                onChange={(e) =>
                  handleFormChange(
                    "marketInformationAndCurrentStatus",
                    e.target.value
                  )
                }
              />
            </div>
            <div>
              <h3>Images</h3>
              <ImageUploader propertyId={property._id!} images={images} />
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default PropertyOverview;
