import { store } from '../..';
import formSteps from '../../../components/property-analyzer/steps/form-steps';
import generateEmptyProperty from '../../../components/property-analyzer/templates/empty/property';
import { IProperty } from '../../../types/property';
import { PropertyAction } from "../../actions/property";

export interface IPropertyState {
  property: IProperty;
}

const initialState: IPropertyState = {
  property: generateEmptyProperty(),
};

export const propertyReducer = (
  state: IPropertyState = initialState,
  action: PropertyAction
): IPropertyState => {
  const { property } = state;
  switch (action.type) {
    case "UPDATE_PROPERTY":
      return { ...state, property: action.payload };
    case "UPDATE_PROPERTY_OVERVIEW":
      const { name, streetAddress, city, state: propertyState, zipCode, county, yearBuilt, buildingClass, currentOccupancy, type, investmentOverview, marketInformationAndCurrentStatus, images } = action.payload;
      property.name = name;
      property.streetAddress = streetAddress;
      property.city = city;
      property.state = propertyState;
      property.zipCode = zipCode;
      property.county = county;
      property.yearBuilt = yearBuilt;
      property.buildingClass = buildingClass;
      property.currentOccupancy = currentOccupancy;
      property.type = type;
      property.investmentOverview = investmentOverview;
      property.marketInformationAndCurrentStatus = marketInformationAndCurrentStatus;
      property.images = images;
      return { ...state, property };
    case "UPDATE_PROPERTY_RENT_ROLL_UNIT_MIX":
      property.updateRentRollUnitMix(action.payload);
      return state;
    case "UPDATE_PROPERTY_RENOVATIONS":
      const { payload: { interiorRenovations, exteriorRenovations, contingencyPercentage } } = action;
      property.updateInteriorRenovations(interiorRenovations || []);
      property.updateExteriorRenovations(exteriorRenovations || []);
      property.updateRenovationContingencyPercentage(contingencyPercentage || 0);
      setTimeout(() => store.dispatch({ type: "UPDATE_LOAN_CAP_EX_RENOVATION_BUDGET", payload: property.renovations.totalBudget }));
      return { ...state, property };
    case "UPDATE_PROPERTY_INCOME_EXPENSE_ITEMS":
      const { payload: { rentIncomeItems, otherIncomeItems, expenseItems, grossPotentialRent } } = action;
      property.rentIncomeItems = rentIncomeItems;
      property.otherIncomeItems = otherIncomeItems;
      property.expenseItems = expenseItems;
      property.grossPotentialRent = grossPotentialRent;
      property.yearOneProformaAssumptions.rentIncomeItems.forEach(item => item.amount = rentIncomeItems.find(e => e.description === item.description)!.amount)
      property.yearOneProformaAssumptions.otherIncomeItems.forEach(item => item.amount = otherIncomeItems.find(e => e.description === item.description)!.amount)
      property.yearOneProformaAssumptions.expenseItems.forEach(item => item.amount = expenseItems.find(e => e.description === item.description)!.amount)
      return { ...state, property };
    case "UPDATE_PROPERTY_RESERVES":
      const { taxes, insurance } = action.payload;
      property.reservesInsurance = insurance;
      property.reservesTaxes = taxes;
      property.updateReserves();
      return { ...state, property }
    case "UPDATE_PROPERTY_ASSUMPTIONS":
      const { growthAssumptions, annualAssumptions } = action.payload;
      property.growthAssumptions = growthAssumptions;
      property.annualAssumptions = annualAssumptions;
      property.updateRentRollUnitMix(property.rentRollUnitMix);
      return { ...state, property }
    case "UPDATE_PROPERTY_YEAR_ONE_PROFORMA_ASSUMPTIONS":
      property.yearOneProformaAssumptions = {
        ...property.yearOneProformaAssumptions,
        otherIncomeItems: action.payload.otherIncomeItems,
        expenseItems: action.payload.expenseItems,
      }
      return { ...state, property };
    case "UPDATE_PROPERTY_INVESTMENT_TIMELINE":
      property.investmentTimeline = action.payload;
      return { ...state, property };
    case "UPDATE_PROPERTY_EXIT_DISPOSITION_SUMMARY":
      property.exitDispositionSummary = action.payload;
      return { ...state, property };
    case "UPDATE_PROPERTY_PROMOTE_INCENTIVE_HURDLES":
      property.promoteIncentiveHurdles = action.payload;
      return { ...state, property };
    case "UPDATE_PROPERTY_CURRENT_STEP":
      const currentStep = property.inProgress ? Math.max(property.currentStep, action.payload) : formSteps.length - 1;
      property.currentStep = currentStep;
      return { ...state, property };
    default:
      return state;
  }
};