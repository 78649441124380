import { Upload, Modal, notification } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import s3Service, { IS3File } from '../../../api/services/s3';

interface IImageUploaderProps {
  viewOnly?: boolean;
  propertyId: string;
  images: IS3File[];
}

const getBase64 = (file: UploadFile): Promise<string | ArrayBuffer | null> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file.originFileObj as Blob);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

const ImageUploader = ({ viewOnly = false, propertyId, images }: IImageUploaderProps) => {
  const [previewVisible, setPreviewVisible] = useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState<string>("");
  const [previewTitle, setPreviewTitle] = useState<string>("");
  const [fileList, setFilesList] = useState<IS3File[]>(images);

  const handleCancel = () => setPreviewVisible(false);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      const result = await getBase64(file);
      if (result) {
        file.preview = result.toString();
      }
    }

    setPreviewImage(file.url || file.preview || "");
    setPreviewVisible(true);
    setPreviewTitle(file.name || (file.url || "").substring((file.url || "").lastIndexOf('/') + 1));
  };

  const handleChange = (event: UploadChangeParam<UploadFile<any>>) => {
    const { file, fileList } = event;
    if (file.status === "removed") {
      s3Service.delete(`property/${propertyId}/${file.name}`)
        .then(() => setFilesList(fileList as IS3File[]))
        .catch(() => {
          notification.error({
            message: "An error was encountered while removing image from property",
            duration: 5,
            placement: 'bottomRight',
          });
        })
    } else {
      s3Service.upload(propertyId, 'property', fileList[fileList.length - 1])
        .then(() => setFilesList(fileList as IS3File[]))
        .catch(() => {
          notification.error({
            message: "An error was encountered while uploading image to property",
            duration: 5,
            placement: 'bottomRight',
          });
        });
    }
  }

  useEffect(() => {
    setFilesList(images);
  }, [images])

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  useEffect(() => {
    if (document.readyState === "complete" && viewOnly) {
      const elements = document.getElementsByClassName('ant-upload ant-upload-select');
      while (elements.length > 0) {
        // @ts-ignore
        elements[0].parentNode.removeChild(elements[0]);
      }
    }
  }, [document.readyState])

  return (
    <>
      <Upload
        disabled={viewOnly}
        beforeUpload={() => false}
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
      >
        {fileList.length >= 8 ? null : uploadButton}
      </Upload>
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </>
  );
}

export default ImageUploader;