import { Input, Table } from "antd";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import { IPromoteIncentiveHurdles } from "../../../../../types/property";

const columns = [
  {
    dataIndex: 'description',
    key: 'description',
    minWidth: 100,
  },
  {
    dataIndex: 'value',
    key: 'value',
    minWidth: 100,
  },
];

const PromoteIncentiveHurdles = () => {
  const dispatch = useDispatch();
  const { property: { promoteIncentiveHurdles } } = useSelector((state: RootState) => state.propertyReducer);
  const [form, setForm] = useState<IPromoteIncentiveHurdles>(promoteIncentiveHurdles);
  const formRef = useRef<IPromoteIncentiveHurdles | undefined>();

  const handleFormChange = (field: string, value: any) => {
    if (field === "investorPreferredReturn") {
      setForm(form => ({ ...form, investorPreferredReturn: value, t1HurdlePercentage: value }));
    } else {
      setForm(form => ({ ...form, [field]: value }));
    }
  };

  useEffect(() => {
    formRef.current = form;
  }, [form])

  useEffect(() => {
    return () => {
      dispatch({ type: "UPDATE_PROPERTY_PROMOTE_INCENTIVE_HURDLES", payload: formRef.current });
    }
  }, [])

  const dataSource = [
    {
      key: '1',
      description: 'Investor Preferred Return',
      value: <Input suffix={<span>%</span>} style={{ minWidth: 100, float: 'right' }} type="number" min={0} max={100} step={1} value={form.investorPreferredReturn} onChange={e => handleFormChange("investorPreferredReturn", parseFloat(e.target.value) || 0)} />
    },
    {
      key: '2',
      description: <b>Promote Hurdles/Tiers</b>,
      value: <b>GP/Sponsor Promote</b>
    },
    {
      key: '3',
      description: <Input disabled prefix={<span>{">"}</span>} suffix={<span>% IRR</span>} style={{ minWidth: 100, float: 'right' }} type="number" min={0} max={100} step={1} value={form.t1HurdlePercentage} onChange={e => handleFormChange("t1HurdlePercentage", parseFloat(e.target.value) || 0)} />,
      value: <Input suffix={<span>%</span>} style={{ minWidth: 100, float: 'right' }} type="number" min={0} max={100} step={1} value={form.t1PromotePercentage} onChange={e => handleFormChange("t1PromotePercentage", parseFloat(e.target.value) || 0)} />,
    },
    {
      key: '4',
      description: <Input prefix={<span>{">"}</span>} suffix={<span>% IRR</span>} style={{ minWidth: 100, float: 'right' }} type="number" min={0} max={100} step={1} value={form.t2HurdlePercentage} onChange={e => handleFormChange("t2HurdlePercentage", parseFloat(e.target.value) || 0)} />,
      value: <Input suffix={<span>%</span>} style={{ minWidth: 100, float: 'right' }} type="number" min={0} max={100} step={1} value={form.t2PromotePercentage} onChange={e => handleFormChange("t2PromotePercentage", parseFloat(e.target.value) || 0)} />,
    },
    {
      key: '5',
      description: <Input prefix={<span>{">"}</span>} suffix={<span>% IRR</span>} style={{ minWidth: 100, float: 'right' }} type="number" min={0} max={100} step={1} value={form.t3HurdlePercentage} onChange={e => handleFormChange("t3HurdlePercentage", parseFloat(e.target.value) || 0)} />,
      value: <Input suffix={<span>%</span>} style={{ minWidth: 100, float: 'right' }} type="number" min={0} max={100} step={1} value={form.t3PromotePercentage} onChange={e => handleFormChange("t3PromotePercentage", parseFloat(e.target.value) || 0)} />,
    },
    {
      key: '6',
      description: <Input prefix={<span>{">"}</span>} suffix={<span>% IRR</span>} style={{ minWidth: 100, float: 'right' }} type="number" min={0} max={100} step={1} value={form.t4HurdlePercentage} onChange={e => handleFormChange("t4HurdlePercentage", parseFloat(e.target.value) || 0)} />,
      value: <Input suffix={<span>%</span>} style={{ minWidth: 100, float: 'right' }} type="number" min={0} max={100} step={1} value={form.t4PromotePercentage} onChange={e => handleFormChange("t4PromotePercentage", parseFloat(e.target.value) || 0)} />,
    }
  ];



  return <Table columns={columns} dataSource={dataSource} bordered pagination={false} showHeader={false} style={{whiteSpace:'nowrap'}} scroll={{x:true}} />

}
export default PromoteIncentiveHurdles;