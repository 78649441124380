import IFeedback from "../../../types/feedback";
import http from "../../http";

const getRoute = (path?: string) => {
  const root = "/feedback";
  if (!path) return root;
  return root + "/" + path;
};

const feedbackService = {
  create: (feedback: IFeedback) => http.post<string>(getRoute(), { feedback }),
};

export default feedbackService;
