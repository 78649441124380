import { Modal as AntdModal } from "antd";
import React, { useState, useEffect } from "react";

type ModalProps = {
  title?: string;
  visible: boolean;
  width?: number | string;
  centered?: boolean;
  onDismiss(): void;
};

const Modal: React.FC<ModalProps> = ({
  title,
  visible,
  width,
  onDismiss,
  children,
  centered = true,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleDismiss = () => {
    setIsModalVisible(false);
    onDismiss();
  };

  useEffect(() => {
    setIsModalVisible(visible);
  }, [visible]);

  return (
    <AntdModal
      title={title}
      visible={isModalVisible}
      onOk={handleDismiss}
      onCancel={handleDismiss}
      footer={false}
      width={width}
      centered={centered}
    >
      {children}
    </AntdModal>
  );
};

export default Modal;
