import formSteps from "../../components/property-analyzer/steps/form-steps";
import Property, { IProperty } from "../property";
import { IRentRollUnitBaseValues, RentRollUnit } from "../rent-roll-unit";

const mapProperty = (property: IProperty): Property => {
  let newProperty = new Property();
  Object.keys(property).forEach(key => {
      if (key !== "rentRollUnitMix") {
        // @ts-ignore
        newProperty[key] = property[key];
      }
  });

  const rentRollUnitMix = property.rentRollUnitMix.map(r => {
    const baseValues: IRentRollUnitBaseValues = {
      type : r.type,
      unitCount:  r.unitCount,
      squareFtPerUnit:  r.squareFtPerUnit,
      rent:  r.rent,
      marketRent:  r.marketRent,
      renovatedUnits:  r.renovatedUnits,
      renovatedMarketRent:  r.renovatedMarketRent,
      unitsScheduledForRenovation:  r.unitsScheduledForRenovation,
      renovationsStartedPerMonth:  r.renovationsStartedPerMonth,
      renovationStartMonth:  r.renovationStartMonth,
      renovationDownTime:  r.renovationDownTime,
    }
    return new RentRollUnit(baseValues, newProperty.getGrowthAssumption("grossPotentialRent"));
  });
  newProperty.updateRentRollUnitMix(rentRollUnitMix);

  newProperty.exitDispositionSummary.date =  newProperty.exitDispositionSummary.date !== null ? new Date(newProperty.exitDispositionSummary.date) : null;
  newProperty.investmentTimeline = {
    ...newProperty.investmentTimeline,
    acquisitionDate: newProperty.investmentTimeline.acquisitionDate !== null ? new Date(newProperty.investmentTimeline.acquisitionDate) : null,
    exitDispositionDate: newProperty.investmentTimeline.exitDispositionDate !== null ? new Date(newProperty.investmentTimeline.exitDispositionDate) : null,
    refinanceDate: newProperty.investmentTimeline.refinanceDate !== null ? new Date(newProperty.investmentTimeline.refinanceDate) : null,
  }
  newProperty.created = property.createdAt ? new Date(property.createdAt) : undefined;
  newProperty.updated = property.updatedAt ? new Date(property.updatedAt) : undefined;
  newProperty.updateInteriorRenovations(property.renovations.interiorRenovations);
  newProperty.updateExteriorRenovations(property.renovations.exteriorRenovations);
  newProperty.updateRenovationContingencyPercentage(property.renovations.contingencyPercentage);
  newProperty.currentStep = newProperty.inProgress ? newProperty.currentStep : formSteps.length - 1;

  return newProperty;

}

export default mapProperty;

