import axios from 'axios';

let baseURL = process.env.REACT_APP_API_URL;

const http = axios.create({
  baseURL,
});

export const updateHeaderAuth0UserId = (auth0Id?: string) => {
  if (auth0Id) {
    http.interceptors.request.use (
      (config) => {
        config.headers!.AUTH0_USER_ID = auth0Id;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }
};

export default http;