import { Table, } from "antd";
import { IContactDetails } from "../../../api/services/user";
import formatPhoneNumber from "../../../utils/format-phone-number";
import Avatar from "../../shared/avatar";
import Button from "antd-button-color";

type ContactRequestsProps = {
  contacts: IContactDetails[];
  loading: boolean;
  onAccept(contact: IContactDetails): void;
  onDecline(contact: IContactDetails): void;
};

const ContactRequests: React.FC<ContactRequestsProps> = ({
  contacts,
  loading,
  onAccept,
  onDecline
}) => {
  return (
    <Table
      dataSource={[...contacts]}
      bordered
      scroll={{ x: 0 }}
      pagination={false}
      loading={loading}
      style={{ whiteSpace: "nowrap" }}
      columns={[
        {
          key: "2",
          width: "20%",
          title: "Name",
          render: (record: IContactDetails) => (
            <div>
              <Avatar
                userId={record.userId}
                firstName={record.firstName}
                lastName={record.lastName}
                avatarColor={record.avatarColor}
              />
              <span style={{ marginLeft: 10 }}>
                {record.firstName + " " + record.lastName}
              </span>
            </div>
          ),
        },
        {
          key: "4",
          title: "Email",
          width: "15%",
          render: (record: IContactDetails) => (
            <a href={`mailto:${record.email}`}>{record.email}</a>
          ),
        },
        {
          key: "5",
          title: "Phone",
          width: "15%",
          render: (record: IContactDetails) => (
            <a href={`tel:${record.phoneNumber}`}>
              {formatPhoneNumber(record.phoneNumber)}
            </a>
          ),
        },
        {
          key: "6",
          title: "Location",
          width: "15%",
          render: (record: IContactDetails) => (
            <span>
              {" "}
              {record.city + (record.city ? `, ${record.state}` : null)}
            </span>
          ),
        },
        {
          key: "7",
          title: "Actions",
          width: "10%",
          render: (record: IContactDetails) => {
            return (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <Button onClick={() => onAccept(record)} type="success" size="small">
                  Accept
                </Button>
                <Button onClick={() => onDecline(record)} type="primary" danger size="small">
                  Decline
                </Button>
              </div>
            );
          },
        },
      ]}
    />
  );
};

export default ContactRequests;
