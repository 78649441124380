const DaySkeleton: React.FC<{ style?: React.CSSProperties, onClick(): void }> = ({ style, onClick }) => {
  return (
    <div onClick={() => onClick()} style={{ ...style, border: '1px solid #001529', borderRadius: 5 }}>
      <div className="ant-skeleton-content" style={{ position: 'relative', backgroundColor: '#001529', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', borderRadius: '4px 4px 0 0', padding: 10 }}>
        <h3 className="ant-skeleton-title" style={{ background: 'white', width: '30%', marginRight: '1em' }}></h3>
        <div style={{ height: 35, width: 35, borderRadius: 100, position: 'absolute', right: 25, background: 'rgba(190, 190, 190, 0.2)' }}></div>
      </div>
      <div className="ant-skeleton-content" style={{ display: 'block', padding: 25,background: 'white', }}>
        <div style={{ border: '3px solid rgba(190, 190, 190, 0.2)', padding: 25, borderRadius: 4 }}>
          <h3 className="ant-skeleton-title" style={{ width: '50%' }}></h3>
          <ul className="ant-skeleton-paragraph" >
            <li></li>
            <li></li>
            <li></li>
            <li style={{ width: '12%', display: 'inline-block', marginRight: 10, background: '#28a745' }}></li>
            <li style={{ width: '12%', display: 'inline-block', marginRight: 10, background: '#1890ff' }}></li>
            <li style={{ width: '12%', display: 'inline-block', marginRight: 10, background: '#eca52b' }}></li>
            <li style={{ width: '12%', display: 'inline-block', background: '#ff4d4f' }}></li>
          </ul>

        </div>
      </div>
      <div className="ant-skeleton-content" style={{ backgroundColor: '#001529', display: 'flex', justifyContent: 'flex-start', borderRadius: '0 0 4px 4px', padding: 10 }}>
      </div>
    </div>
  )
}

export { DaySkeleton }