import Renovations from "./steps/renovations";
import PropertyOverview from "./steps/property-overview";
import Units from "./steps/units";
import BridgeLoanAssumptions from "./steps/loan-assumptions";
import AcquisitionCosts from "./steps/acquisition-costs";
import formSteps from "./steps/form-steps";
import ProformaExpenseReconciliation from "./steps/proforma-expense-reconciliation";
import AdditionalReservesDetails from "./steps/additional-reserves-details";
import SourcesAndUses from "./steps/sources-and-uses";
import OperatingAssumptions from "./steps/operating-assumptions";
import YearOneProformaAssumptions from "./steps/year-one-proforma-assumptions";
import RefinancingLoanAssumptions from "./steps/refinancing-loan-assumptions";
import InvestmentTimeline from "./steps/investment-timeline";
import ExitDispositionSummary from "./steps/exit-disposition-summary";
import InvestmentSummary from "./steps/investment-summary";
import { useAppDispatch, useAppSelector } from "../../store";
import ProformaViewer from "./steps/proforma-viewer";
import { Card, notification, Tabs } from "antd";
import { useEffect, useState } from "react";
import "./index.css";
import propertyService from "../../api/services/property";
import { SaveOutlined } from "@ant-design/icons";
import { useHotkeys } from "react-hotkeys-hook";
import ShortcutButton from "../shared/shortcut-button";
const { TabPane } = Tabs;

const PropertyAnalyzer = () => {
  const dispatch = useAppDispatch();
  const { property, currentStepId, loan } = useAppSelector((state) => {
    return {
      property: state.propertyReducer.property,
      currentStepId: state.stepReducer.currentStepId,
      loan: state.loanReducer.loan,
    };
  });
  useHotkeys("i", () => {
    if (property.inProgress) {
      notification.warning({
        message:
          "Investment summary only available after all tabs have been completed",
        duration: 5,
        placement: "bottomRight",
      });
    } else {
      dispatch({
        type: "UPDATE_STEP_CURRENT_STEP_ID",
        payload: formSteps.length,
      });
    }
  });

  useHotkeys("p", () => {
    dispatch({
      type: "UPDATE_STEP_CURRENT_STEP_ID",
      payload: 1,
    });
  });

  useHotkeys("s", () => {
    handleSave();
  });
  const [saving, setSaving] = useState<boolean>(false);
  const [localStepId, setLocalStepId] = useState<number>(0);

  const handleSave = async () => {
    setSaving(true);
    property.currentStep = property.inProgress
      ? (property.currentStep = Math.max(property.currentStep, localStepId))
      : formSteps.length - 1;
    propertyService
      .update({ ...property, loan })
      .then(() => {
        setSaving(false);
        notification.success({
          message: "Property updated",
          duration: 5,
          placement: "bottomRight",
        });
      })
      .catch((err) => {
        setSaving(false);
        console.error(err);
        notification.error({
          message: "An error was encountered while updating property",
          duration: 5,
          placement: "bottomRight",
        });
      });
  };

  useEffect(() => {
    if (currentStepId) {
      setLocalStepId((localStepId) => Math.max(localStepId, currentStepId));
    }
  }, [currentStepId]);

  return (
    <>
      {currentStepId === formSteps.length ? null : (
        <div style={{ display: "flex", justifyContent: "end", marginTop: -58 }}>
          <ShortcutButton
            title="Save"
            type="success"
            size="small"
            shortcutKey="s"
            disabled={saving}
            loading={saving}
            onClick={() => handleSave()}
            mobileIcon={<>Save</>}
          />
        </div>
      )}
      <Card
        style={{ marginTop: currentStepId === formSteps.length ? -16 : 10 }}
      >
        <Tabs
          style={{ marginTop: -15 }}
          activeKey={currentStepId.toString()}
          onTabClick={(tab) => {
            dispatch({
              type: "UPDATE_STEP_CURRENT_STEP_ID",
              payload: parseFloat(tab),
            });
          }}
        >
          {formSteps.map((step) => (
            <TabPane
              key={step.id}
              tab={step.description}
              disabled={property.inProgress ? step.id > localStepId + 1 : false}
            />
          ))}
        </Tabs>
        <div>
          {currentStepId === 1 && <PropertyOverview />}
          {currentStepId === 2 && <Units />}
          {currentStepId === 3 && <AcquisitionCosts />}
          {currentStepId === 4 && <InvestmentTimeline />}
          {currentStepId === 5 && <BridgeLoanAssumptions />}
          {currentStepId === 6 && <AdditionalReservesDetails />}
          {currentStepId === 7 && <ProformaExpenseReconciliation />}
          {currentStepId === 8 && <YearOneProformaAssumptions />}
          {currentStepId === 9 && <OperatingAssumptions />}
          {currentStepId === 10 && <Renovations />}
          {currentStepId === 11 && <SourcesAndUses />}
          {currentStepId === 12 && <RefinancingLoanAssumptions />}
          {currentStepId === 13 && <ExitDispositionSummary />}
          {currentStepId === 14 && <ProformaViewer />}
          {currentStepId === 15 && <InvestmentSummary />}
        </div>
      </Card>
    </>
  );
};
export default PropertyAnalyzer;
