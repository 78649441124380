export type growthAssumptionCategory =  "grossPotentialRent" | "otherIncome" | "expenses" | "realEstateTaxes";
export type annualAssumptionCategory =
  "vacancyLoss" |
  "lossToLease" |
  "rentConcessions" |
  "creditLossBadDebt" |
  "nonRevenueUnits" |
  "propertyManagementFee" |
  "assetManagementFee";

export type assumptionType = "growth" | "annual"

export interface IAssumption {
  description: string;
  name: growthAssumptionCategory | annualAssumptionCategory;
  type: assumptionType;
  fixed: boolean;
  year1: number;
  year2: number;
  year3: number;
  year4: number;
  year5: number;
  year6: number;
  year7: number;
  year8: number;
  year9: number;
  year10: number;
}

export class Assumption implements IAssumption {
  description = "";
  fixed = false;
  year1 = 0;
  year2 = 0;
  year3 = 0;
  year4 = 0;
  year5 = 0;
  year6 = 0;
  year7 = 0;
  year8 = 0;
  year9 = 0;
  year10 = 0;

  constructor(description: string, name: growthAssumptionCategory | annualAssumptionCategory, type: assumptionType) {
    this.description = description;
    this.name = name;
    this.type = type;
    this.year1 = 0;
    this.year2 = 0;
    this.year3 = 0;
    this.year4 = 0;
    this.year5 = 0;
    this.year6 = 0;
    this.year7 = 0;
    this.year8 = 0;
    this.year9 = 0;
    this.year10 = 0;
  }
  name: growthAssumptionCategory | annualAssumptionCategory;
  type: assumptionType;
};


export const getGrowthAssumptions = (): IAssumption[] => [
  new Assumption("Gross Potential Rent", "grossPotentialRent", "growth"),
  new Assumption("Other Income", "otherIncome", "growth"),
  new Assumption("Expenses", "expenses", "growth"),
  new Assumption("Real Estate Taxes", "realEstateTaxes", "growth"),
];

export const getAnnualAssumptions = (): IAssumption[] => [
  new Assumption("Vacancy Loss", "vacancyLoss", "annual"),
  new Assumption("Loss-to-Lease", "lossToLease", "annual"),
  new Assumption("Rent Concessions", "rentConcessions", "annual"),
  new Assumption("Credit Loss / Bad Debt", "creditLossBadDebt", "annual"),
  new Assumption("Non-Revenue Units", "nonRevenueUnits", "annual"),
  new Assumption("Property Management Fee", "propertyManagementFee", "annual"),
  new Assumption("Asset Management Fee", "assetManagementFee", "annual"),
];