import { Input, Select, Table } from "antd";
import { useEffect, useState } from "react";
import { IReserveItem } from "../../../../../types/property";
import NumberField from "../../../../shared/number-field";
const { Option } = Select;

interface IAdditionalReservesInsuranceProps {
  reserveItem: IReserveItem;
  onFormChange(item: IReserveItem): void;
}

const AdditionalReservesInsurance = ({  reserveItem: { months, release, releaseMonths, yearOneMonthlyAmount, yearOneTotal, yearOneTotalPerUnit }, onFormChange }: IAdditionalReservesInsuranceProps) => {
  const [form, setForm] = useState<IReserveItem>({
    months,
    release,
    releaseMonths,
    yearOneMonthlyAmount,
    yearOneTotal,
    yearOneTotalPerUnit,
  });

  const handleFormChange = (field: string, value: any) => {
    if (field === "release" && value === false) {
      setForm(form => ({ ...form, [field]: value, releaseMonths: 0 }));
      return;
    }
    setForm(form => ({ ...form, [field]: value }));
  };

  useEffect(() => {
    onFormChange({
      ...form,
      months: parseInt(form.months.toString()),
      releaseMonths: parseInt(form.releaseMonths.toString()),
    });
  }, [form]);

  const insuranceColumns = [
    {
      key: 'description',
      dataIndex: 'description',
      width: '50%',
    },
    {
      key: 'months',
      dataIndex: 'months',
      width: '25%',
    },
    {
      key: 'value',
      dataIndex: 'value',
      width: '25%',
    },
  ];

  const insuranceDataSource = [
    {
      description: "Insurance Reserves",
      months: <Input suffix={<span>%</span>} style={{minWidth:100}} value={form.months} type="number" placeholder="Months" min={0} step={1} onChange={e => handleFormChange('months', parseFloat(e.target.value) || 0)} />,
      value: <NumberField type="money" value={form.yearOneMonthlyAmount * form.months} />,
    },
    {
      description: "Insurance Reserves - Release",
      months: null,
      value: <Select style={{minWidth:100}} value={form.release} defaultActiveFirstOption={false} placeholder="Release" onChange={e => handleFormChange("release", e)}>
        <Option value={true}>Yes</Option>
        <Option value={false}>No</Option>
      </Select>,
    },
    {
      description: "Insurance Reserves - Release Timing",
      months: <Input style={{minWidth:100}} value={form.releaseMonths} disabled={!form.release} type="number" placeholder="Months" min={0} step={1} onChange={e => handleFormChange('releaseMonths', parseFloat(e.target.value) || 0)} />,
      value: form.release ? <NumberField type="money" value={form.yearOneMonthlyAmount * form.months} /> : <NumberField type="money" value={0}/>,
    },
  ];

  return (
    <Table
      title={() => <b>Insurance</b>}
      pagination={false}
      bordered
      size="small"
      showHeader={false}
      columns={insuranceColumns}
      dataSource={insuranceDataSource}
      style={{whiteSpace:'nowrap'}}
      scroll={{x:true}}
    />
  )

}
export default AdditionalReservesInsurance;