import { Checkbox, Input, Table } from "antd";
import { ChangeEvent, useEffect, useState } from "react";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { IAssumption } from "../../../../../types/assumptions";

interface IGrowthAssumptionsProps {
  assumptions: IAssumption[];
  onChange(items: IAssumption[]): void;
}

const GrowthAssumptions = ({ assumptions, onChange }: IGrowthAssumptionsProps) => {
  const [growthAssumptions, setGrowthAssumptions] = useState<IAssumption[]>(assumptions);
  const years = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const handleGrowthAssumptionChange = (index: number, event: ChangeEvent<HTMLInputElement>, yearKey: string) => {
    const { target: { value } } = event;
    let newAssumptions = [...growthAssumptions];
    let parsedValue = parseFloat(value);
    if (!isNaN(parsedValue)) {
      parsedValue = parsedValue > 100 ? 100 : parsedValue < 0 ? 0 : parsedValue;
      // @ts-ignore
      newAssumptions[index][yearKey] = parsedValue;
      if (newAssumptions[index].fixed) {
        newAssumptions[index] = {
          ...newAssumptions[index],
          year2: parsedValue,
          year3: parsedValue,
          year4: parsedValue,
          year5: parsedValue,
          year6: parsedValue,
          year7: parsedValue,
          year8: parsedValue,
          year9: parsedValue,
          year10: parsedValue,
        }
      }
    } else {
      //@ts-ignore
      newAssumptions[index][yearKey] = 0;
    }
    setGrowthAssumptions(newAssumptions);
  }

  const handleApplyAll = (e: CheckboxChangeEvent, index: number) => {
    const { target: { checked } } = e;
    let newAssumptions = [...growthAssumptions];
    if (checked) {
      let fixedValue = newAssumptions[index].year1;
      newAssumptions[index] = {
        ...newAssumptions[index],
        fixed: true,
        year2: fixedValue,
        year3: fixedValue,
        year4: fixedValue,
        year5: fixedValue,
        year6: fixedValue,
        year7: fixedValue,
        year8: fixedValue,
        year9: fixedValue,
        year10: fixedValue,
      }
    } else {
      newAssumptions[index].fixed = false;
    }
    setGrowthAssumptions(newAssumptions)
  }

  useEffect(() => {
    onChange(growthAssumptions);
  }, [growthAssumptions]);

  const columns = [
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (_: any, record: any, index: number) =>
        <span style={{ whiteSpace: 'nowrap' }}>{record.description}</span>
    },
    {
      title: 'Fixed',
      dataIndex: 'fixed',
      key: 'fixed',
      render: (_: any, record: any, index: number) =>
        <Checkbox checked={growthAssumptions[index].fixed} value={growthAssumptions[index].fixed} onChange={e => handleApplyAll(e, index)} />
    },
  ].concat(years.map(year => {
    const yearKey = `year${year}`;
    return {
      title: `Year ${year}`,
      dataIndex: yearKey,
      key: yearKey,
      render: (_: any, record: any, index: number) =>
        <Input
          suffix={<span>%</span>}
          type="number"
          min={0}
          max={100}
          step={0.1}
          style={{ minWidth: 100 }}
          disabled={year > 1 && growthAssumptions[index].fixed}
          // @ts-ignore
          value={growthAssumptions[index][yearKey]}
          onChange={e => handleGrowthAssumptionChange(index, e, yearKey)}
        />
    }
  }));

  return (
    <Table
      scroll={{ x: true }}
      columns={columns}
      dataSource={growthAssumptions}
      bordered
      pagination={false}
    />
  )

}
export default GrowthAssumptions;