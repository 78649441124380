import { notification, Rate } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React from "react";
import { useEffect, useState } from "react";
import feedbackService from "../../../api/services/feedback";
import { useAppSelector } from "../../../store";
import Modal from "..";
import ButtonColor from 'antd-button-color';
import { SyncOutlined } from '@ant-design/icons';

type FeedbackModalProps = {
  visible: boolean;
  onDismiss(): void;
}

const FeedbackModal: React.FC<FeedbackModalProps> = ({ visible, onDismiss }) => {
  const { current } = useAppSelector(state => state.userReducer);
  const [showFeedbackModal, setShowFeedbackModal] = useState<boolean>(visible);
  const [rating, setRating] = useState<number>(0);
  const [notes, setNotes] = useState<string>("");
  const [ratingChanged, setRatingChanged] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  
  const handleDismiss = () => {
    setShowFeedbackModal(false);
    onDismiss();
    setRatingChanged(false);
    setRating(0);
    setNotes("");
  }

  useEffect(() => {
    setShowFeedbackModal(visible);
  }, [visible]);

  const handleRatingChange = (rating: number) => {
    if (!ratingChanged) setRatingChanged(true);
    setRating(rating);
  }

  const onFinish = () => {
    setSaving(true);
    feedbackService.create({
      userId: current?._id!,
      rating: rating,
      notes: notes,
    })
    .then(() => {
      setSaving(false);
      notification.success({
        message: "Thank you for your feedback!",
        duration: 5,
        placement: 'bottomRight',
      });
      handleDismiss();
    })
    .catch(err => {
      setSaving(false);
      notification.error({
        message: "An error was encountered while submitting feedback",
        duration: 5,
        placement: 'bottomRight',
      });
    })
  };

  return (
    <Modal
      title="Feedback"
      visible={showFeedbackModal}
      onDismiss={() => handleDismiss()}
    >
      <h3>How well does this application meet your expectations?</h3>
      <Rate allowClear={false} count={10} value={rating} onChange={handleRatingChange} />
      {
        ratingChanged ? (
          <>
            <h3 style={{ margin: '25px 0px' }}>
              {rating <= 3 ? <span>Uh oh! It sounds like we need to make some <i>major</i> changes...</span> : null}
              {rating > 3 && rating <= 6 ? <span>It sounds like we are doing okay...</span> : null}
              {rating > 6 && rating <= 9 ? <span>We are glad to hear were are doing a good job!</span> : null}
              {rating === 10 ? <span>Oh wow - you're making us blush!</span> : null}
            </h3>
            <h3 style={{ marginTop: 25 }}>
              {rating <= 3 ? "What can we do to help?" : null}
              {rating > 3 && rating <= 6 ? "What can we do to improve your experience?" : null}
              {rating > 6 && rating ? "Do you have any suggestions or comments for us?" : null}
            </h3>
            <TextArea value={notes} onChange={e => setNotes(e.target.value)} rows={6} />
          </>
        ) : null
      }
      <div style={{ marginTop: 25 }}>
        { rating !== 0 && <ButtonColor style={{marginRight:10}}  type="success" onClick={onFinish} disabled={rating === 0}>
        { saving ? <span>Submitting <SyncOutlined spin/></span> : 'Submit' }
        </ButtonColor> }
        <ButtonColor type="lightdark" onClick={handleDismiss}>Cancel</ButtonColor>
      </div>
    </Modal>
  )
}

export default FeedbackModal;