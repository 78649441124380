import { Input, Table } from "antd";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../../../../store";
import { IEquitySplit } from "../../../../../types/loan";
import NumberField from "../../../../shared/number-field";

interface ISourcesProps {
  totalUses: number;
  totalUnits: number;
  loanTotal: number;
  equitySplit: IEquitySplit;
  capitalNeededToClose: number;
}


const Sources = ({ totalUses, totalUnits, loanTotal, equitySplit: { gpSponsor, lpInvestors }, capitalNeededToClose }: ISourcesProps) => {
  const dispatch = useAppDispatch();
  const [gpSponsorPercentage, setGpSponsorPercentage] = useState<number>(gpSponsor);
  const [lpInvestorPercentage, setLpInvestorPercentage] = useState<number>(lpInvestors);

  useEffect(() => {
    dispatch({
      type: "UPDATE_LOAN_EQUITY_SPLIT", payload: {
        lpInvestors: lpInvestorPercentage,
        gpSponsor: gpSponsorPercentage,
      }
    });
    
  }, [gpSponsorPercentage, lpInvestorPercentage])

  const handleEquityChange = (event: React.ChangeEvent<HTMLInputElement>, type: "gpSponsor" | "lpInvestor") => {
    const { target: { value } } = event;
    if (!isNaN(parseFloat(value))) {
      switch (type) {
        case "gpSponsor":
          setGpSponsorPercentage(parseFloat(value));
          setLpInvestorPercentage(100 - parseFloat(value));
          break;
        case "lpInvestor":
          setGpSponsorPercentage(100 - parseFloat(value));
          setLpInvestorPercentage(parseFloat(value));
          break;
        default:
          break;
      }
    } else {
      switch (type) {
        case "gpSponsor":
          setGpSponsorPercentage(0);
          setLpInvestorPercentage(100);
          break;
        case "lpInvestor":
          setGpSponsorPercentage(100);
          setLpInvestorPercentage(0);
          break;
        default:
          break;
      }
    }
  };

  const equityColumns = [
    {
      title: 'Total Equity',
      dataIndex: 'description',
      key: 'description',
      width: '45%',
    },
    {
      dataIndex: 'percent',
      key: 'percent',
      width: '15%',
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      width: '20%',
    },
    {
      title: 'Per Unit',
      dataIndex: 'perUnit',
      key: 'perUnit',
      width: '20%',
    },
  ];

  const equityDataSource = [
    {
      key: '1',
      description: <span style={{ marginLeft: 10 }}>GP/Sponsor</span>,
      percent: <Input  suffix={<span>%</span>} min={0} max={100} type="number" placeholder="Percentage" step={1} style={{ minWidth: 100 }} value={gpSponsorPercentage} onChange={e => handleEquityChange(e, "gpSponsor")} />,
      total: <NumberField type="money" value={(totalUses - loanTotal) * (gpSponsorPercentage / 100)} />,
      perUnit: <NumberField type="money" value={((totalUses - loanTotal) * (gpSponsorPercentage / 100)) / totalUnits} />,
    },
    {
      key: '2',
      description: <span style={{ marginLeft: 10 }}>LP Investors</span>,
      percent: <Input  suffix={<span>%</span>} min={0} max={100} type="number" placeholder="Percentage" step={1} style={{ minWidth: 100 }} value={lpInvestorPercentage} onChange={e => handleEquityChange(e, "lpInvestor")} />,
      total: <NumberField type="money" value={(totalUses - loanTotal) * (lpInvestorPercentage / 100)} />,
      perUnit: <NumberField type="money" value={((totalUses - loanTotal) * (lpInvestorPercentage / 100)) / totalUnits} />,
    },
    {
      key: '3',
      description: <span>Total Peak Equity</span>,
      total: <NumberField type="money" value={(totalUses - loanTotal)} />,
      perUnit: <NumberField type="money" value={(totalUses - loanTotal) / totalUnits} />,
    },
    {
      key: '4',
      description: <b>Debt</b>,
      total: <b><NumberField type="money" value={loanTotal} /></b>,
      perUnit: <b><NumberField type="money" value={loanTotal / totalUnits} /></b>,
    },
    {
      key: '5',
      description: <b>Total Sources</b>,
      total: <b><NumberField type="money" value={totalUses} /></b>,
      perUnit: <b><NumberField type="money" value={totalUses / totalUnits} /></b>,
    },
    {
      key: '6',
      description: <b>Total Capital Needed to Close</b>,
      total: <b><NumberField type="money" value={capitalNeededToClose} /></b>,
      perUnit: <b><NumberField type="money" value={capitalNeededToClose / totalUnits} /></b>,
    }
  ];

  return (
    <>
      <Table
        pagination={false}
        bordered
        columns={equityColumns}
        dataSource={equityDataSource}
        style={{ marginBottom: 25, whiteSpace:'nowrap' }}
        scroll={{x:true}}
      />
    </>
  )
}
export default Sources;