import {
  Form,
  notification,
  Row,
  Col,
  Card,
  Input,
  Badge,
  Avatar,
  Typography,
  Popconfirm,
} from "antd";
import { useState, useRef, ChangeEvent, useEffect } from "react";
import { BsSun, BsMoon } from "react-icons/bs";
import locationService from "../../api/services/location";
import s3Service from "../../api/services/s3";
import userService from "../../api/services/user";
import { useAppDispatch, useAppSelector } from "../../store";
import IUser, { User } from "../../types/user";
import { DaySkeleton } from "./theme-options/day";
import { NightSkeleton } from "./theme-options/night";
import ButtonColor from "antd-button-color";
import useMediaQuery from "use-media-antd-query";
import { SyncOutlined } from "@ant-design/icons";
import styled from "styled-components";
import formatPhoneNumber from "../../utils/format-phone-number";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router";

interface AvatarColorProps {
  color: string;
  active?: boolean;
  last?: boolean;
}

const AvatarColor = styled.div<AvatarColorProps>`
  cursor: pointer;
  height: 25px;
  margin-right: ${(props) => (props.last ? "0px" : "10px")};
  width: 25px;
  border-radius: 100%;
  background-color: ${(props) => props.color};
  border: ${(props) => (props.active ? "2px solid white" : "none")};
  &:hover {
    transform: scale(1.25);
  }
`;

const Profile = () => {
  const navigate = useNavigate();
  const { logout } = useAuth0();
  const dispatch = useAppDispatch();
  const { current } = useAppSelector((state) => state.userReducer);
  const [offset, setOffset] = useState<number>(0);
  const [saving, setSaving] = useState<boolean>(false);
  const [savingTheme, setSavingTheme] = useState<boolean>(false);
  const [city, setCity] = useState<string>(current?.city || "");
  const [state, setState] = useState<string>(current?.state || "");
  const inputFile = useRef<HTMLInputElement | null>(null);
  const colSize = useMediaQuery();
  const [selectedTheme, setSelectedTheme] = useState(current?.theme);
  const [avatarColor, setAvatarColor] = useState<string>();
  const [form] = Form.useForm();
  const [saveDisabled, setSaveDisabled] = useState<boolean>(false);
  const [deleteAccountText, setDeleteAccountText] = useState<
    string | undefined
  >();

  const handleFormChange = () => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    setSaveDisabled(hasErrors);
  };

  const handleZipCodeChange = (zipCode: number) => {
    locationService
      .get(zipCode)
      .then((res) => {
        const {
          location: { city, state },
        } = res.data;
        setCity(city);
        setState(state);
      })
      .catch((err) => console.log(err));
  };

  const setSavingState = (type: "profile" | "theme", saving: boolean) => {
    switch (type) {
      case "profile":
        setSaving(saving);
        break;
      case "theme":
        setSavingTheme(saving);
        break;
      default:
        break;
    }
  };

  const updateUser = (user: IUser, type: "profile" | "theme") => {
    setSavingState(type, true);
    userService
      .update(user)
      .then(async (res) => {
        setSavingState(type, false);
        const user = new User(res.data.user);
        await user.getAvatarFromS3();
        await dispatch({ type: "UPDATE_USER_CURRENT_USER", payload: user });
        notification.success({
          message: "Profile updated successfully",
          duration: 5,
          placement: "bottomRight",
        });
      })
      .catch(() => {
        setSavingState(type, false);
        notification.error({
          message: "An error was encountered while updating profile",
          duration: 5,
          placement: "bottomRight",
        });
      });
  };

  const handleSave = (values: any) => {
    if (current) {
      const user: IUser = {
        ...current,
        firstName: values.firstName,
        lastName: values.lastName,
        zipCode: parseFloat(values.zipCode),
        city,
        state,
        phoneNumber:
          values.phoneNumber &&
          parseFloat(values.phoneNumber.replace(/\D/g, "")),
      };

      updateUser(user, "profile");
    }
  };

  const handleSaveTheme = () => {
    if (current) {
      const user: IUser = {
        ...current,
        theme: selectedTheme,
      };

      updateUser(user, "theme");
    }
  };

  const handleAvatarDeleted = () => {
    s3Service
      .delete(`user/profile/${current?._id}/profile`)
      .catch(() => {
        notification.error({
          message: "An error was encountered while deleting user avatar",
          duration: 5,
          placement: "bottomRight",
        });
      })
      .then(() => {
        if (current) {
          const user: IUser = {
            ...current,
            avatarUrl: undefined,
          };

          updateUser(user, "profile");
        }
      });
  };

  const handleAvatarChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    event.preventDefault();
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      s3Service
        .upload(current?._id!, `user/profile`, undefined, file, "profile")
        .then(() => {
          if (current) {
            const user: IUser = {
              ...current,
              profileImageName: file.name,
            };

            updateUser(user, "profile");
          }
        })
        .catch(() => {
          notification.error({
            message: "An error was encountered while uploading user avatar",
            duration: 5,
            placement: "bottomRight",
          });
        });
    }
  };

  const handleDeleteAccount = () => {
    userService
      .deleteAccount()
      .then(() => {
        notification.success({
          message: "Your account has been deleted",
          duration: 5,
          placement: "bottomRight",
        });
        setTimeout(() => {
          logout();
          navigate("/");
        }, 3000);
      })
      .catch((err) => {
        console.log(err);
        notification.error({
          message: "An error was encountered while deleting your account",
          duration: 5,
          placement: "bottomRight",
        });
      });
  };

  useEffect(() => {
    if (["xs", "sm", "md"].includes(colSize)) {
      setOffset(0);
    } else if (colSize === "lg") {
      setOffset(4);
    } else {
      setOffset(6);
    }
  }, [colSize]);

  useEffect(() => {
    if (avatarColor) {
      if (current) {
        const user: IUser = {
          ...current,
          avatarColor,
        };

        updateUser(user, "profile");
      }
    }
  }, [avatarColor]);

  return (
    <Row gutter={24}>
      <input
        type="file"
        id="file"
        ref={inputFile}
        onChange={handleAvatarChange}
        style={{ display: "none" }}
      />
      <Col offset={offset} xs={24} lg={16} xl={12}>
        <Card
          style={{ marginBottom: 50 }}
          bodyStyle={{ padding: "12px 24px" }}
          title={
            <Row gutter={24}>
              <Col xs={24} md={12} style={{marginBottom: 25}}>
                <h3>Your Avatar</h3>
                <span style={{ fontSize: 12 }}>
                  This is your avatar.
                  <br />
                  Click on the avatar to upload a custom one from your files.
                </span>
              </Col>
              <Col xs={24} md={12}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                {current?.avatarUrl ? (
                  <>
                    <div onClick={() => inputFile?.current!.click()}>
                      <Avatar
                        style={{ cursor: "pointer" }}
                        className="avatar"
                        size={90}
                        src={current.avatarUrl}
                      />
                    </div>
                    <Typography.Paragraph
                      onClick={() => handleAvatarDeleted()}
                      type="danger"
                      style={{ cursor: "pointer" }}
                    >
                      Remove
                    </Typography.Paragraph>
                  </>
                ) : (
                  <>
                    <div onClick={() => inputFile?.current!.click()}>
                      <Avatar
                        className="avatar"
                        size={90}
                        style={{
                          backgroundColor: avatarColor || current?.avatarColor,
                          marginBottom: 10,
                          cursor: "pointer",
                        }}
                      >
                        <span
                          style={{
                            fontSize: 40,
                            textShadow:
                              "-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black",
                          }}
                        >
                          {current?.initials}
                        </span>
                      </Avatar>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <AvatarColor
                        onClick={() => {
                          setAvatarColor("#ef476f");
                        }}
                        color="#ef476f"
                        active={current?.avatarColor === "#ef476f"}
                      />
                      <AvatarColor
                        onClick={() => {
                          setAvatarColor("#ffd166");
                        }}
                        color="#ffd166"
                        active={current?.avatarColor === "#ffd166"}
                      />
                      <AvatarColor
                        onClick={() => {
                          setAvatarColor("#06d6a0");
                        }}
                        color="#06d6a0"
                        active={current?.avatarColor === "#06d6a0"}
                      />
                      <AvatarColor
                        onClick={() => {
                          setAvatarColor("#118ab2");
                        }}
                        color="#118ab2"
                        active={current?.avatarColor === "#118ab2"}
                      />
                      <AvatarColor
                        onClick={() => {
                          setAvatarColor("#073b4c");
                        }}
                        color="#073b4c"
                        active={current?.avatarColor === "#073b4c"}
                        last
                      />
                    </div>
                  </>
                )}
                </div>
              </Col>
            </Row>
          }
        >
          <span> An avatar is optional but strongly recommended. </span>
          {/* <Divider /> */}
        </Card>
        <Card
          style={{ marginBottom: 50 }}
          bodyStyle={{ padding: "12px 24px" }}
          title={<h3 style={{ marginBottom: 0 }}>Your Information</h3>}
        >
          <Form
            layout="vertical"
            onFinish={handleSave}
            form={form}
            initialValues={{
              firstName: current?.firstName,
              lastName: current?.lastName,
              phoneNumber: formatPhoneNumber(current?.phoneNumber),
              zipCode: current?.zipCode,
            }}
            onFieldsChange={handleFormChange}
          >
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  initialValue={current?.email}
                  name="email"
                  label="Email"
                >
                  <Input
                    placeholder="Ex: jsmith@gmail.com"
                    type="text"
                    name="email"
                    disabled
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="firstName" label="First Name">
                  <Input placeholder="Ex: John" type="text" name="firstName" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="lastName" label="Last Name">
                  <Input placeholder="Ex: Smith" type="text" name="lastName" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="phoneNumber"
                  label="Phone Number"
                  rules={[
                    {
                      pattern: new RegExp(/^[1-9]\d{2}-\d{3}-\d{4}/),
                      message: "Expected format: 555-555-5555",
                    },
                  ]}
                >
                  <Input
                    type="tel"
                    name="phoneNumber"
                    placeholder="Ex: 555-555-5555"
                    pattern="^[1-9]\d{2}-\d{3}-\d{4}"
                    title="Ex: 555-555-5555"
                    maxLength={12}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="zipCode" label="Zip Code">
                  <Input
                    type="number"
                    onChange={(e) =>
                      handleZipCodeChange(parseFloat(e.target.value) || 0)
                    }
                    placeholder="Ex: 90210"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="City">
                  <Input
                    value={city}
                    disabled
                    placeholder="Ex: Beverly Hills"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="State">
                  <Input value={state} disabled placeholder="Ex: CA" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <ButtonColor
                  onClick={() => handleSave(form.getFieldsValue())}
                  type="success"
                  disabled={saveDisabled}
                  style={{ float: "right" }}
                >
                  {saving ? (
                    <span>
                      Saving <SyncOutlined spin />
                    </span>
                  ) : (
                    "Save"
                  )}
                </ButtonColor>
              </Col>
            </Row>
          </Form>
        </Card>
        <Card
          style={{ marginBottom: 50 }}
          bodyStyle={{ padding: "12px 24px" }}
          title={<h3 style={{ marginBottom: 0 }}>Your Preferences</h3>}
        >
          <Row gutter={24}>
            <Col xs={24} sm={12}>
              <div className="ant-form-item-label">
                <label>
                  <BsSun size={20} style={{ marginRight: 5 }} /> Day{" "}
                  {selectedTheme === "day" && (
                    <Badge
                      style={{
                        backgroundColor: "rgb(24, 144, 255)",
                        marginLeft: 5,
                      }}
                      count={"Active"}
                    />
                  )}{" "}
                </label>
              </div>
              <DaySkeleton
                onClick={() => setSelectedTheme("day")}
                style={{
                  cursor: "pointer",
                  opacity: selectedTheme === "night" ? 0.5 : 1,
                }}
              />
            </Col>
            <Col xs={24} sm={12}>
              <div className="ant-form-item-label">
                <label>
                  <BsMoon size={20} style={{ marginRight: 5 }} /> Night{" "}
                  {selectedTheme === "night" && (
                    <Badge
                      style={{
                        backgroundColor: "rgb(24, 144, 255)",
                        marginLeft: 5,
                      }}
                      count={"Active"}
                    />
                  )}{" "}
                </label>
              </div>
              <NightSkeleton
                onClick={() => setSelectedTheme("night")}
                style={{
                  cursor: "pointer",
                  opacity: selectedTheme === "night" ? 1 : 0.5,
                }}
              />
            </Col>
            <Col span={24}>
              <h5 style={{marginTop: 10}}>
                <i>* Theme changes require application refresh</i>
              </h5>
              <ButtonColor
                style={{ float: "right" }}
                onClick={() => handleSaveTheme()}
                type="success"
              >
                {savingTheme ? (
                  <span>
                    Saving <SyncOutlined spin />
                  </span>
                ) : (
                  "Save"
                )}
              </ButtonColor>
            </Col>
          </Row>
        </Card>
        <Card
          title={<h3 style={{ marginBottom: 0 }}>Delete Your Account</h3>}
          style={{ border: "1px solid red" }}
        >
          <p>
            Permanently remove your account and all of its contents from the
            platform. This action is not reversible, so please continue with
            caution.
          </p>
          <Popconfirm
            title={
              <div style={{ marginLeft: -24, textAlign: "center" }}>
                <p>
                  To confirm deletion, enter "delete-my-account"
                  <br /> in the text input field.
                </p>
                <Input
                  onChange={(e) => setDeleteAccountText(e.target.value)}
                  size="small"
                  placeholder="delete-my-account"
                ></Input>
              </div>
            }
            onConfirm={() => handleDeleteAccount()}
            okText="Delete Account"
            cancelText="Cancel"
            okButtonProps={{
              danger: true,
              disabled: deleteAccountText !== "delete-my-account",
            }}
            cancelButtonProps={{
              type: "ghost",
            }}
            icon={null}
          >
            <ButtonColor style={{ float: "right" }} type="danger">
              {savingTheme ? (
                <span>
                  Saving <SyncOutlined spin />
                </span>
              ) : (
                "Delete Account"
              )}
            </ButtonColor>
          </Popconfirm>
        </Card>
      </Col>
    </Row>
  );
};

export default Profile;
