import { Table } from "antd";
import NumberField from "../../../../shared/number-field";
import { IProjectLevelReturns } from "../investment-summary-service";

interface IProjectLevelReturnsProps {
  projectLevelReturns: IProjectLevelReturns;
}

const ProjectLevelReturns: React.FC<IProjectLevelReturnsProps> = ({
  projectLevelReturns,
}) => {
  const {
    unleveredProjectIRR,
    unleveredProjectEquityMultiple,
    leveredProjectIRR,
    leveredProjectEquityMultiple,
    exitCapRate,
    grossExitValue,
    grossExitValuePriceUnit,
  } = projectLevelReturns;

  const columns = [
    {
      dataIndex: "description",
      key: "description",
      width: "50%",
    },
    {
      dataIndex: "value",
      key: "value",
      width: "50%",
    },
  ];

  const dataSource = [
    {
      key: "1",
      description: "Unlevered Project IRR",
      value: <NumberField type="percentage" value={unleveredProjectIRR} />,
    },
    {
      key: "2",
      description: "Unlevered Project Equity Multiple",
      value: (
        <span style={{ float: "right" }}>{unleveredProjectEquityMultiple}</span>
      ),
    },
    {
      key: "3",
      description: "Levered Project IRR",
      value: <NumberField type="percentage" value={leveredProjectIRR} />,
    },
    {
      key: "4",
      description: "Levered Project Equity Multiple",
      value: (
        <span style={{ float: "right" }}>{leveredProjectEquityMultiple}</span>
      ),
    },
    {
      key: "5",
      description: "Exit Cap Rate",
      value: <NumberField type="percentage" value={exitCapRate} />,
    },
    {
      key: "6",
      description: "Gross Exit Value",
      value: <NumberField type="money" value={grossExitValue} />,
    },
    {
      key: "7",
      description: "Gross Exit Value Price/Unit",
      value: <NumberField type="money" value={grossExitValuePriceUnit} />,
    },
  ];

  return (
    <>
      <Table
        title={() => (
          <div style={{ textAlign: "center" }}>
            <b>Project Level Returns</b>
          </div>
        )}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        bordered
        showHeader={false}
        style={{ whiteSpace: "nowrap" }}
        scroll={{ x: true }}
      />
    </>
  );
};
export default ProjectLevelReturns;
