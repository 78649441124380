import { Input, Table } from "antd";
import React, { ReactElement, useEffect, useState } from "react";
import { IIncomeExpenseItem } from "../../../../../types/property";
import NumberField from "../../../../shared/number-field";

interface IProformaExpenseReconciliationExpenseTableProps {
  currentItems: IIncomeExpenseItem[];
  totalUnits: number;
  grossPotentialRent: number;
  onItemsChanged(items: IIncomeExpenseItem[]): void;
}

interface IExpenseItem extends IIncomeExpenseItem {
  view: string | ReactElement;
}

const ProformaExpenseReconciliationExpenseTable = ({ currentItems, totalUnits, grossPotentialRent, onItemsChanged }: IProformaExpenseReconciliationExpenseTableProps) => {
  const [items, setItems] = useState<IExpenseItem[]>(currentItems.map((item, i) => {
    return {
      ...item,
      view: i === currentItems.length - 1 ? <b>{item.description}</b> : <span>{item.description}</span>,
    }
  }));

  const handleExpenseItemChange = (event: React.ChangeEvent<HTMLInputElement>, index: number, field: string) => {
    const { target: { value } } = event;
    let newExpenseItems = [...items];
    if (!isNaN(parseFloat(value))) {
      newExpenseItems[index] = {
        ...newExpenseItems[index],
        [field]: parseFloat(value),
      }

      const netExpenses = newExpenseItems
        .map((x, index) => index !== newExpenseItems.length - 1 ? x.amount : 0)
        .reduce((prev, curr) => prev + curr, 0);

      newExpenseItems[newExpenseItems.length - 1].amount = netExpenses;

      setItems(newExpenseItems);
    } else {
      newExpenseItems[index].amount = 0
      setItems(newExpenseItems);
    }
  }

  const expenseColumns = [
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: '40%',
      render: (_: any, record: any, index: number) => items[index].view,
    },
    {
      title: 'T-12 Financials',
      dataIndex: 'amount',
      key: 'amount',
      width: '15%',
      render: (_: any, record: any, index: number) =>
        index === items.length - 1 ? <b><NumberField value={items[index].amount} type="money" /></b> :
          <Input
            type="number"
            min={0}
            placeholder="Amount"
            value={items[index].amount}
            onChange={e => handleExpenseItemChange(e, index, "amount")}
          />
    },
    {
      title: '% of GPR / EGI',
      dataIndex: 'percentOfGprEgi',
      key: 'percentOfGprEgi',
      width: '15%',
      render: (_: any, record: any, index: number) =>
        index === items.length - 1 ? null :
          <NumberField value={grossPotentialRent <= 0 ? 0 : (items[index].amount / grossPotentialRent) * 100} type="percentage" />
    },
    {
      title: '$ / Month',
      dataIndex: 'costPerMonth',
      key: 'costPerMonth',
      width: '15%',
      render: (_: any, record: any, index: number) =>
        index === items.length - 1 ? <b><NumberField value={items[index].amount / 12} type="money" /></b> :
          <NumberField value={items[index].amount / 12} type="money" />
    },
    {
      title: '$ / Unit',
      dataIndex: 'costPerUnit',
      key: 'costPerUnit',
      width: '15%',
      render: (_: any, record: any, index: number) =>
        index === items.length - 1 ? <b><NumberField value={items[index].amount / totalUnits} type="money" /></b> :
          <NumberField value={items[index].amount / totalUnits} type="money" />
    },
  ];

  useEffect(() => {
    onItemsChanged(items.map(i => {
      return {
        description: i.description,
        name: i.name,
        amount: i.amount,
      }
    }));
  }, [items]);

  return (
    <>
      <Table
        style={{whiteSpace:'nowrap'}}
        columns={expenseColumns}
        dataSource={items}
        pagination={false}
        bordered
        scroll={{x:true}}
        size="small"
      />
    </>
  )

}
export default ProformaExpenseReconciliationExpenseTable;