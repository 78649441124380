import { Card, Col, Tabs } from "antd";
import { useEffect, useRef, useState } from "react";
import {
  getStoreLoan,
  getStoreProperty,
  useAppDispatch,
  useAppSelector,
} from "../../../../store";
import { IUpdatePropertyYearOneProformaAssumptionsPayload } from "../../../../store/actions/property";
import { IIncomeExpenseItem } from "../../../../types/property";
import YearOneProformaExpensesAssumptions from "./expenses";
import YearOneProformaOtherIncomeAssumptions from "./other-income";
import YearOneProformaRentIncomeAssumptions from "./rent-income";
const { TabPane } = Tabs;

export interface IYearOneProformaIncomeExpenseItem extends IIncomeExpenseItem {
  yearOneAmount: number;
}

const YearOneProformaAssumptions = () => {
  const dispatch = useAppDispatch();
  const {
    propertyReducer: {
      property: {
        yearOneProformaAssumptions: {
          rentIncomeItems,
          otherIncomeItems,
          expenseItems,
        },
        totalUnits,
      },
    },
  } = useAppSelector(({ propertyReducer }) => {
    return { propertyReducer };
  });

  const [payload, setPayload] =
    useState<IUpdatePropertyYearOneProformaAssumptionsPayload>({
      rentIncomeItems,
      otherIncomeItems,
      expenseItems,
    });
  const payloadRef = useRef<
    IUpdatePropertyYearOneProformaAssumptionsPayload | undefined
  >();

  const handleChange = (
    items: IYearOneProformaIncomeExpenseItem[],
    type: "rentIncome" | "expense" | "otherIncome"
  ) => {
    const newPayload = { ...payload };
    switch (type) {
      case "rentIncome":
        newPayload.rentIncomeItems = items;
        break;
      case "expense":
        newPayload.expenseItems = items;
        break;
      case "otherIncome":
        newPayload.otherIncomeItems = items;
        break;
      default:
        break;
    }

    setPayload(newPayload);
  };

  useEffect(() => {
    payloadRef.current = payload;
  }, [payload]);

  useEffect(() => {
    dispatch({
      type: "UPDATE_CASH_FLOW_DISTRIBUTION",
      payload: { property: getStoreProperty(), loan: getStoreLoan() },
    });

    return () => {
      dispatch({
        type: "UPDATE_PROPERTY_YEAR_ONE_PROFORMA_ASSUMPTIONS",
        payload: payloadRef.current!,
      });
      dispatch({
        type: "UPDATE_CASH_FLOW_DISTRIBUTION",
        payload: { property: getStoreProperty(), loan: getStoreLoan() },
      });
    };
  }, []);

  return (
    <>
      <Col
        xs={24}
        sm={24}
        md={24}
        lg={24}
        xl={20}
        xxl={18}
        style={{ paddingLeft: 0 }}
      >
        <Tabs defaultActiveKey="1" style={{ marginTop: -15 }}>
          <TabPane tab="Rent Income" key={1}>
            <YearOneProformaRentIncomeAssumptions
              rentIncomeItems={rentIncomeItems}
              totalUnits={totalUnits}
            />
          </TabPane>
          <TabPane tab="Other Income" key={2}>
            <YearOneProformaOtherIncomeAssumptions
              otherIncomeItems={otherIncomeItems}
              totalUnits={totalUnits}
              onChange={(items) => handleChange(items, "otherIncome")}
            />
          </TabPane>
          <TabPane tab="Expenses" key={3}>
            <YearOneProformaExpensesAssumptions
              expenseItems={expenseItems}
              totalUnits={totalUnits}
              onChange={(items) => handleChange(items, "expense")}
            />
          </TabPane>
        </Tabs>
      </Col>
    </>
  );
};
export default YearOneProformaAssumptions;
