import { Layout, Row, Col } from "antd";
import { useEffect, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useLocation, useNavigate } from "react-router";
import AppFooter from "./footer";
import Header from "./header";
import "./index.css";
const { Header: AntDHeader } = Layout;

const LayoutMain: React.FC = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  useHotkeys("g+p", () => navigate("/properties"));
  useHotkeys("g+c", () => navigate("/contacts"));
  useHotkeys("g+i", () => navigate("/report-issue"));
  useHotkeys("g+f", () => navigate("/feature-request"));
  useHotkeys("n", () => navigate("/new-property"));
  const [breadCrumbName, setBreadCrumbName] = useState<string>();

  const getBreadCrumbName = () => {
    try {
      let pathName = location.pathname
        .split("/")[1]
        .replace(/[\W_]+/g, " ")
        .split(" ");
      for (let i = 0; i < pathName.length; i++) {
        pathName[i] = pathName[i][0].toUpperCase() + pathName[i].substring(1);
      }
      return pathName.join(" ");
    } catch (error) {
      return "";
    }
  };

  useEffect(() => {
    setBreadCrumbName(getBreadCrumbName());
  }, [location]);

  return (
    <Layout>
      <AntDHeader className="header">
        <Header />
      </AntDHeader>
      <Row gutter={24} className="content">
        <Col span={24}>
          {location.pathname.replace("/", "") !== "properties" ? (
            <div style={{ display: "flex", marginTop: 10, marginBottom: 25 }}>
              <h4
                onClick={() => navigate("/properties")}
                style={{ opacity: 0.5, cursor: "pointer" }}
              >
                Home
              </h4>
              <h4 style={{ marginLeft: 5, marginRight: 5 }}>{">"}</h4>
              <h4>{breadCrumbName}</h4>
            </div>
          ) : null}
          {children}
        </Col>
      </Row>
      <AppFooter />
    </Layout>
  );
};

export default LayoutMain;
