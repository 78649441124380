import Button from "antd-button-color";
import AdminComponent from "../../shared/admin-component";

const ContactOptions: React.FC<{
  title: "Contacts" | "Favorites" | "Contact Requests";
  showButtons: boolean;
  onAddContactModalVisible: Function;
  onInviteUsersModalVisible: Function;
}> = ({
  title,
  showButtons,
  onAddContactModalVisible,
  onInviteUsersModalVisible,
}) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <h1>{title}</h1>
      {showButtons && (
        <div style={{ display: "flex", justifyContent: "end" }}>
          <Button onClick={() => onAddContactModalVisible(true)} type="primary">
            Add Contact
          </Button>
          <AdminComponent>
            <Button
              onClick={() => onInviteUsersModalVisible(true)}
              type="success"
              style={{ marginLeft: 15 }}
            >
              Invite Users
            </Button>
          </AdminComponent>
        </div>
      )}
    </div>
  );
};

export default ContactOptions;
