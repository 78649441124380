import { Card, Col, Collapse, Row } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import versions, { IVersion, IVersionNote } from "./version";
const { Panel } = Collapse;

interface IVersionMonth {
  month: number;
  year: number;
  versions: IVersion[];
}

const monthEmojies = {
  3: <span style={{marginLeft: 5, fontSize: 15}}>☘️</span>,
  4: <span style={{marginLeft: 5, fontSize: 15}}>☂️</span>,
  5: <span style={{marginLeft: 5, fontSize: 15}}>🌻</span>
}

const VersionHistory = () => {
  const [versionMonths, setVersionMonths] = useState<IVersionMonth[]>([]);
  
  useEffect(() => {
    let versionMonths: IVersionMonth[] = [];
    if (versions) {
      versions.forEach(version => {
        const momentDate = moment(version.date);
        const [month, year] = [momentDate.month(), momentDate.year()]

        const versionMonthIndex = versionMonths.findIndex(v => v.month === month && v.year === year)
        if (versionMonthIndex < 0) {
          versionMonths.push({
            month,
            year,
            versions: [version],
          })
        } else {
          versionMonths[versionMonthIndex].versions.push(version);
        }
      });

      setVersionMonths(versionMonths);
    }
  }, [versions]);


  return (
    <Row gutter={24} data-tour="version-history">
      <Col span={24} >
      <h1>Release History</h1>
      <Card>
      <Collapse defaultActiveKey={0}>
        {
          versionMonths.map((versionMonth, index) => {
            var newDate = moment.utc();
            newDate.set('year', versionMonth.year);
            newDate.set('month', versionMonth.month);
            return (
              // @ts-ignore
              <Panel header={<>{newDate.format("MMMM YYYY")}{monthEmojies[versionMonth.month + 1]}</>} key={index}>
                { versionMonth.versions.map(version => <Version version={version} />) }
              </Panel>
            )
          })
        }
      </Collapse>
      </Card>
      </Col>
    </Row>
  )
}

export default VersionHistory;

type IVersionProps = {
  version: IVersion
}

const Version: React.FC<IVersionProps> = ({ version: { number, features, bugFixes, other, date } }) => {
  const versionNumber = number !== 'Beta' ? `v${number}` : 'Beta'
  return (
    <>
      <div>
        <h2>{versionNumber} - {moment(date).format("MMMM Do")}  </h2>
        <VersionNotes category="features" notes={features || []} />
        <VersionNotes category="bugfixes" notes={bugFixes || []} />
        <VersionNotes category="other" notes={other || []} />
      </div>
    </>
  )
}

type IVersionNotesProps = {
  category: "features" | "bugfixes" | "other";
  notes: IVersionNote[];
}


const VersionNotes: React.FC<IVersionNotesProps> = ({ category, notes }) => {
  if (notes.length <= 0) return null;
  let title: JSX.Element | null = null;
  switch (category) {
    case "features":
      title = <h3>New Features</h3>
      break;
    case "bugfixes":
      title = <h3>Fixes & Improvements</h3>
      break;
    case "other":
      title = <h3>Notes</h3>
      break;
    default:
      break;
  }
  return (
    <>
      {title}
      <ul>
        {notes?.map(note => (
          <>
            <li style={{ marginLeft: 20 }}>
              {note.mainNote}
              {note.secondaryNotes.length > 0 ? (
                <ul style={{ fontSize: 10 }}>
                  {note.secondaryNotes.map(note => <li>{note}</li>)}
                </ul>
              ) : null}
            </li>
          </>
        ))}
      </ul>
    </>
  )
}