export interface IFormStep {
  id: number;
  description: string;
}

const formSteps: IFormStep[] = [
  { id: 1, description: "Property Overview" },
  { id: 2, description: "Units" },
  { id: 3, description: "Acquisition Costs" },
  { id: 4, description: "Investment Timeline" },
  { id: 5, description: "Loan Assumptions" },
  { id: 6, description: "Additional Reserves" },
  { id: 7, description: "T-12 Month Income & Expense Reconciliation" },
  { id: 8, description: "Year One Assumptions" },
  { id: 9, description: "Operating Assumptions" },
  { id: 10, description: "Renovations" },
  { id: 11, description: "Sources & Uses" },
  { id: 12, description: "Refinancing Loan Assumptions" },
  { id: 13, description: "Exit Summary" },
  { id: 14, description: "Proforma" },
  { id: 15, description: "Investment Summary" },
]

export default formSteps;