import { ILoan, Loan } from "../loan";

const mapLoan = (loan: ILoan): Loan => {
  let newLoan = new Loan();
  Object.keys(loan).forEach(key => {
      // @ts-ignore
      newLoan[key] = loan[key];
  });
  newLoan.loanAssumptions.date = new Date(newLoan.loanAssumptions.date);
  newLoan.refinancingPermanentLoanAssumptions.date = new Date(newLoan.refinancingPermanentLoanAssumptions.date);
  newLoan.calculateLoanSchedule("acquisition");
  newLoan.calculateLoanSchedule("refinancing");
  newLoan.calculateDynamicAcquisitionCosts();
  newLoan.calculateLoanTotal();
  newLoan.calculateDebtService();
  return newLoan;
}

export default mapLoan;

