import { Card, Tabs } from "antd";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { IUpdatePropertyAssumptionsPayload } from "../../../../store/actions/property";
import { IAssumption } from "../../../../types/assumptions";
import AnnualAssumptions from "./annual";
import GrowthAssumptions from "./growth";
const { TabPane } = Tabs;

const OperatingAssumptions = () => {
  const dispatch = useAppDispatch();
  const {
    property: { growthAssumptions, annualAssumptions },
  } = useAppSelector((state) => state.propertyReducer);
  const [payload, setPaylod] = useState<IUpdatePropertyAssumptionsPayload>({
    growthAssumptions,
    annualAssumptions,
  });

  const handleAssumptionChange = (
    items: IAssumption[],
    type: "growth" | "annual"
  ) => {
    let newPayload = { ...payload };
    switch (type) {
      case "growth":
        newPayload.growthAssumptions = items;
        break;
      case "annual":
        newPayload.annualAssumptions = items;
        break;
      default:
        break;
    }

    setPaylod(newPayload);
  };

  useEffect(() => {
    dispatch({ type: "UPDATE_PROPERTY_ASSUMPTIONS", payload });
  }, [payload]);

  return (
    <>
      <Tabs defaultActiveKey="1" style={{ marginTop: -15 }}>
        <TabPane tab="Growth Assumptions" key={1}>
          <GrowthAssumptions
            assumptions={growthAssumptions}
            onChange={(items) => handleAssumptionChange(items, "growth")}
          />
        </TabPane>
        <TabPane tab="Annual Assumptions" key={2}>
          <AnnualAssumptions
            assumptions={annualAssumptions}
            onChange={(items) => handleAssumptionChange(items, "annual")}
          />
        </TabPane>
      </Tabs>
    </>
  );
};
export default OperatingAssumptions;
