import { Input, Table, DatePicker, Select, Tabs, Row, Col, Card } from "antd";
import { useEffect, useState } from "react";
import moment from "moment";
import {
  getStoreLoan,
  getStoreProperty,
  useAppDispatch,
  useAppSelector,
} from "../../../../store";
import AcquisitionLoanSchedule from "./acquisition-loan-schedule";
import NumberField from "../../../shared/number-field";
import useMediaQuery from "use-media-antd-query";
import OneMonthTermSofrForwardCurve from "./one-month-term-sofr-forward-curve";
const { TabPane } = Tabs;
const { Option } = Select;

interface IBridgeLoanAssumptionsForm {
  lender: string;
  date: Date;
  purchasePriceLeveragePercentage: number;
  capExLeveragePercentage: number;
  termYears: number;
  interestRate: number;
  interestOnlyMonths: number;
  amoritizationYears: number;
  loanFeesPercentage: number;
  prepaymentPenaltyPercentage: number;
  debtServiceReservesPandIMonths: number;
  debtServiceReservesPandIRelase: "yes" | "no";
  debtServiceReservesPandIRelaseTiming: number;
  debtServiceReservesPandTotal: number;
}

const BridgeLoanAssumptions = () => {
  const dispatch = useAppDispatch();
  const {
    loan: { loanTotal, loanAssumptions: bridgeLoanAssumptions },
    property: {
      investmentTimeline: { acquisitionDate },
    },
  } = useAppSelector((state) => {
    return {
      loan: state.loanReducer.loan,
      property: state.propertyReducer.property,
    };
  });

  const [form, setForm] = useState<IBridgeLoanAssumptionsForm>({
    lender: bridgeLoanAssumptions.lender,
    date: bridgeLoanAssumptions.date,
    capExLeveragePercentage: bridgeLoanAssumptions.capExLeveragePercentage,
    purchasePriceLeveragePercentage:
      bridgeLoanAssumptions.purchasePriceLeveragePercentage,
    termYears: bridgeLoanAssumptions.termYears,
    interestRate: bridgeLoanAssumptions.interestRate,
    interestOnlyMonths: bridgeLoanAssumptions.interestOnlyMonths,
    amoritizationYears: bridgeLoanAssumptions.amortizationYears,
    loanFeesPercentage: bridgeLoanAssumptions.loanFeesPercentage,
    prepaymentPenaltyPercentage:
      bridgeLoanAssumptions.prepaymentPenaltyPercentage,
    debtServiceReservesPandIMonths:
      bridgeLoanAssumptions.debtServiceReservesPandIMonths,
    debtServiceReservesPandIRelase:
      bridgeLoanAssumptions.debtServiceReservesPandIRelase,
    debtServiceReservesPandIRelaseTiming:
      bridgeLoanAssumptions.debtServiceReservesPandIRelaseTiming,
    debtServiceReservesPandTotal:
      bridgeLoanAssumptions.debtServiceReservesPandITotal,
  });
  const colSize = useMediaQuery();

  const handleFormChange = (field: string, value: any) => {
    if (field === "debtServiceReservesPandIRelase" && value === "no") {
      setForm((form) => ({
        ...form,
        [field]: value,
        debtServiceReservesPandIRelaseTiming: 0,
      }));
      return;
    }
    setForm((form) => ({ ...form, [field]: value }));
  };

  const columns = [
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: ["xs", "sm"].includes(colSize) ? "50%" : "65%",
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
      width: ["xs", "sm"].includes(colSize) ? "50%" : "35%",
    },
  ];

  const dataSource = [
    {
      key: "0",
      description: "Lender",
      value: (
        <Input
          value={form.lender}
          type="text"
          placeholder="Name"
          onChange={(e) => handleFormChange("lender", e.target.value)}
        />
      ),
    },
    {
      key: "1",
      description: "Acquisition Date",
      value: (
        <span style={{ float: "right" }}>
          {moment(acquisitionDate).format("MM/DD/YYYY")}
        </span>
      ),
    },
    {
      key: "2",
      description: "Date",
      value: (
        <DatePicker
          value={moment(form.date)}
          placeholder="Date"
          format={"MM/DD/YYYY"}
          style={{ width: "100%" }}
          onChange={(e) => handleFormChange("date", e?.toDate())}
        />
      ),
    },
    {
      key: "3",
      description: "Leverage on Purchase Price (% LTV)",
      value: (
        <Input
          suffix={<span>%</span>}
          value={form.purchasePriceLeveragePercentage}
          type="number"
          placeholder="Percentage"
          min={0}
          max={100}
          step={0.01}
          onChange={(e) =>
            handleFormChange(
              "purchasePriceLeveragePercentage",
              parseFloat(e.target.value)
            )
          }
        />
      ),
    },
    {
      key: "4",
      description: "Leverage on CapEx (% of CapEx budget)",
      value: (
        <Input
          suffix={<span>%</span>}
          value={form.capExLeveragePercentage}
          type="number"
          placeholder="Percentage"
          min={0}
          max={100}
          step={0.01}
          onChange={(e) =>
            handleFormChange(
              "capExLeveragePercentage",
              parseFloat(e.target.value)
            )
          }
        />
      ),
    },
    {
      key: "5",
      description: "Term (years)",
      value: (
        <Input
          value={form.termYears}
          type="number"
          placeholder="Years"
          min={0}
          step={1}
          onChange={(e) =>
            handleFormChange("termYears", parseInt(e.target.value))
          }
        />
      ),
    },
    {
      key: "6",
      description: "Loan Amount",
      value: <NumberField type="money" value={loanTotal} />,
    },
    {
      key: "7",
      description: "Interest Rate",
      value: (
        <Input
          suffix={<span>%</span>}
          value={form.interestRate}
          type="number"
          placeholder="Percentage"
          min={0}
          max={100}
          step={0.01}
          onChange={(e) =>
            handleFormChange("interestRate", parseFloat(e.target.value))
          }
        />
      ),
    },
    {
      key: "8",
      description: "Interest Only (months)",
      value: (
        <Input
          value={form.interestOnlyMonths}
          type="number"
          placeholder="Months"
          min={0}
          step={1}
          onChange={(e) =>
            handleFormChange("interestOnlyMonths", parseInt(e.target.value))
          }
        />
      ),
    },
    {
      key: "9",
      description: "Amoritization (years)",
      value: (
        <Input
          value={form.amoritizationYears}
          type="number"
          placeholder="Years"
          min={0}
          step={1}
          onChange={(e) =>
            handleFormChange("amoritizationYears", parseInt(e.target.value))
          }
        />
      ),
    },
    {
      key: "10",
      description: "Loan Fees",
      value: (
        <Input
          suffix={<span>%</span>}
          value={form.loanFeesPercentage}
          type="number"
          placeholder="Percentage"
          min={0}
          max={100}
          step={0.01}
          onChange={(e) =>
            handleFormChange("loanFeesPercentage", parseFloat(e.target.value))
          }
        />
      ),
    },
    {
      key: "11",
      description: "Loan Fees Total",
      value: (
        <NumberField
          type="money"
          value={((form.loanFeesPercentage || 0) / 100) * loanTotal}
        />
      ),
    },
    {
      key: "12",
      description: "Prepayment Penalty",
      value: (
        <Input
          suffix={<span>%</span>}
          value={form.prepaymentPenaltyPercentage}
          type="number"
          placeholder="Percentage"
          min={0}
          max={100}
          step={0.01}
          onChange={(e) =>
            handleFormChange(
              "prepaymentPenaltyPercentage",
              parseFloat(e.target.value)
            )
          }
        />
      ),
    },
    {
      key: "13",
      description: "Debt Service Reserves (P&I) - Months",
      value: (
        <Input
          value={form.debtServiceReservesPandIMonths}
          type="number"
          placeholder="Months"
          min={0}
          step={1}
          onChange={(e) =>
            handleFormChange(
              "debtServiceReservesPandIMonths",
              parseInt(e.target.value)
            )
          }
        />
      ),
    },
    {
      key: "14",
      description: "Debt Service Reserves (P&I) - Release",
      value: (
        <Select
          value={form.debtServiceReservesPandIRelase}
          defaultActiveFirstOption={false}
          style={{ width: "100%" }}
          placeholder="Yes/No"
          onChange={(e) =>
            handleFormChange("debtServiceReservesPandIRelase", e)
          }
        >
          <Option value="yes">Yes</Option>
          <Option value="no">No</Option>
        </Select>
      ),
    },
    {
      key: "15",
      description: "Debt Service Reserves (P&I) - Release Timing",
      value: (
        <Input
          value={form.debtServiceReservesPandIRelaseTiming}
          disabled={form.debtServiceReservesPandIRelase === "no"}
          type="number"
          placeholder="Months"
          min={0}
          step={1}
          onChange={(e) =>
            handleFormChange(
              "debtServiceReservesPandIRelaseTiming",
              parseInt(e.target.value)
            )
          }
        />
      ),
    },
    {
      key: "16",
      description: "Debt Service Reserves (P&I) - Total",
      value: (
        <NumberField
          type="money"
          value={
            form.debtServiceReservesPandIMonths *
            bridgeLoanAssumptions.monthlyDebtService
          }
        />
      ),
    },
  ];

  useEffect(() => {
    dispatch({
      type: "UPDATE_LOAN_ACQUISITION_BRIDGE_LOAN_ASSUMPTIONS",
      payload: {
        lender: form.lender,
        date: form.date,
        capExLeveragePercentage: form.capExLeveragePercentage,
        purchasePriceLeveragePercentage: form.purchasePriceLeveragePercentage,
        termYears: form.termYears,
        interestRate: form.interestRate,
        interestOnlyMonths: form.interestOnlyMonths,
        amortizationYears: form.amoritizationYears,
        loanFeesPercentage: form.loanFeesPercentage,
        loanFees: ((form.loanFeesPercentage || 0) / 100) * loanTotal,
        prepaymentPenaltyPercentage: form.prepaymentPenaltyPercentage || 0,
        prepaymentPenalty:
          loanTotal * ((form.prepaymentPenaltyPercentage || 0) / 100),
        debtServiceReservesPandIMonths: form.debtServiceReservesPandIMonths,
        debtServiceReservesPandIRelase: form.debtServiceReservesPandIRelase,
        debtServiceReservesPandIRelaseTiming:
          form.debtServiceReservesPandIRelaseTiming,
        debtServiceReservesPandITotal:
          form.debtServiceReservesPandIMonths *
          bridgeLoanAssumptions.annualDebtService,
        monthlyDebtService: bridgeLoanAssumptions.annualDebtService,
        annualDebtService: bridgeLoanAssumptions.monthlyDebtService,
      },
    });
  }, [form]);

  useEffect(() => {
    return () => {
      dispatch({
        type: "UPDATE_CASH_FLOW_DISTRIBUTION",
        payload: { property: getStoreProperty(), loan: getStoreLoan() },
      });
    };
  }, []);

  return (
    <>
      <Tabs defaultActiveKey="1" style={{ marginTop: -15 }}>
        <TabPane tab="Assumptions" key={1}>
          <Row>
            <Col xs={24} sm={24} md={12}>
              <Table
                dataSource={dataSource}
                columns={columns}
                pagination={false}
                bordered
                style={{ boxShadow: "none!important" }}
              />
            </Col>
          </Row>
        </TabPane>
        <TabPane tab="Acquisition Loan Schedule" key={2}>
          <AcquisitionLoanSchedule />
        </TabPane>
        <TabPane tab="One Month Term SOFR Forward Curve" key={3}>
          <OneMonthTermSofrForwardCurve />
        </TabPane>
      </Tabs>
    </>
  );
};
export default BridgeLoanAssumptions;
