import { Row, Col, Table, Form, Input, Button, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { IRenovationItem } from "../../../../../types/property";
import NumberField from "../../../../shared/number-field";
import ButtonColor from "antd-button-color";
import { DeleteOutlined} from '@ant-design/icons';

interface IInteriorRenovationsProps {
  items: IRenovationItem[];
  totalRenovatingUnits: number;
  onChange(items: IRenovationItem[]): void;
}

const InteriorRenovations = ({ items, totalRenovatingUnits, onChange }: IInteriorRenovationsProps) => {
  const [form] = Form.useForm()
  const [renovations, setRenovations] = useState<IRenovationItem[]>(items);

  const columns = [
    { title: 'Description', key: 'description', dataIndex: 'description' },
    {
      title: () => <span style={{ whiteSpace: 'nowrap' }}>Cost per Unit</span>, render: (_: any, record: any, index: number) =>
        <NumberField type="money" value={renovations[index].cost} />
    },
    {
      title: 'Total', render: (_: any, record: any, index: number) =>
        <NumberField type="money" value={renovations[index].cost * totalRenovatingUnits} />
    },
    {
      title: 'Actions', render: (_: any, record: any, index: number) =>
        <Tooltip title="Delete Renovation Item">
        <ButtonColor onClick={() => handleDelete(index)} type="primary" danger style={{ marginLeft: 10 }} icon={<DeleteOutlined />} />
      </Tooltip>
    },
  ];

  const handleDelete = (unitIndex: number) => {
    setRenovations(renovations.filter((_, index) => index !== unitIndex));
  }

  const handleSubmit = (): void => {
    const formValues = form.getFieldsValue(true);
    const updated = renovations.concat({
      description: formValues.interiorRenovationDescription,
      cost: parseFloat(formValues.interiorRenovationCost),
    });
    setRenovations(updated);
    form.resetFields();
  };

  useEffect(() => {
    onChange(renovations);

  }, [renovations]);

  return (
    <Col span={24} style={{ paddingLeft: 0 }}>
      <h5>{totalRenovatingUnits > 0 ? 'Total cost based on units being renovated' : 'No units are scheduled for interior renovations'}</h5>
      {totalRenovatingUnits > 0 ? (
        <>
          {renovations.length > 0 ? (
            <Table
              scroll={{ x: true }}
              columns={columns}
              dataSource={renovations}
              pagination={false}
            />
          ) : null}
          <Form form={form} layout="vertical">
            <Row gutter={24} style={{ marginTop: 25 }}>
              <Col xs={12} sm={6}>
                <Form.Item
                  name="interiorRenovationDescription"
                  label="Description"
                  required
                >
                  <Input placeholder="Description" type="text" required />
                </Form.Item>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Item
                  name="interiorRenovationCost"
                  label="Cost per Unit"
                  required
                >
                  <Input placeholder="Cost per unit" type="number" min={0} step={0.01} required />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item className="form-footer">
              <Button htmlType="submit" type="primary" onClick={handleSubmit}>
                Add Item
              </Button>
            </Form.Item>
          </Form>
        </>
      ) : null}
    </Col>
  )

}
export default InteriorRenovations;