import { IMonthlyProforma, MonthlyProforma } from ".";
// @ts-ignore
import xirr from "xirr";
import moment from "moment";
import { ILoan } from "../loan";
import { IProperty } from "../property";

interface IAnnualHypotheticalInvestmentCashFlow {
  year: number;
  lpInvestorCashOnCash: number;
  lpInvestorCashOnCashPercentage: number;
  capitalEventCashFlow: number;
  capitalEventReturn: number;
}

interface ITierNetCashflowWaterFallDistribution {
  startingBalance: number;
  requiredReturnByLpToHitTierHurdle: number;
  contributionsFromLp: number;
  priorDistributions: number;
  distributionsToLp: number;
  endingBalance: number;
  irrCheck: number;
  distributionToLp: number;
  distributionToSponsor: number;
  totalDistributions: number;
  cashFlowRemaining: number;
}

export interface IReturnOfCapitalAndPreferredReturn {
  startingBalance: number;
  lpInvestorReturnOfCapital: number;
  requiredReturnToByLpToHitPreferredReturnHurdle: number;
  contributionsFromLp: number;
  distributionsToLp: number;
  endingBalance: number;
  irrCheck: number;
  distributiontoLp: number;
  contributionsFromSponsor: number;
  distributionToSponsor: number;
  totalDistributions: number;
  cashFlowRemaining: number;
}

export interface ICashFlow {
  irr: number;
  cashFlow: { amount: number; when: Date }[];
  totalEquityInvested: number;
  totalDistributions: number;
  equityMultiple: number;
  profit: number;
  year0?: number;
  year1?: number;
  year2?: number;
  year3?: number;
  year4?: number;
  year5?: number;
  year6?: number;
  year7?: number;
  year8?: number;
  year9?: number;
  year10?: number;
}

export class NetCashflowWaterfallDistribution {
  monthlyProforma: IMonthlyProforma[];
  prefAndPromoteSplits = {
    preferredReturn: 0,
    lpInvestors: {
      preferredReturn: 0,
      tier1Percentage: 0,
      tier2Percentage: 0,
      tier3Percentage: 0,
      tier4Percentage: 0,
    },
    gpSponsor: {
      preferredReturn: 0,
      tier1Percentage: 0,
      tier2Percentage: 0,
      tier3Percentage: 0,
      tier4Percentage: 0,
    },
  };
  returnOfCapitalAndPreferredReturns: IReturnOfCapitalAndPreferredReturn[] = [];
  tier1NetCashflowWaterfallDistribution: ITierNetCashflowWaterFallDistribution[] =
    [];
  tier2NetCashflowWaterfallDistribution: ITierNetCashflowWaterFallDistribution[] =
    [];
  tier3NetCashflowWaterfallDistribution: ITierNetCashflowWaterFallDistribution[] =
    [];
  tier4NetCashflowWaterfallDistribution: {
    distributionToLp: number;
    distributionToSponsor: number;
    totalDistributions: number;
    cashFlowRemaining: number;
  }[] = [];

  unleveredCashFlow: ICashFlow = {
    irr: 0,
    cashFlow: [],
    totalEquityInvested: 0,
    totalDistributions: 0,
    equityMultiple: 0,
    profit: 0,
  };

  leveredCashFlow: ICashFlow = {
    irr: 0,
    cashFlow: [],
    totalEquityInvested: 0,
    totalDistributions: 0,
    equityMultiple: 0,
    profit: 0,
  };

  netLpInvestorCashFlow: ICashFlow = {
    irr: 0,
    cashFlow: [],
    totalEquityInvested: 0,
    totalDistributions: 0,
    equityMultiple: 0,
    profit: 0,
  };

  netGpCashFlow: ICashFlow = {
    irr: 0,
    cashFlow: [],
    totalEquityInvested: 0,
    totalDistributions: 0,
    equityMultiple: 0,
    profit: 0,
  };

  hypotheticalCashFlow: ICashFlow = {
    irr: 0,
    cashFlow: [],
    totalEquityInvested: 0,
    totalDistributions: 0,
    equityMultiple: 0,
    profit: 0,
    year0: 0,
    year1: 0,
    year2: 0,
    year3: 0,
    year4: 0,
    year5: 0,
    year6: 0,
    year7: 0,
    year8: 0,
    year9: 0,
    year10: 0,
  };

  coInvestDistributions = {
    total: 0,
    amounts: [0],
  };

  coPromote = {
    total: 0,
    amounts: [0],
  };

  lpInvestorCashOnCashFlow = {
    year0: 0,
    year1: 0,
    year2: 0,
    year3: 0,
    year4: 0,
    year5: 0,
    year6: 0,
    year7: 0,
    year8: 0,
    year9: 0,
    year10: 0,
    amounts: [0],
    total: 0,
  };

  lpInvestorRefiSaleCashFlow = {
    year0: 0,
    year1: 0,
    year2: 0,
    year3: 0,
    year4: 0,
    year5: 0,
    year6: 0,
    year7: 0,
    year8: 0,
    year9: 0,
    year10: 0,
    amounts: [0],
  };

  operatingCashFlowShortfall = 0;
  totalEquity = 0;

  constructor(property: IProperty, loan: ILoan) {
    let monthlyProforma: IMonthlyProforma[] = [];
    [...Array(121)].forEach((_, month) => {
      monthlyProforma.push(new MonthlyProforma(month, property, loan));
    });
    this.monthlyProforma = monthlyProforma;
    if (this.monthlyProforma.length > 0) {
      this._calculatePrefAndPromoteSplits();
      this._calculateReturnOfCapitalPreferredReturn();
      this._calculateTier1NetCashflowWaterfallDistribution();
      this._calculateTier2NetCashflowWaterfallDistribution();
      this._calculateTier3NetCashflowWaterfallDistribution();
      this._calculateTier4NetCashflowWaterfallDistribution();
      this._calculateUnleveredCashFlow();
      this._calculateLeveredCashFlow();
      this._calculateNetLpInvestorCashFlow();
      this._calculateNetGpCashFlow();
      this._calculateHypotheticalInvestment();
      this._calculateCoinvestDistributions();
      this._calculateGpPromote();
      this._calculateLpInvestorCashOnCashFlow();
      this._calculateLpInvestorRefiSaleCashFlow();
      this._calculcateOperatingCashFlowShortfall();
      this._calculateTotalEquity(property, loan);
    }
  }

  private _calculatePrefAndPromoteSplits(): void {
    const equitySplit = this.monthlyProforma[0].equitySplit;
    const promoteIncentiveHurdles =
      this.monthlyProforma[0].promoteIncentiveHurdles;

    this.prefAndPromoteSplits = {
      ...this.prefAndPromoteSplits,
      preferredReturn: promoteIncentiveHurdles.investorPreferredReturn / 100,
      lpInvestors: {
        preferredReturn: equitySplit.lpInvestors / 100,
        tier1Percentage:
          (equitySplit.lpInvestors / 100) *
          (1 - promoteIncentiveHurdles.t1PromotePercentage / 100),
        tier2Percentage:
          (equitySplit.lpInvestors / 100) *
          (1 - promoteIncentiveHurdles.t2PromotePercentage / 100),
        tier3Percentage:
          (equitySplit.lpInvestors / 100) *
          (1 - promoteIncentiveHurdles.t3PromotePercentage / 100),
        tier4Percentage:
          (equitySplit.lpInvestors / 100) *
          (1 - promoteIncentiveHurdles.t4PromotePercentage / 100),
      },
    };

    this.prefAndPromoteSplits = {
      ...this.prefAndPromoteSplits,
      gpSponsor: {
        preferredReturn: equitySplit.gpSponsor / 100,
        tier1Percentage:
          1 - this.prefAndPromoteSplits.lpInvestors.tier1Percentage,
        tier2Percentage:
          1 - this.prefAndPromoteSplits.lpInvestors.tier2Percentage,
        tier3Percentage:
          1 - this.prefAndPromoteSplits.lpInvestors.tier3Percentage,
        tier4Percentage:
          1 - this.prefAndPromoteSplits.lpInvestors.tier4Percentage,
      },
    };
  }

  private _calculateReturnOfCapitalPreferredReturn(): void {
    this.monthlyProforma.forEach((month, index) => {
      if (
        month.month <=
        Math.min(this.monthlyProforma[0].investmentTimeline.exitMonth, 121)
      ) {
        const startingBalance =
          month.month === 0
            ? 0
            : this.returnOfCapitalAndPreferredReturns[index - 1].endingBalance;
        const requiredReturnToByLpToHitPreferredReturnHurdle =
          startingBalance *
          ((1 + this.prefAndPromoteSplits.preferredReturn) ** (1 / 12) - 1);
        const contributionsFromLp =
          -1 *
          Math.min(
            0,
            month.leveredCashFlow *
              this.prefAndPromoteSplits.lpInvestors.preferredReturn
          );
        const distributionsToLp =
          month.month === 0
            ? Math.max(
                requiredReturnToByLpToHitPreferredReturnHurdle,
                month.leveredCashFlow
              )
            : Math.min(
                startingBalance +
                  requiredReturnToByLpToHitPreferredReturnHurdle,
                Math.max(month.leveredCashFlow, 0) *
                  this.prefAndPromoteSplits.lpInvestors.preferredReturn
              );
        const endingBalance =
          startingBalance +
          contributionsFromLp -
          distributionsToLp +
          requiredReturnToByLpToHitPreferredReturnHurdle;
        const irrCheck = -1 * contributionsFromLp + distributionsToLp;
        const distributiontoLp = distributionsToLp;
        const contributionsFromSponsor =
          -1 *
          Math.min(
            0,
            month.leveredCashFlow *
              this.prefAndPromoteSplits.gpSponsor.preferredReturn
          );
        const distributionToSponsor =
          distributiontoLp !== 0
            ? (distributiontoLp /
                this.prefAndPromoteSplits.gpSponsor.preferredReturn) *
              this.prefAndPromoteSplits.lpInvestors.preferredReturn
            : 0;
        const totalDistributions = distributionToSponsor + distributiontoLp;
        const cashFlowRemaining = Math.max(
          0,
          month.leveredCashFlow - totalDistributions
        );
        const lpInvestorReturnOfCapital =
          month.month === 0
            ? Math.max(
                requiredReturnToByLpToHitPreferredReturnHurdle,
                month.leveredCashFlow
              )
            : distributiontoLp - requiredReturnToByLpToHitPreferredReturnHurdle;
        this.returnOfCapitalAndPreferredReturns.push({
          startingBalance,
          lpInvestorReturnOfCapital,
          requiredReturnToByLpToHitPreferredReturnHurdle,
          contributionsFromLp,
          distributionsToLp,
          endingBalance,
          irrCheck,
          distributiontoLp,
          contributionsFromSponsor,
          distributionToSponsor,
          totalDistributions,
          cashFlowRemaining,
        });
      } else {
        this.returnOfCapitalAndPreferredReturns.push({
          startingBalance: 0,
          lpInvestorReturnOfCapital: 0,
          requiredReturnToByLpToHitPreferredReturnHurdle: 0,
          contributionsFromLp: 0,
          distributionsToLp: 0,
          endingBalance: 0,
          irrCheck: 0,
          distributiontoLp: 0,
          contributionsFromSponsor: 0,
          distributionToSponsor: 0,
          totalDistributions: 0,
          cashFlowRemaining: 0,
        });
      }
    });
  }

  private _calculateTier1NetCashflowWaterfallDistribution(): void {
    const promoteIncentiveHurdles =
      this.monthlyProforma[0].promoteIncentiveHurdles;
    this.monthlyProforma.forEach((month, index) => {
      if (
        month.month <=
        Math.min(this.monthlyProforma[0].investmentTimeline.exitMonth, 121)
      ) {
        const startingBalance =
          month.month === 0
            ? 0
            : this.tier1NetCashflowWaterfallDistribution[index - 1]
                .endingBalance;
        const requiredReturnByLpToHitTierHurdle =
          startingBalance *
          ((1 + promoteIncentiveHurdles.t2HurdlePercentage / 100) ** (1 / 12) -
            1);
        const contributionsFromLp =
          -1 *
          Math.min(
            0,
            month.leveredCashFlow *
              this.prefAndPromoteSplits.lpInvestors.preferredReturn
          );
        const priorDistributions =
          this.returnOfCapitalAndPreferredReturns[index].distributionsToLp;
        const distributionsToLp = Math.min(
          this.returnOfCapitalAndPreferredReturns[index].cashFlowRemaining *
            this.prefAndPromoteSplits.lpInvestors.tier1Percentage,
          startingBalance +
            requiredReturnByLpToHitTierHurdle -
            priorDistributions
        );
        const endingBalance =
          startingBalance +
          requiredReturnByLpToHitTierHurdle +
          contributionsFromLp -
          priorDistributions -
          distributionsToLp;
        const irrCheck =
          month.month === 0
            ? -1 * contributionsFromLp + distributionsToLp
            : -1 *
              (-1 * contributionsFromLp -
                priorDistributions -
                distributionsToLp);
        const distributionToLp = distributionsToLp;
        const distributionToSponsor =
          distributionsToLp !== 0
            ? (distributionsToLp /
                (this.prefAndPromoteSplits.lpInvestors.tier1Percentage * 10)) *
              (this.prefAndPromoteSplits.gpSponsor.tier1Percentage * 10)
            : 0;
        const totalDistributions = distributionToLp + distributionToSponsor;
        const cashFlowRemaining = Math.max(
          month.leveredCashFlow -
            this.returnOfCapitalAndPreferredReturns[index].totalDistributions -
            totalDistributions,
          0
        );
        this.tier1NetCashflowWaterfallDistribution.push({
          startingBalance,
          requiredReturnByLpToHitTierHurdle,
          contributionsFromLp,
          priorDistributions,
          distributionsToLp,
          endingBalance,
          irrCheck,
          distributionToLp,
          distributionToSponsor,
          totalDistributions,
          cashFlowRemaining,
        });
      } else {
        this.tier1NetCashflowWaterfallDistribution.push({
          startingBalance: 0,
          requiredReturnByLpToHitTierHurdle: 0,
          contributionsFromLp: 0,
          priorDistributions: 0,
          distributionsToLp: 0,
          endingBalance: 0,
          irrCheck: 0,
          distributionToLp: 0,
          distributionToSponsor: 0,
          totalDistributions: 0,
          cashFlowRemaining: 0,
        });
      }
    });
  }

  private _calculateTier2NetCashflowWaterfallDistribution(): void {
    const promoteIncentiveHurdles =
      this.monthlyProforma[0].promoteIncentiveHurdles;
    this.monthlyProforma.forEach((month, index) => {
      if (
        month.month <=
        Math.min(this.monthlyProforma[0].investmentTimeline.exitMonth, 121)
      ) {
        const startingBalance =
          month.month === 0
            ? 0
            : this.tier2NetCashflowWaterfallDistribution[index - 1]
                .endingBalance;
        const requiredReturnByLpToHitTierHurdle =
          startingBalance *
          ((1 + promoteIncentiveHurdles.t3HurdlePercentage / 100) ** (1 / 12) -
            1);
        const contributionsFromLp =
          -1 *
          Math.min(
            0,
            month.leveredCashFlow *
              this.prefAndPromoteSplits.lpInvestors.preferredReturn
          );
        const priorDistributions =
          this.returnOfCapitalAndPreferredReturns[index].distributionsToLp +
          this.tier1NetCashflowWaterfallDistribution[index].distributionsToLp;
        const distributionsToLp = Math.min(
          this.tier1NetCashflowWaterfallDistribution[index].cashFlowRemaining *
            this.prefAndPromoteSplits.lpInvestors.tier3Percentage,
          startingBalance +
            requiredReturnByLpToHitTierHurdle -
            priorDistributions
        );
        const endingBalance =
          startingBalance +
          requiredReturnByLpToHitTierHurdle +
          contributionsFromLp -
          priorDistributions -
          distributionsToLp;
        const irrCheck =
          month.month === 0
            ? -1 * contributionsFromLp + distributionsToLp
            : -1 *
              (-1 * contributionsFromLp -
                priorDistributions -
                distributionsToLp);
        const distributionToLp = distributionsToLp;
        const distributionToSponsor =
          distributionsToLp !== 0
            ? (distributionsToLp /
                (this.prefAndPromoteSplits.lpInvestors.tier2Percentage * 10)) *
              (this.prefAndPromoteSplits.gpSponsor.tier2Percentage * 10)
            : 0;
        const totalDistributions = distributionToLp + distributionToSponsor;
        const cashFlowRemaining = Math.max(
          month.leveredCashFlow -
            this.returnOfCapitalAndPreferredReturns[index].totalDistributions -
            this.tier1NetCashflowWaterfallDistribution[index]
              .totalDistributions -
            totalDistributions,
          0
        );
        this.tier2NetCashflowWaterfallDistribution.push({
          startingBalance,
          requiredReturnByLpToHitTierHurdle,
          contributionsFromLp,
          priorDistributions,
          distributionsToLp,
          endingBalance,
          irrCheck,
          distributionToLp,
          distributionToSponsor,
          totalDistributions,
          cashFlowRemaining,
        });
      } else {
        this.tier2NetCashflowWaterfallDistribution.push({
          startingBalance: 0,
          requiredReturnByLpToHitTierHurdle: 0,
          contributionsFromLp: 0,
          priorDistributions: 0,
          distributionsToLp: 0,
          endingBalance: 0,
          irrCheck: 0,
          distributionToLp: 0,
          distributionToSponsor: 0,
          totalDistributions: 0,
          cashFlowRemaining: 0,
        });
      }
    });
  }

  private _calculateTier3NetCashflowWaterfallDistribution(): void {
    const promoteIncentiveHurdles =
      this.monthlyProforma[0].promoteIncentiveHurdles;
    this.monthlyProforma.forEach((month, index) => {
      if (
        month.month <=
        Math.min(this.monthlyProforma[0].investmentTimeline.exitMonth, 121)
      ) {
        const startingBalance =
          month.month === 0
            ? 0
            : this.tier3NetCashflowWaterfallDistribution[index - 1]
                .endingBalance;
        const requiredReturnByLpToHitTierHurdle =
          startingBalance *
          ((1 + promoteIncentiveHurdles.t4HurdlePercentage / 100) ** (1 / 12) -
            1);
        const contributionsFromLp =
          -1 *
          Math.min(
            0,
            month.leveredCashFlow *
              this.prefAndPromoteSplits.lpInvestors.preferredReturn
          );
        const priorDistributions =
          this.returnOfCapitalAndPreferredReturns[index].distributionsToLp +
          this.tier1NetCashflowWaterfallDistribution[index].distributionsToLp +
          this.tier2NetCashflowWaterfallDistribution[index].distributionsToLp;
        const distributionsToLp = Math.min(
          this.tier2NetCashflowWaterfallDistribution[index].cashFlowRemaining *
            this.prefAndPromoteSplits.lpInvestors.tier3Percentage,
          startingBalance +
            requiredReturnByLpToHitTierHurdle -
            priorDistributions
        );
        const endingBalance =
          startingBalance +
          requiredReturnByLpToHitTierHurdle +
          contributionsFromLp -
          priorDistributions -
          distributionsToLp;
        const irrCheck =
          month.month === 0
            ? -1 * contributionsFromLp + distributionsToLp
            : -1 *
              (-1 * contributionsFromLp -
                priorDistributions -
                distributionsToLp);
        const distributionToLp = distributionsToLp;
        const distributionToSponsor =
          distributionsToLp !== 0
            ? (distributionsToLp /
                (this.prefAndPromoteSplits.lpInvestors.tier3Percentage * 10)) *
              (this.prefAndPromoteSplits.gpSponsor.tier3Percentage * 10)
            : 0;
        const totalDistributions = distributionToLp + distributionToSponsor;
        const cashFlowRemaining = Math.max(
          month.leveredCashFlow -
            this.returnOfCapitalAndPreferredReturns[index].totalDistributions -
            this.tier1NetCashflowWaterfallDistribution[index]
              .totalDistributions -
            this.tier2NetCashflowWaterfallDistribution[index]
              .totalDistributions -
            totalDistributions,
          0
        );
        this.tier3NetCashflowWaterfallDistribution.push({
          startingBalance,
          requiredReturnByLpToHitTierHurdle,
          contributionsFromLp,
          priorDistributions,
          distributionsToLp,
          endingBalance,
          irrCheck,
          distributionToLp,
          distributionToSponsor,
          totalDistributions,
          cashFlowRemaining,
        });
      } else {
        this.tier3NetCashflowWaterfallDistribution.push({
          startingBalance: 0,
          requiredReturnByLpToHitTierHurdle: 0,
          contributionsFromLp: 0,
          priorDistributions: 0,
          distributionsToLp: 0,
          endingBalance: 0,
          irrCheck: 0,
          distributionToLp: 0,
          distributionToSponsor: 0,
          totalDistributions: 0,
          cashFlowRemaining: 0,
        });
      }
    });
  }

  private _calculateTier4NetCashflowWaterfallDistribution(): void {
    this.monthlyProforma.forEach((month, index) => {
      if (
        month.month <=
        Math.min(this.monthlyProforma[0].investmentTimeline.exitMonth, 121)
      ) {
        const t3CashFlowRemaining =
          this.tier3NetCashflowWaterfallDistribution[index].cashFlowRemaining;
        const distributionToLp =
          t3CashFlowRemaining *
          this.prefAndPromoteSplits.lpInvestors.tier4Percentage;
        const distributionToSponsor = t3CashFlowRemaining - distributionToLp;
        const totalDistributions = distributionToLp + distributionToSponsor;
        const cashFlowRemaining = t3CashFlowRemaining - totalDistributions;
        this.tier4NetCashflowWaterfallDistribution.push({
          distributionToLp,
          distributionToSponsor,
          totalDistributions,
          cashFlowRemaining,
        });
      } else {
        this.tier4NetCashflowWaterfallDistribution.push({
          distributionToLp: 0,
          distributionToSponsor: 0,
          totalDistributions: 0,
          cashFlowRemaining: 0,
        });
      }
    });
  }

  private _calculateUnleveredCashFlow(): void {
    let cashFlow: { amount: number; when: Date }[] = [];
    let irr = 0;
    let totalEquityInvested = 0;
    let totalDistributions = 0;
    let equityMultiple = 0;
    let profit = 0;
    this.monthlyProforma.forEach((month, index) => {
      const amount = month.unleveredCashFlow;
      var when = moment(month.acquisitionBridgeLoanAssumptions.date)
        .add(month.month, "M")
        .endOf("M")
        .toDate();
      cashFlow.push({ amount, when });
      amount < 0
        ? (totalEquityInvested += amount)
        : (totalDistributions += amount);
      profit += amount;
    });

    try {
      irr = xirr(cashFlow) * 100;
    } catch (error) {
      // console.error(error);
    }
    equityMultiple = profit / (-1 * totalEquityInvested) + 1;

    this.unleveredCashFlow = {
      irr,
      totalEquityInvested: -1 * totalEquityInvested,
      totalDistributions,
      equityMultiple,
      profit,
      cashFlow,
    };
  }

  private _calculateLeveredCashFlow(): void {
    let cashFlow: { amount: number; when: Date }[] = [];
    let irr = 0;
    let totalEquityInvested = 0;
    let totalDistributions = 0;
    let equityMultiple = 0;
    let profit = 0;
    this.monthlyProforma.forEach((month, index) => {
      const amount = month.leveredCashFlow || 0;
      var when = moment(month.acquisitionBridgeLoanAssumptions.date)
        .add(month.month, "M")
        .endOf("M")
        .toDate();
      cashFlow.push({ amount, when });
      amount < 0
        ? (totalEquityInvested += amount)
        : (totalDistributions += amount);
      profit += amount;
    });

    try {
      irr = xirr(cashFlow) * 100;
    } catch (error) {
      // console.error(error);
    }
    equityMultiple = profit / (-1 * totalEquityInvested) + 1;

    this.leveredCashFlow = {
      irr,
      totalEquityInvested: -1 * totalEquityInvested,
      totalDistributions,
      equityMultiple,
      profit,
      cashFlow,
    };
  }

  private _calculateNetLpInvestorCashFlow(): void {
    let cashFlow: { amount: number; when: Date }[] = [];
    let irr = 0;
    let totalEquityInvested = 0;
    let totalDistributions = 0;
    let equityMultiple = 0;
    let profit = 0;
    this.monthlyProforma.forEach((month, index) => {
      const value1 =
        -1 * this.returnOfCapitalAndPreferredReturns[index].contributionsFromLp;
      const value2 =
        this.tier1NetCashflowWaterfallDistribution[index].distributionToLp;
      const value3 =
        this.tier2NetCashflowWaterfallDistribution[index].distributionToLp;
      const value4 =
        this.tier3NetCashflowWaterfallDistribution[index].distributionToLp;
      const value5 =
        this.tier4NetCashflowWaterfallDistribution[index].distributionToLp;
      const value6 =
        this.returnOfCapitalAndPreferredReturns[index].distributiontoLp;
      const amount = value1 + value2 + value3 + value4 + value5 + value6;
      var when = moment(month.acquisitionBridgeLoanAssumptions.date)
        .add(month.month, "M")
        .endOf("M")
        .toDate();
      cashFlow.push({ amount, when });
      amount < 0
        ? (totalEquityInvested += amount)
        : (totalDistributions += amount);
      profit += amount;
    });

    try {
      irr = xirr(cashFlow) * 100;
    } catch (error) {
      // console.error(error);
    }
    equityMultiple = profit / (-1 * totalEquityInvested) + 1;

    this.netLpInvestorCashFlow = {
      irr,
      totalEquityInvested: -1 * totalEquityInvested,
      totalDistributions,
      equityMultiple,
      profit,
      cashFlow,
    };
  }

  private _calculateNetGpCashFlow(): void {
    let cashFlow: { amount: number; when: Date }[] = [];
    let irr = 0;
    let totalEquityInvested = 0;
    let totalDistributions = 0;
    let equityMultiple = 0;
    let profit = 0;
    this.monthlyProforma.forEach((month, index) => {
      const value1 =
        -1 *
        this.returnOfCapitalAndPreferredReturns[index].contributionsFromSponsor;
      const value2 =
        this.tier1NetCashflowWaterfallDistribution[index].distributionToSponsor;
      const value3 =
        this.tier2NetCashflowWaterfallDistribution[index].distributionToSponsor;
      const value4 =
        this.tier3NetCashflowWaterfallDistribution[index].distributionToSponsor;
      const value5 =
        this.tier4NetCashflowWaterfallDistribution[index].distributionToSponsor;
      const value6 =
        this.returnOfCapitalAndPreferredReturns[index].distributionToSponsor;
      const amount = value1 + value2 + value3 + value4 + value5 + value6;
      var when = moment(month.acquisitionBridgeLoanAssumptions.date)
        .add(month.month, "M")
        .endOf("M")
        .toDate();
      cashFlow.push({ amount, when });
      amount < 0
        ? (totalEquityInvested += amount)
        : (totalDistributions += amount);
      profit += amount;
    });

    try {
      irr = xirr(cashFlow) * 100;
    } catch (error) {
      // console.error(error);
    }
    equityMultiple = profit / (-1 * totalEquityInvested) + 1;

    this.netGpCashFlow = {
      irr,
      totalEquityInvested: -1 * totalEquityInvested,
      totalDistributions,
      equityMultiple,
      profit,
      cashFlow,
    };
  }

  private _calculateHypotheticalInvestment(): void {
    const investment = 100000;
    const lpEquity = this.netLpInvestorCashFlow.totalEquityInvested;
    const multiplier = investment / lpEquity;
    let cashFlow: { amount: number; when: Date }[] = [];
    let irr = 0;
    let totalEquityInvested = 0;
    let totalDistributions = 0;
    let equityMultiple = 0;
    let profit = 0;
    this.monthlyProforma.forEach((month, index) => {
      const amount =
        multiplier * this.netLpInvestorCashFlow.cashFlow[index].amount;
      var when = moment(month.acquisitionBridgeLoanAssumptions.date)
        .add(month.month, "M")
        .endOf("M")
        .toDate();
      cashFlow.push({ amount, when });
      amount < 0
        ? (totalEquityInvested += amount)
        : (totalDistributions += amount);
      profit += amount;
      // @ts-ignore
      this.hypotheticalCashFlow[`year${month.year}`] += amount;
    });

    try {
      irr = xirr(cashFlow) * 100;
    } catch (error) {
      // console.error(error);
    }
    equityMultiple = profit / (-1 * totalEquityInvested) + 1;

    this.hypotheticalCashFlow = {
      ...this.hypotheticalCashFlow,
      irr,
      totalEquityInvested: -1 * totalEquityInvested,
      totalDistributions,
      equityMultiple,
      profit,
      cashFlow,
    };
  }

  private _calculateCoinvestDistributions(): void {
    let total = 0;
    let amounts: number[] = [];
    this.monthlyProforma.forEach((_, index) => {
      if (this.netGpCashFlow.cashFlow[index].amount < 0) {
        total += 0;
        amounts.push(0);
      } else {
        total +=
          (this.netLpInvestorCashFlow.cashFlow[index].amount /
            this.prefAndPromoteSplits.lpInvestors.preferredReturn) *
          this.prefAndPromoteSplits.gpSponsor.preferredReturn;
        amounts.push(
          (this.netLpInvestorCashFlow.cashFlow[index].amount /
            this.prefAndPromoteSplits.lpInvestors.preferredReturn) *
            this.prefAndPromoteSplits.gpSponsor.preferredReturn
        );
      }
    });

    this.coInvestDistributions = {
      total,
      amounts,
    };
  }

  private _calculateGpPromote(): void {
    let total = 0;
    let amounts: number[] = [];
    this.monthlyProforma.forEach((_, index) => {
      if (this.netGpCashFlow.cashFlow[index].amount < 0) {
        total += 0;
        amounts.push(0);
      } else {
        total +=
          this.netGpCashFlow.cashFlow[index].amount -
          this.coInvestDistributions.amounts[index];
        amounts.push(
          this.netGpCashFlow.cashFlow[index].amount -
            this.coInvestDistributions.amounts[index]
        );
      }
    });

    this.coPromote = {
      total,
      amounts,
    };
  }

  private _calculateLpInvestorCashOnCashFlow(): void {
    let amount = 0;
    this.lpInvestorCashOnCashFlow.amounts = [];
    this.monthlyProforma.forEach((month, index) => {
      if (month.netCashFlowAfterDebtService <= 0) {
        amount = 0;
      } else {
        amount =
          Math.min(
            month.netCashFlowAfterDebtService *
              this.prefAndPromoteSplits.lpInvestors.preferredReturn,
            this.netLpInvestorCashFlow.cashFlow[index].amount
          ) || 0;
      }

      // @ts-ignore
      this.lpInvestorCashOnCashFlow[`year${month.year}`] += amount;
      this.lpInvestorCashOnCashFlow.amounts.push(amount);
      this.lpInvestorCashOnCashFlow.total += amount;
    });
  }

  private _calculateLpInvestorRefiSaleCashFlow(): void {
    let amount = 0;
    this.lpInvestorRefiSaleCashFlow.amounts = [];
    this.monthlyProforma.forEach((month, index) => {
      if (this.netLpInvestorCashFlow.cashFlow[index].amount > 0) {
        amount =
          this.netLpInvestorCashFlow.cashFlow[index].amount -
            this.lpInvestorCashOnCashFlow.amounts[index] || 0;
      } else {
        amount = 0;
      }

      // @ts-ignore
      this.lpInvestorRefiSaleCashFlow[`year${month.year}`] += amount;
      this.lpInvestorRefiSaleCashFlow.amounts.push(amount);
    });
  }

  private _calculcateOperatingCashFlowShortfall() {
    let total = 0;
    const refinanceMonth = this.monthlyProforma[0].refinanceMonth;
    const exitMonth = this.monthlyProforma[0].investmentTimeline.exitMonth;
    let monthCap = refinanceMonth > 0 ? refinanceMonth : exitMonth;
    this.leveredCashFlow.cashFlow.forEach((flow, index) => {
      if (index > 0 && index <= monthCap && flow.amount < 0) {
        total -= flow.amount;
      }
    });

    this.operatingCashFlowShortfall = total;
  }

  private _calculateTotalEquity(property: IProperty, loan: ILoan): void {
    const {
      loanAssumptions: {
        debtServiceReservesPandITotal,
        loanFeesPercentage,
      },
      acquisitionCosts: {
        purchasePrice,
        acquisitionFee,
        transferTaxes,
        dueDilligence,
        legalFees,
        reserves,
        otherClosingCosts,
      },
      loanTotal,
    } = loan;

    const {
      renovations: { totalBudget },
      reservesTaxes,
      reservesInsurance,
    } = property;

    let acquisitionCosts: { total: number }[] = [
      { total: purchasePrice },
      { total: acquisitionFee },
      { total: transferTaxes },
      { total: loanTotal * (loanFeesPercentage / 100) },
      { total: dueDilligence },
      { total: legalFees },
      { total: totalBudget },
      { total: reserves },
      { total: otherClosingCosts },
      { total: debtServiceReservesPandITotal },
      { total: reservesTaxes.yearOneMonthlyAmount * reservesTaxes.months },
      {
        total:
          reservesInsurance.yearOneMonthlyAmount * reservesInsurance.months,
      },
      { total: this.operatingCashFlowShortfall },
    ];

    this.totalEquity =
      acquisitionCosts
        .map((x) => x.total)
        .reduce((prev, curr) => prev + curr, 0) - loanTotal;
  }

  getTotalLpInvestorCashFlow(): { total: number; nonZeroCount: number } {
    let total = 0;
    let nonZeroCount = 0;
    [
      "year1",
      "year2",
      "year3",
      "year4",
      "year5",
      "year6",
      "year7",
      "year8",
      "year9",
      "year10",
    ].forEach((year) => {
      let annualTotal =
        // @ts-ignore
        this.lpInvestorCashOnCashFlow[year] /
        this.netLpInvestorCashFlow.totalEquityInvested;
      if (annualTotal !== 0) {
        nonZeroCount += 1;
      }
      total += annualTotal;
    });

    return {
      total,
      nonZeroCount,
    };
  }

  getNoiByYear = (year?: number): number => {
    if (year) {
      return this.monthlyProforma
        .filter((m) => m.year === year)
        .map((m) => m.netOperatingIncome)
        .reduce((prev, curr) => prev + curr);
    }

    return this.monthlyProforma
      .map((m) => m.netOperatingIncome)
      .reduce((prev, curr) => prev + curr);
  };

  getNetOperatingProjetCashFlow = (year?: number): number => {
    const monthlyProforma = this.monthlyProforma.filter(
      (m) => !isNaN(m.netCashFlowAfterDebtService)
    );
    if (year) {
      return monthlyProforma
        .filter((m) => m.year === year)
        .map((m) => m.netCashFlowAfterDebtService)
        .reduce((prev, curr) => prev + curr);
    }

    return monthlyProforma
      .map((m) => m.netCashFlowAfterDebtService)
      .reduce((prev, curr) => prev + curr);
  };

  getHypotheticalCashFlowYears(): IAnnualHypotheticalInvestmentCashFlow[] {
    let years: IAnnualHypotheticalInvestmentCashFlow[] = [];
    if (this.monthlyProforma.length > 0) {
      const proformaYears = new Set(
        this.monthlyProforma.filter((m) => m.year > 0).map((m) => m.year)
      );
      proformaYears.forEach((proformaYear) => {
        let lpInvestorCashOnCashPercentage =
          100 *
          //@ts-ignore
          (this.lpInvestorCashOnCashFlow[`year${proformaYear}`] /
            this.netLpInvestorCashFlow.totalEquityInvested);
        years.push({
          year: proformaYear,
          lpInvestorCashOnCash: 100000 * (lpInvestorCashOnCashPercentage / 100),
          lpInvestorCashOnCashPercentage,
          capitalEventCashFlow:
            Math.max(
              0,
              (100000 / this.netLpInvestorCashFlow.totalEquityInvested) *
                // @ts-ignore
                this.lpInvestorRefiSaleCashFlow[`year${proformaYear}`]
            ) || 0,
          capitalEventReturn:
            100 *
              (Math.max(
                0,
                (100000 / this.netLpInvestorCashFlow.totalEquityInvested) *
                  // @ts-ignore
                  this.lpInvestorRefiSaleCashFlow[`year${proformaYear}`]
              ) /
                100000) || 0,
        });
      });
    }

    return years;
  }

  getYearOneRentIncomeTotals = () => {
    const yearOneRentIncomeItems = this.monthlyProforma
      .filter((month) => month.year === 1)
      .map((month) => month.rentalRevenue);

    let results = {
      grossPotentialRent: 0,
      vacancyLoss: 0,
      lossToLease: 0,
      rentConcessions: 0,
      creditLossBadDebt: 0,
      nonRevenueUnits: 0,
    };

    yearOneRentIncomeItems.forEach((month) => {
      Object.keys(month).forEach((key) => {
        //@ts-ignore
        results[key] += month[key];
      });
    });

    return results;
  };
}
