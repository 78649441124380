import { Input, notification } from "antd";
import React from "react";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../store";
import Modal from "..";
import { SyncOutlined } from '@ant-design/icons';
import userService from "../../../api/services/user";
import Button from "antd-button-color";

type AddContactModalProps = {
  visible: boolean;
  onDismiss(): void;
}

const AddContactModal: React.FC<AddContactModalProps> = ({ visible, onDismiss }) => {
  const { current } = useAppSelector(state => state.userReducer);
  const [addContactModalVisible, setAddContactModalVisible] = useState<boolean>(visible);
  const [addingEmail, setAddingEmail] = useState<string | null>(null);
  const [newContact, setNewContact] = useState<{
    found: boolean;
    userId: string;
    email: string;
    firstName: string;
    lastName: string;
  } | null>(null);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const handleDismiss = () => {
    setSubmitting(false);
    setAddContactModalVisible(false);
    setNewContact(null);
    setAddingEmail(null);
    onDismiss();
  }

  useEffect(() => {
    setAddContactModalVisible(visible);
  }, [visible]);

  const submitContactRequest = () => {
    setSubmitting(true);
    userService.addContact(current?._id!, addingEmail!)
      .then(res => {
        if (res.data.found) {
          notification.success({
            message: "Contact request submitted successfully",
            duration: 5,
            placement: 'bottomRight',
          });
          handleDismiss();
        } else {
          notification.warning({
            message: `User with email ${addingEmail} was not found`,
            duration: 5,
            placement: 'bottomRight',
          });
          setSubmitting(false)
        }
      })
      .catch(err => {
        console.log(err);
        setSubmitting(false);
        notification.error({
          message: "An error occurred while submitting contact request",
          duration: 5,
          placement: 'bottomRight',
        });
      });
  };

  return (
    <Modal
      title="Add New Contact"
      visible={addContactModalVisible}
      onDismiss={() => handleDismiss()}
    >
      <h4>Email <span style={{ color: 'red' }}>*</span> </h4>
      <Input
        placeholder="Search for user by email"
        type="email"
        value={addingEmail!}
        onChange={e => {
          setAddingEmail(e.target.value)
        }}
      />
      <small><span style={{ color: 'red' }}>*</span> User will have to accept you as a contact before properties can be shared.</small>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: 25 }}>
        <Button onClick={() => submitContactRequest()} type="success" disabled={!addingEmail}>
          {submitting ? <span>Submitting <SyncOutlined spin /></span> : 'Submit'}
        </Button>
        <Button onClick={handleDismiss} type="lightdark" style={{ marginLeft: 10 }}>Cancel</Button>
      </div>
    </Modal>
  )
}

export default AddContactModal;