import { StepType } from '@reactour/tour';

export const tourSteps: StepType[] = [
  {
    selector: '.ant-avatar',
    content: <span>Welcome the 7 Figure Family Multifamily Property Analyzer!  <br /> Let me show you around!</span>,
    position: 'center',
  },
  {
    selector: '.ant-avatar',
    content: <span>From this menu, you can access a vast majority of the application's features.</span>,

    highlightedSelectors: ['.ant-avatar', '.ant-dropdown-placement-bottomLeft'],
  },
  {
    selector: "[data-tour='menu-btn-properties']",
    content: <span>Properties can be accessed here - including those both analyzed and in progress. <br />  Outside of analyzing properties, this will probably the page you become most familiar with!</span>,
  },
  {
    selector: "[data-tour='logo']",
    content: <span>Clicking on the 7 Figure Multifamily logo will also take you the properties page.</span>,
  },
  {
    selector: "[data-tour='menu-btn-profile']",
    content: <span>This where you can manage your profile, theme preferences and other items related to you.</span>,
  },
  {
    selector: "[data-tour='menu-btn-request-feature']",
    content: <span>Is there something you would really like to see implemented in the app? <br />  Request it here - we'll do what we can to satisfy your needs!</span>,
  },
  {
    selector: "[data-tour='menu-btn-report-issue']",
    content: <span>Nobody is perfect. <br />  If you find a problem, let us know and we'll do our best to resolve it quickly!</span>,
  },
  {
    selector: "[data-tour='menu-btn-provide-feedback']",
    content: <span>Let us know what we are doing right - or better yet - what we can improve on, here.</span>,
  },
  {
    selector: "[data-tour='new-property-btn']",
    content: <span>Ready to start analyzing a new property? This is where the whole process begins - exciting!</span>,
  },
  {
    selector: "[data-tour='choose-build-method']",
    content: <span>When creating a new property, there are multiple methods to choose from.</span>,
    position: 'center'
  },
  {
    selector: "[data-tour='property-build-methods']",
    content: <span>Each method has its own way of initializing a new property.</span>,
    position: 'left',
    highlightedSelectors: ["[data-tour='property-build-methods']"],
  },
  {
    selector: "[data-tour='create-property']",
    content: <h4 style={{ color: 'black', textAlign: 'center' }}> Once you have chosen your preferred method, a new property will be generated and you will be dropped into the property analyzer!</h4>,
    position: 'center',
  },
  {
    selector: "[data-tour='property-table']",
    content: <h4 style={{ color: 'black', textAlign: 'center' }}>As you create and analyze properties, they will become available to view, edit, delete and download from this table.</h4>,
    position: 'left'
  },
  {
    selector: "[data-tour='property-statistics']",
    content: <h4 style={{ color: 'black', textAlign: 'center' }}>Statistics related to your properties will also update in real time.</h4>,
    position: 'left'
  },
  {
    selector: "[data-tour='version-button']",
    content: <h4 style={{ color: 'black', textAlign: 'center' }}>You can keep track of new application by clicking here.</h4>,
    position: 'left'
  },
  {
    selector: "[data-tour='version-history']",
    content: <h4 style={{ color: 'black', textAlign: 'center' }}>As the application changes, you can see which improvements and fixed have been implemented. <br />  The application is constantly evolving - be sure to check this page often!</h4>,
    position: 'center'
  },
  {
    selector: "[data-tour='properties']",
    content: <h4 style={{ color: 'black', textAlign: 'center' }}>Thanks for taking the tour!  We are excited to have you both using and aiding in the improvment of the overall expereince.  <br />  The tutorial can be accessed again at any time.</h4>,
    position: 'center'
  },
]