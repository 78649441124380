import * as Sentry from "@sentry/react";

const parseToNumberString = (target: number, digits?: number): string => {
  let paresedValue = 0;
  try {
    paresedValue = parseFloat((target || 0).toFixed(digits || 2));
  } catch (error) {
    console.log(error);
    Sentry.captureException(`parseToNumberString error: received ${target}`);
    paresedValue = parseFloat((0).toFixed(digits || 2));
  }

  return paresedValue.toLocaleString();
};

export default parseToNumberString;
