import { Tabs } from "antd";
import { useEffect } from "react";
import {
  getStoreLoan,
  getStoreProperty,
  useAppDispatch,
  useAppSelector,
} from "../../../../store";
import AnnualProforma from "./annual";
import MonthlyProformaViewer from "./monthly";
const { TabPane } = Tabs;

const ProformaViewer = () => {
  const dispatch = useAppDispatch();
  const {
    distribution,
    property: {
      investmentTimeline: { acquisitionDate: date },
    },
  } = useAppSelector((state) => {
    return {
      distribution: state.cashFlowReducer.distribution,
      loan: state.loanReducer.loan,
      property: state.propertyReducer.property,
    };
  });

  useEffect(() => {
    dispatch({
      type: "UPDATE_CASH_FLOW_DISTRIBUTION",
      payload: { property: getStoreProperty(), loan: getStoreLoan() },
    });
  }, []);

  if (!distribution) {
    return null;
  }

  return (
    <Tabs defaultActiveKey="1" style={{ marginTop: -15 }}>
      <TabPane tab="Annual Proforma" key="1">
        <AnnualProforma distribution={distribution} startDate={date!} />
      </TabPane>
      <TabPane tab="Monthly Proforma" key="2">
        <MonthlyProformaViewer distribution={distribution} startDate={date!} />
      </TabPane>
    </Tabs>
  );
};

export default ProformaViewer;
