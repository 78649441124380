import React from "react"

const NightSkeleton: React.FC<{style?: React.CSSProperties, onClick(): void }> = ({ style, onClick }) => {
  return (
    <div onClick={() => onClick()} style={{ border: '1px solid #001529', borderRadius: 5, ...style }}>
      <div className="ant-skeleton-content" style={{ position: 'relative', backgroundColor: '#001529', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', borderRadius: '4px 4px 0 0', padding: 10 }}>
        <h3 className="ant-skeleton-title" style={{ width: '30%', marginRight: '1em', background: 'white' }}></h3>
        <div style={{ height: 35, width: 35, borderRadius: 100, position: 'absolute', right: 25, background: 'white'  }}></div>
      </div>
      <div className="ant-skeleton-content" style={{ display: 'block', padding: 25, background:'black' }}>
        <div style={{ border: '3px solid rgba(190, 190, 190, 0.2)', padding: 25, borderRadius: 4 }}>
          <h3 className="ant-skeleton-title" style={{ width: '50%', background:"white"}}></h3>
          <ul className="ant-skeleton-paragraph" >
            <li style={{background:'white'}} ></li>
            <li style={{background:'white'}} ></li>
            <li style={{background:'white'}} ></li>
            <li style={{ width: '12%', display: 'inline-block', marginRight: 10, background: '#28a745' }}></li>
            <li style={{ width: '12%', display: 'inline-block', marginRight: 10, background: '#1890ff' }}></li>
            <li style={{ width: '12%', display: 'inline-block', marginRight: 10, background: '#eca52b' }}></li>
            <li style={{ width: '12%', display: 'inline-block', background: '#ff4d4f' }}></li>
          </ul>

        </div>
      </div>
      <div className="ant-skeleton-content" style={{ backgroundColor: '#001529', display: 'flex', justifyContent: 'flex-start', borderRadius: '0 0 4px 4px', padding: 10 }}>
      </div>
    </div>
  )
}

export { NightSkeleton }