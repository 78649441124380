import { Avatar, Badge, Dropdown, Menu, Select } from "antd";
import useMedia from "use-media-antd-query";
import { useAppDispatch, useAppSelector } from "../../../store";
import {
  MenuOutlined,
  HomeOutlined,
  CloseOutlined,
  UserOutlined,
  LoginOutlined,
  BugOutlined,
  RocketOutlined,
  CommentOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";
import FeedbackModal from "../../modals/feedback";
import { useAuth0 } from "@auth0/auth0-react";
import { useTour } from "@reactour/tour";
import { BsCaretDownFill } from "react-icons/bs";
import { AvailableStatus, BusyStatus, getStatusByStatus, InactiveStatus } from "../../../store/reducers/user/status";

const MainMenu = () => {
  const { status } = useAppSelector((state) => state.userReducer);
  const dispatch = useAppDispatch();
  const [showMenu, setShowMenu] = useState(false);
  const { isOpen, currentStep } = useTour();
  const [showFeedbackForm, setShowFeedbackForm] = useState(false);
  const { current } = useAppSelector((state) => state.userReducer);
  const colSize = useMedia();
  const navigate = useNavigate();
  const { logout } = useAuth0();

  const handleLogout = async () => {
    localStorage.setItem("hardlogout", "yes");
    localStorage.removeItem("theme");
    localStorage.removeItem("avatarUrl");
    logout({ returnTo: window.location.origin });
  };

  const handleVisibility = (
    event: "scroll" | "component" | "tour",
    visible?: boolean
  ) => {
    const canCloseTourVisibility =
      (isOpen as boolean) === false || currentStep > 7;
    switch (event) {
      case "component":
        if (canCloseTourVisibility) {
          setShowMenu(visible!);
        }
        break;
      case "scroll":
        if (canCloseTourVisibility) {
          setShowMenu(false);
        }
        break;
      case "tour":
        setShowMenu(visible!);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if ((isOpen as boolean) && currentStep <= 7) {
      handleVisibility("tour", true);
    } else if ((isOpen as boolean) && currentStep > 7) {
      handleVisibility("tour", false);
    } else if (!isOpen as boolean) {
      setShowMenu(false);
    }
  }, [isOpen, currentStep]);

  const menuItems = (
    <Menu
      mode="inline"
      style={{ maxWidth: 200 }}
      items={[
        {
          key: "1",
          label: (
            <div
              style={{
                whiteSpace: "nowrap",
                textOverflow: 'ellipsis',
                overflow:'hidden',
                width: 180
              }}
            >
              Signed in as
              <br /> {current?.email}
            </div>
          ),
        },
        {
          key: "2",
          label: (
            <Select
              value={status.status}
              bordered={false}
              onChange={(status) =>
                dispatch({ type: "UPDATE_USER_STATUS", payload: getStatusByStatus(status) })
              }
              size="small"
              style={{ width: "100%" }}
              placeholder="Set your status"
            >
              <Select.Option value={AvailableStatus.status}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    style={{
                      width: 10,
                      height: 10,
                      marginRight: 10,
                      background: AvailableStatus.color,
                      borderRadius: "100%",
                    }}
                  />{" "}
                  <span>{AvailableStatus.status}</span>
                </div>
              </Select.Option>
              <Select.Option value={BusyStatus.status}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    style={{
                      width: 10,
                      height: 10,
                      marginRight: 10,
                      background: BusyStatus.color,
                      borderRadius: "100%",
                    }}
                  />{" "}
                  <span>{BusyStatus.status}</span>
                </div>
              </Select.Option>
              <Select.Option value={InactiveStatus.status}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    style={{
                      width: 10,
                      height: 10,
                      marginRight: 10,
                      background: InactiveStatus.color,
                      borderRadius: "100%",
                    }}
                  />{" "}
                  <span>{InactiveStatus.status}</span>
                </div>
              </Select.Option>
            </Select>
          ),
        },
        {
          key: "3",
          icon: <HomeOutlined style={{ fontSize: 15, marginLeft: 5 }} />,
          label: "Properties",
          onClick: () => navigate("/properties"),
        },
        {
          key: "4",
          icon: <UserOutlined style={{ fontSize: 15, marginLeft: 5 }} />,
          label: "Profile",
          onClick: () => navigate("/profile"),
        },
        {
          key: "5",
          icon: <TeamOutlined style={{ fontSize: 15, marginLeft: 5 }} />,
          label: (
            <div style={{ display: "flex", alignContent: "center" }}>
              <span style={{ marginRight: 5 }}>Contacts</span>
              <Badge count={localStorage.getItem("contact-request-count")} />
            </div>
          ),
          onClick: () => navigate("/contacts"),
        },
        {
          key: "6",
          icon: <RocketOutlined style={{ fontSize: 15, marginLeft: 5 }} />,
          label: "Request Feature",
          onClick: () => navigate("/feature-request"),
        },
        {
          key: "7",
          icon: <BugOutlined style={{ fontSize: 15, marginLeft: 5 }} />,
          label: "Report Issue",
          onClick: () => navigate("/report-issue"),
        },
        {
          key: "8",
          icon: <CommentOutlined style={{ fontSize: 15, marginLeft: 5 }} />,
          label: "Provide Feedback",
          onClick: () => setShowFeedbackForm(true),
        },
        {
          key: "9",
          type: "divider",
        },
        {
          key: "10",
          icon: <LoginOutlined style={{ fontSize: 15, marginLeft: 5 }} />,
          label: "Logout",
          onClick: handleLogout,
        },
      ]}
    />
  );

  return (
    <div style={{ marginTop: ["xs", "sm"].includes(colSize) ? -10 : 0 }}>
      <Dropdown
        overlayStyle={{
          position: "fixed",
          boxShadow: "0 8px 24px #010409",
          border: "1px solid #30363d",
        }}
        overlay={menuItems}
        placement="bottomLeft"
        trigger={["click"]}
        visible={showMenu}
        onVisibleChange={(visible) => handleVisibility("component", visible)}
      >
        <div>
          {["xs", "sm"].includes(colSize) ? (
            <>
              {showMenu ? (
                <CloseOutlined style={{ color: "white", fontSize: 30 }} />
              ) : (
                <MenuOutlined style={{ color: "white", fontSize: 30 }} />
              )}
            </>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                position: "relative",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  right: 75,
                  whiteSpace: "nowrap",
                  color: "white",
                  display:'flex',
                  justifyContent: 'center',
                  flexDirection: 'column'
                }}
              >
                <span>{current?.displayName}</span>
              </div>
              <Badge count={localStorage.getItem("contact-request-count")}>
                <Avatar
                  className="avatar"
                  size={40}
                  src={current?.avatarUrl}
                  style={{
                    backgroundColor: current?.avatarColor,
                  }}
                >
                  {current?.avatarUrl ? null : (
                    <span
                      style={{
                        fontSize: 20,
                        textShadow:
                          "-.5px 0 black, 0 .5px black, .5px 0 black, 0 -.5px black",
                      }}
                    >
                      {current?.initials}
                    </span>
                  )}
                </Avatar>
              </Badge>
              <BsCaretDownFill style={{ marginLeft: 10, color: "white" }} />
                <div
                className="status-border"
                  style={{
                    width: 12,
                    height: 12,
                    background: status.color,
                    borderRadius: "100%",
                    position:'absolute',
                    bottom: -2,
                  }}
                />
            </div>
          )}
        </div>
      </Dropdown>
      <FeedbackModal
        visible={showFeedbackForm}
        onDismiss={() => setShowFeedbackForm(false)}
      />
    </div>
  );
};

export default MainMenu;
