import { notification, Tooltip, Upload } from "antd";
import { DraggerProps } from "antd/lib/upload";
import { useEffect, useState } from "react";
import { CloseOutlined, HomeOutlined } from '@ant-design/icons';
import IAnalyzedProperty from "../../../types/analyzed-property";
const { Dragger } = Upload;

type PropertyUploaderProps = {
  onPropertyUploaded(property: IAnalyzedProperty | undefined): void;
}

const PropertyUploader: React.FC<PropertyUploaderProps> = ({ onPropertyUploaded }) => {
  const [property, setProperty] = useState<IAnalyzedProperty>();

  const props: DraggerProps = {
    name: 'file',
    multiple: false,
    beforeUpload: () => false,
    onChange(info) {
      const fileReader = new FileReader();
      fileReader.readAsText(info.fileList[0].originFileObj!, "UTF-8");
      fileReader.onload = e => {
        if (e.target?.result) {
          try {
            const property: IAnalyzedProperty = JSON.parse(e.target.result.toString());
            setProperty(property);
          } catch (error) {
            notification.error({
              message: "An invalid property file was encountered",
              duration: 5,
              placement: 'bottomRight',
            });
          }
        }
      };
    },
  };

  useEffect(() => {
    onPropertyUploaded(property);
  }, [property])

  return (
    <div style={{ marginBottom: 25 }}>
      {property ? (
        <div>
          <span style={{ display: 'block' }}>
            Property <b>"{property.name}"</b> was uploaded succesfully
            <Tooltip title="Remove uploaded property" mouseEnterDelay={0.5}>
              <CloseOutlined onClick={() => setProperty(undefined)} style={{ marginLeft: 10, color: 'red', cursor: 'pointer' }} />
            </Tooltip>
          </span>
        </div>
      ) : (
        <Dragger {...props} showUploadList={false} maxCount={1}>
          <p className="ant-upload-drag-icon">
            <HomeOutlined style={{color:'lightgray'}} />
          </p>
          <p className="ant-upload-text" style={{color:'gray'}}>Click or drag property file to this area to upload</p>
        </Dragger>
      )}
    </div>
  )
}

export default PropertyUploader;