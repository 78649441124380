import { Table } from "antd";
import React from "react";
import NumberField from "../../../../shared/number-field";
import { IReturns } from "../investment-summary-service";

interface INetReturnsToInvestorsProps {
  netReturnsToInvestors: IReturns;
}

const NetReturnsToInvestors: React.FC<INetReturnsToInvestorsProps> = ({
  netReturnsToInvestors,
}) => {
  const {
    investorIRR,
    investorEquityMultiple,
    investorEquityInvested,
    totalInvestorDistributions,
    investorProfit,
    lPAvgAnnualCashOnCashReturnWithoutSale,
    totalAverageAnnualReturnWithSale,
  } = netReturnsToInvestors;

  const columns = [
    {
      dataIndex: "description",
      key: "description",
      width: "50%",
    },
    {
      dataIndex: "value",
      key: "value",
      width: "50%",
    },
  ];

  const dataSource = [
    {
      key: "1",
      description: "Investor IRR",
      value: (
        <NumberField
          type="percentage"
          value={investorIRR}
        />
      ),
    },
    {
      key: "2",
      description: "Investor Equity Multiple",
      value: (
        <span style={{ float: "right" }}>
          {investorEquityMultiple}
        </span>
      ),
    },
    {
      key: "3",
      description: "Investor Equity Invested",
      value: (
        <NumberField
          type="money"
          value={investorEquityInvested}
        />
      ),
    },
    {
      key: "4",
      description: "Total Investor Distributions",
      value: (
        <NumberField
          type="money"
          value={totalInvestorDistributions}
        />
      ),
    },
    {
      key: "5",
      description: "Investor Profit",
      value: (
        <NumberField
          type="money"
          value={investorProfit}
        />
      ),
    },
    {
      key: "6",
      description: "LP Avg. Annual Cash-on-Cash Return (w/out sale)",
      value: <NumberField type="percentage" value={lPAvgAnnualCashOnCashReturnWithoutSale} />,
    },
    {
      key: "7",
      description: "Total Average Annual Return (w/sale)",
      value: (
        <NumberField type="percentage" value={totalAverageAnnualReturnWithSale * 100} />
      ),
    },
  ];

  return (
    <>
      <Table
        title={() => (
          <div style={{ textAlign: "center" }}>
            <b>Net Returns to Investors</b>
          </div>
        )}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        bordered
        showHeader={false}
      />
    </>
  );
};
export default NetReturnsToInvestors;
