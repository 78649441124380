import { LoadingOutlined } from "@ant-design/icons";
import { useLocation } from "react-router";

type SpinnerProps = {
  size: "small" | "large";
};

const Spinner: React.FC<SpinnerProps> = ({ size }) => {
  const location = useLocation();
  let spinnerStyle: React.CSSProperties = {
    animation: "loadingCircle 0.95s infinite linear",
    fontSize: 0,
  };

  switch (size) {
    case "small":
      spinnerStyle = { ...spinnerStyle, fontSize: 60 };
      break;
    case "large":
      spinnerStyle = { ...spinnerStyle, fontSize: 150 };
      break;
    default:
      break;
  }

  if (location.pathname.includes("auth")) {
    spinnerStyle = { ...spinnerStyle, color: "white", backgroundColor:'black' };
  }

  return (
    <div style={{ borderRadius: 100 }} className="spinner-background">
      <LoadingOutlined style={spinnerStyle} className="spinner-color" spin />
    </div>
  );
};

export default Spinner;
