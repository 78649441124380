import Pusher, { Members, PresenceChannel } from "pusher-js";
import React, { useEffect, useState } from "react";
import userService, { IContactDetails } from "../api/services/user";
import { useAppSelector } from "../store";
import { UserStatus } from "../store/reducers/user/status";

interface IPusherMember {
  id: string;
  info: {
    [key: string]: any;
  };
}

export interface IOnlineUser {
  id: string;
  status: UserStatus;
}

const PusherContext = React.createContext<{
  onlineUsers: IOnlineUser[];
}>({
  onlineUsers: [],
});

const PusherProvider: React.FC = ({ children }) => {
  const { current, status } = useAppSelector((state) => state.userReducer);
  const [onlineUsers, setOnlineUsers] = useState<IOnlineUser[]>([]);
  const [pusher, setPusher] = useState<Pusher>();

  const subscribeToChannel = (channelName: string, channelPusher: Pusher) => {
    if (!current || !channelPusher) return;
    const channel = channelPusher.subscribe(
      channelName
    ) as PresenceChannel;

    channel.bind(
      "pusher:subscription_succeeded",
      (members: Members) => {
        const channelMembers: IOnlineUser[] = [];
        const channelMemberIds = Object.keys(members.members).filter(
          (k) => k !== current._id
        );
        channelMemberIds.forEach((id) => {
          channelMembers.push({
            id: id,
            status: members.members[id].status,
          });
        });
        setOnlineUsers((onlineMembers) => [
          ...onlineMembers,
          ...channelMembers,
        ]);
      }
    );

    channel.bind("pusher:member_added", (member: IPusherMember) => {
      const addedChannelMember: IOnlineUser = {
        id: member.id,
        status: member.info.status,
      };
      setOnlineUsers((onlineUsers) => [
        ...onlineUsers,
        addedChannelMember,
      ]);
    });

    channel.bind(
      "pusher:member_removed",
      (member: { id: string }) => {
        setOnlineUsers((onlineUsers) =>
          onlineUsers.filter((user) => user.id !== member.id)
        );
      }
    );
  }

  const initPusher = async () => {
    if (current) {
      const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY!, {
        cluster: process.env.REACT_APP_PUSHER_CLUSTER!,
        authEndpoint: process.env.REACT_APP_API_URL + "/pusher/auth",
        authTransport: "ajax",
        auth: {
          headers: {
            AUTH0_USER_ID: current.auth0Id,
            USER_STATUS: status.status,
          },
        },
      });

      userService.getContacts().then((res) => {
        if (res.data.contacts) {
          res.data.contacts
            .filter((c) => c.request === false)
            .forEach((c) => {
              subscribeToChannel(`presence-users-${c.userId}`, pusher)
            });
        }
      });

      subscribeToChannel(`presence-users-${current._id}`, pusher)

      setPusher(pusher);
    }
  };

  useEffect(() => {
    initPusher();
  }, [current]);

  useEffect(() => {
    if (status && pusher) {
      pusher.disconnect();
      initPusher();
    }
  }, [status]);

  if (pusher) {
    return (
      <PusherContext.Provider value={{ onlineUsers }}>
        {children}
      </PusherContext.Provider>
    );
  }

  return <>{children}</>;
};

const usePusher = () => {
  const context = React.useContext(PusherContext);
  if (!context) {
    throw new Error("usePusher must be used within a PusherProvider");
  }

  const { onlineUsers } = context;

  return { onlineUsers };
};

export { PusherProvider, usePusher };
