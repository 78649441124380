import { Card, Col, Input, Table } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { IExitDispositionSummary } from "../../../../types/property";
import NumberField from "../../../shared/number-field";

const columns1 = [
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    width: '80%',
  },
  {
    dataIndex: 'value',
    key: 'value',
    width: '20%',
  },
];

const columns2 = [
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    width: '40%',
  },
  {
    dataIndex: 'percentage',
    key: 'percentage',
    width: '20%',
  },
  {
    title: 'Total',
    dataIndex: 'total',
    key: 'total',
    width: '20%',
  },
  {
    title: 'Total Per Unit',
    dataIndex: 'totalPerUnit',
    key: 'totalPerUnit',
    width: '20%',
  },
];

const ExitDispositionSummary = () => {
  const dispatch = useAppDispatch();
  const {
    propertyReducer: { property, property: { annualAssumptions, investmentTimeline, exitDispositionSummary, totalUnits } },
    loanReducer: { loan: { acquisitionCosts: { purchasePrice }, loanAssumptions: { date } } },
    cashFlowReducer: { distribution },
  } = useAppSelector(({ propertyReducer, loanReducer, cashFlowReducer }) => {
    return { propertyReducer, loanReducer, cashFlowReducer }
  });

  const [form, setForm] = useState<IExitDispositionSummary>({ ...exitDispositionSummary, date: investmentTimeline.exitDispositionDate });

  const updateExitForm = async () => {
    const { monthlyProforma } = distribution!;
    if (monthlyProforma.length > 0 && investmentTimeline.exitMonth > 0) {
      let goingInCapRate = (property.getGoingInNoi() / purchasePrice) * 100
      let newForm = { ...form };
      let startMonth = investmentTimeline.exitMonth;
      let total = 0;
      while (startMonth > investmentTimeline.exitMonth - 12) {
        if (typeof monthlyProforma[startMonth] !== 'undefined') {
          total += monthlyProforma[startMonth].netOperatingIncome;
        }
        startMonth -= 1;
      }
      newForm.date = moment(date).add(investmentTimeline.exitMonth, 'M').endOf('M').toDate();;
      newForm.noiTrailing12Months = total;
      newForm.grossSaleProceeds = newForm.noiTrailing12Months / (newForm.capRate / 100);
      newForm.capRateSpread = (newForm.capRate - goingInCapRate) * 100;
      newForm.saleClosingCosts = newForm.grossSaleProceeds * (newForm.saleClosingCostsPercentage / 100);
      newForm.dispositionFee = newForm.grossSaleProceeds * (newForm.dispositionFeePercentage / 100);
      newForm.loanRepaymentAtSale = 0;
      if (monthlyProforma[0].refinancing) {
        newForm.loanRepaymentAtSale = monthlyProforma[0].refinancingLoanSchedule[investmentTimeline.exitMonth - monthlyProforma[0].refinanceMonth].endingBalance;
      } else {
        newForm.loanRepaymentAtSale = monthlyProforma[0].acquisitionLoanSchedule[investmentTimeline.exitMonth].endingBalance
      }

      newForm.netSaleProceeds = newForm.grossSaleProceeds - newForm.saleClosingCosts - newForm.dispositionFee - newForm.loanRepaymentAtSale;
      //@ts-ignore
      newForm.occupancy = 100 - annualAssumptions.filter(x => x.name === "vacancyLoss")[0][`year${moment(newForm.date).diff(date, 'years') || 0}`];
      setForm(newForm);
    }
  }

  useEffect(() => {
    updateExitForm();
  }, [investmentTimeline.exitDispositionDate, form.capRate, form.dispositionFeePercentage, form.saleClosingCostsPercentage]);

  useEffect(() => {
     dispatch({type:'UPDATE_PROPERTY_EXIT_DISPOSITION_SUMMARY', payload: form})
  }, [form])

  const dataSource = [
    {
      key: '1',
      description: "Exit/Disposition Date",
      value: <span style={{ float: 'right' }}>{form.date?.toLocaleDateString()}</span>,
    },
    {
      key: '2',
      description: "Exit Cap Rate Spread (from T-12 cap at acquisition)",
      value: <i style={{ float: 'right' }}>{Math.round(form.capRateSpread)}bps</i>,
    },
    {
      key: '3',
      description: "Exit Cap Rate",
      value: <Input style={{ minWidth: 100 }} suffix={<span>%</span>} type="number" value={form.capRate} min={0} max={100} step={0.01} onChange={e => setForm(form => ({ ...form, capRate: parseFloat(e.target.value) || 0 }))} />,
    },
    {
      key: '4',
      description: "Exit NOI (trailing 12 month NOI)",
      value: <NumberField type="money" value={Math.round(form.noiTrailing12Months)} />
    },
    {
      key: '5',
      description: "Occupancy at Exit",
      value: <NumberField type="percentage" value={form.occupancy} />
    }
  ];

  const dataSource2 = [
    {
      key: '1',
      description: "Gross Sale Proceeds",
      percentage: null,
      total: <NumberField type="money" value={form.grossSaleProceeds} />,
      totalPerUnit: <NumberField type="money" value={form.grossSaleProceeds / totalUnits} />,
    },
    {
      key: '2',
      description: "Disposition Fee",
      percentage: <Input suffix={<span>%</span>} style={{ minWidth: 100 }} type="number" value={form.dispositionFeePercentage} min={0} max={100} step={0.01} onChange={e => setForm(form => ({ ...form, dispositionFeePercentage: parseFloat(e.target.value) || 0 }))} />,
      total: <NumberField type="money" negative={form.dispositionFeePercentage > 0} value={form.dispositionFee} />,
      totalPerUnit: <NumberField type="money" negative={form.dispositionFeePercentage > 0} value={form.dispositionFee / totalUnits} />,
    },
    {
      key: '3',
      description: "Sale/Closing Costs",
      percentage: <Input suffix={<span>%</span>} style={{ minWidth: 100 }} type="number" value={form.saleClosingCostsPercentage} min={0} max={100} step={0.01} onChange={e => setForm(form => ({ ...form, saleClosingCostsPercentage: parseFloat(e.target.value) || 0 }))} />,
      total: <NumberField type="money" negative={form.saleClosingCostsPercentage > 0} value={form.saleClosingCosts} />,
      totalPerUnit: <NumberField type="money" negative={form.saleClosingCostsPercentage > 0} value={form.saleClosingCosts / totalUnits} />,
    },
    {
      key: '4',
      description: null,
      percentage: null,
      total: <NumberField type="money" value={form.grossSaleProceeds - form.dispositionFee - form.saleClosingCosts} />,
      totalPerUnit: <NumberField type="money" value={(form.grossSaleProceeds - form.dispositionFee - form.saleClosingCosts) / totalUnits} />,
    },
  ];

  return (
    <>
      <Col xs={24} sm={24} md={20} lg={16} xl={12} xxl={10} style={{ paddingLeft: 0 }}>
        <Table
          columns={columns1}
          dataSource={dataSource}
          bordered
          pagination={false}
          showHeader={false}
          scroll={{ x: true }}
          style={{
            marginBottom: form.capRate > 0 ? 25 : 0,
            whiteSpace: 'nowrap'
          }}
        />
        {form.capRate > 0 ? <Table
          columns={columns2}
          dataSource={dataSource2}
          bordered
          pagination={false}
          scroll={{ x: true }}
          style={{
            whiteSpace: 'nowrap'
          }}
        /> : null}
      </Col>
    </>
  )

}
export default ExitDispositionSummary;