import { Card, Col, Row } from "antd";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { IReserveItem } from "../../../../types/property";
import AdditionalReservesDetailsInsurance from "./insurance";
import AdditionalReservesDetailsReTaxes from "./re-taxes";

const AdditionalReservesDetails = () => {
  const dispatch = useAppDispatch();
  const { property: { reservesTaxes, reservesInsurance } } = useAppSelector(state => state.propertyReducer);
  const [taxes, setTaxes] = useState<IReserveItem>();
  const taxesRef = useRef<IReserveItem | undefined>();
  const [insurance, setInsurance] = useState<IReserveItem>();
  const insuranceRef = useRef<IReserveItem | undefined>();

  const handleFormChange = (item: IReserveItem, type: "taxes" | "insurance") => {
    switch (type) {
      case "taxes":
        setTaxes(item);
        break;
      case "insurance":
        setInsurance(item);
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    taxesRef.current = taxes;
  }, [taxes]);

  useEffect(() => {
    insuranceRef.current = insurance;
  }, [insurance]);

  useEffect(() => {
    return () => {
      dispatch({ type: "UPDATE_PROPERTY_RESERVES", payload: { taxes: taxesRef.current!, insurance: insuranceRef.current! } });
    }
  }, [])

  return (
    <>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={12} style={{ marginBottom: 25 }}>
          <AdditionalReservesDetailsReTaxes reserveItem={reservesTaxes} onFormChange={taxes => handleFormChange(taxes, "taxes")} />

        </Col>
        <Col xs={24} sm={24} md={24} lg={12}>
          <AdditionalReservesDetailsInsurance reserveItem={reservesInsurance} onFormChange={insurance => handleFormChange(insurance, "insurance")} />
        </Col>
      </Row>
    </>
  )

}
export default AdditionalReservesDetails;