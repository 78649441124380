export type UserStatus = "Available" | "Busy" | "Inactive";

export interface IUserStatus {
  status: UserStatus;
  color: string;
}

export const AvailableStatus: IUserStatus = { status: "Available", color: "#2EB67D" };
export const BusyStatus: IUserStatus = { status: "Busy", color: "#a61d24" };
export const InactiveStatus: IUserStatus = { status: "Inactive", color: "gray" };


export const getStatusByStatus = (status: UserStatus): IUserStatus => {
  switch (status) {
    case "Available":
      return AvailableStatus;
    case "Busy":
      return BusyStatus;
    case "Inactive":
      return InactiveStatus;
    default:
      return InactiveStatus;
  }
}