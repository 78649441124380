import { User } from "../../../types/user";
import { UserAction } from "../../actions/user";
import { AvailableStatus, IUserStatus } from "./status";
import * as Sentry from "@sentry/react";

export interface IUserState {
  current: User | null;
  hardlogout: boolean;
  status: IUserStatus;
}

const initialState: IUserState = {
  current: null,
  hardlogout: false,
  status: AvailableStatus,
};

export const userReducer = (
  state: IUserState = initialState,
  action: UserAction
): IUserState => {
  switch (action.type) {
    case "UPDATE_USER_CURRENT_USER":
      localStorage.setItem(
        "contact-request-count",
        action.payload.contactRequests?.length.toString() || (0).toString()
      );
      Sentry.setUser({
        id: action.payload._id,
        email: action.payload.email,
        username: `${action.payload.firstName} ${action.payload.lastName}`,
      });
      return { ...state, current: action.payload };
    case "UPDATE_USER_HARD_LOGOUT":
      return { ...state, hardlogout: action.payload };
    case "UPDATE_USER_STATUS":
      return { ...state, status: action.payload };
    default:
      return state;
  }
};
