import { Col, Row, Table } from "antd";
import parseToNumberString from "../../../../../utils/parse-to-number-string";
import useMediaQuery from 'use-media-antd-query';
import { useAppSelector } from "../../../../../store";

const AcquisitionLoanSchedule = () => {
  const { loan: { loanTotal, loanAssumptions: assumptions, acquisitionLoanSchedule } } = useAppSelector(state => state.loanReducer);
  const colSize = useMediaQuery();
  const assumptionsColumns = [
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: ["xs", "sm"].includes(colSize) ? "50%" : '65%',
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
      width: ["xs", "sm"].includes(colSize) ? "50%" : '35%',
    },
  ];

  const scheduleColumns = [
    {
      title: 'Year',
      dataIndex: 'year',
      key: 'year',
      render: (_: any, record: any, index: number) =>
        <span style={{ minWidth: 50, float: 'right' }}>{acquisitionLoanSchedule[index].year}</span>
    },
    {
      title: 'Month',
      dataIndex: 'month',
      key: 'month',
      render: (_: any, record: any, index: number) =>
        <span style={{ minWidth: 50, float: 'right' }}>{acquisitionLoanSchedule[index].month}</span>
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (_: any, record: any, index: number) =>
        <span style={{ float: 'right' }}>{acquisitionLoanSchedule[index].date.toLocaleDateString()}</span>
    },
    {
      title: 'Starting Balance',
      dataIndex: 'startingBalance',
      key: 'startingBalance',
      render: (_: any, record: any, index: number) =>
        <span style={{ float: 'right' }}>${parseToNumberString(acquisitionLoanSchedule[index].startingBalance)}</span>
    },
    {
      title: 'Total Monthly Payment',
      dataIndex: 'payment',
      key: 'payment',
      render: (_: any, record: any, index: number) =>
        <span style={{ float: 'right' }}>${parseToNumberString(acquisitionLoanSchedule[index].payment)}</span>
    },
    {
      title: 'Monthly Principal',
      dataIndex: 'principal',
      key: 'principal',
      render: (_: any, record: any, index: number) =>
        <span style={{ float: 'right' }}>${parseToNumberString(acquisitionLoanSchedule[index].principal)}</span>
    },
    {
      title: 'Monthly Interest',
      dataIndex: 'interest',
      key: 'interest',
      render: (_: any, record: any, index: number) =>
        <span style={{ float: 'right' }}>${parseToNumberString(acquisitionLoanSchedule[index].interest)}</span>
    },
    {
      title: 'Ending Balance',
      dataIndex: 'endingBalance',
      key: 'endingBalance',
      render: (_: any, record: any, index: number) =>
        <span style={{ float: 'right' }}>${parseToNumberString(acquisitionLoanSchedule[index].endingBalance)}</span>
    },
    {
      title: 'Total Interest',
      dataIndex: 'totalInterestPaid',
      key: 'totalInterestPaid',
      render: (_: any, record: any, index: number) =>
        <span style={{ float: 'right' }}>${parseToNumberString(acquisitionLoanSchedule[index].totalInterestPaid)}</span>
    },
    {
      title: 'Total Principal',
      dataIndex: 'totalPrincipalPaid',
      key: 'totalPrincipalPaid',
      render: (_: any, record: any, index: number) =>
        <span style={{ float: 'right' }}>${parseToNumberString(acquisitionLoanSchedule[index].totalPrincipalPaid)}</span>
    },
  ];

  const assumptionsDataSource = [
    {
      key: '1',
      description: "Lender",
      value: <span style={{ float: 'right' }}>{assumptions.lender}</span>,
    },
    {
      key: '2',
      description: "Date",
      value: <span style={{ float: 'right' }}>{assumptions.date.toLocaleDateString()}</span>,
    },
    // {
    //   key: '3',
    //   description: <>Loan-to-Value ({assumptions.loanType.toUpperCase()}) %</>,
    //   value: <span style={{ float: 'right' }}>{assumptions.loanToValueCostPercentage}%</span>,
    // },
    {
      key: '4',
      description: "Loan Amount",
      value: <span style={{ float: 'right' }}>${parseToNumberString(loanTotal)}</span>,
    },
    {
      key: '5',
      description: "Term (years)",
      value: <span style={{ float: 'right' }}>{assumptions.termYears}</span>,
    },
    {
      key: '6',
      description: "Amortization (years)",
      value: <span style={{ float: 'right' }}>{assumptions.amortizationYears}</span>,
    },
    {
      key: '7',
      description: "I/O periods (months)",
      value: <span style={{ float: 'right' }}>{assumptions.interestOnlyMonths}</span>,
    },
    {
      key: '8',
      description: "Interest Rate",
      value: <span style={{ float: 'right' }}>{assumptions.interestRate}%</span>,
    },
    {
      key: '9',
      description: "Debt Service (monthly)",
      value: <span style={{ float: 'right' }}>${parseToNumberString(assumptions.monthlyDebtService)}</span>,
    },
    {
      key: '10',
      description: "Debt Service (annual)",
      value: <span style={{ float: 'right' }}>${parseToNumberString(assumptions.annualDebtService)}</span>,
    },
  ]

  return (
    <>
      <Row gutter={24}>
        <Col xs={24} md={12}>
          <Table size="small" title={() => <b>Assumptions</b>} showHeader={false} columns={assumptionsColumns} dataSource={assumptionsDataSource} bordered pagination={false} />
        </Col>
      </Row>
      <Row gutter={24} style={{ marginTop: 50 }}>
        <Col span={24}>
          <Table
            scroll={{ x: true, y: 500 }}
            size="small"
            title={() => <b>Schedule</b>}
            columns={scheduleColumns}
            dataSource={acquisitionLoanSchedule}
            bordered
            pagination={false} />
        </Col>
      </Row>
    </>
  )

}
export default AcquisitionLoanSchedule;