import ButtonColor from 'antd-button-color';
import { useEffect, useState } from "react";
import Modal from "..";

type DisclaimerModalProps = {
  visible: boolean;
  onDismiss(): void;
}

const DisclaimerModal: React.FC<DisclaimerModalProps> = ({ visible, onDismiss }) => {
  const [showDisclaimerModal, setShowDisclaimerModal] = useState<boolean>(visible);

  const handleDismissDisclaimer = () => {
    setShowDisclaimerModal(false);
    onDismiss();
  }

  useEffect(() => {
    setShowDisclaimerModal(visible);
  }, [visible]);
  
  return (
    <Modal
      title="Disclaimer"
      visible={showDisclaimerModal}
      onDismiss={() => handleDismissDisclaimer()}
    >
      <p style={{textAlign:"center"}}>The information contained within this application is for general information purposes only.
          You should not rely upon this application as a basis for making investment, financial, business, or any other decisions. 
          Any reliance you place on this application is therefore strictly at your own risk. All information contained on this application is provided "as-is",
          with no guarantee of completeness, accuracy, timeliness or of the results obtained from the use of this information, and without warranty of any kind,
          express or implied, including, but not limited to warranties of performance and/or fitness for a particular purpose. By proceeding and using this application,
          you agree to the above disclaimer without prejudice.
      </p>
      <div style={{display:'flex', justifyContent:'center'}}>
      <ButtonColor type="lightdark" onClick={handleDismissDisclaimer}>OK</ButtonColor>
      </div>
    </Modal>
  )
}

export default DisclaimerModal;