import { Col, Row, Table } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import curveDataService from "../../../../../api/services/curve-data";
import ICurveData from "../../../../../types/curve-data";

const OneMonthTermSofrForwardCurve = () => {
  const [loading, setLoading] = useState(false);
  const [curveData, setCurveData] = useState<ICurveData[]>([]);
  const [dateUpdated, setDateUpdated] = useState<string>();

  useEffect(() => {
    setLoading(true);
    curveDataService
      .get()
      .then((res) => {
        setCurveData(res.data.data);
        setDateUpdated(
          moment(res.data.data[0].updatedAt).format("MM/DD/YYYY h:mm a")
        );
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  return (
    <Row gutter={24}>
      <Col xs={12} lg={6}>
        {loading ? null : (
          <div style={{ marginBottom: 10 }}>
            <i>Last updated: {dateUpdated}</i>
            <span style={{ marginLeft: 10 }}>
              [
              <a
                href="https://www.chathamfinancial.com/technology/us-forward-curves"
                target="_blank"
                rel="noreferrer"
              >
                source
              </a>
              ]
            </span>
          </div>
        )}
        <Table
          bordered
          loading={loading}
          dataSource={curveData}
          columns={[
            {
              key: "date",
              dataIndex: "date",
              title: "Date",
              render: (value) => (
                <span style={{ float: "right" }}>
                  {new Date(value).toLocaleDateString()}
                </span>
              ),
            },
            {
              key: "percentage",
              dataIndex: "percentage",
              title: "Percentage",
              render: (value) => (
                <span style={{ float: "right" }}>{value}%</span>
              ),
            },
          ]}
          pagination={false}
        />
      </Col>
    </Row>
  );
};

export default OneMonthTermSofrForwardCurve;
