import { Footer } from "antd/lib/layout/layout";
import pj from "../../../../package.json";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../../../store";
import { useHotkeys } from "react-hotkeys-hook";
import { useState } from "react";
import ShortcutsModal from "../../modals/shortcuts";
import useMediaQuery from "use-media-antd-query";
import formSteps from "../../property-analyzer/steps/form-steps";
import ShortcutButton from "../../shared/shortcut-button";

const StyledFooter = styled(Footer)`
  position: fixed;
  left: 0px;
  bottom: 0px;
  width: 100%;
  text-align: center;
  justify-content: center;
  display: flex;
  z-index: 2;
  color: white !important;
  background: #001529 !important;
  padding: 0 !important;
  min-height: 45px;
  opacity: 0.85;
`;

const AppFooter = () => {
  const colSize = useMediaQuery();
  const [showShortcuts, setShowShortcuts] = useState(false);
  const dispatch = useAppDispatch();
  const { currentStepId } = useAppSelector((state) => {
    return {
      currentStepId: state.stepReducer.currentStepId,
    };
  });
  useHotkeys("shift+/", () => setShowShortcuts((visible) => !visible));
  useHotkeys("z", () => handleStepChange("previous"), [currentStepId]);
  useHotkeys("x", () => handleStepChange("next"), [currentStepId]);

  const handleStepChange = (type: "previous" | "next") => {
    if (type === "previous") {
      dispatch({
        type: "UPDATE_STEP_CURRENT_STEP_ID",
        payload: Math.max(1, currentStepId - 1),
      });
      return;
    }
    dispatch({
      type: "UPDATE_STEP_CURRENT_STEP_ID",
      payload: Math.min(formSteps.length, currentStepId + 1),
    });
  };

  return (
    <>
      <StyledFooter>
        <span
          style={{
            position: "absolute",
            fontSize: 8,
            left: 15,
            bottom: 10,
            textTransform: "uppercase",
          }}
        >
          &copy; 7 Figure Flipping 2022. All Rights Reserved.
        </span>
        <ShortcutButton
          title="Shortcuts"
          type="info"
          size="small"
          shortcutKey="?"
          onClick={() => setShowShortcuts(true)}
          style={{ marginTop: 10 }}
        />
        <div style={{ position: "absolute", right: 20, bottom: 10 }}>
          <span
            data-tour="version-button"
            style={{ cursor: "pointer", fontSize: 10 }}
          >
            {pj.beta ? `v1.0.0` : `v${pj.version}`}
          </span>
        </div>
      </StyledFooter>
      <ShortcutsModal
        visible={showShortcuts}
        onDismiss={() => setShowShortcuts(false)}
      />
    </>
  );
};

export default AppFooter;
