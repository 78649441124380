import { Table } from "antd";
import NumberField from "../../../../shared/number-field";
import { IHypotheticalInvestmentReturnVsHoldPeriod } from "../investment-summary-service";

interface IHypotheticalInvestmentReturnVsHoldPeriodProps {
  hypotheticalInvestmentReturnVsHoldPeriod: IHypotheticalInvestmentReturnVsHoldPeriod;
}

const HypotheticalInvestmentReturnVsHoldPeriod: React.FC<
  IHypotheticalInvestmentReturnVsHoldPeriodProps
> = ({ hypotheticalInvestmentReturnVsHoldPeriod }) => {
  const { years } = hypotheticalInvestmentReturnVsHoldPeriod;

  const getYearHeader = (year: number) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h4 style={{ marginBottom: 0 }}>Year {year}</h4>
        <h5>
          <i>{year * 12} Months</i>
        </h5>
      </div>
    );
  };

  const columns = [
    {
      dataIndex: "description",
      key: "description",
      width: "20%",
    },
    {
      title: getYearHeader(3),
      dataIndex: "year3",
      key: "year3",
      width: "10%",
    },
    {
      title: getYearHeader(4),
      dataIndex: "year4",
      key: "year4",
      width: "10%",
    },
    {
      title: getYearHeader(5),
      dataIndex: "year5",
      key: "year5",
      width: "10%",
    },
    {
      title: getYearHeader(6),
      dataIndex: "year6",
      key: "year6",
      width: "10%",
    },
    {
      title: getYearHeader(7),
      dataIndex: "year7",
      key: "year7",
      width: "10%",
    },
    {
      title: getYearHeader(8),
      dataIndex: "year8",
      key: "year8",
      width: "10%",
    },
    {
      title: getYearHeader(9),
      dataIndex: "year9",
      key: "year9",
      width: "10%",
    },
    {
      title: getYearHeader(10),
      dataIndex: "year10",
      key: "year10",
      width: "10%",
    },
  ];

  const dataSource = [
    //     Investor IRR
    // Investor Equity Multiple
    // Investor Profit
    {
      key: "1",
      description: <span style={{ float: "right" }}>Investor IRR</span>,
      year3: <NumberField type="percentage" value={years[0].irr} />,
      year4: <NumberField type="percentage" value={years[1].irr} />,
      year5: <NumberField type="percentage" value={years[2].irr} />,
      year6: <NumberField type="percentage" value={years[3].irr} />,
      year7: <NumberField type="percentage" value={years[4].irr} />,
      year8: <NumberField type="percentage" value={years[5].irr} />,
      year9: <NumberField type="percentage" value={years[6].irr} />,
      year10: <NumberField type="percentage" value={years[7].irr} />,
    },
    {
      key: "2",
      description: (
        <span style={{ float: "right" }}>Investor Equity Multiple</span>
      ),
      year3: (
        <span style={{ float: "right" }}>
          {years[0].equityMultiple.toFixed(2)}x
        </span>
      ),
      year4: (
        <span style={{ float: "right" }}>
          {years[1].equityMultiple.toFixed(2)}x
        </span>
      ),
      year5: (
        <span style={{ float: "right" }}>
          {years[2].equityMultiple.toFixed(2)}x
        </span>
      ),
      year6: (
        <span style={{ float: "right" }}>
          {years[3].equityMultiple.toFixed(2)}x
        </span>
      ),
      year7: (
        <span style={{ float: "right" }}>
          {years[4].equityMultiple.toFixed(2)}x
        </span>
      ),
      year8: (
        <span style={{ float: "right" }}>
          {years[5].equityMultiple.toFixed(2)}x
        </span>
      ),
      year9: (
        <span style={{ float: "right" }}>
          {years[6].equityMultiple.toFixed(2)}x
        </span>
      ),
      year10: (
        <span style={{ float: "right" }}>
          {years[7].equityMultiple.toFixed(2)}x
        </span>
      ),
    },
    {
      key: "3",
      description: <span style={{ float: "right" }}>Investor Profit</span>,
      year3: <NumberField type="money" value={years[0].profit} />,
      year4: <NumberField type="money" value={years[1].profit} />,
      year5: <NumberField type="money" value={years[2].profit} />,
      year6: <NumberField type="money" value={years[3].profit} />,
      year7: <NumberField type="money" value={years[4].profit} />,
      year8: <NumberField type="money" value={years[5].profit} />,
      year9: <NumberField type="money" value={years[6].profit} />,
      year10: <NumberField type="money" value={years[7].profit} />,
    },
  ];

  return (
    <>
      <Table
        title={() => (
          <div style={{ textAlign: "center" }}>
            <b>Hypothetical $100,000 Investment - Return vs. Hold Period</b>
          </div>
        )}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        bordered
        style={{ whiteSpace: "nowrap" }}
        scroll={{ x: true }}
      />
    </>
  );
};
export default HypotheticalInvestmentReturnVsHoldPeriod;
