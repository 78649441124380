import IFeatureRequest from "../../../types/feature-request";
import http from "../../http";

const getRoute = (path?: string) => {
  const root = "/feature-request";
  if (!path) return root;
  return root + "/" + path;
};

const featureRequestService = {
  get: (userId: string) =>
    http.get<{ requests: IFeatureRequest[] }>(getRoute(userId)),
  create: (request: IFeatureRequest) =>
    http.post<{ request: IFeatureRequest }>(getRoute(), { request }),
};

export default featureRequestService;
