import { ArrowLeftOutlined, ArrowRightOutlined, CloseOutlined } from '@ant-design/icons';
import Button from 'antd-button-color';
import { tourSteps } from './tour-steps';

type TourProps = {
  content: any;
  step: number;
  setIsOpen: React.Dispatch<React.SetStateAction<Boolean>> | undefined;
  setCurrentStep(step: number): void;
}

const Tour: React.FC<TourProps> = ({content, setIsOpen, step, setCurrentStep}) => {
  return (
    <div style={{ padding: 15, borderRadius: 5 }}>
    <div style={{ display: 'flex', textAlign: 'center' }}>
      <h4 style={{ marginBottom: 25, color: 'black' }}>{content}</h4>
    </div>
    <div style={{ display: 'flex', justifyContent: step === 0 ? 'flex-end' : 'space-between' }}>
      <ArrowLeftOutlined
        style={{
          fontSize: 20,
          cursor: 'pointer',
          color: 'black',
          display: step === 0 ? 'none' : 'block',
        }}
        onClick={() => setCurrentStep(step - 1)}
      />
      <ArrowRightOutlined
        style={{
          fontSize: 20,
          color: 'black',
          cursor: 'pointer',
          display: step === tourSteps.length - 1 ? 'none' : 'block',
        }}
        onClick={() => setCurrentStep(step + 1)}
      />
    </div>
    <div style={{ display: step !== tourSteps.length - 1 ? 'none' : 'flex', justifyContent: "center", }}>
      <Button type="dark" onClick={() => setIsOpen && setIsOpen(false)}>Dismiss</Button>
    </div>
  </div>
  )
}

export default Tour;