import Button, { ButtonType } from "antd-button-color";
import { StyledKbd } from "../../modals/shortcuts/constants";
import useMediaQuery from "use-media-antd-query";
import { SyncOutlined } from "@ant-design/icons";

type Props = {
  type?: ButtonType;
  size?: "small" | "middle" | "large";
  title: string;
  shortcutKey: string;
  mobileIcon?: JSX.Element | null;
  style?: React.CSSProperties;
  onClick(): void;
  disabled?: boolean;
  loading?: boolean;
};

const ShortcutButton: React.FC<Props> = ({
  type = "primary",
  size = "middle",
  title,
  shortcutKey,
  mobileIcon = null,
  style,
  onClick,
  disabled,
  loading,
}) => {
  const colSize = useMediaQuery();

  if (!mobileIcon && ["xs","sm"].includes(colSize)) {
    return null;
  }

  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      type={type}
      size={size}
      style={{ ...style }}
    >
      {loading ? (
        <SyncOutlined spin />
      ) : ["xs", "sm"].includes(colSize) ? (
        <>{mobileIcon}</>
      ) : (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span style={{ marginRight: 5 }}>{title}</span>
          <StyledKbd
            style={{
              padding: 0,
              width: 20,
              boxShadow: "2px 2px 0px rgb(0 0 0 / 20%), 0 0 0 30px #fff inset",
            }}
          >
            {shortcutKey}
          </StyledKbd>
        </div>
      )}
    </Button>
  );
};

export default ShortcutButton;
