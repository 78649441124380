import { useEffect, useState } from "react";
import { Col, Input, Table } from "antd";
import NumberField from "../../../shared/number-field";
import useMediaQuery from "use-media-antd-query";
import { useAppDispatch, useAppSelector } from "../../../../store";

const AcquisitionCosts = () => {
  const dispatch = useAppDispatch();
  const {
    property: { totalUnits },
    loan: { acquisitionCosts },
  } = useAppSelector((state) => {
    return {
      property: state.propertyReducer.property,
      loan: state.loanReducer.loan,
    };
  });
  const [form, setForm] = useState({
    purchasePrice: acquisitionCosts.purchasePrice,
    acquisitionFeePercentage: acquisitionCosts.acquisitionFeePercentage,
    acquisitionFee: acquisitionCosts.acquisitionFee,
    transferTaxesPercentage: acquisitionCosts.transferTaxesPercentage,
    transferTaxes: acquisitionCosts.transferTaxes,
    dueDilligence: acquisitionCosts.dueDilligence,
    legalFees: acquisitionCosts.legalFees,
    capExRenovation: acquisitionCosts.capExRenovation,
    reserves: acquisitionCosts.reserves,
    otherClosingCostsPercentage: acquisitionCosts.otherClosingCostsPercentage,
    otherClosingCosts: acquisitionCosts.otherClosingCosts,
  });
  const colSize = useMediaQuery();

  const handleFormChange = (field: string, value: any) => {
    setForm((form) => ({ ...form, [field]: value }));
  };

  const columns = [
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: ["xs", "sm"].includes(colSize) ? "25%" : "40%",
      render: (_: any, record: any, index: number) => (
        <span style={{ whiteSpace: "nowrap" }}>{record.description}</span>
      ),
    },
    {
      title: "Percentage",
      dataIndex: "column2",
      key: "column2",
      width: ["xs", "sm"].includes(colSize) ? "25%" : "20%",
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      width: ["xs", "sm"].includes(colSize) ? "25%" : "20%",
    },
    {
      title: "Per Unit",
      dataIndex: "perUnit",
      key: "perUnit",
      width: ["xs", "sm"].includes(colSize) ? "25%" : "20%",
    },
  ];

  const dataSource = [
    {
      key: "1",
      description: "Purchase Price",
      total: (
        <Input
          style={{ minWidth: 100 }}
          value={form.purchasePrice}
          type="number"
          placeholder="Amount"
          min={0}
          step={0.01}
          onChange={(e) =>
            handleFormChange("purchasePrice", parseFloat(e.target.value) || 0)
          }
        />
      ),
      perUnit: (
        <NumberField
          type="money"
          value={acquisitionCosts.purchasePrice / totalUnits}
        />
      ),
    },
    {
      key: "2",
      description: "Acquisition Fee",
      column2: (
        <Input
          suffix={<span>%</span>}
          style={{ minWidth: 100 }}
          value={form.acquisitionFeePercentage}
          type="number"
          min={0}
          max={100}
          step={0.01}
          onChange={(e) =>
            handleFormChange(
              "acquisitionFeePercentage",
              parseFloat(e.target.value)
            )
          }
        />
      ),
      total: (
        <NumberField type="money" value={acquisitionCosts.acquisitionFee} />
      ),
      perUnit: (
        <NumberField
          type="money"
          value={acquisitionCosts.acquisitionFee / totalUnits}
        />
      ),
    },
    {
      key: "3",
      description: "Transfer Taxes",
      column2: (
        <Input
          suffix={<span>%</span>}
          style={{ minWidth: 100 }}
          value={form.transferTaxesPercentage}
          type="number"
          min={0}
          max={100}
          step={0.01}
          onChange={(e) =>
            handleFormChange(
              "transferTaxesPercentage",
              parseFloat(e.target.value)
            )
          }
        />
      ),
      total: (
        <NumberField type="money" value={acquisitionCosts.transferTaxes} />
      ),
      perUnit: (
        <NumberField
          type="money"
          value={acquisitionCosts.transferTaxes / totalUnits}
        />
      ),
    },
    {
      key: "4",
      description: "Due Dilligence",
      total: (
        <Input
          style={{ minWidth: 100 }}
          value={form.dueDilligence}
          type="number"
          placeholder="Amount"
          min={0}
          step={0.01}
          onChange={(e) => handleFormChange("dueDilligence", e.target.value)}
        />
      ),
      perUnit: (
        <NumberField
          type="money"
          value={acquisitionCosts.dueDilligence / totalUnits}
        />
      ),
    },
    {
      key: "5",
      description: "Legal Fees",
      total: (
        <Input
          style={{ minWidth: 100 }}
          value={form.legalFees}
          type="number"
          placeholder="Amount"
          min={0}
          step={0.01}
          onChange={(e) => handleFormChange("legalFees", e.target.value)}
        />
      ),
      perUnit: (
        <NumberField
          type="money"
          value={acquisitionCosts.legalFees / totalUnits}
        />
      ),
    },
    {
      key: "6",
      description: "CapEx/Renovation",
      total: (
        <NumberField type="money" value={acquisitionCosts.capExRenovation} />
      ),
      perUnit: (
        <NumberField
          type="money"
          value={acquisitionCosts.capExRenovation / totalUnits}
        />
      ),
    },
    {
      key: "7",
      description: "Reserves",
      total: (
        <Input
          style={{ minWidth: 100 }}
          value={form.reserves}
          type="number"
          placeholder="Amount"
          min={0}
          step={0.01}
          onChange={(e) => handleFormChange("reserves", e.target.value)}
        />
      ),
      perUnit: (
        <NumberField
          type="money"
          value={acquisitionCosts.reserves / totalUnits}
        />
      ),
    },
    {
      key: "8",
      description: "Other Closing Costs",
      column2: (
        <Input
          suffix={<span>%</span>}
          style={{ minWidth: 100 }}
          value={form.otherClosingCostsPercentage}
          type="number"
          min={0}
          max={100}
          step={0.01}
          onChange={(e) =>
            handleFormChange(
              "otherClosingCostsPercentage",
              parseFloat(e.target.value)
            )
          }
        />
      ),
      total: (
        <NumberField type="money" value={acquisitionCosts.otherClosingCosts} />
      ),
      perUnit: (
        <NumberField
          type="money"
          value={acquisitionCosts.otherClosingCosts / totalUnits}
        />
      ),
    },
  ];

  useEffect(() => {
    //@ts-ignore
    dispatch({ type: "UPDATE_LOAN_ACQUISITION_COSTS", payload: form });
  }, [form]);

  return (
    <>
      <Col
        xs={24}
        sm={24}
        md={24}
        lg={16}
        xl={16}
        xxl={12}
        style={{ paddingLeft: 0 }}
      >
        <Table
          scroll={{ x: true }}
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          bordered
        />
      </Col>
    </>
  );
};
export default AcquisitionCosts;
