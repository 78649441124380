import { Table } from "antd";
import Column from "antd/lib/table/Column";
import React from "react";
import { NetCashflowWaterfallDistribution } from "../../../../../types/monthly-proforma/net-cashflow-waterfall-distribution";
import NumberField from "../../../../shared/number-field";
import { IHypotheticalInvestmentCashFlow } from "../investment-summary-service";

interface IHypotheticalInvestmentCashFlowProps {
  hypotheticalInvestmentCashFlow: IHypotheticalInvestmentCashFlow;
  cashFlow: NetCashflowWaterfallDistribution;
}

const HypotheticalInvestmentCashFlow: React.FC<
  IHypotheticalInvestmentCashFlowProps
> = ({ hypotheticalInvestmentCashFlow, cashFlow }) => {
  const {
    years,
    netOperatingProjectCashFlow,
    cashOnCashReturn,
    capitalEventCashFlow,
    capitalEventReturn,
  } = hypotheticalInvestmentCashFlow;

  return (
    <>
      <Table
        title={() => (
          <div style={{ textAlign: "center" }}>
            <b>Hypothetical $100,000 Investment Cash Flow</b>
          </div>
        )}
        dataSource={years}
        pagination={false}
        bordered
        style={{ whiteSpace: "nowrap" }}
        scroll={{ x: true }}
      >
        <Column
          title="Year"
          dataIndex="year"
          key="year"
          render={(value, record, index) => {
            return index === years.length - 1 ? (
              <b>Total **</b>
            ) : (
              <span>{years[index].year}</span>
            );
          }}
        />
        <Column
          title="Net Operating Project Cash Flow"
          dataIndex="nopcf"
          key="nopcf"
          render={(value, record, index) => {
            return index === years.length - 1 ? (
              <NumberField type="money" value={netOperatingProjectCashFlow} />
            ) : (
              <NumberField
                type="money"
                value={years[index].lpInvestorCashOnCash}
              />
            );
          }}
        />
        <Column
          title="Cash on Cash Return"
          dataIndex="nopcf"
          key="nopcf"
          render={(value, record, index) => {
            return index === years.length - 1 ? (
              <NumberField type="percentage" value={cashOnCashReturn} />
            ) : (
              <NumberField
                type="percentage"
                value={years[index].lpInvestorCashOnCashPercentage}
              />
            );
          }}
        />
        <Column
          title="Capital Event Cash Flow *"
          dataIndex="cecf"
          key="cecf"
          render={(value, record, index) => {
            return index === years.length - 1 ? (
              <NumberField type="money" value={capitalEventCashFlow} />
            ) : (
              <NumberField
                type="money"
                value={years[index].capitalEventCashFlow}
              />
            );
          }}
        />
        <Column
          title="Capital Event Return % *"
          dataIndex="cecf"
          key="cecf"
          render={(value, record, index) => {
            return index === years.length - 1 ? (
              <NumberField type="percentage" value={capitalEventReturn} />
            ) : (
              <NumberField
                type="percentage"
                value={
                  100 *
                  (Math.max(
                    0,
                    (100000 /
                      cashFlow.netLpInvestorCashFlow.totalEquityInvested) *
                      //@ts-ignore
                      cashFlow.lpInvestorRefiSaleCashFlow[
                        `year${years[index].year}`
                      ]
                  ) /
                    100000)
                }
              />
            );
          }}
        />
      </Table>
      <h5>
        * Capital Event refers to a refinancing, sale, or release of certain
        reserve accounts, if applicable
      </h5>
      <h5>
        * Individual year Capital Event Cash Flows and Returns shown include the
        return of capital / initial equity investment
      </h5>
      <h5>
        ** Total overall average annual return from Capital Events shown is
        after return of capital
      </h5>
    </>
  );
};
export default HypotheticalInvestmentCashFlow;
