import { useEffect, useState } from "react";
import { Comment, Tooltip, Card, Divider, Table, Badge } from "antd";
import moment from "moment";
import featureRequestService from "../../api/services/feature-request";
import IFeatureRequest from "../../types/feature-request";
import { useAppSelector } from "../../store";
import Avatar from "../shared/avatar";

type RequestedFeaturesProps = {
  newFeatureCount: number;
};

const RequestedFeatures: React.FC<RequestedFeaturesProps> = ({
  newFeatureCount,
}) => {
  const { current } = useAppSelector((state) => state.userReducer);
  const [featuresRequested, setFeaturesRequested] = useState<IFeatureRequest[]>(
    []
  );
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    featureRequestService
      .get(current?._id!)
      .then((res) => {
        setFeaturesRequested(
          res.data.requests.sort(
            (a, b) =>
              new Date(b.createdAt!).getTime() -
              new Date(a.createdAt!).getTime()
          )
        );
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [newFeatureCount]);

  const StatusBadge = ({ request }: { request: IFeatureRequest }) => {
    let status = "";
    let color = "white";
    let backgroundColor = "";
    switch (request.status) {
      case "open":
        status = "Open";
        color = "black";
        backgroundColor = "white";
        break;
      case "queued":
        status = "Queued";
        backgroundColor = "blue";
        break;
      case "inProgress":
        status = "In Progress";
        backgroundColor = "orange";
        break;
      case "completed":
        status = "Completed";
        backgroundColor = "green";
        break;
      case "closed":
        status = "Closed";
        backgroundColor = "red";
        break;
      default:
        break;
    }

    return (
      <Badge
        size="small"
        count={status}
        style={{ backgroundColor, color, marginLeft: 10 }}
      />
    );
  };

  return (
    <Table
      dataSource={featuresRequested}
      style={{ padding: "0px 10px" }}
      showHeader={false}
      bordered={false}
      loading={loading}
      className="feature-request-table"
      pagination={{
        pageSize: 5,
      }}
    >
      <Table.Column
        render={(record: IFeatureRequest) => {
          const x = 1;
          return (
            <Comment
              author={record.firstName + " " + record.lastName}
              avatar={
                <Avatar
                  userId={record.userId}
                  firstName={record.firstName}
                  lastName={record.lastName}
                  hideStatus
                  size={40}
                />
              }
              content={
                <>
                  <div>
                    <h3>{record.summary}</h3>
                  </div>
                  <p>{record.description}</p>
                </>
              }
              datetime={
                <>
                  <Tooltip
                    title={moment(record.createdAt).format(
                      "MM/DD/YYYY @ hh:mm a"
                    )}
                  >
                    <span>{'requested ' + moment().to(record.createdAt)}</span>
                  </Tooltip>
                  <StatusBadge request={record} />
                </>
              }
            >
              {record.response ? (
                <Comment
                author={record.response.firstName + ' '+  record.response.lastName }
                avatar={
                  <Avatar
                    userId={record.response.userId}
                    firstName={record.firstName}
                    lastName={record.lastName}
                    hideStatus
                    size={40}
                  />
                }
                content={record.response.text}
                datetime={
                  <>
                    <Tooltip
                      title={moment(record.response.date).format(
                        "MM/DD/YYYY @ hh:mm a"
                      )}
                    >
                      <span>{'responded ' + moment().to(record.response.date)}</span>
                    </Tooltip>
                  </>
                }
              ></Comment>
              ) : null}
            </Comment>
          );
        }}
      />
    </Table>
  );
};

export default RequestedFeatures;
