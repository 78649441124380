import { Card, Col, Form, Input, notification, Row, Select } from "antd";
import { useState, useEffect } from "react";
import Button from "antd-button-color";
import issueService from "../../api/services/issue";
import { useAppSelector } from "../../store";
import { SyncOutlined } from "@ant-design/icons";
import ReportedIssues from "./table";
import useMediaQuery from "use-media-antd-query";
import ShortcutButton from "../shared/shortcut-button";
import { PlusOutlined } from "@ant-design/icons";
import { useHotkeys } from "react-hotkeys-hook";
const { Option } = Select;

const ReportIssue = () => {
  const colSize = useMediaQuery();
  const [showForm, setShowForm] = useState(false);
  const [reporting, setReporting] = useState(false);
  const { current } = useAppSelector((state) => state.userReducer);
  const [newIssueCount, setNewIssueCount] = useState(0);
  const [existingError, setExistingError] = useState<{
    found: boolean;
    location: string | null;
    name: string | null;
    message: string | null;
  }>();
  const [form] = Form.useForm();
  useHotkeys("r", () => setShowForm(true));

  const onFinish = (values: any) => {
    setReporting(true);
    issueService
      .create({
        userId: current?._id!,
        subject: values.subject,
        type: values.type,
        blocking: values.blocking,
        description: values.description,
        firstName: current?.firstName || "",
        lastName: current?.lastName || "",
        status: "open",
      })
      .then(() => {
        notification.success({
          message: "Issue reported succesfully",
          duration: 5,
          placement: "bottomRight",
        });
        setExistingError({
          found: false,
          location: null,
          name: null,
          message: null,
        });
        form.resetFields();
        removeErrorFromStorage();
        setNewIssueCount((count) => count + 1);
        setReporting(false);
        setShowForm(false);
      })
      .catch((err) => {
        notification.error({
          message: "An error was encountered while submitting issue",
          duration: 5,
          placement: "bottomRight",
        });
        setReporting(false);
      });
  };

  const removeErrorFromStorage = () => {
    localStorage.removeItem("error-boundary-error");
  };

  useEffect(() => {
    const error = localStorage.getItem("error-boundary-error");
    if (error) {
      const errorContent: { location: string; name: string; message: string } =
        JSON.parse(error);
      const errorMessage = `Error Type: "${errorContent.name}" \nLocation: "${errorContent.location}"\nMesssage: "${errorContent.message}"`;
      setExistingError({
        ...errorContent,
        found: true,
      });
      setShowForm(true);
      form.setFields([
        {
          name: "description",
          value: errorMessage,
        },
      ]);
    }
  }, [localStorage]);

  useEffect(() => {
    return () => removeErrorFromStorage();
  });

  return (
    <Row gutter={24}>
      <Col
        xs={24}
        md={12}
        offset={["xs", "sm"].includes(colSize) ? 0 : 6}
        style={{ marginBottom: 25 }}
      >
        {showForm ? (
          <Card>
            <h1 style={{ fontSize: "1.5em" }}>Report an Issue</h1>
            <Form
              layout="vertical"
              form={form}
              onFinish={onFinish}
              initialValues={{
                from:
                  current?.firstName +
                  " " +
                  current?.lastName +
                  " <" +
                  current?.email +
                  ">",
                subject: null,
                type: null,
                blocking: null,
                description: existingError?.message
                  ? `Error Type: "${existingError.name}" \nLocation: "${existingError.location}"\nMesssage: "${existingError.message}"`
                  : null,
              }}
            >
              <Form.Item label="From" name="from">
                <Input disabled />
              </Form.Item>
              <Form.Item
                label="Subject"
                name="subject"
                rules={[{ required: true, message: "Subject is required" }]}
              >
                <Input placeholder="A brief overview of the issue" />
              </Form.Item>
              <Form.Item
                label="What type of issue is this?"
                name="type"
                rules={[{ required: true, message: "Issue type is required" }]}
              >
                <Select
                  defaultActiveFirstOption={false}
                  style={{ minWidth: 100 }}
                  placeholder="Select the issue type"
                >
                  <Option value="transient">
                    Transient - Only occuring once
                  </Option>
                  <Option value="intermittent">
                    Intermittent - Occuring irregularly
                  </Option>
                  <Option value="permanent">
                    Permanent - Occuring repeatedly
                  </Option>
                </Select>
              </Form.Item>
              <Form.Item
                label="Is this issue blocking you?"
                name="blocking"
                rules={[
                  { required: true, message: "Blocking indicator is required" },
                ]}
              >
                <Select
                  defaultActiveFirstOption={false}
                  style={{ minWidth: 100 }}
                  placeholder="Select if you are blocked"
                >
                  <Option value={false}>Non-Blocking</Option>
                  <Option value={true}>Blocking</Option>
                </Select>
              </Form.Item>
              <Form.Item
                label="What issue are you facing?"
                name="description"
                rules={[{ required: true, message: "Description is required" }]}
              >
                <Input.TextArea
                  placeholder="Please describe the issue you have or are encountering"
                  rows={6}
                />
              </Form.Item>
              {existingError?.found && (
                <div style={{ marginTop: -20, marginBottom: 10 }}>
                  <i>
                    * Error description auto-populated with encountered error
                    details. Any additional feedback is helpful.
                  </i>
                </div>
              )}
              <p>
                <b>Note:</b> any reported issues will be visible by <i>all</i>{" "}
                users across the platform
              </p>
              <Form.Item>
                <Button type="success" htmlType="submit">
                  {reporting ? (
                    <span>
                      Reporting <SyncOutlined spin />
                    </span>
                  ) : (
                    "Report"
                  )}
                </Button>
                <Button
                  type="lightdark"
                  style={{ marginLeft: 10 }}
                  onClick={() => {
                    removeErrorFromStorage();
                    setShowForm(false);
                  }}
                >
                  Cancel
                </Button>
              </Form.Item>
            </Form>
          </Card>
        ) : (
          <>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h1 style={{ fontSize: "1.5em", display: "inline-block" }}>
                Issues Reported
              </h1>
              <ShortcutButton
                title="New Issue"
                type="success"
                size="small"
                shortcutKey="r"
                onClick={() => setShowForm(true)}
                style={{ marginRight: 10 }}
                mobileIcon={<PlusOutlined />}
              />
            </div>
            <ReportedIssues newIssueCount={newIssueCount} />
          </>
        )}
      </Col>
    </Row>
  );
};

export default ReportIssue;
