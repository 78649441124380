import { Loan } from "../../../../types/loan";

export const generateEmptyLoan = (): Loan  => {
  let testLoan = new Loan();
  testLoan.acquisitionCosts = {
    ...testLoan.acquisitionCosts,
    purchasePrice: 0,
    acquisitionFeePercentage: 0,
    acquisitionFee: 0,
    transferTaxesPercentage: 0,
    transferTaxes: 0,
    dueDilligence: 0,
    legalFees: 0,
    reserves: 0,
    otherClosingCostsPercentage: 0,
    otherClosingCosts: 0,
    capExRenovation:  0,
  }
  testLoan.loanAssumptions = {
    ...testLoan.loanAssumptions,
    lender: "",
    date: new Date(),
    purchasePriceLeveragePercentage: 0,
    capExLeveragePercentage: 0,
    termYears: 0,
    interestRate: 0,
    interestOnlyMonths: 0,
    amortizationYears: 0,
    loanFeesPercentage: 0,
    loanFees:  0,
    prepaymentPenaltyPercentage: 0,
    prepaymentPenalty: 0,
    debtServiceReservesPandIMonths: 0,
    debtServiceReservesPandIRelase: "yes",
    debtServiceReservesPandIRelaseTiming: 0,
    debtServiceReservesPandITotal: 0,
  }
  testLoan.refinancingPermanentLoanAssumptions = {
    refinancing: false,
    loanType: "ltv",
    lender: "",
    date: new Date(),
    month: 0,
    year: 0,
    interestRate: 0,
    interestOnlyMonths: 0,
    amortizationYears: 0,
    noiTrailing12Months:  0,
    capRate: 0,
    propertyValuation:  0,
    ltvPercentage: 0,
    dscrPercentage: 0,
    ltvTotal:  0,
    dscrTotal:  0,
    refinanceLoanAmount:  0,
    loanFeesPercentage: 0,
    loanFees:  0,
    originalLoanRepayment:  0,
    distributionProceeds:  0,
  }
  testLoan.loanTotal = 0;
  testLoan.calculateLoanSchedule("acquisition");
  testLoan.calculateLoanSchedule("refinancing");
  testLoan.calculateDynamicAcquisitionCosts();
  testLoan.calculateLoanTotal();
  testLoan.calculateDebtService();
  testLoan.equitySplit = {
    gpSponsor: 50,
    lpInvestors: 50,
  }

  return testLoan;
}
export default generateEmptyLoan;