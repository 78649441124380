import { Col, Divider, Empty, Popconfirm, Table, Tooltip } from "antd";
import IAnalyzedProperty from "../../../types/analyzed-property";
import useMediaQuery from "use-media-antd-query";
import { useEffect, useState } from "react";
import {
  DeleteOutlined,
  EditOutlined,
  DollarCircleOutlined,
  ShareAltOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import AsteriskField from "../../shared/asterisk-field";
import ButtonColor from "antd-button-color";
import formSteps from "../../property-analyzer/steps/form-steps";
import SharePropertyModal from "../../modals/share-property";
import ShortcutButton from "../../shared/shortcut-button";
import {
  downloadSummary,
  getInvestmentSummary,
} from "../../property-analyzer/steps/investment-summary/investment-summary-service";
import { NetCashflowWaterfallDistribution } from "../../../types/monthly-proforma/net-cashflow-waterfall-distribution";
import mapProperty from "../../../types/property/map-property";
import mapLoan from "../../../types/loan/map-loan";

type PropertyTableProps = {
  properties: {
    completed: IAnalyzedProperty[];
    inProgress: IAnalyzedProperty[];
    shared: IAnalyzedProperty[];
  };
  title: string;
  onCreate(): void;
  onEdit(property: IAnalyzedProperty): void;
  onDelete(propertyId: string): void;
};

const PropertyTable: React.FC<PropertyTableProps> = ({
  properties: { completed, inProgress, shared },
  title,
  onCreate,
  onEdit,
  onDelete,
}) => {
  const [sharePropertyModalVisible, setSharePropertyModalVisible] =
    useState(false);
  const [sharingProperty, setSharingProperty] =
    useState<IAnalyzedProperty | null>(null);
  const defaultColumns = [
    {
      key: "1",
      dataIndex: "name",
      title: "Name",
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
      render: (value: any, record: IAnalyzedProperty) =>
        record.inProgress ? (
          <AsteriskField description={record.name} color="red" />
        ) : (
          <span>{record.name}</span>
        ),
    },
    {
      key: "2",
      dataIndex: "step",
      title: "Current Step",
      render: (value: any, record: IAnalyzedProperty) =>
        record.inProgress ? (
          <span>
            {
              formSteps.find((step) => step.id === record.currentStep)
                ?.description
            }
          </span>
        ) : (
          <span>Completed</span>
        ),
    },
    {
      key: "3",
      dataIndex: "streetAddress",
      title: "Address",
      ellipsis: true,
      sorter: (a: any, b: any) =>
        a.streetAddress.localeCompare(b.streetAddress),
    },
    {
      key: "4",
      dataIndex: "actions",
      title: "Actions",
      render: (value: any, property: IAnalyzedProperty) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {!property.inProgress ? (
            <Tooltip title="Investment Summary">
              <ButtonColor
                style={{ minWidth: 35 }}
                type="success"
                onClick={() => {
                  const mappedProperty = mapProperty(property);
                  const mappedLoan = mapLoan(property.loan);
                  const summary = getInvestmentSummary(
                    mappedProperty,
                    mappedLoan,
                    new NetCashflowWaterfallDistribution(
                      mappedProperty,
                      mappedLoan,
                    )
                  );
                  downloadSummary(property.name, summary);
                }}
                icon={<DollarCircleOutlined />}
              />
            </Tooltip>
          ) : null}
          <Tooltip title="Edit Property">
            <ButtonColor
              type="primary"
              style={{ minWidth: 35, marginLeft: 10 }}
              onClick={() => onEdit(property)}
              icon={<EditOutlined />}
            />
          </Tooltip>
          {!property.inProgress ? (
            <Tooltip title="Share Property">
              <ButtonColor
                type="dark"
                style={{ minWidth: 35, marginLeft: 10 }}
                onClick={() => (
                  setSharingProperty(property!),
                  setSharePropertyModalVisible(true)
                )}
                icon={<ShareAltOutlined />}
              />
            </Tooltip>
          ) : null}
          <Popconfirm
            title="Are you sure you want to delete this property?"
            onConfirm={() => onDelete(property._id!)}
            okText="Yes"
            cancelText="No"
          >
            <Tooltip title="Delete Property">
              <ButtonColor
                type="primary"
                danger
                style={{ minWidth: 35, marginLeft: 10 }}
                icon={<DeleteOutlined />}
              />
            </Tooltip>
          </Popconfirm>
        </div>
      ),
    },
  ];

  const defaultSharedColumns = [...defaultColumns];
  defaultSharedColumns.splice(1, 1, {
    key: "2",
    dataIndex: "sharedDetails",
    sorter: () => {},
    title: "Shared By",
    render: (value: any, record: IAnalyzedProperty) => {
      const { email, firstName, lastName } = record.sharedDetails!;
      if (firstName && lastName) {
        return <span>{`${firstName} ${lastName}`}</span>;
      }
      return <span>{email}</span>;
    },
  });

  const [columns, setColumns] = useState<{}[]>(defaultColumns);
  const [sharedColumns, setSharedColumns] =
    useState<{}[]>(defaultSharedColumns);
  const colSize = useMediaQuery();

  useEffect(() => {
    if (["xs", "sm", "md"].includes(colSize)) {
      setColumns(
        defaultColumns.filter((c, index) => [0, 1, 3].includes(index))
      );
      setSharedColumns(
        defaultSharedColumns.filter((c, index) => [0, 1, 3].includes(index))
      );
    } else {
      setColumns(defaultColumns);
      setSharedColumns(defaultSharedColumns);
    }
  }, [colSize]);

  const pagination =
    [...inProgress, ...completed].length > 5
      ? {
          pageSize: 5,
          style: { marginRight: 10 },
        }
      : false;

  return (
    <>
      <Table
        rowKey="_id"
        style={{ marginBottom: 25 }}
        showHeader={[...inProgress, ...completed].length > 0}
        data-tour="property-table"
        className="property-table"
        scroll={{ x: 0 }}
        locale={{
          emptyText: () => (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No properties found"
            />
          ),
        }}
        title={() => (
          <div>
            <h3
              style={{
                margin: 0,
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {title}
            </h3>
            <ShortcutButton
              type="warning"
              size="small"
              title="New Property"
              shortcutKey="n"
              mobileIcon={<PlusOutlined />}
              style={{
                position: "absolute",
                top: 15,
                right: 15,
              }}
              onClick={() => onCreate()}
            />
          </div>
        )}
        columns={columns}
        dataSource={[...inProgress, ...completed]}
        showSorterTooltip={false}
        bordered
        pagination={pagination}
        footer={() => {
          if (inProgress.length > 0) {
            return (
              <>
                <AsteriskField description="" color="red" /> -{" "}
                <i>property is in progress</i>
              </>
            );
          }
        }}
      />
      {["xs", "sm"].includes(colSize) ? null : (
        <Col span={24}>
          <Divider />
        </Col>
      )}
      <Table
        rowKey="_id"
        data-tour="property-table"
        className="property-table"
        scroll={{ x: 0 }}
        locale={{
          emptyText: () => (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No shared properties found"
            />
          ),
        }}
        title={() => (
          <div>
            <h3
              style={{
                margin: 0,
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              Shared Properties ({shared.length})
            </h3>
          </div>
        )}
        columns={sharedColumns}
        dataSource={shared}
        showSorterTooltip={false}
        bordered
        showHeader={shared.length > 0}
        pagination={pagination}
        footer={() => null}
      />
      <SharePropertyModal
        property={sharingProperty!}
        visible={sharePropertyModalVisible}
        onDismiss={() => setSharePropertyModalVisible(false)}
      />
    </>
  );
};

export default PropertyTable;
