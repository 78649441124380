import { Col, Row, Table } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import parseToNumberString from "../../../../../utils/parse-to-number-string";
import NumberField from "../../../../shared/number-field";
// @ts-ignore
import { PMT } from '@formulajs/formulajs'
import useMediaQuery from 'use-media-antd-query';

const RefinancingLoanSchedule = () => {
  const { loan: { refinancingPermanentLoanAssumptions: {
    lender,
    date,
    loanType,
    refinanceLoanAmount,
    amortizationYears,
    interestRate,
    interestOnlyMonths
  }, refinancingLoanSchedule } } = useSelector((state: RootState) => state.loanReducer);
  const colSize = useMediaQuery();

  const assumptionsColumns = [
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: ["xs", "sm"].includes(colSize) ? "50%" : '65%',
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
      width: ["xs", "sm"].includes(colSize) ? "50%" : '35%',
    },
  ];

  const scheduleColumns = [
    {
      title: 'Year',
      dataIndex: 'year',
      key: 'year',
      render: (_: any, record: any, index: number) =>
        <span style={{ minWidth: 50, float: 'right' }}>{refinancingLoanSchedule[index].year}</span>
    },
    {
      title: 'Month',
      dataIndex: 'month',
      key: 'month',
      render: (_: any, record: any, index: number) =>
        <span style={{ minWidth: 50, float: 'right' }}>{refinancingLoanSchedule[index].month}</span>
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (_: any, record: any, index: number) =>
        <span style={{ float: 'right' }}>{refinancingLoanSchedule[index].date.toLocaleDateString()}</span>
    },
    {
      title: 'Starting Balance',
      dataIndex: 'startingBalance',
      key: 'startingBalance',
      render: (_: any, record: any, index: number) =>
        <span style={{ float: 'right' }}>${parseToNumberString(refinancingLoanSchedule[index].startingBalance)}</span>
    },
    {
      title: 'Total Monthly Payment',
      dataIndex: 'payment',
      key: 'payment',
      render: (_: any, record: any, index: number) =>
        <NumberField type="money" value={refinancingLoanSchedule[index].payment} />
    },
    {
      title: 'Monthly Principal',
      dataIndex: 'principal',
      key: 'principal',
      render: (_: any, record: any, index: number) =>
        <NumberField type="money" value={refinancingLoanSchedule[index].principal} />
    },
    {
      title: 'Monthly Interest',
      dataIndex: 'interest',
      key: 'interest',
      render: (_: any, record: any, index: number) =>
        <NumberField type="money" value={refinancingLoanSchedule[index].interest} />
    },
    {
      title: 'Ending Balance',
      dataIndex: 'endingBalance',
      key: 'endingBalance',
      render: (_: any, record: any, index: number) =>
        <NumberField type="money" value={refinancingLoanSchedule[index].endingBalance} />
    },
    {
      title: 'Total Interest',
      dataIndex: 'totalInterestPaid',
      key: 'totalInterestPaid',
      render: (_: any, record: any, index: number) =>
        <NumberField type="money" value={refinancingLoanSchedule[index].totalInterestPaid} />
    },
    {
      title: 'Total Principal',
      dataIndex: 'totalPrincipalPaid',
      key: 'totalPrincipalPaid',
      render: (_: any, record: any, index: number) =>
        <NumberField type="money" value={refinancingLoanSchedule[index].totalPrincipalPaid} />
    },
  ];

  const assumptionsDataSource = [
    {
      key: '1',
      description: "Loan Method",
      value: <span style={{ float: 'right' }}>{loanType.toUpperCase()}</span>,
    },
    {
      key: '2',
      description: "Lender",
      value: <span style={{ float: 'right' }}>{lender}</span>,
    },
    {
      key: '3',
      description: "Refinance Date",
      value: <span style={{ float: 'right' }}>{date.toLocaleDateString()}</span>,
    },
    {
      key: '4',
      description: "Loan Amount",
      value: <NumberField type="money" value={refinanceLoanAmount} />,
    },
    {
      key: '6',
      description: "Amortization (years)",
      value: <span style={{ float: 'right' }}>{amortizationYears}</span>,
    },
    {
      key: '7',
      description: "I/O periods (months)",
      value: <span style={{ float: 'right' }}>{interestOnlyMonths}</span>,
    },
    {
      key: '8',
      description: "Interest Rate",
      value: <span style={{ float: 'right' }}>{interestRate}%</span>,
    },
    {
      key: '9',
      description: "Debt Service (monthly)",
      value: <NumberField type="money" value={-1 * PMT((interestRate / 100) / 12, amortizationYears * 12, refinanceLoanAmount)} />
    },
    {
      key: '10',
      description: "Debt Service (annual)",
      value: <NumberField type="money" value={12 * (-1 * PMT((interestRate / 100) / 12, amortizationYears * 12, refinanceLoanAmount))} />
    },
  ]

  return (
    <>
      <Row gutter={24}>
        <Col xs={24} md={12}>
          <Table size="small" title={() => <b>Assumptions</b>} showHeader={false} columns={assumptionsColumns} dataSource={assumptionsDataSource} bordered pagination={false} />
        </Col>
      </Row>
      <Row gutter={24} style={{ marginTop: 50 }}>
        <Col span={24}>
          <Table
            scroll={{ x: true, y: 500 }}
            size="small"
            title={() => <b>Schedule</b>}
            columns={scheduleColumns}
            dataSource={refinancingLoanSchedule}
            bordered
            pagination={false} />
        </Col>
      </Row>
    </>
  )

}
export default RefinancingLoanSchedule;