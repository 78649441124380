import { Row, Col, notification, Divider, Card } from "antd";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";
import mapProperty from "../../types/property/map-property";
import propertyService from "../../api/services/property";
import IAnalyzedProperty from "../../types/analyzed-property";
import { useAppDispatch } from "../../store";
import PropertyStatistics from "./statistics";
import PropertyTable from "./table";
import useMediaQuery from "use-media-antd-query";
import Spinner from "../shared/spinner";
import Modal from "../modals";
import Button from "antd-button-color";
import mapLoan from "../../types/loan/map-loan";
import { Link } from "react-router-dom";

const Properties = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [properties, setProperties] = useState<{
    completed: IAnalyzedProperty[];
    inProgress: IAnalyzedProperty[];
    shared: IAnalyzedProperty[];
  }>({
    completed: [],
    inProgress: [],
    shared: [],
  });
  const [errorModalVisible, setErrorModalVisible] = useState<boolean>(false);
  const colSize = useMediaQuery();

  useEffect(() => {
    dispatch({ type: "UPDATE_STEP_CURRENT_STEP_ID", payload: 1 });
    setLoading(true);
    propertyService
      .get()
      .then((res) => {
        setProperties({
          completed: res.data.complete,
          inProgress: res.data.inProgress,
          shared: res.data.shared,
        });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setProperties({
          completed: [],
          inProgress: [],
          shared: [],
        });
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    const error = localStorage.getItem("error-boundary-error");
    if (error) {
      setErrorModalVisible(true);
    }
  }, [localStorage]);

  const handleDismissErrorModal = (reportIssue: boolean) => {
    if (reportIssue) {
      setErrorModalVisible(false);
      setTimeout(() => {
        navigate("/report-issue");
      }, 100);
    } else {
      setErrorModalVisible(false);
      localStorage.removeItem("error-boundary-error");
    }
  };

  const handleDelete = (id: string) => {
    propertyService
      .delete(id)
      .then(() => {
        notification.success({
          message: "Property deleted succesfully",
          duration: 5,
          placement: "bottomRight",
        });
        setProperties((properties) => ({
          completed: properties.completed.filter((p) => p._id !== id),
          inProgress: properties.inProgress.filter((p) => p._id !== id),
          shared: properties.shared.filter((p) => p._id !== id),
        }));
      })
      .catch((err) => {
        notification.error({
          message: "An error was encountered while deleting property",
          duration: 5,
          placement: "bottomRight",
        });
        console.error(err);
      });
  };

  const handleEdit = async (property: IAnalyzedProperty) => {
    const mappedProperty = mapProperty(property!);
    const mappedLoan = mapLoan(property!.loan);
    await dispatch({ type: "UPDATE_PROPERTY", payload: mappedProperty });
    await dispatch({ type: "UPDATE_LOAN", payload: mappedLoan });
    await dispatch({
      type: "UPDATE_STEP_CURRENT_STEP_ID",
      payload: property.inProgress ? property.currentStep : 1,
    });
    navigate(`/analyzer/${property._id!}`);
  };

  const handleCreate = () => {
    navigate("/new-property");
  };

  if (loading)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner size="large" />
      </div>
    );

  return (
    <Card className="offset" style={{ border: "none", boxShadow: "none" }}>
      <Row gutter={24} data-tour="properties">
        <Col span={24}>
          <div style={{ display: "flex" }}>
            <PropertyStatistics
              complete={properties.completed}
              inProgress={properties.inProgress}
            />
          </div>
        </Col>
        {["xs", "sm"].includes(colSize) ? null : (
          <Col span={24}>
            <Divider />
          </Col>
        )}
        <Col span={24}>
          <PropertyTable
            title={`My Properties (${
              [...properties.inProgress, ...properties.completed].length
            })`}
            properties={properties}
            onCreate={handleCreate}
            onEdit={(property) => handleEdit(property)}
            onDelete={(id) => handleDelete(id)}
          />
        </Col>
      </Row>
      <Modal
        visible={errorModalVisible}
        onDismiss={() => handleDismissErrorModal(false)}
      >
        <div style={{ textAlign: "center" }}>
          <h3 style={{ marginBottom: 25 }}>
            It looks like you were encountering an error. <br /> A notification
            has been sent to our team for further review. <br /> If you would
            like to provide additional details pertaining to the encountered
            issue, you may do so{" "}
            <Link onClick={() => handleDismissErrorModal(true)} replace to={"/report-issue"}>
              here
            </Link>
          </h3>
          <Button
            onClick={() => handleDismissErrorModal(false)}
            type="lightdark"
            style={{ marginLeft: 10 }}
          >
            Dismiss
          </Button>
        </div>
      </Modal>
    </Card>
  );
};

export default Properties;
