import { Card } from "antd";
import styled from "styled-components";

interface NewPropertyCardProps {
  active: boolean;
  color: string;
}

const NewPropertyCard = styled(Card)<NewPropertyCardProps>`
  cursor: pointer;
  min-height: 250px;
  text-align: center;
  margin-bottom: 10px;
  opacity: ${props => props.active ? 1 : 0.3};
  filter: blur(${ props => props.active ? 0 : '.3px' });
  ${props => props.active &&  `border: 1px solid white;`}

  h4 {
    margin-bottom: 20px;
  }

  svg {
    ${props => props.active &&  `color: ${props.color};`}
  }
`

export default NewPropertyCard;