import { useNavigate } from "react-router";
import useMediaQuery from "use-media-antd-query";
import MainMenu from "./main-menu";

const Header = () => {
  const navigate = useNavigate();
  const colSize = useMediaQuery();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        marginTop: 15,
      }}
    >
      {!["xs", "sm"].includes(colSize) ? (
        <img
          data-tour="logo"
          alt="logo-small"
          src="images/logo.png"
          style={{ cursor: "pointer" }}
          height={35}
          width={200}
          onClick={() => navigate("/properties")}
        />
      ) : (
        <img
          data-tour="logo"
          alt="logo-regular"
          src="images/logo-small.png"
          style={{ cursor: "pointer" }}
          height={35}
          onClick={() => navigate("/properties")}
        />
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <MainMenu />
      </div>
    </div>
  );
};

export default Header;
