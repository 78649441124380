import { Table } from "antd";
import { ISourcesAndUsesAcquisitionCost } from "..";
import NumberField from "../../../../shared/number-field";

interface IUsesProps {
  acquisitionCosts: ISourcesAndUsesAcquisitionCost[];
}

const Uses = ({ acquisitionCosts }: IUsesProps) => {
  const usesColumns = [
    {
      key: 'description',
      title: "Acquisition Cost",
      width: '40%',
      render: (_: any, record: any, index: number) =>
        index >= acquisitionCosts.length - 2 ?
          <b>{acquisitionCosts[index].description}</b>
          : <span style={{ marginLeft: 10 }}>{acquisitionCosts[index].description}</span>
    },
    {
      key: 'percentage',
      width: '10%',
      render: (_: any, record: any, index: number) =>
        acquisitionCosts[index].percentage !== undefined ?
          <NumberField type="percentage" value={acquisitionCosts[index].percentage!} /> : null,
    },
    {
      key: 'total',
      title: "Total",
      width: '25%',
      render: (_: any, record: any, index: number) =>
        index >= acquisitionCosts.length - 2 ?
          <b><NumberField type="money" value={acquisitionCosts[index].total} /></b>
          : <span><NumberField type="money" value={acquisitionCosts[index].total} /></span>
    },
    {
      key: 'perUnit',
      title: "Per Unit",
      width: '25%',
      render: (_: any, record: any, index: number) =>
        index >= acquisitionCosts.length - 2 ?
          <b><NumberField type="money" value={acquisitionCosts[index].perUnit} /></b>
          : <span><NumberField type="money" value={acquisitionCosts[index].perUnit} /></span>
    },
  ];


  return (
    <Table
      bordered
      pagination={false}
      columns={usesColumns}
      dataSource={acquisitionCosts}
      style={{whiteSpace:'nowrap'}}
      scroll={{x:true}}
    />
  );

}
export default Uses;