import { Table, Input } from "antd";
import { ChangeEvent, useEffect, useState } from "react";
import { IYearOneProformaIncomeExpenseItem } from "..";
import NumberField from "../../../../shared/number-field";

interface IYearOneProformaOtherIncomeAssumptionsProps {
  otherIncomeItems: IYearOneProformaIncomeExpenseItem[];
  totalUnits: number;
  onChange(items: IYearOneProformaIncomeExpenseItem[]): void;
}

const YearOneProformaOtherIncomeAssumptions = ({ otherIncomeItems: startingOtherIncomeItems, totalUnits, onChange }: IYearOneProformaOtherIncomeAssumptionsProps) => {
  const [otherIncomeItems, setOtherIncomeItems] = useState<IYearOneProformaIncomeExpenseItem[]>(startingOtherIncomeItems);
  const handleChange = (event: ChangeEvent<HTMLInputElement>, index: number) => {
    let newOtherIncomeItems = [...otherIncomeItems];
    const { target: { value } } = event;
    newOtherIncomeItems[index].yearOneAmount = parseFloat(value);
    setOtherIncomeItems(newOtherIncomeItems);
  };

  useEffect(() => {
    onChange(otherIncomeItems);
      
  }, [otherIncomeItems])

  const otherIncomeColumns = [
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: '40%',
      render: (_: any, record: any, index: number) =>
        <span>{otherIncomeItems[index].description}</span>
    },
    {
      title: 'T-12 Financials Amount Per Unit',
      dataIndex: 't12FinancialsAmountPerUnit',
      key: 't12FinancialsAmountPerUnit',
      width: '15%',
      render: (_: any, record: any, index: number) =>
        <NumberField type="money" value={otherIncomeItems[index].amount / totalUnits} />
    },
    {
      title: 'T-12 Financials Amount',
      dataIndex: 't12FinancialsAmount',
      key: 't12FinancialsAmount',
      width: '15%',
      render: (_: any, record: any, index: number) =>
        <NumberField type="money" value={otherIncomeItems[index].amount} />
    },
    {
      title: 'Year One Amount Per Unit',
      dataIndex: 'yearOnePerUnit',
      key: 'yearOnePerUnit',
      width: '15%',
      render: (_: any, record: any, index: number) =>
        <Input type="number" value={parseFloat(otherIncomeItems[index].yearOneAmount.toFixed(2))} min={0} step={0.01} onChange={e => handleChange(e, index)} />
    },
    {
      title: 'Year One Total',
      dataIndex: 'yearOnePerUnit',
      key: 'yearOnePerUnit',
      width: '15%',
      render: (_: any, record: any, index: number) =>
        <NumberField type="money" value={otherIncomeItems[index].yearOneAmount * totalUnits} />
    },
  ];

  return (
    <>
      <Table
        columns={otherIncomeColumns}
        dataSource={otherIncomeItems}
        bordered
        pagination={false}
        scroll={{x:true}}
        style={{whiteSpace:'nowrap'}}
      />
    </>
  );

}
export default YearOneProformaOtherIncomeAssumptions;