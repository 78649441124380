import { IAssumption, Assumption } from "../assumptions";

interface IMonthlyGpr {
  year: number;
  month: number;
  nonRenovatedUnitIncome: number;
  renovatedUnitIncome: number;
  unitRenovationDowntimeLoss: number;
  totalGPR: number;
}

interface IRentMatrix {
  year: number;
  growthAssumption: number;
  nonRenovatedRate: number;
  renovatedRate: number;
}

interface IRenovationMonth {
  year: number;
  month: number;
  totalUnits: number;
  nonRenovatedUnits: number;
  renovatedUnits: number;
  renovationInProgressUnits: number;
  completedUnits: number;
}

export interface IRentRollUnitBaseValues {
  type: string;
  unitCount: number;
  squareFtPerUnit: number;
  rent: number;
  marketRent: number;
  renovatedUnits?: number;
  renovatedMarketRent?: number;
  unitsScheduledForRenovation?: number;
  renovationsStartedPerMonth?: number;
  renovationStartMonth?: number;
  renovationDownTime?: number;
}

export interface IRentRollUnit {
  type: string;
  unitCount: number;
  squareFtPerUnit: number;
  renovatedUnits: number;
  rent: number;
  marketRent: number;
  renovatedMarketRent: number;
  renovationSchedule: IRenovationMonth[];
  rentMatrix: IRentMatrix[];
  monthlyGpr: IMonthlyGpr[];
  unitsScheduledForRenovation: number;
  unitRenovationsStartedPerMonth: number;
  renovationsStartedPerMonth: number;
  renovationStartMonth: number;
  renovationDownTime: number;
  totalSquareFeet: number;
  percentOfTotalSqFt: number;
  rentDollarPerSqFt: number;
  marketRentDollarPerSqFt: number;
  renovatedMarketRentDollarPerSqFt: number;
  renovationEndMonth: number;
  gprGrowthAssumption: IAssumption;
  getBaseValues(): IRentRollUnitBaseValues;
  getEstimatedAnnualGPRByYear(year: number): number
}

export class RentRollUnit implements IRentRollUnit {
  type = "";
  unitCount = 0;
  squareFtPerUnit = 0;
  renovatedUnits = 0;
  rent = 0;
  marketRent = 0;
  renovatedMarketRent = 0;
  renovationSchedule: IRenovationMonth[] = [];
  rentMatrix: IRentMatrix[] = [];
  monthlyGpr: IMonthlyGpr[] = [];
  unitsScheduledForRenovation = 0;
  unitRenovationsStartedPerMonth = 0;
  renovationsStartedPerMonth = 0;
  renovationStartMonth = 0;
  renovationDownTime = 0;
  totalSquareFeet = 0;
  percentOfTotalSqFt = 0;
  rentDollarPerSqFt = 0;
  marketRentDollarPerSqFt = 0;
  renovatedMarketRentDollarPerSqFt = 0;
  renovationEndMonth = 0;
  gprGrowthAssumption: IAssumption = new Assumption("Growth Potential Rent", "grossPotentialRent", "growth");

  constructor(
    { type, unitCount, squareFtPerUnit, rent, marketRent, renovatedUnits, renovatedMarketRent,
      unitsScheduledForRenovation, renovationsStartedPerMonth, renovationStartMonth, renovationDownTime
    }: IRentRollUnitBaseValues,
    gprGrowthAssumption: IAssumption,
  ) {
    this.type = type;
    this.unitCount = unitCount;
    this.squareFtPerUnit = squareFtPerUnit;
    this.rent = rent;
    this.marketRent = marketRent;
    this.renovatedUnits = renovatedUnits || 0;
    this.renovatedMarketRent = renovatedMarketRent || 0;
    this.unitsScheduledForRenovation = unitsScheduledForRenovation || 0;
    this.renovationsStartedPerMonth = renovationsStartedPerMonth || 0;
    this.renovationStartMonth = renovationStartMonth || 0;
    this.renovationDownTime = renovationDownTime || 0;
    this.gprGrowthAssumption = gprGrowthAssumption;

    this.calculateTotalSquareFeet();
    this.calculateRentDollarPerSquareFoot();
    this.calculateMarketRentDollarPerSqFt();
    this.calculateRenovatedMarketRentDollarPerSqFt();
    this.calculateRenovationEndMonth();
    this.populateRenovationSchedule();
    this.calculateRentMatrix();
    this.calculateMonthlyGPR();
  }

  getBaseValues(): IRentRollUnitBaseValues {
    return {
      type:  this.type,
      unitCount: this.unitCount,
      squareFtPerUnit: this.squareFtPerUnit,
      rent: this.rent,
      marketRent: this.marketRent,
      renovatedUnits:  this.renovatedUnits,
      renovatedMarketRent: this.renovatedMarketRent,
      unitsScheduledForRenovation: this.unitsScheduledForRenovation,
      renovationsStartedPerMonth:  this.renovationsStartedPerMonth,
      renovationStartMonth: this.renovationStartMonth,
      renovationDownTime:  this.renovationDownTime,
    }
  }

  private calculateTotalSquareFeet(): void {
    this.totalSquareFeet = this.unitCount * this.squareFtPerUnit;
  }

  private calculateRentDollarPerSquareFoot() {
    if (this.squareFtPerUnit > 0) {
      this.rentDollarPerSqFt = parseFloat((this.rent / this.squareFtPerUnit).toFixed(2));
    } else {
      this.rentDollarPerSqFt = 0;
    }
  }

  private calculateMarketRentDollarPerSqFt(): void {
    if (this.squareFtPerUnit > 0) {
      this.marketRentDollarPerSqFt = parseFloat((this.marketRent / this.squareFtPerUnit).toFixed(2));
    } else {
      this.marketRentDollarPerSqFt = 0;
    }
  }

  private calculateRenovatedMarketRentDollarPerSqFt(): void {
    if (this.squareFtPerUnit > 0 && this.renovatedMarketRent !== undefined) {
      this.renovatedMarketRentDollarPerSqFt = parseFloat((this.renovatedMarketRent / this.squareFtPerUnit).toFixed(2));
    } else {
      this.renovatedMarketRentDollarPerSqFt = 0;
    }
  }

  private calculateRenovationEndMonth(): void {
    if (this.unitsScheduledForRenovation > 0 && this.renovationsStartedPerMonth > 0) {
      const renovationMonths = Math.ceil(this.unitsScheduledForRenovation / this.renovationsStartedPerMonth) - 1
      this.renovationEndMonth = renovationMonths + this.renovationStartMonth + this.renovationDownTime;
    } else {
      this.renovationEndMonth = 0;
    }
  }

  private populateRenovationSchedule(): void {
    let renovationsRemaining = this.unitsScheduledForRenovation;
    let currentRenovations: { monthStart: number, monthEnd: number, count: number }[] = []
    let year = 0;
    Array.from(Array(121)).forEach((_, month) => {
      if (month === 1) {
        year = 1;
      } else if (month > 1 && (month - 1) % 12 === 0) {
        year += 1;
      }
      if (month < this.renovationStartMonth) {
        this.renovationSchedule.push({
          month,
          year,
          totalUnits: this.unitCount,
          nonRenovatedUnits: this.unitCount - this.renovatedUnits,
          renovatedUnits: this.renovatedUnits,
          renovationInProgressUnits: 0,
          completedUnits: 0,
        })
      } else if (month >= this.renovationStartMonth && month <= this.renovationEndMonth) {
        let completedRenovations = 0;
        if (renovationsRemaining > 0) {
          currentRenovations.push({
            monthStart: month,
            monthEnd: month + this.renovationDownTime,
            count: Math.min(this.renovationsStartedPerMonth, renovationsRemaining),
          });
        }
        if (currentRenovations.length > 0) {
          completedRenovations = currentRenovations.filter(m => m.monthEnd === month)[0]?.count || 0
        } else {
          completedRenovations = 0;
        }
        renovationsRemaining -= completedRenovations;

        this.renovationSchedule.push({
          month,
          year,
          totalUnits: this.unitCount - (this.unitsScheduledForRenovation + this.renovatedUnits - renovationsRemaining) + (this.unitsScheduledForRenovation + this.renovatedUnits - renovationsRemaining),
          nonRenovatedUnits: this.unitCount - (this.unitsScheduledForRenovation + this.renovatedUnits - renovationsRemaining),
          renovatedUnits: this.unitsScheduledForRenovation + this.renovatedUnits - renovationsRemaining,
          renovationInProgressUnits: Math.min(this.renovationsStartedPerMonth, renovationsRemaining),
          completedUnits: completedRenovations
        });
      } else {
        this.renovationSchedule.push({
          month,
          year,
          totalUnits: this.unitCount,
          nonRenovatedUnits: this.unitCount - (this.unitsScheduledForRenovation + this.renovatedUnits),
          renovatedUnits: this.unitsScheduledForRenovation + this.renovatedUnits,
          renovationInProgressUnits: 0,
          completedUnits: 0,
        })
      }
    });
  }

  private calculateRentMatrix(): void {
    let estimatedNonRenovatedMarketRent = this.marketRent;
    let estimatedRenovatedMarketRent = this.renovatedMarketRent;
    Array.from(Array(10)).forEach((_, year) => {
      if (year === 0) {
        this.rentMatrix.push({
          year: year,
          growthAssumption: 0,
          nonRenovatedRate: parseFloat(this.marketRent.toFixed(2)),
          renovatedRate: parseFloat(this.renovatedMarketRent.toFixed(2)),
        });

        const growth = this.gprGrowthAssumption.year1/100;
        estimatedNonRenovatedMarketRent = estimatedNonRenovatedMarketRent + (estimatedNonRenovatedMarketRent * growth);
        estimatedRenovatedMarketRent = estimatedRenovatedMarketRent + (estimatedRenovatedMarketRent * growth);

        this.rentMatrix.push({
          year: year + 1,
          growthAssumption: growth * 100,
          nonRenovatedRate: parseFloat(estimatedNonRenovatedMarketRent.toFixed(2)),
          renovatedRate: parseFloat(estimatedRenovatedMarketRent.toFixed(2)),
        });
      } else {
        // @ts-ignore
        const growth: number = this.gprGrowthAssumption[`year${year+1}`]/100
        estimatedNonRenovatedMarketRent = estimatedNonRenovatedMarketRent + (estimatedNonRenovatedMarketRent * growth);
        estimatedRenovatedMarketRent = estimatedRenovatedMarketRent + (estimatedRenovatedMarketRent * growth);
        this.rentMatrix.push({
          year: year + 1,
          growthAssumption: growth * 100,
          nonRenovatedRate: parseFloat(estimatedNonRenovatedMarketRent.toFixed(2)),
          renovatedRate: parseFloat(estimatedRenovatedMarketRent.toFixed(2)),
        })
      }
    });
  }

  private calculateMonthlyGPR(): void {
    this.renovationSchedule.forEach((schedule, month) => {
      const nonRenovatedMarketRent = Math.max(this.getEstimatedNonRenovatedMarketRent(schedule.year) * schedule.nonRenovatedUnits, 0);
      const renovatedMarketRent = Math.max((this.getEstimatedRenovatedMarketRent(schedule.year) * schedule.renovatedUnits), 0) ;
      if (schedule.nonRenovatedUnits > 0 && month >= this.renovationStartMonth) {
        const unitDownTime = this.unitsScheduledForRenovation * this.renovationDownTime;
        const renovationTime = this.renovationEndMonth - this.renovationStartMonth + 1;
        const downTimeUnitLoss = ((unitDownTime / renovationTime) / schedule.nonRenovatedUnits) * nonRenovatedMarketRent;
        this.monthlyGpr.push({
          year: schedule.year,
          month,
          nonRenovatedUnitIncome: nonRenovatedMarketRent,
          renovatedUnitIncome: renovatedMarketRent,
          unitRenovationDowntimeLoss: downTimeUnitLoss,
          totalGPR: nonRenovatedMarketRent + renovatedMarketRent - downTimeUnitLoss,
        });
      } else {
        this.monthlyGpr.push({
          year: schedule.year,
          month,
          nonRenovatedUnitIncome: nonRenovatedMarketRent,
          renovatedUnitIncome: renovatedMarketRent,
          unitRenovationDowntimeLoss: 0,
          totalGPR: nonRenovatedMarketRent + renovatedMarketRent,
        });
      }
    });
  }

  getEstimatedAnnualGPRByYear(year: number): number {
    return this.monthlyGpr
      .filter(x => x.year === year)
      .map(y => y.totalGPR)
      .reduce((prev, curr) => prev + curr, 0);
  }

  private getEstimatedNonRenovatedMarketRent(year: number): number {
    return this.rentMatrix.filter(r => r.year === year)[0].nonRenovatedRate;
  }

  private getEstimatedRenovatedMarketRent(year: number): number {
    return this.rentMatrix.filter(r => r.year === year)[0].renovatedRate;
  }
}