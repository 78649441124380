import { Table } from "antd";
import NumberField from "../../../../shared/number-field";
import { IOwnershipStructure } from "../investment-summary-service";

interface IOwnershipStructureProps {
  ownershipStructure: IOwnershipStructure;
}

const OwnershipStructure: React.FC<IOwnershipStructureProps> = ({
  ownershipStructure,
}) => {
  const {
    totalEquity,
    financing,
    acquisitionDate,
    refinancing,
    refinanceDate,
    exitMonth,
    exitDate,
    acquisitionLoanInterestRate,
    acquisitionLoanAmortization,
  } = ownershipStructure;

  const columns = [
    {
      dataIndex: "description",
      key: "description",
      width: "50%",
    },
    {
      dataIndex: "column2",
      key: "column2",
      width: "25%",
    },
    {
      dataIndex: "column3",
      key: "column3",
      width: "25%",
    },
  ];

  const dataSource = [
    {
      key: "0",
      column2: "Total $",
      column3: "% of Total",
    },
    {
      key: "1",
      description: <span style={{ marginLeft: 20 }}>Total Equity</span>,
      column2: (
        <NumberField type="money" value={ownershipStructure.totalEquity} />
      ),
      column3: (
        <i>
          <NumberField
            type="percentage"
            value={
              100 *
              (totalEquity /
                (financing + totalEquity))
            }
          />
        </i>
      ),
    },
    {
      key: "2",
      description: <span style={{ marginLeft: 20 }}>Financing</span>,
      column2: <NumberField type="money" value={financing} />,
      column3: (
        <i>
          <NumberField
            type="percentage"
            value={100 * (financing / (financing + totalEquity))}
          />
        </i>
      ),
    },
    {
      key: "3",
      description: "Total Capitalization",
      column2: (
        <NumberField
          type="money"
          value={totalEquity + financing}
        />
      ),
      column3: (
        <i>
          <NumberField type="percentage" value={100} />
        </i>
      ),
    },
    {
      key: "4",
      description: "Acquisition Date",
      column3: (
        <span style={{ float: "right" }}>{acquisitionDate.toLocaleDateString()}</span>
      ),
    },
    {
      key: "5",
      description: <i style={{ marginLeft: 20 }}>Refinancing?</i>,
      column3: <i style={{ float: "right" }}>{refinancing ? "Yes" : "No"}</i>,
    },
    {
      key: "6",
      description: <i style={{ marginLeft: 20 }}>if yes, refinance date</i>,
      column3: (
        <i style={{ float: "right" }}>
          {refinancing ? refinanceDate.toLocaleDateString() : null}
        </i>
      ),
    },
    {
      key: "7",
      description: "Exit Month (Projected)",
      column3: <span style={{ float: "right" }}>{"Month " + exitMonth}</span>,
    },
    {
      key: "8",
      description: "Exit Date (Projected)",
      column3: (
        <span style={{ float: "right" }}>
          {exitDate?.toLocaleDateString()}
        </span>
      ),
    },
    {
      key: "9",
      description: "Acquisition Loan - Interest Rate",
      column3: <NumberField type="percentage" value={acquisitionLoanInterestRate} />,
    },
    {
      key: "10",
      description: "Acquisition Loan - Amortization",
      column3: (
        <span style={{ float: "right" }}>{acquisitionLoanAmortization + " years"}</span>
      ),
    },
  ];

  return (
    <>
      <Table
        title={() => (
          <div style={{ textAlign: "center" }}>
            <b>Ownership Structure</b>
          </div>
        )}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        bordered
        showHeader={false}
        style={{ whiteSpace: "nowrap" }}
        scroll={{ x: true }}
      />
    </>
  );
};
export default OwnershipStructure;
