import { Col, Card, Rate } from "antd";
import { FaMapMarkerAlt, FaPhoneAlt, FaRegEnvelope } from "react-icons/fa";
import { IContactDetails } from "../../../api/services/user";
import { usePusher } from "../../../providers/pusher-provider";
import Avatar from "../../shared/avatar";
import { StarFilled } from "@ant-design/icons";
import formatPhoneNumber from "../../../utils/format-phone-number";

type FavoritesProps = {
  contacts: IContactDetails[];
  onUnfavoriteContact(contact: IContactDetails): void;
};

const Favorites: React.FC<FavoritesProps> = ({
  contacts,
  onUnfavoriteContact,
}) => {
  return (
    <>
      {contacts.map((contact, index) => (
        <Col xs={24} md={8} xl={6} key={index}>
          <Card
            style={{ marginBottom: contacts.length > 4 ? 20 : 0 }}
            title={
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Avatar
                    userId={contact.userId}
                    firstName={contact.firstName}
                    lastName={contact.lastName}
                    avatarColor={contact.avatarColor}
                  />
                  <div style={{ marginLeft: 10 }}>
                    <span style={{ display: "block" }}>
                      {contact.firstName + " " + contact.lastName}
                    </span>
                    {/* <span style={{display:'block', fontSize: 10}}>{onlineUsers.map(o => o.id).includes(contact.userId) ? 'Active' : 'Inactive'}</span> */}
                  </div>
                </div>
                <Rate
                  character={<StarFilled />}
                  style={{
                    color: "orange",
                    marginRight: 10,
                    fontSize: 25,
                  }}
                  count={1}
                  tooltips={["Unfavorite"]}
                  value={1}
                  onChange={() => onUnfavoriteContact(contact)}
                />
              </div>
            }
          >
            <span>
              <FaMapMarkerAlt />{" "}
              {contact.city + (contact.city ? `, ${contact.state}` : null)}
            </span>
            <br />
            <div style={{ marginTop: 20 }}>
              <span>
                <FaPhoneAlt />
                <a
                  style={{ marginLeft: 10 }}
                  href={`tel:${contact.phoneNumber}`}
                >
                  {formatPhoneNumber(contact.phoneNumber)}
                </a>
              </span>
              <br />
              <span>
                <FaRegEnvelope />
                <a style={{ marginLeft: 10 }} href={`mailto:${contact.email}`}>
                  {contact.email}
                </a>
              </span>
            </div>
          </Card>
        </Col>
      ))}
    </>
  );
};

export default Favorites;
