import parseToFixed from "../../utils/parse-to-fixed";
import { annualAssumptionCategory, getAnnualAssumptions, getGrowthAssumptions, growthAssumptionCategory, IAssumption } from "../assumptions";
import { IRentRollUnit, RentRollUnit } from "../rent-roll-unit";

type ExpenseItemCategory =  "rentIncome" | "otherIncome" | "expense";

type IncomeExpenseItemName =
"grossPotentialRent" |
"vacancyLoss" |
"lossToLease" |
"rentConcessions" |
"creditLossBadDebt" |
"nonRevenueUnits" |
"netRentalIncome" |
"rubs" |
"laundryVendingIncome" |
"placeholderOtherIncome" |
"feeIncome" |
"other" |
"totalOtherIncome" |
"generalAndAdministration" |
"contractServices" |
"marketing" |
"payroll" |
"repairsAndMaintenance" |
"turnoverCosts" |
"cableInternet" |
"insurance" |
"realEstateTaxes" |
"utilities" |
"propertyManagementFee" |
"assetManagementFee" |
"replacementReserves" |
"expenseTotals";


export interface IRenovationItem {
  description: string;
  cost: number;
}

export interface IPropertyRenovations {
  interiorRenovations: IRenovationItem[];
  exteriorRenovations: IRenovationItem[];
  interiorRenovationCost: number;
  exteriorRenovationCost: number;
  contingencyPercentage: number;
  contingency: number;
  totalBudget: number;
}

export interface IIncomeExpenseItem {
  description: string;
  name: IncomeExpenseItemName;
  amount: number;
}

export interface IYearOneProformaIncomeExpenseItem extends IIncomeExpenseItem {
  yearOneAmount: number;
}


export interface IReserveItem {
  months: number;
  release: boolean;
  releaseMonths: number;
  yearOneMonthlyAmount: number;
  yearOneTotal: number;
  yearOneTotalPerUnit: number;
}

export interface IInvestmentTimeline {
  acquisitionDate: Date | null
  refinanceDate: Date | null
  exitDispositionDate: Date | null
  holdPeriodYears: number;
  exitMonth: number;
  distributionStartDate: number;
  renovationStartMonth: number;
  renovationEndMonth: number;
}

export interface IExitDispositionSummary {
  date: Date | null;
  capRateSpread: number;
  capRate: number;
  noiTrailing12Months: number;
  occupancy: number;
  grossSaleProceeds: number;
  dispositionFeePercentage: number;
  dispositionFee: number;
  saleClosingCostsPercentage: number;
  saleClosingCosts: number;
  loanRepaymentAtSale: number;
  netSaleProceeds: number;
}

export interface IPromoteIncentiveHurdles {
  investorPreferredReturn: number;
  t1HurdlePercentage: number;
  t2HurdlePercentage: number;
  t3HurdlePercentage: number;
  t4HurdlePercentage: number;
  t1PromotePercentage: number;
  t2PromotePercentage: number;
  t3PromotePercentage: number;
  t4PromotePercentage: number;
}

export interface IProperty {
  _id?: string;
  shared: boolean;
  sharedDetails?: {
    userId: string;
    firstName: string;
    lastName: string;
    email: string;
    date: Date;
  }
  name: string;
  streetAddress: string;
  city: string;
  state: string;
  zipCode: number;
  county: string;
  yearBuilt: number;
  buildingClass: string;
  numberOfUnits: number;
  netRentableSquareFeet: number;
  goingInNOI: number;
  goingInCapRate: number;
  currentOccupancy: number;
  investmentOverview: string;
  marketInformationAndCurrentStatus: string;
  images: string[];
  type: string;
  rentRollUnitMix: IRentRollUnit[];
  totalUnits: number;
  totalRenovatingUnits: number;
  totalSqFtUnit: number;
  totalSqFt: number;
  dollarPerSqFtRent: number;
  dollarPerSqFtMarketRent: number;
  dollarPerSqFtRenovatedMarketRent: number;
  averageRent: number;
  averageMarketRent: number;
  averageRenovatedMarketRent: number;
  grossPotentialRent: number;
  renovations: IPropertyRenovations;
  rentIncomeItems: IIncomeExpenseItem[];
  otherIncomeItems: IIncomeExpenseItem[];
  expenseItems: IIncomeExpenseItem[];
  reservesTaxes: IReserveItem;
  reservesInsurance: IReserveItem;
  growthAssumptions: IAssumption[];
  annualAssumptions: IAssumption[];
  yearOneProformaAssumptions: {
    rentIncomeItems: IYearOneProformaIncomeExpenseItem[];
    otherIncomeItems: IYearOneProformaIncomeExpenseItem[];
    expenseItems: IYearOneProformaIncomeExpenseItem[];
  }
  investmentTimeline: IInvestmentTimeline;
  exitDispositionSummary: IExitDispositionSummary;
  promoteIncentiveHurdles: IPromoteIncentiveHurdles;
  ownerId: string;
  inProgress: boolean;
  createdAt?: Date;
  updatedAt?: Date;
  currentStep: number;
  updateRentRollUnitMix(rentRollUnitMix: IRentRollUnit[]): void;
  updateInteriorRenovations(items: IRenovationItem[]): void;
  updateExteriorRenovations(items: IRenovationItem[]): void;
  updateRenovationContingencyPercentage(percentage: number): void;
  updateIncomeExpenseItems(type: "rentIncome" | "otherIncome" | "expense", items: IIncomeExpenseItem[]): void;
  getTaxes(): number;
  getInsurance(): number;
  updateReserves(): void;
  getGoingInNoi(): number;
  getGrowthAssumption(name: growthAssumptionCategory): IAssumption;
  getAnnualAssumption(name: annualAssumptionCategory): IAssumption;
}

export class Property implements IProperty {
  shared = false;
  name = "";
  streetAddress = "";
  city = "";
  state = "";
  zipCode = 0;
  county = "";
  yearBuilt = 0;
  buildingClass = "";
  numberOfUnits = 0;
  netRentableSquareFeet = 0;
  goingInNOI = 0;
  goingInCapRate = 0;
  currentOccupancy = 0;
  investmentOverview = "";
  marketInformationAndCurrentStatus = "";
  images = [];
  type = "";
  rentRollUnitMix: IRentRollUnit[] = [];
  totalUnits = 0;
  totalRenovatingUnits = 0;
  totalSqFtUnit = 0;
  totalSqFt = 0;
  dollarPerSqFtRent = 0;
  dollarPerSqFtMarketRent = 0;
  dollarPerSqFtRenovatedMarketRent = 0;
  averageRent = 0;
  averageMarketRent = 0;
  averageRenovatedMarketRent = 0;
  renovations: IPropertyRenovations = {
    interiorRenovations: [],
    exteriorRenovations: [],
    interiorRenovationCost: 0,
    exteriorRenovationCost: 0,
    contingencyPercentage: 0,
    contingency: 0,
    totalBudget: 0,
  }
  grossPotentialRent = 0;
  rentIncomeItems: IIncomeExpenseItem[] = [
    { description: "Gross Potential Rent", name: "grossPotentialRent", amount: 0 },
    { description: "Vacancy Loss", name: "vacancyLoss", amount: 0 },
    { description: "Loss-to-Lease", name: "lossToLease", amount: 0 },
    { description: "Rent Concessions", name: "rentConcessions", amount: 0 },
    { description: "Credit Loss / Bad Debt", name: "creditLossBadDebt", amount: 0 },
    { description: "Non-Revenue Units", name: "nonRevenueUnits", amount: 0 },
    { description: "Net Rental Income", name: "netRentalIncome", amount: 0 },
  ];
  otherIncomeItems: IIncomeExpenseItem[] = [
    { description: "RUBS", name: "rubs", amount: 0 },
    { description: "Laundry / Vending Income", name: "laundryVendingIncome", amount: 0 },
    { description: "[Placeholder - Other Income]", name: "placeholderOtherIncome", amount: 0 },
    { description: "Fee Income", name: "feeIncome", amount: 0 },
    { description: "Other", name: "other", amount: 0 },
    { description: "Total Other Income", name: "totalOtherIncome", amount: 0 },
  ];
  expenseItems: IIncomeExpenseItem[] = [
    { description: "General and Administration", name: "generalAndAdministration", amount: 0 },
    { description: "Contract Services", name: "contractServices", amount: 0 },
    { description: "Marketing", name: "marketing", amount: 0 },
    { description: "Payroll", name: "payroll", amount: 0 },
    { description: "Repairs and Maintenance", name: "repairsAndMaintenance", amount: 0 },
    { description: "Turnover Costs", name: "turnoverCosts", amount: 0 },
    { description: "Cable / Internet", name: "cableInternet", amount: 0 },
    { description: "Insurance", name: "insurance", amount: 0 },
    { description: "Real Estate Taxes", name: "realEstateTaxes", amount: 0 },
    { description: "Utilities", name: "utilities", amount: 0 },
    { description: "Property Management Fee", name: "propertyManagementFee", amount: 0 },
    { description: "Asset Management Fee", name: "assetManagementFee", amount: 0 },
    { description: "Replacement Reserves", name: "replacementReserves", amount: 0 },
    { description: "Expense Totals", name: "expenseTotals", amount: 0 },
  ];
  reservesTaxes: IReserveItem = {
    months: 0,
    release: false,
    releaseMonths: 0,
    yearOneMonthlyAmount: 0,
    yearOneTotal: 0,
    yearOneTotalPerUnit: 0,
  }
  reservesInsurance: IReserveItem = {
    months: 0,
    release: false,
    releaseMonths: 0,
    yearOneMonthlyAmount: 0,
    yearOneTotal: 0,
    yearOneTotalPerUnit: 0,
  }
  growthAssumptions: IAssumption[] = getGrowthAssumptions();
  annualAssumptions: IAssumption[] = getAnnualAssumptions();
  yearOneProformaAssumptions = {
    rentIncomeItems: this.rentIncomeItems.filter(x => x.name !== "netRentalIncome").map(x => ({ ...x, yearOneAmount: x.amount })),
    otherIncomeItems: this.otherIncomeItems.filter(x => x.name !== "totalOtherIncome").map(x => ({ ...x, yearOneAmount: x.amount })),
    expenseItems: this.expenseItems.filter(x => !["propertyManagementFee", "assetManagementFee", "expenseTotals"].includes(x.name)).map(x => ({ ...x, yearOneAmount: x.amount })),
  };
  investmentTimeline: IInvestmentTimeline = {
    acquisitionDate: null,
    refinanceDate: null,
    exitDispositionDate: null,
    holdPeriodYears: 0,
    exitMonth: 0,
    distributionStartDate: 0,
    renovationStartMonth: 0,
    renovationEndMonth: 0,
  }
  exitDispositionSummary: IExitDispositionSummary = {
    date: null,
    capRate: 0,
    capRateSpread: 0,
    occupancy: 0,
    noiTrailing12Months: 0,
    grossSaleProceeds: 0,
    dispositionFeePercentage: 0,
    dispositionFee: 0,
    saleClosingCostsPercentage: 0,
    saleClosingCosts: 0,
    loanRepaymentAtSale: 0,
    netSaleProceeds: 0,
  }
  promoteIncentiveHurdles: IPromoteIncentiveHurdles = {
    investorPreferredReturn: 0,
    t1HurdlePercentage: 0,
    t2HurdlePercentage: 0,
    t3HurdlePercentage: 0,
    t4HurdlePercentage: 0,
    t1PromotePercentage: 0,
    t2PromotePercentage: 0,
    t3PromotePercentage: 0,
    t4PromotePercentage: 0,
  }
  ownerId: string = "";
  inProgress: boolean = true;
  created: Date | undefined = undefined;
  updated: Date | undefined = undefined;
  currentStep = 0;

  constructor(rentRollUnitMix?: IRentRollUnit[]) {
    this.rentRollUnitMix = rentRollUnitMix || [];
    this.rentRollUnitMix.length > 0 && this._calculateUnitMixTotals();
  }

  updateIncomeExpenseItems(type: ExpenseItemCategory, items: IIncomeExpenseItem[]) {
    switch (type) {
      case "rentIncome":
        this.rentIncomeItems = items;
        this.grossPotentialRent = items[0].amount;
        break;
      case "otherIncome":
        this.otherIncomeItems = items;
        break;
      case "expense":
        this.expenseItems = items;
        break;
      default:
        break;
    }
  }

  updateRentRollUnitMix(rentRollUnitMix: IRentRollUnit[]) {
    this.rentRollUnitMix = rentRollUnitMix.map(r => {
      return new RentRollUnit(r.getBaseValues(), this.getGrowthAssumption("grossPotentialRent"));
    });
    this._calculateUnitMixTotals();
  }

  updateInteriorRenovations(items: IRenovationItem[]) {
    this.renovations.interiorRenovations = items;
    this._calculateInteriorRenovationCost();
    this._calculateRenovationBudget();
  }

  updateExteriorRenovations(items: IRenovationItem[]) {
    this.renovations.exteriorRenovations = items;
    this._calculateExteriorRenovationCost();
    this._calculateRenovationBudget();
  }

  updateRenovationContingencyPercentage(percentage: number) {
    this.renovations.contingencyPercentage = percentage;
    this._calculateRenovationContingency();
    this._calculateRenovationBudget();
  }

  getTaxes(): number {
    return this.yearOneProformaAssumptions.expenseItems.filter(e => e.name === "realEstateTaxes")[0].yearOneAmount;
  }

  getInsurance(): number {
    return this.yearOneProformaAssumptions.expenseItems.filter(e => e.name === "insurance")[0].yearOneAmount;
  }

  updateReserves(): void {
    this._updateInsuranceReserves();
    this._updateTaxReserves();
  }

  getGoingInNoi(): number {
    const { rentIncomeItems, otherIncomeItems, expenseItems } = this.yearOneProformaAssumptions;
    const netRentalIncome = rentIncomeItems[0].amount - rentIncomeItems
      .map((x, index) => index > 0 ? x.amount : 0)
      .reduce((prev, curr) => prev + curr, 0);

    const netOtherIncome = otherIncomeItems
      .map(x => x.amount)
      .reduce((prev, curr) => prev + curr, 0);

    const netExpenses = expenseItems
      .map(x => x.amount)
      .reduce((prev, curr) => prev + curr, 0);

    return netRentalIncome + netOtherIncome - netExpenses - this.expenseItems.filter(x => x.name === "propertyManagementFee")[0].amount;
  }

  getGrowthAssumption(name: growthAssumptionCategory): IAssumption {
    return this.growthAssumptions.filter(g => g.name === name)[0];
  }

  getAnnualAssumption(name: annualAssumptionCategory): IAssumption {
    return this.annualAssumptions.filter(a => a.name === name)[0];
  }

  private _updateInsuranceReserves(): void {
    const insurance = this.getInsurance();
    this.reservesInsurance = {
      ...this.reservesInsurance,
      yearOneMonthlyAmount: (insurance * this.totalUnits) /12,
      yearOneTotal: insurance * this.totalUnits,
      yearOneTotalPerUnit: insurance,
    }
  }

  private _updateTaxReserves(): void {
    const taxes = this.getTaxes();
    this.reservesTaxes = {
      ...this.reservesTaxes,
      yearOneMonthlyAmount: (taxes * this.totalUnits) /12,
      yearOneTotal: taxes * this.totalUnits,
      yearOneTotalPerUnit: taxes,
    }
  }

  private _calculateUnitMixTotals(): void {
    this._calculateTotalUnits();
    this._calculateTotalRenovatingUnits();
    this._calculateTotalSqFt();
    this._calculateTotalSqFtUnit();
    this._calculateRentRollUnitMixPercentOfTotalSqFt();
    this._calculateDollarPerSqFtRent();
    this._calculateDollarPerSqFtMarketRent();
    this._calculateDollarPerSqFtRenovatedMarketRent();
    this._calculcateAverageCurrentRent();
    this._calculcateAverageMarketRent();
    this._calculcateAverageRenovatedMarketRent();
  }

  private _calculateTotalUnits(): void {
    this.totalUnits = this.rentRollUnitMix
      .map(x => x.unitCount)
      .reduce((prev, curr) => prev + curr, 0);
  }

  private _calculateTotalRenovatingUnits(): void {
    this.totalRenovatingUnits = this.rentRollUnitMix
      .map(x => x.unitsScheduledForRenovation)
      .reduce((prev, curr) => prev + curr, 0);
  }

  private _calculateTotalSqFt(): void {
    this.totalSqFt = this.rentRollUnitMix
      .map(x => x.totalSquareFeet)
      .reduce((prev, curr) => prev + curr, 0);
  }

  private _calculateTotalSqFtUnit(): void {
    const totalSqFtUnit = this.rentRollUnitMix
      .map(x => x.unitCount * x.squareFtPerUnit)
      .reduce((prev, curr) => prev + curr, 0);

    this.totalSqFtUnit = parseToFixed(totalSqFtUnit / this.totalUnits);
  }

  private _calculateRentRollUnitMixPercentOfTotalSqFt(): void {
    this.rentRollUnitMix.forEach(rentRollUnitMix => {
      rentRollUnitMix.percentOfTotalSqFt = parseToFixed(rentRollUnitMix.totalSquareFeet / this.totalSqFt, 4) * 100;
    });
  }

  private _calculateDollarPerSqFtRent(): void {
    const dollarPerSqFtRent = this.rentRollUnitMix
      .map(x => (x.percentOfTotalSqFt / 100) * x.rentDollarPerSqFt)
      .reduce((prev, curr) => prev + curr, 0);

    this.dollarPerSqFtRent = parseToFixed(dollarPerSqFtRent);
  }

  private _calculateDollarPerSqFtMarketRent(): void {
    const dollarPerSqFtMarketRent = this.rentRollUnitMix
      .map(x => (x.percentOfTotalSqFt / 100) * x.marketRentDollarPerSqFt)
      .reduce((prev, curr) => prev + curr, 0);

    this.dollarPerSqFtMarketRent = parseToFixed(dollarPerSqFtMarketRent);
  }

  private _calculateDollarPerSqFtRenovatedMarketRent(): void {
    const dollarPerSqFtRenovatedMarketRent = this.rentRollUnitMix
      .map(x => (x.percentOfTotalSqFt / 100) * x.renovatedMarketRentDollarPerSqFt)
      .reduce((prev, curr) => prev + curr, 0);

    this.dollarPerSqFtRenovatedMarketRent = parseToFixed(dollarPerSqFtRenovatedMarketRent);
  }

  private _calculcateAverageCurrentRent(): void {
    const unitRentTotal = this.rentRollUnitMix
      .map(x => x.unitCount * x.rent)
      .reduce((prev, curr) => prev + curr, 0);

    this.averageRent = parseToFixed(unitRentTotal / this.totalUnits);
  }

  private _calculcateAverageMarketRent(): void {
    const unitMarketRentTotal = this.rentRollUnitMix
      .map(x => x.unitCount * x.marketRent)
      .reduce((prev, curr) => prev + curr, 0);

    this.averageMarketRent = parseToFixed(unitMarketRentTotal / this.totalUnits);
  }

  private _calculcateAverageRenovatedMarketRent(): void {
    const unitRenovatedMarketRentTotal = this.rentRollUnitMix
      .map(x => x.unitCount * x.renovatedMarketRent)
      .reduce((prev, curr) => prev + curr, 0);

    this.averageRenovatedMarketRent = parseToFixed((unitRenovatedMarketRentTotal / this.totalUnits));
  }

  private _calculateInteriorRenovationCost(): void {
    this.renovations.interiorRenovationCost = this.renovations.interiorRenovations
      .map(renovation => renovation.cost * this.totalRenovatingUnits)
      .reduce((prev, curr) => prev + curr, 0);
  }

  private _calculateExteriorRenovationCost(): void {
    this.renovations.exteriorRenovationCost = this.renovations.exteriorRenovations
      .map(renovation => renovation.cost * this.totalUnits)
      .reduce((prev, curr) => prev + curr, 0);
  }

  private _calculateRenovationContingency(): void {
    const { interiorRenovationCost, exteriorRenovationCost, contingencyPercentage } = this.renovations;
    this.renovations.contingency = (interiorRenovationCost + exteriorRenovationCost) * (contingencyPercentage / 100);
  }

  private _calculateRenovationBudget(): void {
    const { interiorRenovationCost, exteriorRenovationCost, contingency } = this.renovations;
    this.renovations.totalBudget = interiorRenovationCost + exteriorRenovationCost + contingency;
  }
}

export default Property;
