import { notification, Select } from "antd";
import React from "react";
import { useEffect, useState } from "react";
import Modal from "..";
import userService, { IContactDetails } from "../../../api/services/user";
import { SyncOutlined } from '@ant-design/icons';
import Button from "antd-button-color";
import { Link } from 'react-router-dom'
import IAnalyzedProperty from "../../../types/analyzed-property";
import propertyService from "../../../api/services/property";
import Avatar from "../../shared/avatar";
const { Option } = Select;

type SharePropertyModalProps = {
  property: IAnalyzedProperty;
  visible: boolean;
  onDismiss(): void;
}

const SharePropertyModal: React.FC<SharePropertyModalProps> = ({ property, visible, onDismiss }) => {
  const [showSharePropertyModal, setShowSharePropertyModal] = useState<boolean>(visible);
  const [contacts, setContacts] = useState<IContactDetails[]>([]);
  const [selectedContact, setSelectedConact] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [sharing, setSharing] = useState<boolean>(false);

  const handleDismiss = () => {
    setShowSharePropertyModal(false);
    onDismiss();
  }

  useEffect(() => {
    if (!visible) {
      setSelectedConact(null);
    }
    setShowSharePropertyModal(visible);
  }, [visible]);

  useEffect(() => {
    if (visible) {
      setLoading(true);
      userService.getContacts()
        .then(res => {
          setContacts(res.data.contacts.filter(c => c.request === false));
          setLoading(false);
        })
        .catch(err => {
          console.log(err);
          setContacts([]);
          setLoading(false);
          notification.error({
            message: "An error occurred while retrieving contacts",
            duration: 5,
            placement: 'bottomRight',
          });
        });
    }
  }, [visible])

  const handleSubmit = () => {
    setSharing(true);
    propertyService.share(selectedContact!, property._id!)
      .then(() => {
        setSharing(false);
        handleDismiss();
        notification.success({
          message: "Property shared successfully",
          duration: 5,
          placement: 'bottomRight',
        });
      })
      .catch(err => {
        console.log(err);
        notification.error({
          message: "An error occured while sharing property",
          duration: 5,
          placement: 'bottomRight',
        });
      })
  }

  if (loading) {
    return null;
  }

  return (
    <Modal
      title={`Share Property "${property?.name}"`}
      visible={showSharePropertyModal}
      onDismiss={() => handleDismiss()}
    >
      {contacts.length > 0 ? (
        <>
          <h4>Contact</h4>
          <Select value={selectedContact} placeholder="Select contact" style={{ width: '100%' }} onChange={contactId => setSelectedConact(contactId)}>
            {contacts?.map(contact => <Option key={contact.userId} value={contact.userId}>
              <Avatar 
                userId={contact.userId}
                firstName={contact.firstName}
                lastName={contact.lastName}
                hideStatus={true}
                size={20}  
              />  {contact.firstName && contact.lastName ? `${contact.firstName} ${contact.lastName} (${contact.email})` : contact.email}
            </Option>)}
          </Select>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: 25 }}>
            <Button onClick={handleSubmit} type="success" disabled={!selectedContact || sharing} >
              { sharing ? <><SyncOutlined spin /> Sharing</> : 'Share' }
            </Button>
            <Button onClick={handleDismiss} type="lightdark" style={{ marginLeft: 10 }}>Cancel</Button>
          </div>
        </>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', textAlign: 'center' }}>
          <h3>You currently have no contacts.  In order to share a property, you will need to request a contact <Link to="/contacts">here</Link>.</h3>
          <Button onClick={handleDismiss} type="lightdark" style={{marginTop: 25}}>Cancel</Button>
        </div>
      )}
    </Modal>
  )
}

export default SharePropertyModal;