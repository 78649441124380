import { Table, Rate, Popconfirm, Tooltip, Button, Empty } from "antd";
import { IContactDetails } from "../../../api/services/user";
import formatPhoneNumber from "../../../utils/format-phone-number";
import Avatar from "../../shared/avatar";
import { StarFilled, DeleteOutlined } from "@ant-design/icons";

type ContactsListProps = {
  loading: boolean;
  contacts: IContactDetails[];
  onFavoriteChange(contact: IContactDetails, favorite: boolean): void;
  onRemoveContact(contact: IContactDetails): void;
};

const ContactsList: React.FC<ContactsListProps> = ({
  loading,
  contacts,
  onFavoriteChange,
  onRemoveContact
}) => {    
  return (
    <Table
      dataSource={contacts}
      style={{whiteSpace:'nowrap'}}
      bordered
      pagination={false}
      showHeader={contacts.length > 0}
      loading={loading}
      scroll={{ x: 0 }}
      locale={{
        emptyText: () => (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="No contacts found"
          />
        ),
      }}
      columns={[
        {
          key: "1",
          width: "20%",
          title: "Name",
          render: (record: IContactDetails) => (
            <div>
              <div style={{display:'flex', alignItems:'center'}}>
              <Rate
                character={<StarFilled />}
                style={{ color: "orange", marginRight: 10 }}
                count={1}
                value={record.favorite ? 1 : 0}
                tooltips={[record.favorite ? "Unfavorite" : "Favorite"]}
                onChange={(value) => onFavoriteChange(record, value > 0)}
              />
                  <Avatar
                    userId={record.userId}
                    firstName={record.firstName}
                    lastName={record.lastName}
                    avatarColor={record.avatarColor}
                  />
                  <div style={{ marginLeft: 10 }}>
                    <span style={{display:'block'}}>
                    {record.firstName + " " + record.lastName}
                    </span>
                  </div>
                </div>
            </div>
          ),
        },
        {
          key: "2",
          title: "Email",
          width: "15%",
          render: (record: IContactDetails) => (
            <a href={`mailto:${record.email}`}>{record.email}</a>
          ),
        },
        {
          key: "3",
          title: "Phone",
          width: "15%",
          render: (record: IContactDetails) => (
            <a href={`tel:${record.phoneNumber}`}>
              {formatPhoneNumber(record.phoneNumber)}
            </a>
          ),
        },
        {
          key: "4",
          title: "Location",
          width: "15%",
          render: (record: IContactDetails) => (
            <span>
              {" "}
              {record.city + (record.city ? `, ${record.state}` : null)}
            </span>
          ),
        },
        {
          key: "5",
          title: "Actions",
          width: "10%",
          render: (record: IContactDetails) => {
            return (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <Popconfirm
                  title="Are you sure you want to remove this contact?"
                  onConfirm={() => onRemoveContact(record)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Tooltip title="Remove Contact">
                    <Button
                      type="primary"
                      danger
                      style={{ marginLeft: 10 }}
                      icon={<DeleteOutlined />}
                    />
                  </Tooltip>
                </Popconfirm>
              </div>
            );
          },
        },
      ]}
    />
  );
};

export default ContactsList;
