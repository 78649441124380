import { NetCashflowWaterfallDistribution } from '../../../types/monthly-proforma/net-cashflow-waterfall-distribution';
import { CashFlowAction } from '../../actions/cash-flow';

export interface ICashFlowState {
  distribution: NetCashflowWaterfallDistribution | null; 
}

const initialState: ICashFlowState = {
  distribution: null,
};

export const cashFlowReducer = (
  state: ICashFlowState = initialState,
  action: CashFlowAction
): ICashFlowState => {
  switch (action.type) {
    case "UPDATE_CASH_FLOW_DISTRIBUTION":
      return { ...state, distribution: new NetCashflowWaterfallDistribution(action.payload.property, action.payload.loan) };
    default:
      return state;
  }
};