import { Provider } from "react-redux";
import { store } from "./store";
import ErrorBoundary from "./components/error-boundary";
import { PusherProvider } from "./providers/pusher-provider";
import TourProvider from "./providers/tour-provider";
import RoutesProvider from "./providers/route-provider";

function App() {
  return (
    <ErrorBoundary>
      <Provider store={store}>
        <PusherProvider>
          <TourProvider>
            <RoutesProvider />
          </TourProvider>
        </PusherProvider>
      </Provider>
    </ErrorBoundary>
  );
}

export default App;
