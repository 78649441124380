import { Col, notification, Row } from "antd";
import { useEffect, useRef, useState } from "react";
import userService, { IContactDetails } from "../../api/services/user";
import AddContactModal from "../modals/add-contact";
import InviteUsersModal from "../modals/invite-users";
import ContactOptions from "./options";
import Favorites from "./favorites";
import ContactsList from "./list";
import ContactRequests from "./requests";

const Contacts = () => {
  const avatarColors = ["#ef476f", "#ffd166", "#06d6a0", "#118ab2", "#073b4c"];
  const [favorites, setFavorites] = useState<IContactDetails[]>([]);
  const [contacts, setContacts] = useState<IContactDetails[]>([]);
  const [requests, setRequests] = useState<IContactDetails[]>([]);
  const [addContactModalVisible, setAddContactModalVisible] =
    useState<boolean>(false);
  const [inviteUsersModalVisible, setInviteUsersModalVisible] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const contactsRef = useRef<IContactDetails[]>([]);
  const requestsRef = useRef<IContactDetails[]>([]);

  const getContacts = () => {
    setLoading(true);
    userService
      .getContacts()
      .then((res) => {
        if (res.data.contacts) {
          res.data.contacts.forEach(
            (c) =>
              (c.avatarColor =
                avatarColors[Math.floor(Math.random() * (4 - 0)) + 0])
          );
          setContacts(res.data.contacts.filter(c => c.request === false));
          setRequests(res.data.contacts.filter(c => c.request === true));
          localStorage.setItem(
            "contact-request-count",
            res.data.contacts.filter(c => c.request === true).length.toString()
          );
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setContacts([]);
        setRequests([]);
        setLoading(false);
      });
  };

  useEffect(() => {
    getContacts();
  }, []);

  const handleContactResponse = (
    contact: IContactDetails,
    accepted: boolean
  ) => {
    setLoading(true);
    userService
      .processContactRequest({
        userId: contact.userId,
        accepted,
      })
      .then(() => {
        setLoading(false);
        const requestIndex = requests.findIndex((r) => r.userId === contact.userId);
        setRequests((requests) =>
          requests.filter((_, index) => index !== requestIndex)
        );
        if (accepted) {
          setContacts((contacts) => [...contacts, contact]);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        notification.error({
          message: "An error occurred while responding to contact request",
          duration: 5,
          placement: "bottomRight",
        });
      });
  };

  const handleFavoriteContact = (
    contact: IContactDetails,
    favorite: boolean
  ) => {
    const contactIndex = contacts.findIndex((c) => c.userId === contact.userId);
    const newContacts = [...contacts];
    newContacts[contactIndex].favorite = favorite;
    setContacts(newContacts);
    userService.updateContact(newContacts[contactIndex])
    .catch((err) => {
      console.log(err);
      notification.error({
        message: "An error occurred while updating contact",
        duration: 5,
        placement: "bottomRight",
      });
    });
  };

  const handleRemoveContact = (contact: IContactDetails) => {
    setLoading(true);
    userService
      .removeContact(contact.userId)
      .then(() => {
        setLoading(false);
        setContacts((contacts) =>
          contacts.filter((c) => c.userId !== contact.userId)
        );
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        notification.error({
          message: "An error occurred while removing contact",
          duration: 5,
          placement: "bottomRight",
        });
      });
  };

  useEffect(() => {
    if (contacts) {
      setFavorites(contacts.filter((c) => c.favorite));
      contactsRef.current = contacts;
    }

    if (requests) {
      requestsRef.current = requests;
    }
  }, [contacts, requests]);

  return (
    <>
      <Row gutter={24}>
        {requests.length > 0 && (
          <Col span={24} style={{ marginBottom: 50 }}>
            <ContactOptions
              onAddContactModalVisible={setAddContactModalVisible}
              onInviteUsersModalVisible={setInviteUsersModalVisible}
              title="Contact Requests"
              showButtons
            />
            <ContactRequests
              contacts={requests}
              loading={loading}
              onAccept={(contact) => handleContactResponse(contact, true)}
              onDecline={(contact) => handleContactResponse(contact, false)}
            />
          </Col>
        )}
        {favorites.length > 0 && (
          <Col span={24} style={{ marginBottom: 50 }}>
            <ContactOptions
              onAddContactModalVisible={setAddContactModalVisible}
              onInviteUsersModalVisible={setInviteUsersModalVisible}
              title="Favorites"
              showButtons={requests.length === 0}
            />
            <Row gutter={24}>
              <Favorites
                contacts={favorites}
                onUnfavoriteContact={(contact) =>
                  handleFavoriteContact(contact, false)
                }
              />
            </Row>
          </Col>
        )}
        <Col span={24}>
          <ContactOptions
            onAddContactModalVisible={setAddContactModalVisible}
            onInviteUsersModalVisible={setInviteUsersModalVisible}
            title="Contacts"
            showButtons={favorites.length === 0 && requests.length === 0}
          />
          <ContactsList
            loading={loading}
            contacts={contacts}
            onFavoriteChange={(contact, favorite) =>
              handleFavoriteContact(contact, favorite)
            }
            onRemoveContact={(contact) => handleRemoveContact(contact)}
          />
        </Col>
      </Row>
      <AddContactModal
        visible={addContactModalVisible}
        onDismiss={() => setAddContactModalVisible(false)}
      />
      <InviteUsersModal
        visible={inviteUsersModalVisible}
        onDismiss={() => setInviteUsersModalVisible(false)}
      />
    </>
  );
};

export default Contacts;
