import { Col, DatePicker, Input, Table, Tabs } from "antd";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { IInvestmentTimeline } from "../../../../types/property";
import PromoteIncentiveHurdles from "./promote-incentive-hurdles";
const { TabPane } = Tabs;

const InvestmentTimeline = () => {
  const dispatch = useAppDispatch();
  const {
    property: {
      rentRollUnitMix,
      investmentTimeline: {
        acquisitionDate,
        refinanceDate,
        exitDispositionDate,
        holdPeriodYears,
        exitMonth,
        distributionStartDate,
        renovationStartMonth,
        renovationEndMonth,
      },
    },
    loan: {
      loanAssumptions: { date: originalLoanDate },
      refinancingPermanentLoanAssumptions: {
        refinancing,
        date: refinanceLoanDate,
      },
    },
  } = useAppSelector((state) => {
    return {
      property: state.propertyReducer.property,
      loan: state.loanReducer.loan,
    };
  });

  const getRenovationDates = () => {
    let renovationStartMonth = 0;
    let renovationEndMonth = 0;
    if (rentRollUnitMix.some((x) => x.renovationStartMonth > 0)) {
      renovationStartMonth = rentRollUnitMix
        .filter((x) => x.renovationStartMonth > 0)
        .reduce((prev, curr) => {
          return prev.renovationStartMonth < curr.renovationStartMonth
            ? prev
            : curr;
        }).renovationStartMonth;

      renovationEndMonth = rentRollUnitMix
        .filter((x) => x.renovationEndMonth > 0)
        .reduce((prev, curr) => {
          return prev.renovationEndMonth < curr.renovationEndMonth
            ? curr
            : prev;
        }).renovationEndMonth;
    }

    return {
      start: renovationStartMonth || 0,
      end: renovationEndMonth || 0,
    };
  };

  const [form, setForm] = useState<IInvestmentTimeline>({
    acquisitionDate: acquisitionDate || originalLoanDate,
    refinanceDate: refinanceDate || refinancing ? refinanceLoanDate : null,
    exitDispositionDate: exitDispositionDate || null,
    holdPeriodYears: holdPeriodYears || 0,
    exitMonth: exitMonth || 0,
    distributionStartDate: distributionStartDate || 0,
    renovationStartMonth: renovationStartMonth || getRenovationDates().start,
    renovationEndMonth: renovationEndMonth || getRenovationDates().end,
  });
  const formRef = useRef<IInvestmentTimeline | undefined>();

  const handleFormChange = (field: string, value: any) => {
    setForm((form) => ({ ...form, [field]: value }));
  };

  useEffect(() => {
    let newForm = { ...form };
    const { acquisitionDate, exitMonth } = newForm;
    newForm.exitDispositionDate = moment(acquisitionDate)
      .add(exitMonth, "M")
      .endOf("M")
      .toDate();
    newForm.holdPeriodYears =
      moment(newForm.exitDispositionDate).diff(acquisitionDate, "years") || 0;
    setForm(newForm);
  }, [form.exitMonth]);

  useEffect(() => {
    let renovationStartMonth = 0;
    let renovationEndMonth = 0;
    if (rentRollUnitMix.some((x) => x.renovationStartMonth > 0)) {
      renovationStartMonth = rentRollUnitMix
        .filter((x) => x.renovationStartMonth > 0)
        .reduce((prev, curr) => {
          return prev.renovationStartMonth < curr.renovationStartMonth
            ? prev
            : curr;
        }).renovationStartMonth;

      renovationEndMonth = rentRollUnitMix
        .filter((x) => x.renovationEndMonth > 0)
        .reduce((prev, curr) => {
          return prev.renovationEndMonth < curr.renovationEndMonth
            ? curr
            : prev;
        }).renovationEndMonth;
    }

    setForm((form) => ({ ...form, renovationStartMonth, renovationEndMonth }));
  }, [rentRollUnitMix]);

  useEffect(() => {
    formRef.current = form;
  }, [form]);

  const columns = [
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: "70%",
    },
    {
      dataIndex: "value",
      key: "value",
      width: "30%",
    },
  ];

  const dataSource = [
    {
      key: "1",
      description: "Acquisition",
      value: (
        <DatePicker
          value={moment(form.acquisitionDate)}
          placeholder="Date"
          format={'MM/DD/YYYY'}
          style={{ width: "100%" }}
          onChange={(e) => handleFormChange("acquisitionDate", e?.toDate())}
        />
      ),
    },
    {
      key: "2",
      description: "Refinancing (if applicable)",
      value: refinancing ? (
        <span style={{ float: "right" }}>
          {moment(form.refinanceDate).endOf("M").format("MM/DD/YYYY")}
        </span>
      ) : null,
    },
    {
      key: "5",
      description: "Exit Month",
      value: (
        <Input
          style={{ minWidth: 100 }}
          type="number"
          value={form.exitMonth}
          step={1}
          min={0}
          onChange={(e) =>
            handleFormChange("exitMonth", parseFloat(e.target.value) || 0)
          }
        />
      ),
    },
    {
      key: "3",
      description: "Exit/Disposition",
      value: (
        <span style={{ float: "right" }}>
          {moment(form.exitDispositionDate).format("MM/DD/YYYY")}
        </span>
      ),
    },
    {
      key: "4",
      description: "Hold Period (years)",
      value: <span style={{ float: "right" }}>{form.holdPeriodYears}</span>,
    },
    {
      key: "6",
      description: "Distribution Start Month",
      value: (
        <Input
          style={{ minWidth: 100 }}
          type="number"
          value={form.distributionStartDate}
          step={1}
          min={0}
          onChange={(e) =>
            handleFormChange(
              "distributionStartDate",
              parseFloat(e.target.value) || 0
            )
          }
        />
      ),
    },
    {
      key: "7",
      description: "Renovation Start Month",
      value: (
        <span style={{ float: "right" }}>
          Month {form.renovationStartMonth}
        </span>
      ),
    },
    {
      key: "8",
      description: "Renovation End Month",
      value: (
        <span style={{ float: "right" }}>Month {form.renovationEndMonth}</span>
      ),
    },
  ];

  const dispatchUpdate = async () => {
    await dispatch({
      type: "UPDATE_PROPERTY_INVESTMENT_TIMELINE",
      payload: formRef.current!,
    });
  };

  useEffect(() => {
    return () => {
      dispatchUpdate();
    };
  }, []);

  return (
    <>
      <Col
        xs={24}
        sm={24}
        md={12}
        lg={12}
        xl={10}
        xxl={10}
        style={{ paddingLeft: 0, marginTop: -15 }}
      >
        <Tabs defaultActiveKey="1" onTabClick={() => dispatchUpdate()}>
          <TabPane tab="Overview" key={1}>
            <Table
              dataSource={dataSource}
              columns={columns}
              showHeader={false}
              pagination={false}
              bordered
              scroll={{ x: true }}
              style={{ whiteSpace: "nowrap" }}
            />
          </TabPane>
          <TabPane tab="Promote / Incentive Hurdles" key={3}>
            <PromoteIncentiveHurdles />
          </TabPane>
        </Tabs>
      </Col>
    </>
  );
};
export default InvestmentTimeline;
