import { Badge, Avatar as AntdAvatar } from "antd";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { IContactDetails } from "../../../api/services/user";
import { IOnlineUser, usePusher } from "../../../providers/pusher-provider";
import { getStatusByStatus } from "../../../store/reducers/user/status";

type Props = {
  userId: string;
  firstName: string;
  lastName: string;
  avatarColor?: string;
  size?: number;
  hideStatus?: boolean;
};

const StyledAvatar = styled(AntdAvatar)`
  & > img {
    display: inline-block;
  }
`;

const Avatar: React.FC<Props> = ({
  userId,
  firstName,
  lastName,
  avatarColor,
  size = 60,
  hideStatus = false,
}) => {
  const { onlineUsers } = usePusher();
  const [error, setError] = useState<boolean>(false);
  const [userStatus, setUserStatus] = useState<IOnlineUser>({
    id: userId,
    status: "Inactive",
  });

  useEffect(() => {
    if (onlineUsers) {
      const user = onlineUsers.find((o) => o.id === userId);
      if (user) {
        setUserStatus(user);
      } else {
        setUserStatus({
          id: userId,
          status: "Inactive",
        });
      }
    }
  }, [onlineUsers]);

  if (error) {
    return (
      <Badge
        className="active-indicator"
        //@ts-ignore
        color={getStatusByStatus(userStatus?.status).color}
        style={{
          height: 15,
          width: 15,
          display: hideStatus ? "none" : "inherit",
        }}
        offset={[-7, 50]}
        dot
      >
        <StyledAvatar
          className="avatar"
          size={size}
          style={{
            backgroundColor: avatarColor,
          }}
        >
          <span
            style={{
              fontSize: size / 2,
              textShadow:
                "-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black",
            }}
          >
            {firstName.substring(0, 1) + lastName.substring(0, 1)}
          </span>
        </StyledAvatar>
      </Badge>
    );
  }

  return (
    <Badge
      className="active-indicator"
      //@ts-ignore
      color={getStatusByStatus(userStatus?.status).color}
      //@ts-ignore
      title={getStatusByStatus(userStatus?.status).status}
      style={{
        height: 15,
        width: 15,
        cursor: "pointer",
        display: hideStatus ? "none" : "inherit",
      }}
      offset={[-7, 50]}
      dot
    >
      <StyledAvatar
        size={size}
        onError={() => {
          setError(true);
          return true;
        }}
        src={`${process.env.REACT_APP_S3_ROOT}/user/profile/${userId}/profile`}
      />
    </Badge>
  );
};

export default Avatar;
