import ILocation from '../../../types/location';
import http from '../../http';

const getRoute = (path?: string) => {
  const root = '/location'
  if (!path) return root;
  return root + '/' + path;
}

const locationService = {
  get: (zipCode: number) => http.get<{ location: ILocation}>(getRoute(`${zipCode}`)),
}

export default locationService;