import { Table } from "antd";
import { useEffect, useState } from "react";
import Modal from "..";
import { sitewideShortcuts, propertyAnalyzerShortcuts, IShortcut } from "./constants";

type Props = {
  visible: boolean;
  onDismiss(): void;
};

const ShortcutsModal: React.FC<Props> = ({ visible, onDismiss }) => {
  const [shortcutsVisible, setShortcutsVisible] = useState<boolean>(visible);

  const handleDismissDisclaimer = () => {
    setShortcutsVisible(false);
    onDismiss();
  };

  useEffect(() => {
    setShortcutsVisible(visible);
  }, [visible]);

  return (
    <Modal
      title="Keyboard shortcuts"
      visible={shortcutsVisible}
      onDismiss={() => handleDismissDisclaimer()}
      width={800}
      centered={false}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          width: "100%",
          gap: 30,
        }}
      >
        <ShortcutsTable
          title="Site-wide shortcuts"
          shortcuts={sitewideShortcuts}
        />
        <ShortcutsTable
          title="Property analyzer"
          shortcuts={propertyAnalyzerShortcuts}
        />
      </div>
    </Modal>
  );
};

export default ShortcutsModal;

type ShortcutsTableProps = {
  title: string;
  shortcuts: IShortcut[];
};

const ShortcutsTable: React.FC<ShortcutsTableProps> = ({
  title,
  shortcuts,
}) => {
  return (
    <Table
      title={() => title}
      pagination={false}
      showHeader={false}
      size="small"
      style={{
        display: "flex",
        flexDirection: "column",
        flexBasis: "100%",
        flex: 1,
      }}
      columns={[
        {
          dataIndex: "shortcut",
          render: (value: { description: string; keys: JSX.Element[] }) => {
            return (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                {value.description}
                <div>
                  {value.keys.map((key, index) => {
                    if (index > 0) {
                      return (
                        <>
                          <span style={{marginLeft: 3, marginRight: 3}}>+</span>{key}
                        </>
                      );
                    }
                    return key;
                  })}
                </div>
              </div>
            );
          },
        },
      ]}
      dataSource={shortcuts}
      bordered
    />
  );
};
