import { useEffect, useState } from "react";
import { Comment, Tooltip, Table, Badge } from "antd";
import moment from "moment";
import { useAppSelector } from "../../store";
import issueService from "../../api/services/issue";
import IIssue from "../../types/issue";
import Avatar from "../shared/avatar";

type ReportedIssuesProps = {
  newIssueCount: number;
};

const ReportedIssues: React.FC<ReportedIssuesProps> = ({ newIssueCount }) => {
  const { current } = useAppSelector((state) => state.userReducer);
  const [issuesReported, setIssuesReported] = useState<IIssue[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    issueService
      .get(current?._id!)
      .then((res) => {
        setIssuesReported(
          res.data.issues.sort(
            (a, b) =>
              new Date(b.createdAt!).getTime() -
              new Date(a.createdAt!).getTime()
          )
        );
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [newIssueCount]);

  const StatusBadge = ({ issue }: { issue: IIssue }) => {
    let status = "";
    let color = "white";
    let backgroundColor = "";
    switch (issue.status) {
      case "open":
        status = "Open";
        color = "black";
        backgroundColor = "white";
        break;
      case "queued":
        status = "Queued";
        backgroundColor = "blue";
        break;
      case "inProgress":
        status = "In Progress";
        backgroundColor = "orange";
        break;
      case "completed":
        status = "Completed";
        backgroundColor = "green";
        break;
      case "closed":
        status = "Closed";
        backgroundColor = "red";
        break;
      default:
        break;
    }

    return (
      <Badge
        size="small"
        count={status}
        style={{ backgroundColor, color, marginLeft: 10 }}
      />
    );
  };

  return (
    <Table
      dataSource={issuesReported}
      style={{ padding: "0px 10px" }}
      showHeader={false}
      bordered={false}
      loading={loading}
      className="feature-request-table"
      pagination={{
        pageSize: 5,
      }}
    >
      <Table.Column
        render={(record: IIssue) => {
          return (
            <Comment
              author={record.firstName + " " + record.lastName}
              avatar={
                <Avatar
                  userId={record.userId}
                  firstName={record.firstName}
                  lastName={record.lastName}
                  hideStatus
                  size={40}
                />
              }
              content={
                <>
                  <div>
                    <h3>{record.subject}</h3>
                  </div>
                  <p>{record.description}</p>
                </>
              }
              datetime={
                <>
                  <Tooltip
                    title={moment(record.createdAt).format(
                      "MM/DD/YYYY @ hh:mm a"
                    )}
                  >
                    <span>{'reported ' + moment().to(record.createdAt)}</span>
                  </Tooltip>
                  <StatusBadge issue={record} />
                </>
              }
            >
              {record.response ? (
                <Comment
                  author={record.response.firstName + ' '+  record.response.lastName }
                  avatar={
                    <Avatar
                      userId={record.response.userId}
                      firstName={record.firstName}
                      lastName={record.lastName}
                      hideStatus
                      size={40}
                    />
                  }
                  content={record.response.text}
                  datetime={
                    <>
                      <Tooltip
                        title={moment(record.response.date).format(
                          "MM/DD/YYYY @ hh:mm a"
                        )}
                      >
                        <span>{'responded ' + moment().to(record.response.date)}</span>
                      </Tooltip>
                    </>
                  }
                ></Comment>
              ) : null}
            </Comment>
          );
        }}
      />
    </Table>
  );
};

export default ReportedIssues;
