import styled from "styled-components";

const StyledKbd = styled.kbd`
  -moz-border-radius: 3px;
  -moz-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2), 0 0 0 2px #fff inset;
  -webkit-border-radius: 3px;
  -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2), 0 0 0 2px #fff inset;
  background-color: #f7f7f7;
  border: 1px solid darkslategrey;
  border-radius: 3px;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2), 0 0 0 2px #fff inset;
  color: #333;
  display: inline-block;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 11px;
  line-height: 1.4;
  margin: 0 0.1em;
  padding: 0.1em 0.6em;
  text-shadow: 0 1px 0 #fff;
  width: 21px;
  text-align: center;
`;

export interface IShortcut {
  shortcut: {
    description: string;
    keys: JSX.Element[];
  };
}

const sitewideShortcuts: IShortcut[] = [
  {
    shortcut: {
      description: "Create new property",
      keys: [<StyledKbd>n</StyledKbd>],
    },
  },
  {
    shortcut: {
      description: "Go to Properties",
      keys: [<StyledKbd>g</StyledKbd>, <StyledKbd>p</StyledKbd>],
    },
  },
  {
    shortcut: {
      description: "Go to Contacts",
      keys: [<StyledKbd>g</StyledKbd>, <StyledKbd>c</StyledKbd>],
    },
  },
  {
    shortcut: {
      description: "Go to Issues",
      keys: [<StyledKbd>g</StyledKbd>, <StyledKbd>i</StyledKbd>],
    },
  },
  {
    shortcut: {
      description: "Go to Features",
      keys: [<StyledKbd>g</StyledKbd>, <StyledKbd>f</StyledKbd>],
    },
  },
  {
    shortcut: {
      description: "Bring up this help dialog",
      keys: [<StyledKbd>?</StyledKbd>],
    },
  },
];

const propertyAnalyzerShortcuts: IShortcut[] = [
  {
    shortcut: {
      description: "Save",
      keys: [<StyledKbd>s</StyledKbd>],
    },
  },
  {
    shortcut: {
      description: "Go to previous step",
      keys: [<StyledKbd>z</StyledKbd>],
    },
  },
  {
    shortcut: {
      description: "Go to next step",
      keys: [<StyledKbd>x</StyledKbd>],
    },
  },
  {
    shortcut: {
      description: "Go to Property Overview",
      keys: [<StyledKbd>p</StyledKbd>],
    },
  },
  {
    shortcut: {
      description: "Go to Investment Summary",
      keys: [<StyledKbd>i</StyledKbd>],
    },
  },
];

export { StyledKbd, sitewideShortcuts, propertyAnalyzerShortcuts };
