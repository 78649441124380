import s3Service from "../../api/services/s3";

export interface IContact {
  _id?: string;
  userId: string;
  email: string;
  firstName: string;
  lastName: string;
  favorite: boolean;
}

interface IUser {
  _id?: string;
  auth0Id: string;
  firstName: string;
  lastName: string;
  email: string;
  verified: boolean;
  phoneNumber: number;
  city: string;
  state: string;
  zipCode: number;
  memberSince: Date;
  profileImageName: string;
  theme?: "day" | "night";
  avatarUrl: string | undefined;
  contacts?: IContact[];
  contactRequests?: IContact[];
  avatarColor?: string;
  admin: boolean;
}

export class User implements IUser {
  _id: string | undefined = undefined;
  auth0Id = "";
  firstName = "";
  lastName = "";
  email = "";
  verified = false;
  phoneNumber = 0;
  city = "";
  state = "";
  zipCode = 0;
  memberSince = new Date();
  profileImageName = "";
  theme: "day" | "night" | undefined = undefined;
  avatarUrl: string | undefined = undefined;
  contacts?: IContact[] | undefined = [];
  contactRequests?: IContact[] | undefined = [];
  avatarColor?: string | undefined = "#000000";
  admin: boolean = false;

  constructor(user?: IUser) {
    if (user) {
      this._id = user._id;
      this.auth0Id = user.auth0Id;
      this.firstName = user.firstName;
      this.lastName = user.lastName;
      this.email = user.email;
      this.verified = user.verified;
      this.phoneNumber = user.phoneNumber;
      this.city = user.city;
      this.state = user.state;
      this.zipCode = user.zipCode;
      this.memberSince = user.memberSince;
      this.profileImageName = user.profileImageName;
      this.theme = user.theme;
      this.contacts = user.contacts;
      this.contactRequests = user.contactRequests;
      this.avatarColor = user.avatarColor;
      this.admin = user.admin;
    }
  }

  get fullName(): string {
    const firstName = this.firstName || "";
    const lastName = this.lastName || "";
    return firstName + " " + lastName;
  }

  get displayName(): string {
    if (this.firstName || this.lastName) {
      return (this.firstName + ' ' + this.lastName).replace('  ', ' ');
    }

    return this.email;
  }

  get initials(): string {
    if (this.firstName || this.lastName) {
      return (this.firstName.substring(0,1) + this.lastName.substring(0,1)).replace(' ', '').toUpperCase();
    }

    return this.email.substring(0,1).toUpperCase();
  }

  getAvatarFromS3 = async (): Promise<void> => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        s3Service.download(`user/profile/${this._id!}/profile`)
          .then((res) => {
            if (res.data.files) {
              this.avatarUrl = res.data.files[0]?.url;
              resolve();
            }
          })
          .catch(err => {
            console.log(err);
            this.avatarUrl = undefined;
            reject();
          });
      }, 1000);
      })
  }
}

export default IUser;