import { Assumption } from "../../../../types/assumptions";
import Property from "../../../../types/property";

let gprGrowth = new Assumption("Gross Potential Rent", "grossPotentialRent", "growth");
gprGrowth = {
  ...gprGrowth,
  year1: 0,
  year2: 0,
  year3: 0,
  year4: 0,
  year5: 0,
  year6: 0,
  year7: 0,
  year8: 0,
  year9: 0,
  year10: 0,
}

export const generateEmptyProperty = (): Property => {
  let property = new Property();
  property.name = "";
  property.streetAddress = "";
  property.city = "";
  property.state = "";
  property.zipCode = 0;
  property.county = ""
  property.yearBuilt = 0;
  property.type = ""
  property.buildingClass = "";
  property.grossPotentialRent =  0;

  property.growthAssumptions[0] = {
    ...property.growthAssumptions[0],
    fixed: true,
    year1: 0,
    year2: 0,
    year3: 0,
    year4: 0,
    year5: 0,
    year6: 0,
    year7: 0,
    year8: 0,
    year9: 0,
    year10: 0,
  }
  property.growthAssumptions[1] = {
    ...property.growthAssumptions[1],
    fixed: true,
    year1: 0,
    year2: 0,
    year3: 0,
    year4: 0,
    year5: 0,
    year6: 0,
    year7: 0,
    year8: 0,
    year9: 0,
    year10: 0,
  }
  property.growthAssumptions[2] = {
    ...property.growthAssumptions[2],
    fixed: true,
    year1: 0,
    year2: 0,
    year3: 0,
    year4: 0,
    year5: 0,
    year6: 0,
    year7: 0,
    year8: 0,
    year9: 0,
    year10: 0,
  }
  property.growthAssumptions[3] = {
    ...property.growthAssumptions[3],
    fixed: true,
    year1: 0,
    year2: 0,
    year3: 0,
    year4: 0,
    year5: 0,
    year6: 0,
    year7: 0,
    year8: 0,
    year9: 0,
    year10: 0,
  }

  property.annualAssumptions[0] = {
    ...property.annualAssumptions[0],
    fixed: true,
    year1: 0,
    year2: 0,
    year3: 0,
    year4: 0,
    year5: 0,
    year6: 0,
    year7: 0,
    year8: 0,
    year9: 0,
    year10: 0,
  }

  property.annualAssumptions[1] = {
    ...property.annualAssumptions[1],
    fixed: true,
    year1: 0,
    year2: 0,
    year3: 0,
    year4: 0,
    year5: 0,
    year6: 0,
    year7: 0,
    year8: 0,
    year9: 0,
    year10: 0,
  }


  property.annualAssumptions[2] = {
    ...property.annualAssumptions[2],
    fixed: true,
    year1: 0,
    year2: 0,
    year3: 0,
    year4: 0,
    year5: 0,
    year6: 0,
    year7: 0,
    year8: 0,
    year9: 0,
    year10: 0,
  }

  property.annualAssumptions[3] = {
    ...property.annualAssumptions[3],
    fixed: true,
    year1: 0,
    year2: 0,
    year3: 0,
    year4: 0,
    year5: 0,
    year6: 0,
    year7: 0,
    year8: 0,
    year9: 0,
    year10: 0,
  }

  property.annualAssumptions[4] = {
    ...property.annualAssumptions[4],
    fixed: true,
    year1: 0,
    year2: 0,
    year3: 0,
    year4: 0,
    year5: 0,
    year6: 0,
    year7: 0,
    year8: 0,
    year9: 0,
    year10: 0,
  }

  property.annualAssumptions[5] = {
    ...property.annualAssumptions[5],
    fixed: true,
    year1: 0,
    year2: 0,
    year3: 0,
    year4: 0,
    year5: 0,
    year6: 0,
    year7: 0,
    year8: 0,
    year9: 0,
    year10: 0,
  }

  property.annualAssumptions[6] = {
    ...property.annualAssumptions[6],
    fixed: true,
    year1: 0,
    year2: 0,
    year3: 0,
    year4: 0,
    year5: 0,
    year6: 0,
    year7: 0,
    year8: 0,
    year9: 0,
    year10: 0,
  }

  property.updateRentRollUnitMix([]);
  
 return property;
};

export default generateEmptyProperty;
