import { IMonthlyProforma } from "../../../../types/monthly-proforma";

interface IDataSource {
  title: string | JSX.Element | null;
  type: ProformaType;
  name:
    | AcquisitionType
    | RentalRevenueType
    | OtherIncomeType
    | EffectiveGrossIncomeType
    | ControllableExpenseType
    | NonControllableExpenseType
    | ExpenseTotalType
    | NetOperatingIncomeType
    | AssetManagementFeeType
    | OriginalAcquisitionLoanType
    | RefinanceLoanType
    | DscrType
    | NetCashFlowAfterDebtServiceType
    | DispositionType
    | UnleveredCashFlowType
    | LeveredCashFlowType
    | LpInvestorCashFlowType
    | GpCashFlowType
    | HypotheticalCashFlowType
    | null;
  titleOnly?: boolean;
}

export type ProformaType =
  | "acquisitionCost"
  | "rentalRevenue"
  | "otherIncome"
  | "effectiveGrossIncome"
  | "controllableExpense"
  | "nonControllableExpense"
  | "expenseTotal"
  | "netOperatingIncome"
  | "assetManagementFee"
  | "originalAcquisitionLoan"
  | "refinanceLoan"
  | "dscr"
  | "netCashFlowAfterDebtService"
  | "disposition"
  | "unleveredCashFlow"
  | "leveredCashFlow"
  | "lpInvestorCashFlow"
  | "gpCashFlow"
  | "hypotheticalCashFlow";

export type AcquisitionType =
  | "purchasePrice"
  | "acquisitionFee"
  | "transferTaxes"
  | "dueDilligence"
  | "legalFees"
  | "capExRenovation"
  | "reserves"
  | "otherClosingCosts"
  | "reTaxReserves"
  | "insuranceReserves"
  | "debtServices"
  | "total";
export interface IAcquisitionDataSource extends IDataSource {
  name: AcquisitionType | null;
}

export type RentalRevenueType =
  | "grossPotentialRent"
  | "vacancyLoss"
  | "lossToLease"
  | "rentConcessions"
  | "creditLossBadDebt"
  | "nonRevenueUnits"
  | "total";
export interface IRentalRevenueDataSource extends IDataSource {
  name: RentalRevenueType | null;
}

export type OtherIncomeType =
  | "rubs"
  | "laundryVendingIncome"
  | "placeholderOtherIncome"
  | "feeIncome"
  | "other"
  | "total";
export interface IOtherIncomeDataSource extends IDataSource {
  name: OtherIncomeType | null;
}

export type EffectiveGrossIncomeType = "total";
export interface IEffectiveGrossIncomeType extends IDataSource {
  name: EffectiveGrossIncomeType | null;
}

export type ControllableExpenseType =
  | "propertyManagementFee"
  | "generalAndAdministration"
  | "contractServices"
  | "marketing"
  | "payroll"
  | "repairsAndMaintenance"
  | "turnoverCosts"
  | "cableInternet"
  | "total";
export interface IControllableExpenseType extends IDataSource {
  name: ControllableExpenseType | null;
}

export type NonControllableExpenseType =
  | "insurance"
  | "realEstateTaxes"
  | "utilities"
  | "replacementReserves"
  | "total";
export interface INonControllableExpenseType extends IDataSource {
  name: NonControllableExpenseType | null;
}

export type ExpenseTotalType = "total" | "ratio";
export interface IExpenseTotalType extends IDataSource {
  name: ExpenseTotalType | null;
}

export type NetOperatingIncomeType = "total";
export interface INetOperatingIncomeType extends IDataSource {
  name: NetOperatingIncomeType | null;
}

export type AssetManagementFeeType = "assetManagementFee";
export interface IAssetManagementFeeType extends IDataSource {
  name: AssetManagementFeeType | null;
}

export type OriginalAcquisitionLoanType =
  | "proceeds"
  | "loanFees"
  | "interestExpense"
  | "principalRepayment"
  | "totalDebtService"
  | "outstandingLoanBalance"
  | "loanBalanceRepayment"
  | "prepaymentPenalty";
export interface IOriginalAcquisitionLoanType extends IDataSource {
  name: OriginalAcquisitionLoanType | null;
}

export type RefinanceLoanType =
  | "proceeds"
  | "loanFees"
  | "interestExpense"
  | "principalRepayment"
  | "totalDebtService"
  | "outstandingLoanBalance"
  | "loanBalanceRepayment";
export interface IRefinanceLoanType extends IDataSource {
  name: RefinanceLoanType | null;
}

export type DscrType = "dscr";
export interface IDscrType extends IDataSource {
  name: DscrType | null;
}

export type NetCashFlowAfterDebtServiceType = "netCashFlowAfterDebtService";
export interface INetCashFlowAfterDebtServiceType extends IDataSource {
  name: NetCashFlowAfterDebtServiceType | null;
}

export type DispositionType =
  | "grossSaleProceeds"
  | "dispositionFee"
  | "saleClosingCosts"
  | "loanRepaymentAtSale"
  | "netProceedsFromSale";
export interface IDispositionType extends IDataSource {
  name: DispositionType | null;
}

export type UnleveredCashFlowType = "unleveredCashFlow";
export interface IUnleveredCashFlowType extends IDataSource {
  name: UnleveredCashFlowType | null;
}

export type LeveredCashFlowType = "leveredCashFlow";
export interface ILeveredCashFlowType extends IDataSource {
  name: LeveredCashFlowType | null;
}

export type LpInvestorCashFlowType = "lpInvestorCashFlow";
export interface ILpInvestorCashFlowType extends IDataSource {
  name: LpInvestorCashFlowType | null;
}

export type GpCashFlowType = "gpCashFlow";
export interface IGpCashFlowType extends IDataSource {
  name: GpCashFlowType | null;
}

export type HypotheticalCashFlowType = "hypotheticalCashFlow";
export interface IHypotheticalCashFlowType extends IDataSource {
  name: HypotheticalCashFlowType | null;
}

export const getYearlyProforma = (proforma: IMonthlyProforma[]) => {
  const years = new Set(proforma.map((p) => p.year));
  let yearData: IAnnualProforma[] = [];
  years.forEach((year) => {
    const months = proforma.filter((p) => p.year === year);
    let totals: IAnnualProforma = {
      year,
      acquisitionCosts: {
        purchasePrice: 0,
        acquisitionFee: 0,
        transferTaxes: 0,
        dueDilligence: 0,
        legalFees: 0,
        capExRenovation: 0,
        reserves: 0,
        otherClosingCosts: 0,
        reTaxReserves: 0,
        insuranceReserves: 0,
        debtServiceReservesPandI: 0,
        total: 0,
      },
      rentalRevenue: {
        grossPotentialRent: 0,
        vacancyLoss: 0,
        lossToLease: 0,
        rentConcessions: 0,
        creditLossBadDebt: 0,
        nonRevenueUnits: 0,
        total: 0,
      },
      otherIncome: {
        rubs: 0,
        laundryVendingIncome: 0,
        placeholderOtherIncome: 0,
        feeIncome: 0,
        other: 0,
        total: 0,
      },
      effectiveGrossIncomeTotal: 0,
      expenses: {
        controllable: {
          propertyManagementFee: 0,
          generalAndAdministration: 0,
          contractServices: 0,
          marketing: 0,
          payroll: 0,
          repairsAndMaintenance: 0,
          turnoverCosts: 0,
          cableInternet: 0,
          total: 0,
        },
        nonControllable: {
          insurance: 0,
          realEstateTaxes: 0,
          utilities: 0,
          replacementReserves: 0,
          total: 0,
        },
        total: 0,
        ratio: 0,
      },
      netOperatingIncome: 0,
      assetManagementFee: 0,
      financing: {
        originalLoan: {
          proceeds: 0,
          loanFees: 0,
          interestExpense: 0,
          principalRepayment: 0,
          totalDebtService: 0,
          outstandingLoanBalance: 0,
          loanBalanceRepayment: 0,
          prepaymentPenalty: 0,
        },
        refinanceLoan: {
          proceeds: 0,
          loanFees: 0,
          interestExpense: 0,
          principalRepayment: 0,
          totalDebtService: 0,
          outstandingLoanBalance: 0,
          loanBalanceRepayment: 0,
        },
      },
      dscr: 0,
      netCashFlowAfterDebtService: 0,
      dipositon: {
        grossSaleProceeds: 0,
        dispositionFee: 0,
        saleClosingCosts: 0,
        loanRepaymentAtSale: 0,
        netProceedsFromSale: 0,
      },
      unleveredCashFlow: 0,
      leveredCashFlow: 0,
    };
    months.forEach((month) => {
      totals.acquisitionCosts.purchasePrice +=
        -1 * getAcquisitionCost(month, "purchasePrice");
      totals.acquisitionCosts.acquisitionFee += getAcquisitionCost(
        month,
        "acquisitionFee"
      );
      totals.acquisitionCosts.transferTaxes += getAcquisitionCost(
        month,
        "transferTaxes"
      );
      totals.acquisitionCosts.dueDilligence += getAcquisitionCost(
        month,
        "dueDilligence"
      );
      totals.acquisitionCosts.legalFees += getAcquisitionCost(
        month,
        "legalFees"
      );
      totals.acquisitionCosts.capExRenovation += getAcquisitionCost(
        month,
        "capExRenovation"
      );
      totals.acquisitionCosts.reserves += getAcquisitionCost(month, "reserves");
      totals.acquisitionCosts.otherClosingCosts += getAcquisitionCost(
        month,
        "otherClosingCosts"
      );
      totals.acquisitionCosts.reTaxReserves += getAcquisitionCost(
        month,
        "reTaxReserves"
      );
      totals.acquisitionCosts.insuranceReserves += getAcquisitionCost(
        month,
        "insuranceReserves"
      );
      totals.acquisitionCosts.debtServiceReservesPandI += getAcquisitionCost(
        month,
        "debtServices"
      );

      totals.rentalRevenue.grossPotentialRent += getRentalRevenueItem(
        month,
        "grossPotentialRent"
      );
      totals.rentalRevenue.vacancyLoss +=
        -1 * getRentalRevenueItem(month, "vacancyLoss");
      totals.rentalRevenue.lossToLease +=
        -1 * getRentalRevenueItem(month, "lossToLease");
      totals.rentalRevenue.rentConcessions +=
        -1 * getRentalRevenueItem(month, "rentConcessions");
      totals.rentalRevenue.creditLossBadDebt +=
        -1 * getRentalRevenueItem(month, "creditLossBadDebt");
      totals.rentalRevenue.nonRevenueUnits +=
        -1 * getRentalRevenueItem(month, "nonRevenueUnits");
      totals.rentalRevenue.total += -1 * getRentalRevenueItem(month, "total");

      totals.otherIncome.rubs += getOtherIncomeItem(month, "rubs");
      totals.otherIncome.laundryVendingIncome += getOtherIncomeItem(
        month,
        "laundryVendingIncome"
      );
      totals.otherIncome.placeholderOtherIncome += getOtherIncomeItem(
        month,
        "placeholderOtherIncome"
      );
      totals.otherIncome.feeIncome += getOtherIncomeItem(month, "feeIncome");
      totals.otherIncome.other += getOtherIncomeItem(month, "other");
      totals.otherIncome.total += getOtherIncomeItem(month, "total");

      totals.effectiveGrossIncomeTotal += getEffectiveGrossIncome(
        month,
        "total"
      );

      totals.expenses.controllable.propertyManagementFee +=
        getControllableExpense(month, "propertyManagementFee");
      totals.expenses.controllable.generalAndAdministration +=
        getControllableExpense(month, "generalAndAdministration");
      totals.expenses.controllable.contractServices += getControllableExpense(
        month,
        "contractServices"
      );
      totals.expenses.controllable.marketing += getControllableExpense(
        month,
        "marketing"
      );
      totals.expenses.controllable.payroll += getControllableExpense(
        month,
        "payroll"
      );
      totals.expenses.controllable.repairsAndMaintenance +=
        getControllableExpense(month, "repairsAndMaintenance");
      totals.expenses.controllable.turnoverCosts += getControllableExpense(
        month,
        "turnoverCosts"
      );
      totals.expenses.controllable.cableInternet += getControllableExpense(
        month,
        "cableInternet"
      );
      totals.expenses.controllable.total += getControllableExpense(
        month,
        "total"
      );

      totals.expenses.nonControllable.insurance += getNonControllableExpense(
        month,
        "insurance"
      );
      totals.expenses.nonControllable.realEstateTaxes +=
        getNonControllableExpense(month, "realEstateTaxes");
      totals.expenses.nonControllable.utilities += getNonControllableExpense(
        month,
        "utilities"
      );
      totals.expenses.nonControllable.replacementReserves +=
        getNonControllableExpense(month, "replacementReserves");
      totals.expenses.nonControllable.total += getNonControllableExpense(
        month,
        "total"
      );

      totals.expenses.total += getExpenseTotal(month, "total");
      totals.expenses.ratio = getExpenseTotal(month, "ratio");

      totals.netOperatingIncome += getNetOperatingIncome(month, "total");

      totals.assetManagementFee += getAssetManagementFee(
        month,
        "assetManagementFee"
      );

      totals.financing.originalLoan.proceeds +=
        getOriginalAcquisitionLoan(month, "proceeds") || 0;
      totals.financing.originalLoan.loanFees +=
        getOriginalAcquisitionLoan(month, "loanFees") || 0;
      totals.financing.originalLoan.interestExpense +=
        getOriginalAcquisitionLoan(month, "interestExpense") || 0;
      totals.financing.originalLoan.principalRepayment +=
        getOriginalAcquisitionLoan(month, "principalRepayment") || 0;
      totals.financing.originalLoan.totalDebtService +=
        getOriginalAcquisitionLoan(month, "totalDebtService") || 0;
      totals.financing.originalLoan.outstandingLoanBalance +=
        getOriginalAcquisitionLoan(month, "outstandingLoanBalance") || 0;
      totals.financing.originalLoan.loanBalanceRepayment +=
        -1 * (getOriginalAcquisitionLoan(month, "loanBalanceRepayment") || 0);
      totals.financing.originalLoan.prepaymentPenalty +=
        getOriginalAcquisitionLoan(month, "prepaymentPenalty") || 0;

      totals.financing.refinanceLoan.proceeds += getRefinanceLoan(
        month,
        "proceeds"
      );
      totals.financing.refinanceLoan.loanFees += getRefinanceLoan(
        month,
        "loanFees"
      );
      totals.financing.refinanceLoan.interestExpense += getRefinanceLoan(
        month,
        "interestExpense"
      );
      totals.financing.refinanceLoan.principalRepayment += getRefinanceLoan(
        month,
        "principalRepayment"
      );
      totals.financing.refinanceLoan.totalDebtService += getRefinanceLoan(
        month,
        "totalDebtService"
      );
      totals.financing.refinanceLoan.outstandingLoanBalance += getRefinanceLoan(
        month,
        "outstandingLoanBalance"
      );
      totals.financing.refinanceLoan.loanBalanceRepayment +=
        -1 * (getRefinanceLoan(month, "loanBalanceRepayment") || 0);

      totals.dscr = getDscr(month, "dscr");
      totals.netCashFlowAfterDebtService += getNetCashFlowAfterDebtService(
        month,
        "netCashFlowAfterDebtService"
      );
      totals.dipositon.grossSaleProceeds += getDisposition(
        month,
        "grossSaleProceeds"
      );
      totals.dipositon.dispositionFee += getDisposition(
        month,
        "dispositionFee"
      );
      totals.dipositon.saleClosingCosts += getDisposition(
        month,
        "saleClosingCosts"
      );
      totals.dipositon.loanRepaymentAtSale += getDisposition(
        month,
        "loanRepaymentAtSale"
      );
      totals.dipositon.netProceedsFromSale += getDisposition(
        month,
        "netProceedsFromSale"
      );

      totals.unleveredCashFlow += getUnleveredCashFlow(
        month,
        "unleveredCashFlow"
      );
      totals.leveredCashFlow += getLeveredCashFlow(month, "leveredCashFlow");
    });

    yearData.push(totals);
  });

  return yearData;
};

export const getAcquisitionCost = (
  proforma: IMonthlyProforma,
  type: AcquisitionType | null
) => {
  if (proforma.month > 0 || type === null) return 0;
  let value = 0;
  const {
    purchasePrice,
    acquisitionFee,
    transferTaxes,
    dueDilligence,
    legalFees,
    capExRenovation,
    reserves,
    otherClosingCosts,
    reTaxReserves,
    insuranceReserves,
    debtServiceReservesPandI,
  } = proforma.acquisitionCosts;
  const { acquisitionTotal } = proforma;
  switch (type) {
    case "purchasePrice":
      value = purchasePrice;
      break;
    case "acquisitionFee":
      value = acquisitionFee;
      break;
    case "transferTaxes":
      value = transferTaxes;
      break;
    case "dueDilligence":
      value = dueDilligence;
      break;
    case "legalFees":
      value = legalFees;
      break;
    case "capExRenovation":
      value = capExRenovation;
      break;
    case "reserves":
      value = reserves;
      break;
    case "otherClosingCosts":
      value = otherClosingCosts;
      break;
    case "reTaxReserves":
      value = reTaxReserves;
      break;
    case "insuranceReserves":
      value = insuranceReserves;
      break;
    case "debtServices":
      value = debtServiceReservesPandI;
      break;
    case "total":
      value = acquisitionTotal;
      break;
    default:
      break;
  }

  return value;
};

export const getRentalRevenueItem = (
  proforma: IMonthlyProforma,
  type: RentalRevenueType | null
) => {
  if (proforma.month === 0 || type === null) return 0;
  let value = 0;
  const {
    grossPotentialRent,
    vacancyLoss,
    lossToLease,
    rentConcessions,
    creditLossBadDebt,
    nonRevenueUnits,
  } = proforma.rentalRevenue;
  const { rentalRevenueTotal } = proforma;
  switch (type) {
    case "grossPotentialRent":
      value = grossPotentialRent;
      break;
    case "vacancyLoss":
      value = vacancyLoss;
      break;
    case "lossToLease":
      value = lossToLease;
      break;
    case "rentConcessions":
      value = rentConcessions;
      break;
    case "creditLossBadDebt":
      value = creditLossBadDebt;
      break;
    case "nonRevenueUnits":
      value = nonRevenueUnits;
      break;
    case "total":
      value = rentalRevenueTotal;
      break;
    default:
      break;
  }

  return value;
};

export const getOtherIncomeItem = (
  proforma: IMonthlyProforma,
  type: OtherIncomeType | null
) => {
  if (proforma.month === 0 || type === null) return 0;
  let value = 0;
  const {
    rubs,
    laundryVendingIncome,
    placeholderOtherIncome,
    feeIncome,
    other,
  } = proforma.otherIncome;
  const { otherIncomeTotal } = proforma;
  switch (type) {
    case "rubs":
      value = rubs;
      break;
    case "laundryVendingIncome":
      value = laundryVendingIncome;
      break;
    case "placeholderOtherIncome":
      value = placeholderOtherIncome;
      break;
    case "feeIncome":
      value = feeIncome;
      break;
    case "other":
      value = other;
      break;
    case "total":
      value = otherIncomeTotal;
      break;
    default:
      break;
  }

  return value;
};

export const getEffectiveGrossIncome = (
  proforma: IMonthlyProforma,
  type: EffectiveGrossIncomeType | null
) => {
  if (proforma.month === 0 || type === null) return 0;
  return proforma.effectiveGrossIncome;
};

export const getControllableExpense = (
  proforma: IMonthlyProforma,
  type: ControllableExpenseType | null
) => {
  if (proforma.month === 0 || type === null) return 0;
  let value = 0;
  const {
    propertyManagementFee,
    generalAndAdministration,
    contractServices,
    marketing,
    payroll,
    repairsAndMaintenance,
    turnoverCosts,
    cableInternet,
    total,
  } = proforma.expenses.controllable;
  switch (type) {
    case "propertyManagementFee":
      value = propertyManagementFee;
      break;
    case "generalAndAdministration":
      value = generalAndAdministration;
      break;
    case "contractServices":
      value = contractServices;
      break;
    case "marketing":
      value = marketing;
      break;
    case "payroll":
      value = payroll;
      break;
    case "repairsAndMaintenance":
      value = repairsAndMaintenance;
      break;
    case "turnoverCosts":
      value = turnoverCosts;
      break;
    case "cableInternet":
      value = cableInternet;
      break;
    case "total":
      value = total;
      break;
    default:
      break;
  }

  return value;
};

export const getNonControllableExpense = (
  proforma: IMonthlyProforma,
  type: NonControllableExpenseType | null
) => {
  if (proforma.month === 0 || type === null) return 0;
  let value = 0;
  const { insurance, realEstateTaxes, utilities, replacementReserves, total } =
    proforma.expenses.nonControllable;
  switch (type) {
    case "insurance":
      value = insurance;
      break;
    case "realEstateTaxes":
      value = realEstateTaxes;
      break;
    case "utilities":
      value = utilities;
      break;
    case "replacementReserves":
      value = replacementReserves;
      break;
    case "total":
      value = total;
      break;
    default:
      break;
  }

  return value;
};

export const getExpenseTotal = (
  proforma: IMonthlyProforma,
  type: ExpenseTotalType | null
) => {
  if (proforma.month === 0 || type === null) return 0;
  let value = 0;
  const { totalExpenses, expenseRatio } = proforma;
  switch (type) {
    case "total":
      value = totalExpenses;
      break;
    case "ratio":
      value = expenseRatio * 100;
      break;
    default:
      break;
  }

  return value;
};

export const getNetOperatingIncome = (
  proforma: IMonthlyProforma,
  type: NetOperatingIncomeType | null
) => {
  if (proforma.month === 0 || type === null) return 0;
  return proforma.netOperatingIncome;
};

export const getAssetManagementFee = (
  proforma: IMonthlyProforma,
  type: AssetManagementFeeType | null
) => {
  if (proforma.month === 0 || type === null) return 0;
  return proforma.assetManagementFee;
};

export const getOriginalAcquisitionLoan = (
  proforma: IMonthlyProforma,
  type: OriginalAcquisitionLoanType | null
) => {
  if (
    proforma.refinancing &&
    proforma.month > proforma.refinanceMonth &&
    type !== "outstandingLoanBalance"
  )
    return 0;

  if (proforma.month === 0) {
    switch (type) {
      case "proceeds":
        return proforma.originalLoanTotal;
      case "loanFees":
        return proforma.acquisitionBridgeLoanAssumptions.loanFees * -1;
      default:
        return;
    }
  }

  let value = 0;

  const {
    interestExpense,
    principalRepayment,
    totalDebtService,
    outstandingLoanBalance,
    loanBalanceRepayment,
  } = proforma.financing.originalAcquisitionLoan;
  switch (type) {
    case "interestExpense":
      value = interestExpense;
      break;
    case "principalRepayment":
      value = principalRepayment;
      break;
    case "totalDebtService":
      value = totalDebtService;
      break;
    case "outstandingLoanBalance":
      value = outstandingLoanBalance;
      break;
    case "loanBalanceRepayment":
      value = loanBalanceRepayment;
      break;
    default:
      break;
  }

  return value;
};

export const getRefinanceLoan = (
  proforma: IMonthlyProforma,
  type: RefinanceLoanType | null
) => {
  if (
    proforma.month === 0 ||
    !proforma.refinancing ||
    proforma.month < proforma.refinanceMonth
  )
    return 0;

  let value = 0;
  const {
    interestExpense,
    principalRepayment,
    totalDebtService,
    outstandingLoanBalance,
    loanBalanceRepayment,
    proceeds,
    loanFees,
  } = proforma.financing.refinanceLoan;
  switch (type) {
    case "interestExpense":
      value = interestExpense;
      break;
    case "principalRepayment":
      value = principalRepayment;
      break;
    case "totalDebtService":
      value = totalDebtService;
      break;
    case "outstandingLoanBalance":
      value = outstandingLoanBalance;
      break;
    case "loanBalanceRepayment":
      value = loanBalanceRepayment;
      break;
    case "proceeds":
      value = proceeds;
      break;
    case "loanFees":
      value = -1 * loanFees;
      break;
    default:
      break;
  }

  return value;
};

export const getDscr = (proforma: IMonthlyProforma, type: DscrType | null) => {
  return proforma.financing.dscr || 0;
};

export const getNetCashFlowAfterDebtService = (
  proforma: IMonthlyProforma,
  type: NetCashFlowAfterDebtServiceType | null
) => {
  if (proforma.month === 0) return 0;
  return proforma.netCashFlowAfterDebtService;
};

export const getDisposition = (
  proforma: IMonthlyProforma,
  type: DispositionType | null
) => {
  if (
    proforma.month === 0 ||
    proforma.month < proforma.investmentTimeline.exitMonth
  )
    return 0;

  let value = 0;
  const {
    grossSaleProceeds,
    dispositionFee,
    saleClosingCosts,
    loanRepaymentAtSale,
    netProceedsFromSale,
  } = proforma.financing.disposition;
  switch (type) {
    case "grossSaleProceeds":
      value = grossSaleProceeds;
      break;
    case "dispositionFee":
      value = -1 * dispositionFee;
      break;
    case "saleClosingCosts":
      value = -1 * saleClosingCosts;
      break;
    case "loanRepaymentAtSale":
      value = -1 * loanRepaymentAtSale;
      break;
    case "netProceedsFromSale":
      value = netProceedsFromSale;
      break;
    default:
      break;
  }

  return value;
};

export const getUnleveredCashFlow = (
  proforma: IMonthlyProforma,
  type: UnleveredCashFlowType | null
) => {
  if (type === null) return 0;
  return proforma.unleveredCashFlow;
};

export const getLeveredCashFlow = (
  proforma: IMonthlyProforma,
  type: LeveredCashFlowType | null
) => {
  if (type === null) return 0;
  return proforma.leveredCashFlow;
};

export interface IAnnualProforma {
  year: number;
  acquisitionCosts: {
    purchasePrice: number;
    acquisitionFee: number;
    transferTaxes: number;
    dueDilligence: number;
    legalFees: number;
    capExRenovation: number;
    reserves: number;
    otherClosingCosts: number;
    reTaxReserves: number;
    insuranceReserves: number;
    debtServiceReservesPandI: number;
    total: number;
  };
  rentalRevenue: {
    grossPotentialRent: number;
    vacancyLoss: number;
    lossToLease: number;
    rentConcessions: number;
    creditLossBadDebt: number;
    nonRevenueUnits: number;
    total: number;
  };
  otherIncome: {
    rubs: number;
    laundryVendingIncome: number;
    placeholderOtherIncome: number;
    feeIncome: number;
    other: number;
    total: number;
  };
  effectiveGrossIncomeTotal: number;
  expenses: {
    controllable: {
      propertyManagementFee: number;
      generalAndAdministration: number;
      contractServices: number;
      marketing: number;
      payroll: number;
      repairsAndMaintenance: number;
      turnoverCosts: number;
      cableInternet: number;
      total: number;
    };
    nonControllable: {
      insurance: number;
      realEstateTaxes: number;
      utilities: number;
      replacementReserves: number;
      total: number;
    };
    total: number;
    ratio: number;
  };
  netOperatingIncome: number;
  assetManagementFee: number;
  financing: {
    originalLoan: {
      proceeds: number;
      loanFees: number;
      interestExpense: number;
      principalRepayment: number;
      totalDebtService: number;
      outstandingLoanBalance: number;
      loanBalanceRepayment: number;
      prepaymentPenalty: number;
    };
    refinanceLoan: {
      proceeds: number;
      loanFees: number;
      interestExpense: number;
      principalRepayment: number;
      totalDebtService: number;
      outstandingLoanBalance: number;
      loanBalanceRepayment: number;
    };
  };
  dscr: number;
  netCashFlowAfterDebtService: number;
  dipositon: {
    grossSaleProceeds: number;
    dispositionFee: number;
    saleClosingCosts: number;
    loanRepaymentAtSale: number;
    netProceedsFromSale: number;
  };
  unleveredCashFlow: number;
  leveredCashFlow: number;
}
