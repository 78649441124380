import { useState } from "react";
import { useNavigate } from "react-router";
import { TourProvider as ReactTourProvider } from "@reactour/tour";
import { CloseOutlined } from "@ant-design/icons";
import Tour from "../components/tour";
import { tourSteps } from "../components/tour/tour-steps";

const TourProvider: React.FC = ({ children }) => {
  const navigate = useNavigate();
  const [step, setStep] = useState(0);

  const setCurrentStep = (step: any) => {
    if ([9, 10, 11].includes(step)) {
      navigate("/new-property");
    } else if (step === 15) {
      navigate("version-history");
    } else {
      navigate("/properties");
    }
    setTimeout(() => setStep(step), 300);
  };

  return (
    <ReactTourProvider
      currentStep={step}
      setCurrentStep={setCurrentStep}
      steps={tourSteps}
      showBadge={false}
      disableInteraction
      scrollSmooth
      beforeClose={() => setCurrentStep(0)}
      onClickClose={({ setIsOpen }) => (
        setIsOpen && setIsOpen(false), navigate("/properties")
      )}
      showNavigation={false}
      showPrevNextButtons={false}
      className="tour-box"
      components={{
        Close: ({ onClick }) =>
          step !== tourSteps.length - 1 ? (
            <CloseOutlined
              onClick={onClick}
              style={{
                color: "black",
                float: "right",
                cursor: "pointer",
              }}
            />
          ) : null,
        Content: ({ content, setIsOpen }) => (
          <Tour
            content={content}
            setIsOpen={setIsOpen}
            setCurrentStep={setCurrentStep}
            step={step}
          />
        ),
      }}
    >
      {children}
    </ReactTourProvider>
  );
};

export default TourProvider;
