import { IAssumption } from "../assumptions";
import { ILoanAssumptions, IEquitySplit, ILoan, ILoanScheduleMonth, IRefinancingPermanentLoanAssumptions } from "../loan";
import { IExitDispositionSummary, IIncomeExpenseItem, IInvestmentTimeline, IPromoteIncentiveHurdles, IProperty, IReserveItem, IYearOneProformaIncomeExpenseItem } from "../property";
import { IRentRollUnit } from "../rent-roll-unit";

export interface IMonthyPerformaAcquisition {
  purchasePrice: number;
  acquisitionFee: number;
  transferTaxes: number;
  dueDilligence: number;
  legalFees: number;
  capExRenovation: number;
  reserves: number;
  otherClosingCosts: number;
  reTaxReserves: number;
  insuranceReserves: number;
  debtServiceReservesPandI: number;
}

export interface IMonthlyProforma {
  month: number;
  year: number;
  refinancing: boolean;
  otherIncomeGrowthYears: {
    rubs: number[];
    laundryVendingIncome: number[];
    placeholderOtherIncome: number[];
    feeIncome: number[];
    other: number[];
  };
  expenseGrowthYears: {
    generalAndAdministration: number[];
    contractServices: number[];
    marketing: number[];
    payroll: number[];
    repairsAndMaintenance: number[];
    turnoverCosts: number[];
    cableInternet: number[];
    insurance: number[];
    utilities: number[];
  };
  totalUnits: number;
  yearOneProformaAssumptions: {
    rentIncomeItems: IYearOneProformaIncomeExpenseItem[];
    otherIncomeItems: IYearOneProformaIncomeExpenseItem[];
    expenseItems: IYearOneProformaIncomeExpenseItem[];
  };
  equitySplit: IEquitySplit;
  acquisitionLoanSchedule: ILoanScheduleMonth[];
  refinancingLoanSchedule: ILoanScheduleMonth[];
  refinanceMonth: number;
  originalLoanTotal: number;
  refinanceLoanTotal: number;
  refinanceLoanFees: number;
  investmentTimeline: IInvestmentTimeline;
  exitDispositionSummary: IExitDispositionSummary;
  rentIncomeItems: IIncomeExpenseItem[];
  otherIncomeItems: IIncomeExpenseItem[];
  expenseItems: IIncomeExpenseItem[];
  growthAssumptions: IAssumption[];
  annualAssumptions: IAssumption[];
  rentRollUnitMix: IRentRollUnit[];
  acquisitionCosts: IMonthyPerformaAcquisition;
  acquisitionBridgeLoanAssumptions: ILoanAssumptions;
  refinancingPermanentLoanAssumptions: IRefinancingPermanentLoanAssumptions;
  promoteIncentiveHurdles: IPromoteIncentiveHurdles
  acquisitionTotal: number;
  rentalRevenue: {
    grossPotentialRent: number;
    vacancyLoss: number;
    lossToLease: number;
    rentConcessions: number;
    creditLossBadDebt: number;
    nonRevenueUnits: number;
  },
  rentalRevenueTotal: number,
  otherIncome: {
    rubs: number;
    laundryVendingIncome: number;
    placeholderOtherIncome: number;
    feeIncome: number;
    other: number;
  },
  otherIncomeTotal: number,
  effectiveGrossIncome: number;
  expenses: {
    controllable: {
      propertyManagementFee: number;
      generalAndAdministration: number;
      contractServices: number;
      marketing: number;
      payroll: number;
      repairsAndMaintenance: number;
      turnoverCosts: number;
      cableInternet: number;
      total: number;
    },
    nonControllable: {
      insurance: number;
      realEstateTaxes: number;
      utilities: number;
      replacementReserves: number;
      total: number;
    },
  },
  totalExpenses: number;
  expenseRatio: number;
  netOperatingIncome: number;
  assetManagementFee: number;
  financing: {
    originalAcquisitionLoan: {
      proceeds: number;
      loanFees: number;
      interestExpense: number;
      principalRepayment: number;
      totalDebtService: number;
      outstandingLoanBalance: number;
      loanBalanceRepayment: number;
      prepaymentPenalty: number;
    },
    refinanceLoan: {
      proceeds: number;
      loanFees: number;
      interestExpense: number;
      principalRepayment: number;
      totalDebtService: number;
      outstandingLoanBalance: number;
      loanBalanceRepayment: number;
    },
    disposition: {
      grossSaleProceeds: number;
      dispositionFee: number;
      saleClosingCosts: number;
      loanRepaymentAtSale: number;
      netProceedsFromSale: number;
    },
    dscr: number;
  },
  netCashFlowAfterDebtService: number;
  unleveredCashFlow: number;
  leveredCashFlow: number;
  reservesTaxes: IReserveItem;
  reservesInsurance: IReserveItem;
}

const yearOneProformaAssumptions: { rentIncomeItems: IYearOneProformaIncomeExpenseItem[], otherIncomeItems: IYearOneProformaIncomeExpenseItem[], expenseItems: IYearOneProformaIncomeExpenseItem[] } = {
  rentIncomeItems: [],
  otherIncomeItems: [],
  expenseItems: [],
}

const otherIncomeGrowthYears: {
  rubs: number[];
  laundryVendingIncome: number[];
  placeholderOtherIncome: number[];
  feeIncome: number[];
  other: number[];
} = {
  rubs: [],
  laundryVendingIncome: [],
  placeholderOtherIncome: [],
  feeIncome: [],
  other: [],
}

const expenseGrowthYears: {
  generalAndAdministration: number[],
  contractServices: number[],
  marketing: number[],
  payroll: number[],
  repairsAndMaintenance: number[],
  turnoverCosts: number[],
  cableInternet: number[],
  insurance: number[],
  utilities: number[],
  realEstateTaxes: number[],
} = {
  generalAndAdministration: [],
  contractServices: [],
  marketing: [],
  payroll: [],
  repairsAndMaintenance: [],
  turnoverCosts: [],
  cableInternet: [],
  insurance: [],
  utilities: [],
  realEstateTaxes: [],
}

export class MonthlyProforma implements IMonthlyProforma {
  month = 0;
  year = 0;
  refinancing = false;
  otherIncomeGrowthYears = otherIncomeGrowthYears;
  expenseGrowthYears = expenseGrowthYears;
  totalUnits = 0;
  yearOneProformaAssumptions = yearOneProformaAssumptions;
  acquisitionLoanSchedule: ILoanScheduleMonth[] = [];
  refinancingLoanSchedule: ILoanScheduleMonth[] = [];
  refinanceMonth = 0;
  originalLoanTotal = 0;
  refinanceLoanTotal = 0;
  refinanceLoanFees = 0;
  investmentTimeline: IInvestmentTimeline = {
    acquisitionDate: null,
    refinanceDate: null,
    exitDispositionDate: null,
    holdPeriodYears: 0,
    exitMonth: 0,
    distributionStartDate: 0,
    renovationStartMonth: 0,
    renovationEndMonth: 0,
  }
  exitDispositionSummary: IExitDispositionSummary = {
    date: null,
    capRate: 0,
    capRateSpread: 0,
    occupancy: 0,
    noiTrailing12Months: 0,
    grossSaleProceeds: 0,
    dispositionFeePercentage: 0,
    dispositionFee: 0,
    saleClosingCostsPercentage: 0,
    saleClosingCosts: 0,
    loanRepaymentAtSale: 0,
    netSaleProceeds: 0,
  }
  equitySplit: IEquitySplit = {
    gpSponsor: 0,
    lpInvestors: 0,
  }
  rentIncomeItems: IIncomeExpenseItem[] = [];
  otherIncomeItems: IIncomeExpenseItem[] = [];
  expenseItems: IIncomeExpenseItem[] = [];
  growthAssumptions: IAssumption[] = [];
  annualAssumptions: IAssumption[] = [];
  rentRollUnitMix: IRentRollUnit[] = [];
  acquisitionCosts = {
    purchasePrice: 0,
    acquisitionFee: 0,
    transferTaxes: 0,
    dueDilligence: 0,
    legalFees: 0,
    capExRenovation: 0,
    reserves: 0,
    otherClosingCosts: 0,
    reTaxReserves: 0,
    insuranceReserves: 0,
    debtServiceReservesPandI: 0,
  };
  acquisitionTotal = 0;
  rentalRevenue = {
    grossPotentialRent: 0,
    vacancyLoss: 0,
    lossToLease: 0,
    rentConcessions: 0,
    creditLossBadDebt: 0,
    nonRevenueUnits: 0,
  };
  rentalRevenueTotal = 0;
  otherIncome = {
    rubs: 0,
    laundryVendingIncome: 0,
    placeholderOtherIncome: 0,
    feeIncome: 0,
    other: 0,
  };
  otherIncomeTotal = 0;
  effectiveGrossIncome = 0;
  expenses = {
    controllable: {
      propertyManagementFee: 0,
      generalAndAdministration: 0,
      contractServices: 0,
      marketing: 0,
      payroll: 0,
      repairsAndMaintenance: 0,
      turnoverCosts: 0,
      cableInternet: 0,
      total: 0,
    },
    nonControllable: {
      insurance: 0,
      realEstateTaxes: 0,
      utilities: 0,
      replacementReserves: 0,
      total: 0,
    }
  }
  totalExpenses = 0;
  expenseRatio = 0;
  netOperatingIncome = 0;
  assetManagementFee = 0;
  financing = {
    originalAcquisitionLoan: {
      proceeds: 0,
      loanFees: 0,
      interestExpense: 0,
      principalRepayment: 0,
      totalDebtService: 0,
      outstandingLoanBalance: 0,
      loanBalanceRepayment: 0,
      prepaymentPenalty: 0,
    },
    refinanceLoan: {
      proceeds: 0,
      loanFees: 0,
      interestExpense: 0,
      principalRepayment: 0,
      totalDebtService: 0,
      outstandingLoanBalance: 0,
      loanBalanceRepayment: 0,
    },
    disposition: {
      grossSaleProceeds: 0,
      dispositionFee: 0,
      saleClosingCosts: 0,
      loanRepaymentAtSale: 0,
      netProceedsFromSale: 0,
    },
    dscr: 0,
  }
  acquisitionBridgeLoanAssumptions: ILoanAssumptions = {
    lender: "",
    date: new Date(),
    capExLeveragePercentage: 0,
    purchasePriceLeveragePercentage: 0,
    termYears: 0,
    interestRate: 0,
    interestOnlyMonths: 0,
    amortizationYears: 0,
    loanFeesPercentage: 0,
    loanFees: 0,
    prepaymentPenaltyPercentage: 0,
    prepaymentPenalty: 0,
    debtServiceReservesPandIMonths: 0,
    debtServiceReservesPandIRelase: "yes",
    debtServiceReservesPandIRelaseTiming: 0,
    debtServiceReservesPandITotal: 0,
    monthlyDebtService: 0,
    annualDebtService: 0,
  }
  refinancingPermanentLoanAssumptions: IRefinancingPermanentLoanAssumptions = {
    refinancing: false,
    loanType: "ltv",
    lender: "",
    date: new Date(),
    month: 0,
    year: 0,
    interestRate: 0,
    interestOnlyMonths: 0,
    amortizationYears: 0,
    noiTrailing12Months: 0,
    capRate: 0,
    propertyValuation: 0,
    ltvPercentage: 0,
    dscrPercentage: 0,
    ltvTotal: 0,
    dscrTotal: 0,
    refinanceLoanAmount: 0,
    loanFeesPercentage: 0,
    loanFees: 0,
    originalLoanRepayment: 0,
    distributionProceeds: 0,
  }
  netCashFlowAfterDebtService = 0;
  unleveredCashFlow = 0;
  leveredCashFlow = 0;
  promoteIncentiveHurdles: IPromoteIncentiveHurdles = {
    investorPreferredReturn: 0,
    t1HurdlePercentage: 0,
    t2HurdlePercentage: 0,
    t3HurdlePercentage: 0,
    t4HurdlePercentage: 0,
    t1PromotePercentage: 0,
    t2PromotePercentage: 0,
    t3PromotePercentage: 0,
    t4PromotePercentage: 0,
  }
  reservesTaxes: IReserveItem = {
    months: 0,
    release: false,
    releaseMonths: 0,
    yearOneMonthlyAmount: 0,
    yearOneTotal: 0,
    yearOneTotalPerUnit: 0,
  }
  reservesInsurance: IReserveItem = {
    months: 0,
    release: false,
    releaseMonths: 0,
    yearOneMonthlyAmount: 0,
    yearOneTotal: 0,
    yearOneTotalPerUnit: 0,
  }

  constructor(
    month: number,
    property: IProperty,
    loan: ILoan,
    overrideInvestmentTimeline?: IInvestmentTimeline,
    overrideExitDispositionSummary?: IExitDispositionSummary
  ) {
    this.month = month;
    this._calculateYear();

    const {
      yearOneProformaAssumptions: {
        rentIncomeItems: yearOneRentIncomeItems,
        otherIncomeItems: yearOneOtherIncomeItems,
        expenseItems: yearOneExpenseItems,
      },
      totalUnits,
      rentIncomeItems,
      otherIncomeItems,
      expenseItems,
      growthAssumptions,
      annualAssumptions,
      rentRollUnitMix,
      reservesTaxes,
      reservesInsurance,
      renovations: {
        totalBudget,
      },
      investmentTimeline,
      exitDispositionSummary,
      promoteIncentiveHurdles,
    } = property;

    const {
      acquisitionCosts: {
        purchasePrice,
        acquisitionFee,
        transferTaxes,
        dueDilligence,
        legalFees,
        reserves,
        otherClosingCosts,
      },
      acquisitionLoanSchedule,
      loanAssumptions: acquisitionBridgeLoanAssumptions,
      refinancingLoanSchedule,
      refinancingPermanentLoanAssumptions,
      loanTotal,
      equitySplit,
    } = loan;

    this.totalUnits = totalUnits;

    this.acquisitionCosts = {
      purchasePrice: purchasePrice * -1,
      acquisitionFee: acquisitionFee * -1,
      transferTaxes: transferTaxes * -1,
      dueDilligence: dueDilligence * -1,
      legalFees: legalFees * -1,
      capExRenovation: totalBudget * -1,
      reserves: reserves * -1,
      otherClosingCosts: otherClosingCosts * -1,
      reTaxReserves: (reservesTaxes.yearOneMonthlyAmount * reservesTaxes.months) * -1,
      insuranceReserves: (reservesInsurance.yearOneMonthlyAmount * reservesInsurance.months)* -1,
      debtServiceReservesPandI: this.acquisitionBridgeLoanAssumptions.debtServiceReservesPandITotal * -1,
    }
    this._calculateAcquisitionTotal();

    this.rentRollUnitMix = rentRollUnitMix;
    this._calculateRentalRevenueGpr();

    this.yearOneProformaAssumptions = {
      rentIncomeItems: yearOneRentIncomeItems,
      otherIncomeItems: yearOneOtherIncomeItems,
      expenseItems: yearOneExpenseItems,
    }

    this.growthAssumptions = growthAssumptions;
    this.annualAssumptions = annualAssumptions;
    this._calculateRentalRevenue();

    this.rentIncomeItems = rentIncomeItems;
    this.otherIncomeItems = otherIncomeItems;
    this.expenseItems = expenseItems;

    this._calculateYearlyOtherIncomeGrowth(
      ["rubs", "laundryVendingIncome", "placeholderOtherIncome", "feeIncome", "other"]
    );
    this._calculateYearlyExpenseGrowth(
      [
        "generalAndAdministration",
        "contractServices",
        "marketing",
        "payroll",
        "repairsAndMaintenance",
        "turnoverCosts",
        "cableInternet",
        "insurance",
        "utilities",
      ]
    );
    this._calculateYearlyTaxGrowth();
    this._calculateOtherIncome();
    this._calculateEffectiveGrossIncome();
    this._calculateExpenses();

    this.acquisitionBridgeLoanAssumptions = acquisitionBridgeLoanAssumptions;
    this.acquisitionLoanSchedule = acquisitionLoanSchedule;
    this.refinancingPermanentLoanAssumptions = refinancingPermanentLoanAssumptions;
    this.refinancingLoanSchedule = refinancingLoanSchedule;
    this.refinanceMonth = this.refinancingPermanentLoanAssumptions.month;
    this.originalLoanTotal = loanTotal;
    this.refinanceLoanTotal = this.refinancingPermanentLoanAssumptions.refinanceLoanAmount;
    this.refinancing = this.refinancingPermanentLoanAssumptions.refinancing;
    this.acquisitionBridgeLoanAssumptions = acquisitionBridgeLoanAssumptions;
    this.investmentTimeline = overrideInvestmentTimeline || investmentTimeline;
    this.exitDispositionSummary = overrideExitDispositionSummary || exitDispositionSummary;
    this.promoteIncentiveHurdles = promoteIncentiveHurdles;
    this.equitySplit = equitySplit;
    this.reservesTaxes = reservesTaxes;
    this.reservesInsurance = reservesInsurance;
    this._calculateFinancing();
  }

  private _calculateYear(): void {
    this.year = Math.floor((this.month - 1) / 12) + 1;
  }

  private _calculateAcquisitionTotal(): void {
    this.acquisitionTotal = Object.keys(this.acquisitionCosts)
      //@ts-ignore
      .map(key => this.acquisitionCosts[key])
      .reduce((prev, curr) => prev + curr, 0);
  }

  private _calculateRentalRevenueGpr(): void {
    this.rentalRevenue.grossPotentialRent = this.rentRollUnitMix
      .map(unit => unit.monthlyGpr[this.month].totalGPR)
      .reduce((prev, curr) => prev + curr, 0);
  }

  private _calculateRentalRevenue(): void {
    const { grossPotentialRent } = this.rentalRevenue;
    const vacancyLossPercentage = this._getAnnualAssumptionPercentage("vacancyLoss");
    const lossToLeastPercentage = this._getAnnualAssumptionPercentage("lossToLease");
    const rentConcessionsPercentage = this._getAnnualAssumptionPercentage("rentConcessions");
    const creditLossBadDebtPercentage = this._getAnnualAssumptionPercentage("creditLossBadDebt");
    const nonRevenueUnitsPercentage = this._getAnnualAssumptionPercentage("nonRevenueUnits");

    this.rentalRevenue = {
      ...this.rentalRevenue,
      vacancyLoss: (grossPotentialRent * vacancyLossPercentage) * -1,
      lossToLease: (grossPotentialRent * lossToLeastPercentage) * -1,
      rentConcessions: (grossPotentialRent * rentConcessionsPercentage) * -1,
      creditLossBadDebt: (grossPotentialRent * creditLossBadDebtPercentage) * -1,
      nonRevenueUnits: (grossPotentialRent * nonRevenueUnitsPercentage) * -1,
    }

    this.rentalRevenueTotal = this._calculateRentalRevenueTotal();
  }

  private _getAnnualAssumptionPercentage = (field: string): number => {
    let yearKey = `year${this.year}`;
    //@ts-ignore
    return this.annualAssumptions.filter(a => a.name === field)[0][yearKey] / 100;
  }

  private _calculateRentalRevenueTotal(): number {
    const {
      grossPotentialRent,
      vacancyLoss,
      lossToLease,
      rentConcessions,
      creditLossBadDebt,
      nonRevenueUnits
    } = this.rentalRevenue;
    return grossPotentialRent + vacancyLoss + lossToLease + rentConcessions + creditLossBadDebt + nonRevenueUnits;
  }


  private _getOtherItemMonthlyProformaAmount(field: string): number {
    return (this.yearOneProformaAssumptions.otherIncomeItems.filter(x => x.name === field)[0].yearOneAmount * this.totalUnits) / 12;
  }

  private _calculateOtherIncome(): void {
    let rubs = 0;
    let laundryVendingIncome = 0;
    let placeholderOtherIncome = 0;
    let feeIncome = 0;
    let other = 0;

    if (this.year > 0) {
      // @ts-ignore
      rubs = this.otherIncomeGrowthYears.rubs[this.year - 1];
      laundryVendingIncome = this.otherIncomeGrowthYears.laundryVendingIncome[this.year - 1];
      placeholderOtherIncome = this.otherIncomeGrowthYears.placeholderOtherIncome[this.year - 1];
      feeIncome = this.otherIncomeGrowthYears.feeIncome[this.year - 1];
      other = this.otherIncomeGrowthYears.other[this.year - 1];
    }

    this.otherIncome = {
      ...this.otherIncome,
      rubs,
      laundryVendingIncome,
      placeholderOtherIncome,
      feeIncome,
      other,
    }

    this.otherIncomeTotal = this._calculateOtherIncomeTotal();
  }

  private _calculateOtherIncomeTotal(): number {
    const {
      rubs,
      laundryVendingIncome,
      placeholderOtherIncome,
      feeIncome,
      other,
    } = this.otherIncome;
    return rubs + laundryVendingIncome + placeholderOtherIncome + feeIncome + other;
  }

  private _calculateEffectiveGrossIncome(): void {
    this.effectiveGrossIncome = this.rentalRevenueTotal + this.otherIncomeTotal;
  }

  private _calculateExpenses(): void {
    this._calculateControllableExpenses();
    this._calculateNonControllableExpenses();
    this._calculateTotalExpenses();
    this._calculateNetOperatingIncome();
    this._calculateAssetManagementFee();
  }

  private _calculateControllableExpenses(): void {
    const propertyManagementFeePercentage = this.annualAssumptions.filter(a => a.name === "propertyManagementFee")[0].year1 / 100;
    let generalAndAdministration = 0;
    let contractServices = 0;
    let marketing = 0;
    let payroll = 0;
    let repairsAndMaintenance = 0;
    let turnoverCosts = 0;
    let cableInternet = 0;

    if (this.year > 0) {
      generalAndAdministration = this.expenseGrowthYears.generalAndAdministration[this.year - 1];
      contractServices = this.expenseGrowthYears.contractServices[this.year - 1];
      marketing = this.expenseGrowthYears.marketing[this.year - 1];
      payroll = this.expenseGrowthYears.payroll[this.year - 1];
      repairsAndMaintenance = this.expenseGrowthYears.repairsAndMaintenance[this.year - 1];
      turnoverCosts = this.expenseGrowthYears.turnoverCosts[this.year - 1];
      cableInternet = this.expenseGrowthYears.cableInternet[this.year - 1];
    }

    this.expenses = {
      ...this.expenses,
      controllable: {
        ...this.expenses.controllable,
        propertyManagementFee: this.effectiveGrossIncome * propertyManagementFeePercentage,
        generalAndAdministration,
        contractServices,
        marketing,
        payroll,
        repairsAndMaintenance,
        turnoverCosts,
        cableInternet,
      }
    }

    this.expenses.controllable.total = this._calculateControllableExpensesTotal();
  }

  private _getExpenseItemMonthlyProformaAmount(field: string): number {
    return (this.yearOneProformaAssumptions.expenseItems.filter(x => x.name === field)[0].yearOneAmount * this.totalUnits) / 12;
  }

  private _calculateControllableExpensesTotal(): number {
    const {
      propertyManagementFee,
      generalAndAdministration,
      contractServices,
      marketing,
      payroll,
      repairsAndMaintenance,
      turnoverCosts,
      cableInternet,
    } = this.expenses.controllable;

    return propertyManagementFee + generalAndAdministration + contractServices + marketing + payroll + repairsAndMaintenance + turnoverCosts + cableInternet;
  }

  private _calculateNonControllableExpenses(): void {
    let insurance = this.expenseGrowthYears.insurance[this.year - 1];
    let realEstateTaxes = this.expenseGrowthYears.realEstateTaxes[this.year - 1];
    let utilities = this.expenseGrowthYears.utilities[this.year - 1];
    let replacementReserves = this._getExpenseItemMonthlyProformaAmount("replacementReserves");;

    this.expenses = {
      ...this.expenses,
      nonControllable: {
        ...this.expenses.nonControllable,
        insurance,
        realEstateTaxes,
        utilities,
        replacementReserves,
      }
    }

    this.expenses.nonControllable.total = this._calculateNonControllableExpensesTotal();
  }

  private _calculateNonControllableExpensesTotal(): number {
    const {
      insurance,
      realEstateTaxes,
      utilities,
      replacementReserves
    } = this.expenses.nonControllable;

    return insurance + realEstateTaxes + utilities + replacementReserves;
  }

  private _calculateTotalExpenses(): void {
    const { controllable: { total: controllableTotal }, nonControllable: { total: nonControllableTotal } } = this.expenses;
    this.totalExpenses = controllableTotal + nonControllableTotal;
    this.expenseRatio = this.totalExpenses / this.effectiveGrossIncome;
  }

  private _calculateNetOperatingIncome(): void {
    this.netOperatingIncome = this.effectiveGrossIncome - this.totalExpenses;
  }

  private _calculateAssetManagementFee(): void {
    const assetManagementFeePercentage = this._getAnnualAssumptionPercentage("assetManagementFee");
    this.assetManagementFee = this.effectiveGrossIncome * assetManagementFeePercentage;
  }

  private _calculateFinancing(): void {
    const { exitMonth } = this.investmentTimeline;
    const originalLoanMonth = this.acquisitionLoanSchedule[this.month];
    this.financing = {
      ...this.financing,
      originalAcquisitionLoan: {
        ...this.financing.originalAcquisitionLoan,
        interestExpense: originalLoanMonth?.interest,
        principalRepayment: originalLoanMonth?.principal,
        outstandingLoanBalance: originalLoanMonth?.endingBalance,
        totalDebtService: originalLoanMonth?.interest + originalLoanMonth?.principal,
      },
    }

    if (this.refinancing && this.month >= this.refinanceMonth) {
      if (this.refinanceMonth === this.month) {
        this.financing.originalAcquisitionLoan = {
          ...this.financing.originalAcquisitionLoan,
          loanBalanceRepayment: -1 * originalLoanMonth?.endingBalance,
          prepaymentPenalty: -1 * this.acquisitionBridgeLoanAssumptions.prepaymentPenalty,
        }
        this.financing.refinanceLoan = {
          ...this.financing.refinanceLoan,
          proceeds: this.refinanceLoanTotal,
          loanFees: this.refinancingPermanentLoanAssumptions.loanFees,
          interestExpense: 0,
          principalRepayment: 0,
          totalDebtService: 0,
          outstandingLoanBalance: this.refinanceLoanTotal,
          loanBalanceRepayment: 0,
        }
      } else {
        this.financing.originalAcquisitionLoan = {
          ...this.financing.originalAcquisitionLoan,
          proceeds: 0,
          loanFees: 0,
          interestExpense: 0,
          principalRepayment: 0,
          totalDebtService: 0,
          loanBalanceRepayment: 0,
          prepaymentPenalty: 0,
        }
        const refinanceMonth = this.refinancingLoanSchedule[this.month - this.refinanceMonth];
        if (refinanceMonth) {
          this.financing.refinanceLoan = {
            proceeds: 0,
            loanFees: 0,
            interestExpense: refinanceMonth.interest,
            principalRepayment: refinanceMonth.principal,
            totalDebtService: refinanceMonth.interest + refinanceMonth.principal,
            outstandingLoanBalance: refinanceMonth.endingBalance,
            loanBalanceRepayment: 0,
          }
        }
      }

      const { grossSaleProceeds, dispositionFee, saleClosingCosts, loanRepaymentAtSale, netSaleProceeds } = this.exitDispositionSummary;
      if (exitMonth > 0 && this.month >= exitMonth) {
        if (this.month === exitMonth) {
          this.financing.disposition = {
            grossSaleProceeds,
            dispositionFee,
            saleClosingCosts,
            loanRepaymentAtSale,
            netProceedsFromSale: netSaleProceeds,
          }
          this.financing.refinanceLoan = {
            ...this.financing.refinanceLoan,
            loanBalanceRepayment: -1 * this.financing.refinanceLoan.outstandingLoanBalance,
          };
        } else {
          this.financing.refinanceLoan = {
            ...this.financing.refinanceLoan,
            proceeds: 0,
            loanFees: 0,
            interestExpense: 0,
            principalRepayment: 0,
            totalDebtService: 0,
            loanBalanceRepayment: 0,
          }
        }
      }
    }

    if (!this.refinancing && this.month >= exitMonth) {
      if (this.month === exitMonth) {
        this.financing.originalAcquisitionLoan = {
          ...this.financing.originalAcquisitionLoan,
          loanBalanceRepayment: -1 *this.financing.originalAcquisitionLoan.outstandingLoanBalance,
        }

        this.financing.disposition = {
          grossSaleProceeds: this.exitDispositionSummary.grossSaleProceeds,
          dispositionFee: this.exitDispositionSummary.dispositionFee,
          saleClosingCosts: this.exitDispositionSummary.saleClosingCosts,
          loanRepaymentAtSale: this.exitDispositionSummary.loanRepaymentAtSale,
          netProceedsFromSale: this.exitDispositionSummary.netSaleProceeds,
        }
      } else {
        this.financing.originalAcquisitionLoan = {
          ...this.financing.originalAcquisitionLoan,
          proceeds: 0,
          loanFees: 0,
          interestExpense: 0,
          principalRepayment: 0,
          totalDebtService: 0,
          loanBalanceRepayment: 0,
          prepaymentPenalty: 0,
        }
      }
    }

    if ((this.month <= this.investmentTimeline.exitMonth) || this.investmentTimeline.exitMonth === 0) {
      this.netCashFlowAfterDebtService = this.netOperatingIncome - this.assetManagementFee - this.financing.originalAcquisitionLoan.totalDebtService - this.financing.refinanceLoan.totalDebtService;
      this.financing.dscr = this.netOperatingIncome / (this.financing.originalAcquisitionLoan.totalDebtService + this.financing.refinanceLoan.totalDebtService);
    } else {
      this.netCashFlowAfterDebtService = 0;
      this.financing.dscr = 0;
    }

    if (this.month === 0) {
      const {
        purchasePrice,
        acquisitionFee,
        transferTaxes,
        dueDilligence,
        legalFees,
        capExRenovation,
        reserves,
        otherClosingCosts,
        reTaxReserves,
        insuranceReserves,
      } = this.acquisitionCosts;
      let acquisitionCosts = purchasePrice + acquisitionFee + transferTaxes + dueDilligence + legalFees + capExRenovation + reserves + otherClosingCosts + reTaxReserves + insuranceReserves;
      this.leveredCashFlow = acquisitionCosts + this.originalLoanTotal + (-1 * this.acquisitionBridgeLoanAssumptions.loanFees);
      this.unleveredCashFlow = -1 * ((Math.abs(acquisitionCosts) - Math.abs(reTaxReserves) - Math.abs(insuranceReserves) - (this.acquisitionBridgeLoanAssumptions.debtServiceReservesPandIRelase === "yes" ?  Math.abs(this.acquisitionBridgeLoanAssumptions.annualDebtService) : 0)))
    } else if (this.month > 0 && this.month !== this.refinanceMonth && (this.month < this.investmentTimeline.exitMonth || this.investmentTimeline.exitMonth === 0)) {
      this.unleveredCashFlow = this.netOperatingIncome - this.assetManagementFee;
      this.leveredCashFlow = this.netCashFlowAfterDebtService
    } else if (this.month === this.investmentTimeline.exitMonth) {
      this.unleveredCashFlow = this.exitDispositionSummary.grossSaleProceeds - this.exitDispositionSummary.dispositionFee - this.exitDispositionSummary.saleClosingCosts + this.netOperatingIncome - this.assetManagementFee;
      if (this.month === this.refinanceMonth) {
        this.leveredCashFlow = this.netOperatingIncome + (-1 * this.assetManagementFee) + this.financing.originalAcquisitionLoan.proceeds +
          (-1 * this.financing.originalAcquisitionLoan.loanFees) + (-1 * this.financing.originalAcquisitionLoan.totalDebtService) +
          (-1 * this.financing.originalAcquisitionLoan.outstandingLoanBalance) + (-1 * this.acquisitionBridgeLoanAssumptions.prepaymentPenalty) +
          this.financing.refinanceLoan.proceeds + (-1 * this.financing.refinanceLoan.loanFees) + (-1 * this.financing.refinanceLoan.totalDebtService) +
          (this.financing.refinanceLoan.loanBalanceRepayment) + this.exitDispositionSummary.grossSaleProceeds +
          (-1 * this.exitDispositionSummary.dispositionFee) + (-1 * this.exitDispositionSummary.saleClosingCosts);
      } else {
        this.leveredCashFlow = this.exitDispositionSummary.netSaleProceeds + this.netCashFlowAfterDebtService;
      }
    } else if (this.month === this.refinanceMonth) {
      this.unleveredCashFlow = this.netOperatingIncome - this.assetManagementFee;
      this.leveredCashFlow = this.financing.originalAcquisitionLoan.loanBalanceRepayment + this.financing.refinanceLoan.proceeds - this.financing.refinanceLoan.loanFees + this.netCashFlowAfterDebtService;
    } else {
      this.unleveredCashFlow = 0;
    }

    let leveredCashFlow = 0
    if (this.month === this.reservesTaxes.releaseMonths && this.reservesTaxes.release) {
      leveredCashFlow += this.reservesTaxes.yearOneMonthlyAmount;
    }

    if (this.month === this.reservesInsurance.releaseMonths && this.reservesInsurance.release) {
      leveredCashFlow += this.reservesInsurance.yearOneMonthlyAmount;
    }

    if (this.month === this.acquisitionBridgeLoanAssumptions.debtServiceReservesPandIRelaseTiming && this.acquisitionBridgeLoanAssumptions.debtServiceReservesPandIRelase === "yes") {
      leveredCashFlow += this.acquisitionBridgeLoanAssumptions.debtServiceReservesPandITotal;
    }

    if (leveredCashFlow !== 0) {
      this.leveredCashFlow = leveredCashFlow + this.netCashFlowAfterDebtService;
    }
  }

  private _getGrowthAssumptionPercentage = (field: string, year?: number): number => {
    let yearKey = `year${year || this.year}`;
    //@ts-ignore
    return this.growthAssumptions.filter(a => a.name === field)[0][yearKey] / 100;
  }

  private _calculateYearlyOtherIncomeGrowth(items: string[]): void {
    items.forEach(item => {
      let startValue = this._getOtherItemMonthlyProformaAmount(item);
      let growthYears: number[] = [];
      [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].forEach((year, index) => {
        const growth = this._getGrowthAssumptionPercentage("otherIncome", year);
        if (index === 0) {
          growthYears.push(startValue * (1 + growth));
        } else {
          growthYears.push(growthYears[index - 1] * (1 + growth));
        }
      });

      //@ts-ignore
      this.otherIncomeGrowthYears[item] = growthYears;
    });
  };


  private _calculateYearlyExpenseGrowth(items: string[]): void {
    items.forEach(item => {
      let startValue = this._getExpenseItemMonthlyProformaAmount(item);
      let growthYears: number[] = [];
      [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].forEach((year, index) => {
        const growth = this._getGrowthAssumptionPercentage("expenses", year);
        if (index === 0) {
          growthYears.push(startValue * (1 + growth));
        } else {
          growthYears.push(growthYears[index - 1] * (1 + growth));
        }
      });

      //@ts-ignore
      this.expenseGrowthYears[item] = growthYears;
    });
  }

  private _calculateYearlyTaxGrowth(): void {
    let startValue = this._getExpenseItemMonthlyProformaAmount("realEstateTaxes");
    let growthYears: number[] = [];
    [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].forEach((year, index) => {
      const growth = this._getGrowthAssumptionPercentage("realEstateTaxes", year);
      if (index === 0) {
        growthYears.push(startValue * (1 + growth));
      } else {
        growthYears.push(growthYears[index - 1] * (1 + growth));
      }
    });

    //@ts-ignore
    this.expenseGrowthYears.realEstateTaxes = growthYears;
  }
}
