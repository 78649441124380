import { Card, Col, Tabs } from "antd";
import { useEffect, useState } from "react";
import {
  getStoreLoan,
  getStoreProperty,
  useAppSelector,
} from "../../../../store";
import { NetCashflowWaterfallDistribution } from "../../../../types/monthly-proforma/net-cashflow-waterfall-distribution";
import Sources from "./sources";
import Uses from "./uses";

const { TabPane } = Tabs;

export interface ISourcesAndUsesAcquisitionCost {
  description: string;
  percentage?: number;
  total: number;
  perUnit: number;
}

const SourcesAndUses = () => {
  const {
    loan: {
      acquisitionCosts: {
        purchasePrice,
        acquisitionFeePercentage,
        acquisitionFee,
        transferTaxesPercentage,
        transferTaxes,
        dueDilligence,
        legalFees,
        reserves,
        otherClosingCostsPercentage,
        otherClosingCosts,
      },
      loanAssumptions: { debtServiceReservesPandITotal, loanFeesPercentage },
      loanTotal,
      equitySplit,
    },
  } = useAppSelector((state) => state.loanReducer);
  const {
    property: {
      totalUnits,
      reservesTaxes,
      reservesInsurance,
      renovations: { totalBudget },
    },
  } = useAppSelector((state) => state.propertyReducer);
  const [acquisitionCosts, setAcquisitionCosts] = useState<
    ISourcesAndUsesAcquisitionCost[]
  >([
    {
      description: "Purchase Price",
      total: purchasePrice,
      perUnit: purchasePrice / totalUnits,
    },
    {
      description: "Acquisition Fee",
      percentage: acquisitionFeePercentage,
      total: acquisitionFee,
      perUnit: acquisitionFee / totalUnits,
    },
    {
      description: "Transfer Taxes",
      percentage: transferTaxesPercentage,
      total: transferTaxes,
      perUnit: transferTaxes / totalUnits,
    },
    {
      description: "Loan Fees",
      total: loanTotal * (loanFeesPercentage / 100),
      perUnit: (loanTotal * (loanFeesPercentage / 100)) / totalUnits,
    },
    {
      description: "Due Diligence",
      total: dueDilligence,
      perUnit: dueDilligence / totalUnits,
    },
    {
      description: "Legal Fees",
      total: legalFees,
      perUnit: legalFees / totalUnits,
    },
    {
      description: "CapEx/Renovation",
      total: totalBudget,
      perUnit: totalBudget / totalUnits,
    },
    {
      description: "Reserves",
      total: reserves,
      perUnit: reserves / totalUnits,
    },
    {
      description: "Other Closing Costs",
      percentage: otherClosingCostsPercentage,
      total: otherClosingCosts,
      perUnit: otherClosingCosts / totalUnits,
    },
    {
      description: "Debt Service Reserves (P&I)",
      total: debtServiceReservesPandITotal,
      perUnit: debtServiceReservesPandITotal / totalUnits,
    },
    {
      description: "RE Tax Reserves",
      total: reservesTaxes.yearOneMonthlyAmount * reservesTaxes.months,
      perUnit:
        (reservesTaxes.yearOneMonthlyAmount * reservesTaxes.months) /
        totalUnits,
    },
    {
      description: "Insurance Reserves",
      total: reservesInsurance.yearOneMonthlyAmount * reservesInsurance.months,
      perUnit:
        (reservesInsurance.yearOneMonthlyAmount * reservesInsurance.months) /
        totalUnits,
    },
  ]);
  const [totalUses, setTotalUses] = useState<number>(0);

  useEffect(() => {
    const { operatingCashFlowShortfall } = new NetCashflowWaterfallDistribution(
      getStoreProperty(),
      getStoreLoan()
    );
    let acquisitionCost: ISourcesAndUsesAcquisitionCost = {
      description: "Operating Cash Flow Shortfall (TBD)",
      total: operatingCashFlowShortfall,
      perUnit: operatingCashFlowShortfall / totalUnits,
    };

    setAcquisitionCosts((acquisitionCosts) => [
      ...acquisitionCosts,
      acquisitionCost,
    ]);

    setTotalUses(
      acquisitionCosts
        .map((x) => x.total)
        .reduce((prev, curr) => prev + curr, 0) + acquisitionCost.total
    );
  }, []);

  return (
    <>
      <Col
        xs={24}
        sm={24}
        md={24}
        lg={24}
        xl={12}
        xxl={10}
        style={{ paddingLeft: 0, marginTop: -15 }}
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab="Sources" key={1}>
            <Sources
              totalUses={totalUses}
              totalUnits={totalUnits}
              loanTotal={loanTotal}
              equitySplit={equitySplit}
              capitalNeededToClose={totalUses - loanTotal}
            />
          </TabPane>
          <TabPane tab="Uses" key={2}>
            <Uses
              acquisitionCosts={acquisitionCosts.concat(
                {
                  description: "Total Uses",
                  total: totalUses,
                  perUnit: totalUses / totalUnits,
                },
                {
                  description: "Total Capital Needed to Close",
                  total: totalUses - loanTotal,
                  perUnit: (totalUses - loanTotal) / totalUnits,
                }
              )}
            />
          </TabPane>
        </Tabs>
      </Col>
    </>
  );
};

export default SourcesAndUses;
