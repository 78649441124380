import { Card, Col, Tabs } from "antd";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { IIncomeExpenseItem } from "../../../../types/property";
import EgiNoiTable from "./egi-noi-table";
import ProformaExpenseReconciliationExpenseTable from "./expenses";
import ProformaExpenseReconciliationOtherIncomeTable from "./other-income";
import ProformaExpenseReconciliationRentIncomeTable from "./rent-income";
const { TabPane } = Tabs;

interface IAllItems {
  rentIncomeItems: IIncomeExpenseItem[];
  otherIncomeItems: IIncomeExpenseItem[];
  expenseItems: IIncomeExpenseItem[];
  grossPotentialRent: number;
  netRentalIncome: number;
  totalOtherIncome: number;
  expenseTotals: number;
  assetManagementFee: number;
}

const ProformaExpenseReconciliation = () => {
  const dispatch = useAppDispatch();
  const { property: { totalUnits, rentIncomeItems, otherIncomeItems, expenseItems, grossPotentialRent } } = useAppSelector(state => state.propertyReducer);
  const [allItems, setAllItems] = useState<IAllItems>({
    rentIncomeItems,
    otherIncomeItems,
    expenseItems,
    grossPotentialRent,
    netRentalIncome: rentIncomeItems[rentIncomeItems.length - 1].amount,
    totalOtherIncome: otherIncomeItems[otherIncomeItems.length - 1].amount,
    expenseTotals: expenseItems[expenseItems.length - 1].amount,
    assetManagementFee: expenseItems.filter(e => e.description === "Asset Management Fee")[0].amount,
  });

  const handleItemsChanged = (items: IIncomeExpenseItem[], type: "rentIncomeItems" | "otherIncomeItems" | "expenseItems") => {
    const currentItems = { ...allItems };
    currentItems[type] = items;
    switch (type) {
      case "rentIncomeItems":
        currentItems.grossPotentialRent = items[0].amount;
        currentItems.netRentalIncome = items[items.length - 1].amount;
        break;
      case "otherIncomeItems":
        currentItems.totalOtherIncome = items[items.length - 1].amount;
        break;
      case "expenseItems":
        currentItems.expenseTotals = items[items.length - 1].amount;
        currentItems.assetManagementFee = items.filter(e => e.description === "Asset Management Fee")[0].amount;
        break;
      default:
        break;
    }
    setAllItems(currentItems);
  };

  useEffect(() => {
    const { rentIncomeItems, otherIncomeItems, expenseItems, grossPotentialRent } = allItems;
    dispatch({
      type: "UPDATE_PROPERTY_INCOME_EXPENSE_ITEMS", payload: {
        rentIncomeItems,
        otherIncomeItems,
        expenseItems,
        grossPotentialRent,
      }
    });
  }, [allItems]);

  return (
    <>
      <Col xs={24} sm={24} md={24} lg={16} xl={16} xxl={12} style={{ paddingLeft: 0, marginTop: -15 }}>
        <Tabs defaultActiveKey="1">
          <TabPane tab="Rent Income" key={1}>
            <ProformaExpenseReconciliationRentIncomeTable
              currentItems={allItems.rentIncomeItems}
              totalUnits={totalUnits}
              grossPotentialRent={grossPotentialRent}
              onItemsChanged={items => handleItemsChanged(items, "rentIncomeItems")}
            />
          </TabPane>
          <TabPane tab="Other Income" key={2}>
            <ProformaExpenseReconciliationOtherIncomeTable
              currentItems={allItems.otherIncomeItems}
              totalUnits={totalUnits}
              grossPotentialRent={allItems.grossPotentialRent}
              onItemsChanged={items => handleItemsChanged(items, "otherIncomeItems")}
            />
          </TabPane>
          <TabPane tab="Expenses" key={3}>
            <ProformaExpenseReconciliationExpenseTable
              currentItems={allItems.expenseItems}
              totalUnits={totalUnits}
              grossPotentialRent={allItems.grossPotentialRent}
              onItemsChanged={items => handleItemsChanged(items, "expenseItems")}
            />
          </TabPane>
        </Tabs>
        <EgiNoiTable
          grossPotentialRent={allItems.grossPotentialRent}
          netRentalIncome={allItems.netRentalIncome}
          totalOtherIncome={allItems.totalOtherIncome}
          expenseTotals={allItems.expenseTotals}
          assetManagementFee={allItems.assetManagementFee}
          totalUnits={totalUnits}
        />
      </Col>
    </>
  )

}
export default ProformaExpenseReconciliation;