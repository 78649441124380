import {
  Form,
  Input,
  Row,
  Col,
  Radio,
  RadioChangeEvent,
  Table,
  Tooltip,
  Card,
} from "antd";
import Column from "antd/lib/table/Column";
import { useState } from "react";
import { useAppSelector, useAppDispatch } from "../../../../store";
import { IRentRollUnit, RentRollUnit } from "../../../../types/rent-roll-unit";
import ButtonColor from "antd-button-color";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import Button from "antd-button-color";

const initialFormValues = {
  unitType: null,
  unitCount: null,
  unitSqFt: null,
  rent: null,
  marketRent: null,
  renovatedMarketRent: null,
  renovatedUnits: null,
  renovationsExpected: false,
  scheduledRenovatedUnits: null,
  renovationsPerMonth: null,
  renovationStartMonth: null,
  renovationDownTime: null,
};

const Units = () => {
  const { property } = useAppSelector((state) => state.propertyReducer);
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [units, setUnits] = useState<IRentRollUnit[]>(
    property.rentRollUnitMix || []
  );
  const [renovationsExpected, setRenovationsExpected] = useState<boolean>();
  const [editingUnit, setEditingUnit] = useState<boolean>(false);
  const [showForm, setShowForm] = useState<boolean>(false);
  const [editingUnitIndex, setEditingUnitIndex] = useState<number>(-1);

  const handleViewUnit = (record: any, index: number) => {
    setRenovationsExpected(record.unitsScheduledForRenovation > 0);
    setEditingUnit(true);
    setEditingUnitIndex(index);
    setShowForm(true);
    form.setFieldsValue({
      unitType: record.type,
      unitCount: record.unitCount,
      unitSqFt: record.squareFtPerUnit,
      rent: record.rent,
      marketRent: record.marketRent,
      renovatedMarketRent: record.renovatedMarketRent,
      renovatedUnits: record.renovatedUnits,
      renovationsExpected: record.unitsScheduledForRenovation > 0,
      scheduledRenovatedUnits: record.unitsScheduledForRenovation,
      renovationsPerMonth: record.renovationsStartedPerMonth,
      renovationStartMonth: record.renovationStartMonth,
      renovationDownTime: record.renovationDownTime,
    });
  };

  const handleDeleteUnit = (unitIndex: number) => {
    const updatedUnits = units.filter((_, index) => index !== unitIndex);
    setUnits(updatedUnits);
    dispatch({
      type: "UPDATE_PROPERTY_RENT_ROLL_UNIT_MIX",
      payload: updatedUnits,
    });
  };

  const handleRenovationsExpectedChange = (event: RadioChangeEvent) => {
    const {
      target: { value },
    } = event;
    setRenovationsExpected(value);
  };

  const submitForm = () => {
    const formValues = form.getFieldsValue(true);
    const newUnit = new RentRollUnit(
      {
        type: formValues.unitType,
        unitCount: parseFloat(formValues.unitCount),
        squareFtPerUnit: parseFloat(formValues.unitSqFt),
        rent: parseFloat(formValues.rent),
        marketRent: parseFloat(formValues.marketRent),
        renovatedUnits: parseFloat(formValues.renovatedUnits) || 0,
        renovatedMarketRent: parseFloat(formValues.renovatedMarketRent) || 0,
        unitsScheduledForRenovation:
          parseFloat(formValues.scheduledRenovatedUnits) || 0,
        renovationsStartedPerMonth:
          parseFloat(formValues.renovationsPerMonth) || 0,
        renovationStartMonth: parseFloat(formValues.renovationStartMonth) || 0,
        renovationDownTime: parseFloat(formValues.renovationDownTime) || 0,
      },
      property.getGrowthAssumption("grossPotentialRent")
    );

    if (editingUnit) {
      const updatedUnits = units.slice(0);
      updatedUnits[editingUnitIndex] = newUnit;
      setUnits(updatedUnits);
      dispatch({
        type: "UPDATE_PROPERTY_RENT_ROLL_UNIT_MIX",
        payload: updatedUnits,
      });
    } else {
      setUnits((units) => [...units, newUnit]);
      dispatch({
        type: "UPDATE_PROPERTY_RENT_ROLL_UNIT_MIX",
        payload: [...units, newUnit],
      });
    }
    form.resetFields();
    setShowForm(false);
    setRenovationsExpected(false);
    setEditingUnit(false);
    setEditingUnitIndex(-1);
  };

  const unitColumns = [
    { title: "Type", key: "type", dataIndex: "type" },
    { title: "Total Units", key: "unitCount", dataIndex: "unitCount" },
    {
      title: "Renovations",
      render: (_: any, record: any, index: number) => {
        const { unitsScheduledForRenovation } = units[index];
        return <span>{unitsScheduledForRenovation > 0 ? "Yes" : "No"}</span>;
      },
    },
    {
      title: "Sq. Ft./Unit",
      render: (_: any, record: any, index: number) => (
        <span>{units[index].squareFtPerUnit.toLocaleString()}</span>
      ),
    },
    {
      title: "Total Sq. Ft.",
      render: (_: any, record: any, index: number) => (
        <span>
          {(
            units[index].squareFtPerUnit * units[index].unitCount
          ).toLocaleString()}
        </span>
      ),
    },
    {
      title: "Rent",
      render: (_: any, record: any, index: number) => (
        <div style={{ textAlign: "end" }}>
          <span>
            ${parseFloat(units[index].rent.toFixed(2)).toLocaleString()}
          </span>
        </div>
      ),
    },
    {
      title: "Est. Year 1 GPR",
      render: (_: any, record: any, index: number) => (
        <div style={{ textAlign: "end" }}>
          <span>
            $
            {parseFloat(
              units[index].getEstimatedAnnualGPRByYear(1).toFixed(2)
            ).toLocaleString()}
          </span>
        </div>
      ),
    },
    {
      title: "Actions",
      render: (_: any, record: any, index: number) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Tooltip title="Edit">
            <ButtonColor
              onClick={() => handleViewUnit(record, index)}
              type="primary"
              style={{ marginLeft: 10 }}
              icon={<EditOutlined />}
            />
          </Tooltip>
          <Tooltip title="Delete">
            <ButtonColor
              onClick={() => handleDeleteUnit(index)}
              disabled={editingUnit}
              type="primary"
              danger
              style={{ marginLeft: 10 }}
              icon={<DeleteOutlined />}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <>
      <Col
        xs={24}
        sm={24}
        md={24}
        lg={24}
        xl={20}
        xxl={16}
        style={{ paddingLeft: 0 }}
      >
          <Table
            scroll={{ x: true }}
            columns={unitColumns}
            dataSource={units}
            pagination={false}
            style={{ marginBottom: 10, whiteSpace: "nowrap" }}
            bordered
            footer={() => (
              <div style={{ display: showForm || editingUnit ? 'none' : "flex", justifyContent: "end" }}>
                <Button type="success" onClick={() => setShowForm(true)}>
                  Add Unit Type
                </Button>
              </div>
            )}
          >
            <Column title="actions" />
          </Table>
        <Card
          className="box-shadow"
          style={{ display: showForm ? "inherit" : "none" }}
        >
          <Form form={form} initialValues={initialFormValues} layout="vertical">
            <Row gutter={24}>
              <Col xs={24} md={8} xl={6}>
                <Form.Item
                  name="unitType"
                  label="Unit Type"
                  rules={[{ required: true }]}
                >
                  <Input placeholder="Unit type" type="text" required />
                </Form.Item>
              </Col>
              <Col xs={24} md={8} xl={6}>
                <Form.Item name="unitCount" label="Unit Count" required>
                  <Input
                    placeholder="Unit count"
                    type="number"
                    min={0}
                    required
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={8} xl={6}>
                <Form.Item name="unitSqFt" label="Unit Sq. Ft" required>
                  <Input
                    placeholder="Unit square feet"
                    type="number"
                    min={1}
                    step={1}
                    required
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={8} xl={6}>
                <Form.Item name="rent" label="Rent" required>
                  <Input
                    placeholder="Rent"
                    type="number"
                    min={0}
                    step={0.01}
                    required
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={8} xl={6}>
                <Form.Item name="marketRent" label="Market Rent" required>
                  <Input
                    placeholder="Market rent"
                    type="number"
                    min={0}
                    step={0.01}
                    required
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={8} xl={6}>
                <Form.Item
                  name="renovatedMarketRent"
                  label="Renovated Market Rent"
                  required={renovationsExpected}
                >
                  <Input
                    placeholder="Renovated market rent"
                    type="number"
                    min={0}
                    step={0.01}
                    required={renovationsExpected}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={8} xl={6}>
                <Form.Item
                  name="renovatedUnits"
                  label="Renovated Units"
                  required={renovationsExpected}
                >
                  <Input
                    placeholder="Renovated units"
                    type="number"
                    min={0}
                    step={1}
                    required={renovationsExpected}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={8}>
                <Form.Item
                  name="renovationsExpected"
                  label="Renovations Expected?"
                >
                  <Radio.Group onChange={handleRenovationsExpectedChange}>
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24} md={8} xl={6}>
                <Form.Item
                  name="scheduledRenovatedUnits"
                  label="Scheduled Renovated Units"
                  required={renovationsExpected}
                >
                  <Input
                    placeholder="Scheduled renovated units"
                    type="number"
                    min={1}
                    step={1}
                    disabled={!renovationsExpected}
                    required={renovationsExpected}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={8} xl={6}>
                <Form.Item
                  name="renovationsPerMonth"
                  label="Renovations Per Month"
                  required={renovationsExpected}
                >
                  <Input
                    placeholder="Renovations per month"
                    type="number"
                    min={1}
                    step={1}
                    disabled={!renovationsExpected}
                    required={renovationsExpected}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={8} xl={6}>
                <Form.Item
                  name="renovationStartMonth"
                  label="Renovation Start Month"
                  required={renovationsExpected}
                >
                  <Input
                    placeholder="Renovation start month"
                    type="number"
                    min={1}
                    step={1}
                    disabled={!renovationsExpected}
                    required={renovationsExpected}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={8} xl={6}>
                <Form.Item
                  name="renovationDownTime"
                  label="Renovations Down Time Months"
                  required={renovationsExpected}
                >
                  <Input
                    placeholder="Renovation down time months"
                    type="number"
                    min={1}
                    step={1}
                    disabled={!renovationsExpected}
                    required={renovationsExpected}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item className="form-footer" shouldUpdate>
              {({ getFieldsValue }) => {
                const {
                  unitType,
                  unitCount,
                  unitSqFt,
                  rent,
                  marketRent,
                  renovatedMarketRent,
                  renovatedUnits,
                  renovationsExpected,
                  scheduledRenovatedUnits,
                  renovationsPerMonth,
                  renovationStartMonth,
                  renovationDownTime,
                } = getFieldsValue();
                const unitFieldsValid =
                  unitType && unitCount && unitSqFt && rent && marketRent;
                const renovationFieldsValid = renovationsExpected
                  ? parseFloat(scheduledRenovatedUnits) > 0 &&
                    parseFloat(renovationsPerMonth) > 0 &&
                    parseFloat(renovationStartMonth) >= 0 &&
                    parseFloat(renovationDownTime) >= 0 &&
                    parseFloat(renovatedMarketRent) >= 0 &&
                    parseFloat(renovatedUnits) >= 0 &&
                    parseFloat(renovationDownTime) >= 0
                  : true;
                const valid =
                  unitFieldsValid &&
                  (renovationsExpected ? renovationFieldsValid : true);
                return (
                  <>
                    <Button
                      htmlType="submit"
                      type="success"
                      disabled={!valid}
                      onClick={() => {
                        submitForm();
                      }}
                    >
                      {editingUnit ? "Update " : "Create "}
                    </Button>
                    <Button
                      type="lightdark"
                      onClick={() => {
                        form.resetFields();
                      }}
                      style={{ marginLeft: "15px" }}
                    >
                      Clear
                    </Button>
                    <Button
                      type="dark"
                      onClick={() => {
                        form.resetFields();
                        setEditingUnit(false);
                        setShowForm(false);
                      }}
                      style={{ marginLeft: "15px" }}
                    >
                      Cancel
                    </Button>
                  </>
                );
              }}
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </>
  );
};

export default Units;
